import { searchEmployees } from '~/shared/api/request/employee';
import { QueriesTypes } from '~/shared/enums/queries';
import { Employee } from '~/shared/models/api/employee';
import { PartialEmployeeFilters } from '~/shared/models/search/filters/employees';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useEmployeesSearch = (filters?: PartialEmployeeFilters) =>
  useQuery<PaginatedResponse<Employee>, AxiosError>({
    queryKey: [QueriesTypes.SEARCH_EMPLOYEES, { ...filters }],
    queryFn: searchEmployees,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!filters,
  });
