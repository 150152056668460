import SkillDeleteConfirmationModal from '../confirmation/SkillDeleteConfirmationModal';
import SkillMergeConfirmationModal from '../confirmation/SkillMergeConfirmationModal';
import SkillEditModalForm from './forms/SkillEditModalForm';
import SkillMergeModalForm from './forms/SkillMergeModalForm';
import {
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import useConfirmationModal from '~/shared/hooks/useConfirmationModal';
import { Skill, UpdateSkill } from '~/shared/models/api/skills';
import React, { FC, useState } from 'react';

export interface SkillEditModalProps {
  skillUpdate?: UpdateSkill;
  isUpdating: boolean;
  isMerging: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  update: (formValues: Partial<UpdateSkill>) => void;
  onDelete: () => void;
  merge: (skillToMerge: Skill) => void;
}

const SkillEditModal: FC<SkillEditModalProps> = ({
  skillUpdate,
  isUpdating,
  isMerging,
  isOpen,
  onClose,
  onDelete,
  update,
  merge,
}) => {
  const {
    isOpen: isConfirmationModalOpen,
    openConfirmation,
    closeConfirmation,
  } = useConfirmationModal();
  const {
    isOpen: isDeleteConfirmationModalOpen,
    openConfirmation: openDeleteConfirmation,
    closeConfirmation: closeDeleteConfirmation,
  } = useConfirmationModal();

  const [skillToMerge, setSkillToMerge] = useState<Skill>();

  if (!skillUpdate) {
    return null;
  }

  const handleMergeConfirm = () => {
    closeConfirmation();
    if (!skillToMerge) {
      return;
    }
    merge(skillToMerge);
  };

  const handleMerge = (skill: Skill) => {
    setSkillToMerge(skill);
    openConfirmation();
  };

  return (
    <>
      {skillUpdate && skillToMerge && (
        <SkillMergeConfirmationModal
          origin={skillUpdate}
          final={skillToMerge}
          isOpen={isConfirmationModalOpen}
          closeConfirmation={closeConfirmation}
          onConfirm={handleMergeConfirm}
        />
      )}
      {skillUpdate && (
        <SkillDeleteConfirmationModal
          skill={skillUpdate}
          isOpen={isDeleteConfirmationModalOpen}
          closeConfirmation={closeDeleteConfirmation}
          onConfirm={() => {
            closeDeleteConfirmation();
            onDelete();
          }}
        />
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{skillUpdate.name}</ModalHeader>
          <ModalCloseButton />
          <SkillEditModalForm
            skillUpdate={skillUpdate}
            onUpdate={update}
            onDelete={openDeleteConfirmation}
            isUpdating={isUpdating}
          />
          <Divider my={8} />
          <SkillMergeModalForm
            selectedSkill={skillUpdate}
            merge={handleMerge}
            isUpdating={isMerging}
            onClose={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default SkillEditModal;
