import 'react-quill/dist/quill.snow.css';
import React from 'react';
import ReactQuill from 'react-quill';

interface ReadOnlyRichTextProps {
  value: string;
}

const ReadOnlyRichText = ({ value }: ReadOnlyRichTextProps) => {
  return <ReactQuill theme="bubble" value={value} readOnly />;
};

export default ReadOnlyRichText;
