import { Modal, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react';
import React from 'react';

interface LoadingModalProps {
  isOpen: boolean;
}

const LoadingModal: React.FC<LoadingModalProps> = ({ isOpen }) => (
  <Modal
    blockScrollOnMount
    isOpen={isOpen}
    onClose={() => ({})}
    size="lg"
    isCentered
  >
    <ModalOverlay bg="blackAlpha.400" />
    <ModalContent
      backgroundColor="transparent"
      shadow="none"
      alignItems="center"
    >
      <Spinner size="xl" label="loading" />
    </ModalContent>
  </Modal>
);

export default LoadingModal;
