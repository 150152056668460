import SearchProfileCardSkills from './SearchProfileCardSkills';
import {
  Badge,
  Box,
  Card,
  CardBody,
  CardFooter,
  Grid,
  GridItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import useEmployeeMatchingSkills from '~/search/hooks/useEmployeeMatchingSkills';
import CompanyLogo from '~/shared/components/CompanyLogo';
import EmployeeAvatar from '~/shared/components/EmployeeAvatar';
import EmployeeLink from '~/shared/components/EmployeeLink';
import { locationNameToKey } from '~/shared/mappers/locationNameToKey';
import { Employee } from '~/shared/models/api/employee';
import { PartialEmployeeFilters } from '~/shared/models/search/filters/employees';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SearchProfileCardProps {
  employee: Employee;
  filters: PartialEmployeeFilters;
}

const SearchProfileCard: React.FC<SearchProfileCardProps> = ({
  employee: {
    id,
    first_name,
    second_name,
    email,
    image_url,
    job_title,
    office,
    country,
    main_skills,
    seniority,
    company,
  },
  filters,
}) => {
  const { t: tBase } = useTranslation('base');
  const { t: tSearch } = useTranslation('search');
  const { matchingSkills, otherSkills } = useEmployeeMatchingSkills(
    main_skills,
    filters.skills
  );

  return (
    <Card
      employeeId={id}
      data-testid="test-search-main-card-profile"
      as={EmployeeLink}
      p="1"
      borderWidth="1px"
      borderColor="gray.200"
      rounded="md"
      bg="gray.50"
      shadow="none"
      _hover={{ shadow: 'md' }}
      minH="44"
      position="relative"
    >
      <CardBody border="none" p={2}>
        <Box className="hello">
          {matchingSkills && matchingSkills.length > 0 && (
            <Badge
              colorScheme="green"
              fontSize="xs"
              position="absolute"
              textTransform="capitalize"
              top={0}
              right={0}
            >
              {tSearch('number_matching_skills', {
                matches: matchingSkills.length,
              })}
            </Badge>
          )}
        </Box>
        <Grid templateColumns="repeat(5, 1fr)" gap={2}>
          <GridItem rowSpan={1} colSpan={1} mt="4">
            <EmployeeAvatar
              name={`${first_name} ${second_name}`}
              imagePath={image_url}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <Stack direction="row">
              <Text fontSize="md" fontWeight="bold" mt="2">
                {`${first_name} ${second_name}`}
              </Text>
            </Stack>
            <Text fontSize="small">{email}</Text>
            <Box display="inline-block">
              <Text fontSize="xs" color="gray.700" marginRight={1}>
                {job_title}
              </Text>
              {seniority && (
                <Text fontSize="xs" color="gray.600">
                  ({seniority})
                </Text>
              )}
            </Box>
          </GridItem>
          <GridItem colSpan={2} ml="auto" width="20">
            {company && (
              <CompanyLogo path={company.logo_url} name={company.name} mt="6" />
            )}
            <Box mt="1" fontSize="small" textAlign="right">
              <Text>{tBase(`locations.${locationNameToKey(office)}`)}</Text>
              <Text>{country}</Text>
            </Box>
          </GridItem>
        </Grid>
      </CardBody>
      <CardFooter flexDirection="column" p={2}>
        <SearchProfileCardSkills
          matchingSkills={matchingSkills}
          otherSkills={otherSkills}
        />
      </CardFooter>
    </Card>
  );
};

export default SearchProfileCard;
