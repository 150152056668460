import { useToast } from '@chakra-ui/react';
import {
  createCertificate as create,
  deleteCertificate as deleteCert,
  updateCertificate as update,
} from '~/shared/api/request/certificates';
import { getErrorMessage } from '~/shared/errors/error';
import { ApiErrorResponse } from '~/shared/models/api/axios';
import {
  Certificate,
  CreateCertificate,
  DeleteCertificate,
  UpdateCertificate,
} from '~/shared/models/api/certificate';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

interface UseCertificatesType {
  createCertificate: UseMutationResult<
    Certificate,
    unknown,
    CreateCertificate,
    unknown
  >;
  updateCertificate: UseMutationResult<
    Certificate,
    unknown,
    UpdateCertificate,
    unknown
  >;
  deleteCertificate: UseMutationResult<
    Certificate,
    unknown,
    DeleteCertificate,
    unknown
  >;
}

const useCertificates = (): UseCertificatesType => {
  const toast = useToast();
  const { t } = useTranslation('permissions');

  const createCertificate = useMutation(
    (newCertificate: CreateCertificate) => create({ ...newCertificate }),
    {
      onSuccess: (updatedCertificate: Certificate) => {
        toast({
          title: updatedCertificate.name,
          description: 'Certificate Created Successfully',
          status: 'success',
        });
      },
      onError: (
        err: ApiErrorResponse,
        certificateCreate: CreateCertificate
      ) => {
        const errorMsg =
          err.response?.data.detail ||
          'Something Went Wrong Creating Certificate';
        toast({
          title: `Error Creating Certificate ${certificateCreate.name}`,
          description: getErrorMessage(err.response?.status, errorMsg, t),
          status: 'error',
        });
      },
    }
  );

  const updateCertificate = useMutation(
    (certificateUpdate: UpdateCertificate) => update({ ...certificateUpdate }),
    {
      onSuccess: (updatedCertificate: Certificate) => {
        toast({
          title: updatedCertificate.name,
          description: 'Certificate Updated Successfully',
          status: 'success',
        });
      },
      onError: (
        err: ApiErrorResponse,
        certificateUpdate: UpdateCertificate
      ) => {
        toast({
          title: certificateUpdate.name,
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Updating Certificate',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  const deleteCertificate = useMutation(
    (certificateDelete: DeleteCertificate) => deleteCert(certificateDelete.id),
    {
      onSuccess: (updatedCertificate: Certificate) => {
        toast({
          title: updatedCertificate.name,
          description: 'Certificate Deleted Successfully',
          status: 'success',
        });
      },
      onError: (
        err: ApiErrorResponse,
        certificateDelete: DeleteCertificate
      ) => {
        toast({
          title: certificateDelete.name,
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Deleting Certificate',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return {
    createCertificate,
    updateCertificate,
    deleteCertificate,
  };
};

export default useCertificates;
