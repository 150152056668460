import { SocialNetworks } from '~/shared/enums/socialNetworks';
import { SocialNetwork } from '~/shared/models/api/social';

export const OTHER_LINK: SocialNetwork = {
  base: 'https://',
  slug: SocialNetworks.Other,
  icon: 'other.svg',
  name: 'Other',
};

export const SOCIAL_LINKS: SocialNetwork[] = [
  {
    base: 'https://github.com/',
    slug: SocialNetworks.Github,
    icon: 'github.svg',
    name: 'Github',
  },
  {
    base: 'https://linkedin.com/in/',
    slug: SocialNetworks.LinkedIn,
    icon: 'linkedin.svg',
    name: 'LinkedIn',
  },
  {
    base: 'https://xing.com/profile/',
    slug: SocialNetworks.Xing,
    icon: 'xing.svg',
    name: 'Xing',
  },
  OTHER_LINK,
];
