import {
  ClientMappingModal,
  ModalType,
} from '../components/ClientMappingModal';
import SearchResponseContent from '../components/SearchResponseContent';
import { Box } from '@chakra-ui/react';
import LoadingData from '~/shared/components/ui/LoadingData';
import { CompleteTable } from '~/shared/components/ui/table/CompleteTable';
import { ColumnData, TableAction } from '~/shared/components/ui/table/types';
import { initialFilters } from '~/shared/constants/search';
import { Permission } from '~/shared/enums/permissions';
import useSearchFilters from '~/shared/hooks/filters/useSearchFilters';
import useRouteGuard from '~/shared/hooks/permissions/useRouteGuard';
import { Client } from '~/shared/models/api/Client';
import { ClientSearchFilters } from '~/shared/models/search/filters/clients';
import { OrderDirection } from '~/shared/models/search/filters/search';
import { useClientsSearch } from '~/shared/queries/useClientsSearch';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ClosedModal {
  isOpen: false;
}

interface OpenModal {
  type: ModalType;
  isOpen: true;
  data: Client[];
}

type ModalState = ClosedModal | OpenModal;

const useModalState = () => {
  const [modalProps, setModalProps] = useState<ModalState>({
    isOpen: false,
  });

  const openModal = (type: ModalType, data: Client[]) =>
    setModalProps({ type, isOpen: true, data });
  const closeModal = () => setModalProps({ isOpen: false });

  return { openModal, closeModal, ...modalProps };
};

export const ClientMapping = () => {
  useRouteGuard([Permission.CLIENT_MAPPING]);

  const { filters, onPageChange, onFilterChange } =
    useSearchFilters<ClientSearchFilters>({
      ...initialFilters,
      hasMap: false,
    });

  const modal = useModalState();

  const { isFetching, error, data } = useClientsSearch(filters);

  const { t } = useTranslation('clients');

  const actions: TableAction[] = useMemo(
    () =>
      data
        ? [
            {
              label: t('actions.map'),
              action: (clients) =>
                modal.openModal(
                  ModalType.SetMap,
                  data.page_results.filter((c) =>
                    clients.includes(c.id.toString())
                  )
                ),
              isVisible: (clients) =>
                clients.length > 0 &&
                clients.every(
                  (c) =>
                    !data.page_results.find(
                      (client) => client.id.toString() === c
                    )?.mappedName
                ),
            },
            {
              label: t('actions.remove'),
              action: (clients) =>
                modal.openModal(
                  ModalType.RemoveMap,
                  data.page_results.filter((c) =>
                    clients.includes(c.id.toString())
                  )
                ),
              isVisible: (clients) =>
                clients.length > 0 &&
                clients.every(
                  (c) =>
                    data.page_results.find(
                      (client) => client.id.toString() === c
                    )?.mappedName
                ),
            },
          ]
        : [],
    [data]
  );

  if (!data) {
    return (
      <>
        <LoadingData></LoadingData>
      </>
    );
  }

  const headers: ColumnData<Client>[] = [
    { id: 'name', label: t('name') },
    { id: 'mappedName', label: t('mappedName') },
  ];

  const sortableColumns = ['name', 'mappedName'];

  const onSort = (column: string, order: OrderDirection) => {
    onFilterChange({
      order,
      order_by: column as ClientSearchFilters['order_by'],
    });
  };

  const onSaveMap = (_mappedName: string | null) => {
    modal.closeModal();

    // TODO: update in the BE
  };

  return (
    <>
      <ClientMappingModal
        onSave={onSaveMap}
        onClose={modal.closeModal}
        isOpen={modal.isOpen}
        data={modal.isOpen ? modal.data : []}
        type={modal.isOpen ? modal.type : ModalType.SetMap}
      />
      <SearchResponseContent
        loading={isFetching}
        error={error}
        noResults={data.page_results.length === 0}
        paginationData={{
          filters,
          totalItems: data.total_results_count,
          onPageChange,
        }}
      >
        <Box overflowX="auto">
          <CompleteTable
            headers={headers}
            data={data.page_results}
            singleRowActions
            actions={actions}
            sortableColumns={sortableColumns}
            onSort={onSort}
            sorting={
              filters.order_by && filters.order
                ? { column: filters.order_by, order: filters.order }
                : undefined
            }
            hasCheckboxes
          />
        </Box>
      </SearchResponseContent>
    </>
  );
};
