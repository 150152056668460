import { useDisclosure, useToast, UseToastOptions } from '@chakra-ui/react';
import useProfileStore from '~/profile/stores/profile';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { EmployeeEducation } from '~/shared/models/api/employee';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface UseProfileEducationType {
  currentEducation?: EmployeeEducation;
  isOpen: boolean;
  deleteEducation: (education: EmployeeEducation) => void;
  upsertEducation: (education: EmployeeEducation) => void;
  onClose: () => void;
  openModal: (education?: EmployeeEducation | undefined) => void;
}

const useProfileEducationModal = (): UseProfileEducationType => {
  const toast = useToast();
  const {
    deleteEducation: deleteEmployeeEducation,
    updateEducation: updateEmployeeEducation,
    createEducation: createEmployeeEducation,
  } = useProfileStore();
  const { openConfirmation } = useConfirmationModalContext();
  const { startLoading, stopLoading } = useLoadingContext();
  const { t } = useTranslation('base');
  const [currentEducation, setCurrentEducation] = useState<EmployeeEducation>();
  const {
    isOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const openModal = (education: EmployeeEducation | undefined) => {
    setCurrentEducation(education);
    onModalOpen();
  };

  const onClose = () => {
    setCurrentEducation(undefined);
    onModalClose();
  };

  const deleteEducation = async (education: EmployeeEducation) => {
    const result = await openConfirmation({
      isDangerAction: true,
      titleText: _getEducationNameWithField(education),
      descriptionText: t('delete_confirmation'),
      confirmText: t('delete'),
    });
    if (!result) {
      return;
    }
    startLoading();
    const updateSuccess = await deleteEmployeeEducation(education);
    stopLoading();
    toast({
      title: 'Profile Education Delete',
      description: updateSuccess
        ? `${_getEducationNameWithField(
            education
          )} deleted from profile successfully`
        : 'Something went wrong',
      status: updateSuccess ? 'success' : 'error',
    });
  };

  const upsertEducation = async (education: EmployeeEducation) => {
    startLoading();
    if (currentEducation) {
      _updateEducation(education);
      return;
    }
    _createEducation(education);
  };

  const _updateEducation = async (education: EmployeeEducation) => {
    const updateSuccess = await updateEmployeeEducation(education);
    stopLoading();
    _finishUpsert(
      {
        title: 'Profile Education Update',
        description: `${_getEducationNameWithField(
          education
        )} updated from profile successfully`,
      },
      updateSuccess
    );
  };

  const _createEducation = async (education: EmployeeEducation) => {
    startLoading();
    const updateSuccess = await createEmployeeEducation(education);
    _finishUpsert(
      {
        title: 'Profile Education Create',
        description: `${_getEducationNameWithField(
          education
        )} added to profile successfully`,
      },
      updateSuccess
    );
  };

  const _finishUpsert = async (
    toastOptions: UseToastOptions,
    success: boolean
  ) => {
    stopLoading();
    toast({
      title: toastOptions.title,
      description: success ? toastOptions.description : 'Something went wrong',
      status: success ? 'success' : 'error',
    });
    onClose();
  };

  const _getEducationNameWithField = (education: EmployeeEducation): string =>
    `${education.degree_name} in ${education.education.study_field_name}`;

  return {
    currentEducation,
    deleteEducation,
    isOpen,
    onClose,
    openModal,
    upsertEducation,
  };
};

export default useProfileEducationModal;
