import { DegreeNameKey } from '~/shared/enums/degree';
import { Employee, EmployeeEducation } from '~/shared/models/api/employee';
import { formatDate } from '~/shared/utils/date';
import { randomId } from '~/tests/factories';
import { object, string } from 'yup';

export interface EducationAdd {
  education_id?: number;
  degree_name: string;
  institution_name: string;
  started: string;
  ended?: string;
  study_field_name: string;
  employee_id?: number;
}

export const educationSchema = object().shape({
  degree_name: string().required('Degree is required.'),
  institution_name: string().required('Institution is required.'),
  started: string().required('Start date is required.'),
  ended: string()
    .nullable()
    .when('start', (start, schema) =>
      schema.test({
        test: (endAt) => (endAt ? endAt >= (start as unknown as string) : true),
        message: 'The end date should be greater than the start date.',
      })
    ),
  study_field_name: string().required('Field of education is required.'),
});

export const defaultNewEducation = (
  profile: Employee | null
): EducationAdd => ({
  degree_name: '',
  institution_name: '',
  started: '',
  ended: '',
  employee_id: profile?.id,
  study_field_name: '',
});

export const mapToEducationAdd = (
  employeeEducation: EmployeeEducation
): EducationAdd => ({
  education_id: employeeEducation.education_id,
  degree_name: employeeEducation.degree_name,
  institution_name: employeeEducation.institution_name,
  started: employeeEducation.started,
  ended: employeeEducation.ended,
  study_field_name: employeeEducation.education.study_field_name,
  employee_id: employeeEducation.employee_id,
});

export const mapToEmployeeEducation = (
  employeeEducation: EducationAdd
): EmployeeEducation => ({
  education_id: employeeEducation.education_id || randomId(),
  institution_name: employeeEducation.institution_name,
  started: formatDate(employeeEducation.started),
  ended: employeeEducation.ended
    ? formatDate(employeeEducation.ended)
    : undefined,
  degree_name: employeeEducation?.degree_name as DegreeNameKey,
  education: {
    study_field_name: employeeEducation.study_field_name,
  },
  employee_id: employeeEducation.employee_id,
});
