import { SearchIcon } from '@chakra-ui/icons';
import { InputGroupProps } from '@chakra-ui/react';
import InputGroupWithClearable from '~/shared/components/ui/InputGroupWithClearable';
import { InputVariant } from '~/shared/enums/variant';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface SearchInputProps {
  query?: string;
  placeholder?: string;
  label?: string;
  variant?: InputVariant;
  onChange: (value: string) => void;
  onEnter?: () => void;
}

const SearchInput: FC<SearchInputProps & Omit<InputGroupProps, 'onChange'>> = ({
  query = '',
  placeholder,
  label,
  variant,
  onChange,
  onEnter,
  ...props
}) => {
  const { t } = useTranslation('base');

  return (
    <InputGroupWithClearable
      mb={8}
      w="auto"
      display="inline-block"
      value={query}
      placeholder={placeholder || t('search')}
      label={label}
      leftIcon={SearchIcon}
      variant={variant}
      {...props}
      onChange={onChange}
      onEnter={onEnter}
    />
  );
};

export default SearchInput;
