import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Grid, IconButton, Stack, Text, Tooltip } from '@chakra-ui/react';
import AlertInfo from '~/shared/components/ui/AlertInfo';
import { LanguageProficiency } from '~/shared/enums/language';
import { EmployeeLanguage } from '~/shared/models/api/employee';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface LanguagesEditListProps {
  languages: EmployeeLanguage[];
  onDelete: (languageIndex: number) => void;
}

const LanguagesEditList: React.FC<LanguagesEditListProps> = ({
  languages,
  onDelete,
}) => {
  const { t } = useTranslation('profile');

  const buildDeleteIconLabel = (languages: EmployeeLanguage) =>
    t(`about_me.languages.remove`, { language: languages.language?.name });

  return (
    <Stack>
      <Text fontSize="md" color="gray.600" fontWeight="bold">
        {t(`about_me.languages.current`)}
      </Text>
      {!languages.length ? (
        <AlertInfo text={t(`about_me.languages.please_add`)} />
      ) : (
        <Grid templateColumns="repeat(4, 1fr)" gap={2} mb={3}>
          {languages?.map((l, index) => (
            <Stack
              data-testid={`language-${index}`}
              key={l.language_id}
              borderWidth="1px"
              rounded="lg"
              bg="gray.50"
              p={4}
              direction="row"
              align="center"
              spacing="auto"
              _hover={{ bg: 'gray.100' }}
              borderColor="gray.100"
              borderRadius="4px"
              shadow="xs"
            >
              <Box>
                <Text
                  data-testid={`language-name-${index}`}
                  fontSize="sm"
                  fontWeight="bold"
                  color="gray.500"
                >
                  {l.language?.name}
                </Text>
                <Text
                  data-testid={`language-proficiency-${index}`}
                  fontSize="md"
                  fontWeight="bold"
                >
                  {t(
                    `language_proficiency.${LanguageProficiency[
                      l.language_level_id
                    ].toLowerCase()}`
                  )}
                </Text>
              </Box>
              <Box>
                <Tooltip label={buildDeleteIconLabel(l)}>
                  <IconButton
                    aria-label={buildDeleteIconLabel(l)}
                    icon={<DeleteIcon alignContent="center" />}
                    data-testid="language-remove-button"
                    variant="ghost"
                    boxSize={6}
                    onClick={() => onDelete(index)}
                    colorScheme="red"
                  />
                </Tooltip>
              </Box>
            </Stack>
          ))}
        </Grid>
      )}
    </Stack>
  );
};

export default LanguagesEditList;
