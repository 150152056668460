import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';

interface AdvancedFiltersElementsContainerProps {
  showAdvanced?: boolean;
}

const AdvancedFiltersElementsContainer: FC<
  PropsWithChildren<AdvancedFiltersElementsContainerProps & SimpleGridProps>
> = ({ showAdvanced = true, children, ...props }) => {
  return (
    <SimpleGrid
      display={showAdvanced ? 'grid' : 'none'}
      mb={8}
      spacing={4}
      columns={{ base: 1, sm: 2, md: 3, lg: 5 }}
      {...props}
    >
      {children}
    </SimpleGrid>
  );
};

export default AdvancedFiltersElementsContainer;
