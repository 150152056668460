import { useToast } from '@chakra-ui/react';
import { createSkill } from '~/shared/api/request/skills';
import { getErrorMessage } from '~/shared/errors/error';
import { CreateSkill, Skill } from '~/shared/models/api/skills';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

interface UseUpdateSkillType {
  createSkill: UseMutationResult<Skill, unknown, CreateSkill, unknown>;
}

const useSkillCreate = (): UseUpdateSkillType => {
  const toast = useToast();
  const { t } = useTranslation('permissions');

  const create = useMutation(
    (newSkill: CreateSkill) => createSkill({ ...newSkill }),
    {
      onSuccess: (updatedSkill: Skill) => {
        toast({
          title: updatedSkill.name,
          description: 'Skill Created Successfully',
          status: 'success',
        });
      },
      onError: (err: AxiosError, skillUpdate: CreateSkill) => {
        toast({
          title: skillUpdate.name || 'Error Creating Skill',
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Creating Skill',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return {
    createSkill: create,
  };
};

export default useSkillCreate;
