import SearchInput from './SearchInput';
import UserMenu from './UserMenu';
import { useMsal } from '@azure/msal-react';
import { HStack, Img, Link, Show } from '@chakra-ui/react';
import useProfileStore from '~/profile/stores/profile';
import { MainRoutesPaths } from '~/routes/enums/main';
import React from 'react';
import { Link as RouterDomLink } from 'react-router-dom';

const Nav = () => {
  const { myProfile, isLoadingMyProfile: isLoading } = useProfileStore();
  const { instance } = useMsal();

  const handleLogoutClick = async () => {
    instance.logoutRedirect();
  };

  return (
    <HStack
      align="center"
      backgroundColor="gray.200"
      boxShadow="md"
      justify="space-between"
      gap="2"
      minW="full"
      p="4"
    >
      <Link
        as={RouterDomLink}
        minW="16"
        sx={{
          '&:focus': {
            boxShadow: 'none',
          },
        }}
        to={MainRoutesPaths.DASHBOARD}
      >
        <Img src="/logo.svg" w="16" h="9" alt="KI Group Icon" />
      </Link>
      <Show above="sm">
        <SearchInput />
      </Show>
      <UserMenu
        isLoading={isLoading}
        handleLogoutClick={handleLogoutClick}
        loggedProfile={myProfile}
      />
    </HStack>
  );
};

export default Nav;
