export enum EndpointPaths {
  BASIC_INFO = 'basic-info',
  CERTIFICATES = 'certificates',
  CERTIFICATIONS = 'certifications',
  CHILDREN = 'children',
  DESCRIPTION = 'description',
  COMPANIES = 'companies',
  EMPLOYEES = 'employees',
  EXTERNAL_EXPERIENCES = 'experiences-outside-ki',
  EXPORT = 'export',
  FILTER_PARAMS = 'filter-params',
  INDUSTRIES = 'industries',
  INTERNAL_EXPERIENCES = 'ki-projects',
  LANGUAGES = 'languages',
  MEMBERS = 'members',
  ME = 'me',
  MERGE = 'merge',
  NOTIFY = 'notify',
  PERMISSIONS = 'permissions',
  PICTURE = 'picture',
  PROJECTS = 'projects',
  ROLES = 'roles',
  SEARCH = 'search',
  SKILLS = 'skills',
  SKILL_TYPES = 'skillTypes',
  PARENT = 'parent',
  PARENT_ID = 'parent_id',
  POTENTIAL_CHILDREN = 'potential-children',
  PROJECT_ALLOCATIONS = 'project-allocations',
  PROJECT_POSITIONS = 'project-positions',
  VERIFY = 'verify',
}
