import { Box, Tag } from '@chakra-ui/react';
import React from 'react';

interface ExperienceSkillsProps {
  skills: string[];
  skillsKey: string;
}

export const ExperienceSkills = ({
  skills,
  skillsKey,
}: ExperienceSkillsProps) => {
  return (
    <Box maxW="container.lg" ml={4}>
      {skills?.map((skill) => (
        <Tag
          key={`${skillsKey}-${skill.trim()}`}
          colorScheme="green"
          mr={2}
          mb={2}
          borderRadius={25}
          whiteSpace="nowrap"
        >
          {skill}
        </Tag>
      ))}
    </Box>
  );
};
