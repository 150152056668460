import { Box, Button, Stack, Text, Tooltip } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { languageSchema } from '~/profile/forms/about';
import {
  defaultNewLanguage,
  LanguageAdd,
  LanguageFormType,
} from '~/profile/forms/languages';
import FormControl from '~/shared/components/form/FormControl';
import LanguageProficiencySelect from '~/shared/components/select/LanguageProficiencySelect';
import LanguageSelect from '~/shared/components/select/LanguageSelect';
import { EmployeeLanguage } from '~/shared/models/api/employee';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface LanguagesFormProps {
  languages: EmployeeLanguage[];
  onAdded: (languages: LanguageAdd) => void;
}

const LanguagesForm: React.FC<LanguagesFormProps> = ({
  languages,
  onAdded,
}) => {
  const { t } = useTranslation('profile');
  const methods = useForm({
    resolver: yupResolver(languageSchema),
    defaultValues: defaultNewLanguage(),
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = methods;

  const onSubmit: SubmitHandler<LanguageFormType> = ({
    language,
    language_level_id,
  }) => {
    if (!language || !language_level_id) {
      return;
    }
    onAdded({ language_level_id, language });

    reset();
  };

  return (
    <Stack spacing="2">
      <Text fontSize="md" color="gray.600" fontWeight="bold">
        {t('about_me.languages.add')}
      </Text>
      <Stack direction="row">
        <FormProvider {...methods}>
          <FormControl id="language" control={control} error={errors.language}>
            <LanguageSelect
              placeholder={t('about_me.languages.placeholder')}
              selectedLanguages={languages.map((l) => l.language)}
            />
          </FormControl>
          <FormControl
            id="language_level_id"
            control={control}
            error={errors.language_level_id}
          >
            <LanguageProficiencySelect
              placeholder={t('about_me.languages.proficiency_placeholder')}
            />
          </FormControl>
        </FormProvider>
        <Box>
          <Tooltip label="Add New Language">
            <Button
              data-testid="form-language-add-button"
              colorScheme="yellow"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
      </Stack>
    </Stack>
  );
};

export default LanguagesForm;
