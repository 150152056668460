import {
  Box,
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useProjectEditModal from '~/administration/hooks/projects/useProjectEditModal';
import FormControlInput from '~/shared/components/form/FormControlInput';
import FormControlRichText from '~/shared/components/form/FormControlRichText';
import IndustriesSelect from '~/shared/components/IndustrySelect';
import { CustomTag } from '~/shared/components/ui/CustomTag';
import ModalButton from '~/shared/components/ui/ModalButton';
import { Industry } from '~/shared/models/api/industry';
import { Project } from '~/shared/models/api/project';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { boolean, object, string } from 'yup';

const projectEditModalSchema = object().shape({
  sms_title: string().required(),
  description: string().required(),
  industry: object().required(),
  hide_name: boolean(),
});

const ProjectEditModalBox = (props: PropsWithChildren<BoxProps>) => {
  const { children, ...rest } = props;
  return (
    <Box mb={4} {...rest}>
      {children}
    </Box>
  );
};

interface Props {
  isOpen: boolean;
  project?: Project;
  onClose: () => void;
  onChanged: () => void;
}

const EditModal: FC<Props> = ({ isOpen, onClose, onChanged, project }) => {
  const { t } = useTranslation('projects');
  const form = useForm({ resolver: yupResolver(projectEditModalSchema) });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const { isUpdating, onSubmit } = useProjectEditModal({
    onChanged,
    onClose: handleClose,
  });

  useEffect(() => {
    if (project) {
      form.reset(project);
    }
  }, [project, form.reset]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...form}>
          <Stack direction="column">
            <ModalHeader data-testid="modal-industries-header" mt={2} mb="0">
              <CustomTag colorScheme="blue">{t(`edit_modal.badge`)}</CustomTag>
              <Heading fontSize="2xl" fontWeight="bold" mt="2">
                {t(`edit_modal.title`)}
              </Heading>
              <Heading fontSize="md" mt={3} fontWeight={800} color="gray.500">
                {project?.title}
              </Heading>
            </ModalHeader>
          </Stack>
          <ModalCloseButton />
          <ModalBody>
            <ProjectEditModalBox>
              <FormControlInput
                id="sms_title"
                name={t('headers.project_alias')}
                placeholder=""
                register={form.register}
                error={form.formState.errors.sms_title}
              />
            </ProjectEditModalBox>
            <ProjectEditModalBox>
              <FormControl
                id="industry"
                isInvalid={Boolean(form.formState.errors.industry)}
              >
                <FormLabel>{t('headers.industry')}</FormLabel>
                <IndustriesSelect
                  id="industry-select"
                  selected={project?.industry}
                  isCreatable
                  onChange={(industry: Industry | null) =>
                    form.setValue('industry', industry)
                  }
                />
                <FormErrorMessage data-testid="test-form-error-message">
                  {!!form.formState.errors &&
                    form.formState.errors.industry?.message &&
                    t(`edit_modal.required_industry_message`)}
                </FormErrorMessage>
              </FormControl>
            </ProjectEditModalBox>
            <ProjectEditModalBox>
              <FormControlRichText
                id="description"
                name={t('headers.description')}
                value={form.watch('description')}
                register={form.register}
                setValue={form.setValue}
                placeholder=""
                error={form.formState.errors.description}
              />
            </ProjectEditModalBox>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <ModalButton
              data-testid="modal-industries-close-button"
              type="button"
              onClick={handleClose}
            >
              {t(`edit_modal.cancel`)}
            </ModalButton>
            <ModalButton
              colorScheme="blue"
              type="submit"
              variant="solid"
              onClick={form.handleSubmit(onSubmit)}
              disabled={!form.formState.isDirty}
              isUpdating={isUpdating}
            >
              {t(`edit_modal.update`)}
            </ModalButton>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
export default EditModal;
