import { DateFormat } from '~/shared/enums/date';
import { formatDate } from '~/shared/utils/date';
import { t } from 'i18next';

type BuildHeadingProps = {
  title: string;
  role?: string;
  company?: string;
};

export const buildHeading = ({ title, company, role }: BuildHeadingProps) => {
  const heading = role ? `${title} - ${role}` : `${title}`;
  if (company) {
    return `${heading} @${company}`;
  }

  return heading;
};

export const buildStartEndDateText = (
  startDate: string,
  endDate: string | null | undefined,
  endDateReplacementI18nKey?: string,
  format: DateFormat = DateFormat.YEAR_MONTH
) =>
  endDate
    ? `${formatDate(startDate, format)} - ${formatDate(endDate, format)}`
    : `${formatDate(startDate, format)}${
        endDateReplacementI18nKey ? ` - ${t(endDateReplacementI18nKey)}` : ''
      }`;
