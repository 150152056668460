import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { SearchableFilter } from '~/search/components/SearchableFilter';
import { SkillFilter } from '~/search/components/SkillFilter';
import { useEmployeeSearchContext } from '~/search/EmployeeSearchContext';
import useEmployeeFilterParams from '~/search/hooks/useEmployeeFilterParams';
import SearchInput from '~/shared/components/SearchInput';
import LoadingData from '~/shared/components/ui/LoadingData';
import { filterTabs, initialFilters } from '~/shared/constants/search';
import { SearchFilterTab } from '~/shared/enums/searchFilterTab';
import { PartialEmployeeFilters } from '~/shared/models/search/filters/employees';
import {
  isSearchableFilter,
  parseSearchFilterTabValues,
} from '~/shared/utils/filterUtils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SearchFilter = () => {
  const { t } = useTranslation('base');
  const { filters: activeFilters, onFiltersChange } =
    useEmployeeSearchContext();
  const { filterParams, loaded } = useEmployeeFilterParams();

  const [filters, setFilters] = useState<PartialEmployeeFilters>(activeFilters);

  const patchFilters = (patch: PartialEmployeeFilters) =>
    setFilters({
      ...filters,
      ...patch,
    });

  const handleCheckboxGroupChange = (tab: SearchFilterTab, values: string[]) =>
    patchFilters({ [tab]: parseSearchFilterTabValues(tab, values) });

  const handleResetFilters = () => {
    setFilters(initialFilters);
    onFiltersChange(initialFilters);
  };

  return !loaded || filterParams === null ? (
    <Box w="xs">
      <LoadingData></LoadingData>
    </Box>
  ) : (
    <Box>
      <Stack gap="2" position="sticky" top={10}>
        <Box>
          <SearchInput
            placeholder={t(`search`)}
            label={t(`filters.filter_by_name`)}
            query={filters.query}
            onChange={(query) => patchFilters({ query })}
            onEnter={() => onFiltersChange(filters)}
            w="full"
          />
        </Box>
        <Accordion w="xs" allowToggle defaultIndex={[0]}>
          {filterTabs.map((tab) => {
            const filterValue = filters[tab] || [];

            return (
              <CheckboxGroup
                key={tab}
                onChange={(checks) =>
                  handleCheckboxGroupChange(tab, checks as string[])
                }
                value={filterValue}
              >
                <AccordionItem>
                  <AccordionButton textTransform="capitalize">
                    <Box>
                      {tab}
                      {filterValue.length > 0 && (
                        <Badge ml="1" colorScheme="green">
                          {filterValue.length}
                        </Badge>
                      )}
                    </Box>
                    <AccordionIcon ml="auto" />
                  </AccordionButton>

                  <AccordionPanel>
                    {tab === 'skills' ? (
                      <SkillFilter
                        filter={filterParams[tab]}
                        filterValue={filterValue}
                      />
                    ) : isSearchableFilter(tab) ? (
                      <SearchableFilter
                        dataType={tab}
                        filter={filterParams[tab]}
                        filterValue={filterValue}
                      />
                    ) : (
                      <Stack>
                        {filterParams[tab].map(({ id, name }) => {
                          return (
                            <Checkbox key={`${tab}.${id}`} value={id}>
                              {name}
                            </Checkbox>
                          );
                        })}
                      </Stack>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </CheckboxGroup>
            );
          })}
        </Accordion>
        <Box>
          <Checkbox
            isChecked={filters.show_internal_resources ?? false}
            onChange={(e) =>
              patchFilters({ show_internal_resources: !!e.target.checked })
            }
          >
            {t(`filters.include_internal_resources`)}
          </Checkbox>
        </Box>
        <HStack gap="2" justify="end">
          <Button onClick={handleResetFilters}>{t(`filters.reset`)}</Button>
          <Button onClick={() => onFiltersChange(filters)}>
            {t(`search`)}
          </Button>
        </HStack>
      </Stack>
    </Box>
  );
};

export default SearchFilter;
