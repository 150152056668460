import { LanguageProficiency } from '~/shared/enums/language';
import { EmployeeLanguage } from '~/shared/models/api/employee';
import { Language } from '~/shared/models/api/language';

export interface LanguageAdd {
  language_id?: number;
  language: Language;
  language_level_id: LanguageProficiency;
  employee_id?: number;
}

export type LanguageFormType = {
  language?: Language;
  language_level_id: LanguageProficiency | null;
};

export const defaultNewLanguage = (): LanguageFormType => ({
  language: undefined,
  language_level_id: null,
});

export const mapLanguage = (
  employeeLanguage: LanguageAdd
): EmployeeLanguage => ({
  language_id: employeeLanguage.language.id,
  language_level_id: employeeLanguage.language_level_id,
  employee_id: employeeLanguage.employee_id,
  language: employeeLanguage.language,
});
