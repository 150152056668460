import { EndpointItem, EndpointUrl } from '~/shared/interfaces/endpoints';

// TODO: avoid many string joins by converting path in an array
// of type "EndpointPaths | string | number" and doing `const uri = path.join('/')` here
export const urlBuilder = (url: string): EndpointUrl => ({ url });

export const urlItemBuilder = <T>(url: string): EndpointItem<T> => ({
  url,
  id: (id) => urlBuilder(`${url}/${id}`),
});
