import { Badge, Box, Grid, GridItem, List, ListItem } from '@chakra-ui/react';
import { EmployeeSkill } from '~/shared/models/api/employee';
import { getSkillProficiencyAsSimbols } from '~/shared/utils/skills';
import React from 'react';

interface SkillsPanelProps {
  skills: EmployeeSkill[] | undefined;
}

const SkillsPanel: React.FC<SkillsPanelProps> = ({ skills }) => {
  if (!skills || !skills.length) return <></>;

  const columns = skills.reduce<Record<string, EmployeeSkill[]>>(
    (accumulator, skill) => {
      const { type } = skill.skill;
      const columnName = type || 'Pending';
      const sortedSkills = [...(accumulator[columnName] || []), skill].sort(
        (a, b) => {
          if (a.skill_level_id !== b.skill_level_id) {
            return b.skill_level_id - a.skill_level_id;
          }
          return a.skill.name.localeCompare(b.skill.name);
        }
      );
      return {
        ...accumulator,
        [columnName]: sortedSkills,
      };
    },
    {}
  );

  const sortedColumns = Object.keys(columns).sort((a, b) => {
    if (a === 'Pending') {
      return 1;
    }
    if (b === 'Pending') {
      return -1;
    }
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  });

  return (
    <Grid templateColumns="repeat(5, 1fr)" gap={3} textAlign="center">
      {sortedColumns.map((item) => {
        return (
          <GridItem
            key={item}
            backgroundColor="gray.50"
            padding="10px"
            rounded="xl"
          >
            <Box fontWeight="bold" fontSize="sm" textAlign="center" h="10">
              {item}
            </Box>
            <List spacing={1} mt="3">
              {columns[item].map(({ skill, skill_level_id }) => {
                return (
                  <ListItem key={skill.id}>
                    <Badge
                      colorScheme="facebook"
                      px={2}
                      textTransform="inherit"
                      rounded="lg"
                    >
                      {`${skill.name} ${getSkillProficiencyAsSimbols(
                        skill_level_id
                      )}`}
                    </Badge>
                  </ListItem>
                );
              })}
            </List>
          </GridItem>
        );
      })}
    </Grid>
  );
};

export default SkillsPanel;
