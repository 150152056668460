import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import {
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';

interface FormControlSelectProps<T extends FieldValues> {
  id: Path<T>;
  name?: string;
  register: UseFormRegister<T>;
  placeholder?: string;
  error?: FieldError;
  isHidden?: boolean;
  disabled?: true;
}

const FormControlSelect = <T extends FieldValues>({
  children,
  id,
  name,
  register,
  placeholder,
  error,
  isHidden,
  disabled,
}: PropsWithChildren<FormControlSelectProps<T>>) => {
  const { onChange, ...rest } = register(id);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    onChange({ target: { value, name: id } });
  };

  return (
    <FormControl id={id} isInvalid={Boolean(error)} mb="2" hidden={isHidden}>
      {!!name && <FormLabel>{name}</FormLabel>}
      <Select
        {...rest}
        onChange={handleChange}
        data-testid={`test-form-${id}`}
        disabled={disabled}
      >
        <option hidden disabled value="">
          {placeholder}
        </option>
        {children}
      </Select>
      <FormErrorMessage data-testid="test-form-error-message">
        {!!error && error.message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormControlSelect;
