import { getCompanyLogo } from '~/shared/api/request/company';
import { QueriesTypes } from '~/shared/enums/queries';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useLoadProfileCompanyLogo = (companyLogoPath?: string) =>
  useQuery<string, AxiosError>({
    queryKey: [QueriesTypes.GET_PROFILE_COMPANY_LOGO, companyLogoPath],
    queryFn: ({ queryKey }) => getCompanyLogo(queryKey[1] as string),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!companyLogoPath,
  });
