import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

interface ModalButtonProps {
  isUpdating?: boolean;
}

const ModalButton: React.FC<ButtonProps & ModalButtonProps> = (props) => {
  const { children, isUpdating, disabled, ...rest } = props;

  return (
    <Button w="90px" isDisabled={disabled} isLoading={isUpdating} {...rest}>
      {children}
    </Button>
  );
};

export default ModalButton;
