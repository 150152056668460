import { Avatar, AvatarProps, Icon } from '@chakra-ui/react';
import { useLoadAzureBlob } from '~/shared/queries/useLoadAzureBlob';
import React, { FC, useEffect } from 'react';
import { FaGripHorizontal } from 'react-icons/fa';
import { MdOutlineHideImage } from 'react-icons/md';

export interface LogoProps extends AvatarProps {
  logo?: string;
  imageUrl?: string;
  noImageIconSize?: number;
  onImageLoaded?: (image: string) => void;
}

const Logo: FC<LogoProps> = ({
  logo,
  imageUrl,
  onImageLoaded,
  noImageIconSize = 75,
  ...avatarProps
}) => {
  const { data: img, isLoading } = useLoadAzureBlob(
    imageUrl ? undefined : logo
  );

  useEffect(() => {
    if (img && onImageLoaded) {
      onImageLoaded(img);
    }
  }, [img]);

  return (
    <Avatar
      size="xl"
      variant="square"
      borderRadius={0}
      backgroundColor="transparent"
      color="lightGrey"
      src={imageUrl || img}
      icon={
        <Icon
          as={isLoading ? FaGripHorizontal : MdOutlineHideImage}
          boxSize={noImageIconSize}
          opacity=".5"
        />
      }
      {...avatarProps}
    />
  );
};

export default Logo;
