import SelectFiltersOrderBySelect from '~/shared/components/filters/SelectFiltersOrderBy';
import { OptionWithTranslation } from '~/shared/models/react-select/option-with-translation';
import {
  EmployeeOrderBy,
  PartialEmployeeFilters,
} from '~/shared/models/search/filters/employees';
import { TranslationTables } from '~/shared/models/translation/translation';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const verifyCertificatesOrderByOptions: OptionWithTranslation<EmployeeOrderBy>[] =
  [
    {
      value: EmployeeOrderBy.NAME,
      table: TranslationTables.BASE,
      key: 'name',
    },
    {
      value: EmployeeOrderBy.CERTIFICATIONS,
      table: TranslationTables.BASE,
      key: 'certifications',
    },
  ];

interface VerifyCertificatesFiltersOrderBySelectProps {
  filters?: PartialEmployeeFilters;
  onFilterChange: (partialFilters: PartialEmployeeFilters) => void;
}

const VerifyCertificatesFiltersOrderBySelect: FC<
  VerifyCertificatesFiltersOrderBySelectProps
> = ({ filters, onFilterChange }) => {
  const { t } = useTranslation('base');

  return (
    <SelectFiltersOrderBySelect<EmployeeOrderBy>
      filters={filters}
      options={verifyCertificatesOrderByOptions}
      isClearable={false}
      disableNone
      placeholder={t('order.order_by')}
      onFilterChange={onFilterChange}
    />
  );
};

export default VerifyCertificatesFiltersOrderBySelect;
