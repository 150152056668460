import { Flex, Heading } from '@chakra-ui/react';
import { ActionButtons } from '~/profile/components/shared/ActionButtons';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import React, { FC, PropsWithChildren } from 'react';

interface ProfileSectionHeaderProps {
  sectionTitle: string;
  onClickAdd?: () => void;
  onClickEdit?: () => void;
  headingDataTestId?: string;
}

export const ProfileSectionHeader: FC<
  PropsWithChildren<ProfileSectionHeaderProps>
> = ({
  sectionTitle,
  onClickAdd,
  onClickEdit,
  headingDataTestId,
  children,
}) => {
  const { canEditProfile } = useAccessPermissions();

  return (
    <Flex
      alignContent="center"
      alignItems="center"
      backgroundColor="whiteSmoke"
      borderColor="gray.200"
      borderRadius={25}
      borderWidth={1}
      justifyContent="space-between"
      my={4}
      py={1}
      pl={4}
      pr={2}
    >
      <Heading
        data-testid={headingDataTestId}
        color="gray.600"
        fontSize="lg"
        m="0"
      >
        {sectionTitle}
      </Heading>
      {children}
      <ActionButtons
        onClickAdd={onClickAdd}
        onClickEdit={onClickEdit}
        hasPermissions={canEditProfile}
        tooltipLabel={sectionTitle}
      />
    </Flex>
  );
};
