export enum ChildStatus {
  ADDED = 'added',
  REMOVED = 'removed',
  SAVED = 'saved',
}

export const ChildStatusColor = {
  [ChildStatus.ADDED]: 'green.100',
  [ChildStatus.REMOVED]: 'red.100',
  [ChildStatus.SAVED]: 'blue.100',
};

export const ChildStatusOrderValue = {
  [ChildStatus.ADDED]: 1,
  [ChildStatus.REMOVED]: 0,
  [ChildStatus.SAVED]: -1,
};
