import { InternalProjectSelectItem } from './InternalProjectSelectItem';
import { VStack } from '@chakra-ui/react';
import LoadingData from '~/shared/components/ui/LoadingData';
import { EmployeeProject } from '~/shared/models/api/project';
import React from 'react';

interface InternalProjectSelectItemsListProps {
  employeeProjects: EmployeeProject[];
  isLoading: boolean;
  onClick: (employeeProject: EmployeeProject | null) => void;
  selected: EmployeeProject | null;
}
export const InternalProjectSelectItemsList: React.FC<
  InternalProjectSelectItemsListProps
> = ({ employeeProjects, isLoading, onClick, selected }) => {
  if (isLoading) {
    return <LoadingData />;
  }

  return (
    <VStack align="start" spacing={1} h="218px">
      {employeeProjects.map((employeeProject) => (
        <InternalProjectSelectItem
          key={`internal-project-select-item-${employeeProject.project_id}`}
          employeeProject={employeeProject}
          isSelected={employeeProject.project_id === selected?.project_id}
          onClick={onClick}
        />
      ))}
    </VStack>
  );
};
