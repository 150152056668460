import { Center, Icon, IconProps, Tooltip } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { CiFileOn } from 'react-icons/ci';
import { TiInfoOutline } from 'react-icons/ti';

type MissingFileIconProps = {
  tooltip?: string;
  as?: IconType;
};

const MissingFileIcon: FC<MissingFileIconProps & IconProps> = ({
  tooltip,
  as,
  ...props
}) => {
  const { t } = useTranslation('base');

  return (
    <Tooltip label={tooltip || t('missing_file')}>
      <Center position="relative" ml={2} justifyContent="center">
        <Icon as={as || CiFileOn} boxSize={6} color="red" {...props} />
        <Icon
          as={TiInfoOutline}
          position="absolute"
          color="red"
          boxSize={2.5}
          mt={1}
        />
      </Center>
    </Tooltip>
  );
};

export default MissingFileIcon;
