import CertificateImageUploadModal from '../CertificateImageUploadModal';
import { Box, ModalFooter, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { CertificateModalMode } from '~/administration/hooks/certificates/useCertificateModal';
import FormControl from '~/shared/components/form/FormControl';
import FormControlCheckbox from '~/shared/components/form/FormControlCheckbox';
import FormControlInput from '~/shared/components/form/FormControlInput';
import ModalButton from '~/shared/components/ui/ModalButton';
import { URL_REGEX } from '~/shared/constants/regex';
import { Certificate } from '~/shared/models/api/certificate';
import { DisableState } from '~/shared/models/form/disable';
import React, { FC, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { boolean, mixed, object, string } from 'yup';

const certificateUpsertContentFormSchema = object().shape({
  name: string().required('Name is required.'),
  logo: string().nullable(),
  newLogo: mixed().nullable(),
  issuer: string().required('Issuer is required.'),
  unique_identifier: string().nullable(),
  certificate_url: string().nullable().matches(URL_REGEX, {
    message: 'Invalid url!',
    excludeEmptyString: true,
  }),
  permanent: boolean(),
});

interface CertificateUpsertContentProp {
  certificate?: Certificate;
  isUpdating: boolean;
  mode: CertificateModalMode;
  onCancel: () => void;
  onSubmit: (upsert: FieldValues) => void;
}

const CertificateUpsertContent: FC<CertificateUpsertContentProp> = ({
  certificate,
  mode,
  isUpdating,
  onCancel,
  onSubmit,
}) => {
  const [disabledState, setDisabledState] = useState<DisableState>({
    disabled: undefined,
  });
  const { t: tCertificates } = useTranslation('certificates');
  const { t: tBase } = useTranslation('base');
  const form = useForm({
    resolver: yupResolver(certificateUpsertContentFormSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = form;

  useEffect(() => {
    setDisabledState({
      disabled: isUpdating || undefined,
    });
  }, [isUpdating]);

  const permanent = watch('permanent');
  const logo = watch('logo');

  useEffect(() => {
    reset(certificate);
  }, [certificate, reset]);

  return (
    <>
      <FormProvider {...form}>
        <form id="certForm">
          <VStack spacing="3">
            <FormControl id="newLogo" control={control} error={errors.newLogo}>
              <Box
                p={5}
                borderRadius="md"
                border="1px solid transparent"
                {...(errors.newLogo
                  ? {
                      borderColor: '#E53E3E',
                      boxShadow: '0 0 0 1px #e53e3e',
                    }
                  : {})}
              >
                <CertificateImageUploadModal
                  id="certificate-image-upload"
                  logo={logo}
                  onChange={(image) => {
                    setValue('newLogo', image);
                  }}
                />
              </Box>
            </FormControl>
            <FormControlInput
              id="name"
              name={tCertificates('certificate.name')}
              register={register}
              placeholder="Certificate"
              error={errors.name}
              {...disabledState}
            />
            <FormControlInput
              id="issuer"
              name={tCertificates('certificate.issuer')}
              register={register}
              placeholder="Certificate Entity"
              error={errors.issuer}
              {...disabledState}
            />
            <FormControlInput
              id="unique_identifier"
              name={tCertificates('certificate.unique_identifier')}
              tooltip={tCertificates('certificate.unique_identifier_tooltip')}
              register={register}
              placeholder="1234-ABCD"
              error={errors.unique_identifier}
              {...disabledState}
            />
            <FormControlInput
              id="certificate_url"
              name={tCertificates('certificate.certification_url')}
              register={register}
              placeholder="https://..."
              error={errors.certificate_url}
              {...disabledState}
            />
            <FormControlCheckbox
              id="permanent"
              name={`${tCertificates('certificate.permanent_certification')}`}
              pt={1}
              register={register}
              checked={permanent}
              error={errors.permanent}
            />
          </VStack>
        </form>
      </FormProvider>

      <ModalFooter justifyContent="space-between" px={0} pb={2} mt={6}>
        <ModalButton type="button" onClick={onCancel} {...disabledState}>
          {tBase('cancel')}
        </ModalButton>
        <ModalButton
          colorScheme="blue"
          type="submit"
          variant="solid"
          onClick={handleSubmit(onSubmit)}
          isUpdating={isUpdating}
        >
          {mode === CertificateModalMode.UPDATE
            ? tBase('update')
            : tBase('create')}
        </ModalButton>
      </ModalFooter>
    </>
  );
};

export default CertificateUpsertContent;
