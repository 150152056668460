import CustomSelect, {
  CustomSelectBaseProps,
} from '~/shared/components/select/CustomSelect';
import useSkillCreate from '~/shared/hooks/skills/useSkillCreate';
import {
  mapDataListToOptions,
  mapDataToOption,
} from '~/shared/mappers/selectOption';
import { Skill } from '~/shared/models/api/skills';
import {
  SelectOption,
  SelectOptionMapperKeysType,
} from '~/shared/models/react-select/select-option';
import { useSkillsSearch } from '~/shared/queries/skills/useSkillsSearch';
import React, { FC, useEffect, useState } from 'react';

interface SkillSelectProps {
  selectedSkills?: Skill[];
  selectedSkill?: Skill;
  returnValue?: 'id' | 'name';
  onChange?: (skill?: Skill) => void;
}

const SkillSelect: FC<SkillSelectProps & CustomSelectBaseProps> = ({
  id = 'skill-select',
  selectedSkills,
  selectedSkill,
  returnValue,
  onChange,
  ...props
}) => {
  const [selectedOption, setSelectedOption] =
    useState<SelectOption<Skill> | null>(null);
  const [query, setQuery] = useState<string>();
  const { data: skills, isLoading } = useSkillsSearch(
    query ? { query } : undefined
  );
  const { createSkill } = useSkillCreate();
  const keys: SelectOptionMapperKeysType<Skill> = {
    valueKey: returnValue || 'id',
    labelKey: 'name',
  };

  useEffect(() => {
    if (!selectedSkill) {
      setSelectedOption(null);
      return;
    }
    setSelectedSkill(selectedSkill);
  }, [selectedSkill]);

  const setSelectedSkill = (skill: Skill) => {
    setSelectedOption(
      mapDataToOption({
        option: skill,
        ...keys,
      })
    );
  };

  const handleCreate = (name: string) => {
    createSkill.mutateAsync(
      { name },
      {
        onSuccess: (skill: Skill) => {
          setSelectedSkill(skill);
          onChange && onChange(skill);
        },
      }
    );
  };

  const buildOptions = (skills: Skill[] = []) =>
    mapDataListToOptions<Skill>({
      options: skills,
      ...keys,
    });

  return (
    <CustomSelect<Skill>
      id={id}
      isLoading={isLoading}
      selectedOption={selectedOption}
      options={buildOptions(skills)}
      selectedOptions={buildOptions(selectedSkills)}
      onQueryChange={setQuery}
      onCreate={handleCreate}
      onChange={onChange}
      onlyShowOptionsIfQuery
      {...props}
    />
  );
};

export default SkillSelect;
