import { Flex } from '@chakra-ui/react';
import ActionButton from '~/profile/components/shared/ActionButton';
import { ButtonTypes } from '~/shared/enums/actionButton';
import React from 'react';

export interface ActionButtonsProps {
  hasPermissions?: boolean;
  isLoadingAdd?: boolean;
  isLoadingEdit?: boolean;
  isLoadingDelete?: boolean;
  isLoadingMap?: boolean;
  onClickAdd?: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onClickMap?: () => void;
  tooltipLabel?: string;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  hasPermissions = true,
  isLoadingAdd = false,
  isLoadingEdit = false,
  isLoadingDelete = false,
  isLoadingMap = false,
  onClickAdd,
  onClickEdit,
  onClickDelete,
  onClickMap,
  tooltipLabel,
}) => {
  const actionButtons = [
    { onClick: onClickAdd, type: ButtonTypes.Add, isLoading: isLoadingAdd },
    {
      onClick: onClickDelete,
      type: ButtonTypes.Delete,
      isLoading: isLoadingDelete,
    },
    { onClick: onClickEdit, type: ButtonTypes.Edit, isLoading: isLoadingEdit },
    { onClick: onClickMap, type: ButtonTypes.Map, isLoading: isLoadingMap },
  ].filter((button) => button.onClick);

  return (
    <>
      {hasPermissions && (
        <Flex rounded={25} gap={1}>
          {actionButtons.map((button) => (
            <ActionButton
              key={button.type}
              type={button.type}
              onClick={button.onClick}
              hasPermission={hasPermissions}
              tooltipLabel={tooltipLabel}
              isLoading={button.isLoading}
            />
          ))}
        </Flex>
      )}
    </>
  );
};
