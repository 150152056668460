import { Box, Checkbox, Stack } from '@chakra-ui/react';
import SearchInput from '~/shared/components/SearchInput';
import { InputVariant } from '~/shared/enums/variant';
import { SingleFilterParameter } from '~/shared/models/api/employee';
import Fuse from 'fuse.js';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SkillFilterProps {
  filter: Record<string, SingleFilterParameter[]>;
  filterValue: (string | number)[];
}

// TODO use SearchableFilter
export const SkillFilter = ({ filter, filterValue }: SkillFilterProps) => {
  const { t } = useTranslation('base');
  const [skillFilter, setSkillFilter] = useState('');

  useEffect(() => {
    if (filterValue.length || !skillFilter.length) {
      return;
    }
    setSkillFilter('');
  }, [filterValue]);

  const fuseSearch = useMemo(() => {
    const skillArray = Object.keys(filter).reduce<string[]>(
      (acc, type) => acc.concat(filter[type].map((skill) => skill.name)),
      []
    );

    return new Fuse(skillArray, {
      shouldSort: false,
      threshold: 0.3,
    });
  }, []);

  const visibleSkills = useMemo<Map<string, SingleFilterParameter[]>>(() => {
    const mapResult = new Map<string, SingleFilterParameter[]>();
    const result = fuseSearch.search(skillFilter).map(({ item }) => item);
    const parsedFilterValue = filterValue.map((val) => Number(val));

    for (const type in filter) {
      for (const skill of filter[type]) {
        if (
          parsedFilterValue.includes(Number(skill.id)) ||
          result.includes(skill.name)
        ) {
          if (!mapResult.has(type)) {
            mapResult.set(type, []);
          }

          mapResult.get(type)?.push(skill);
        }
      }
    }

    return mapResult;
  }, [skillFilter, filterValue.join(',')]);

  return (
    <Stack gap="2">
      <Box>
        <SearchInput
          placeholder={t('search')}
          variant={InputVariant.FLUSHED}
          query={skillFilter}
          onChange={setSkillFilter}
          w="full"
          size="sm"
        />
      </Box>
      {Array.from(visibleSkills).map(([type, skills]) => (
        <Box key={`skills.${type}`}>
          <Box>{type}</Box>
          <Stack>
            {skills.map((filter) => (
              <Checkbox key={`skills.${type}.${filter.id}`} value={filter.id}>
                {filter.name}
              </Checkbox>
            ))}
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};
