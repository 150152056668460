import { useMissingAttributes } from '../hooks/useMissingAttributes';
import {
  Badge,
  Box,
  ListItem,
  Progress,
  Text,
  Tooltip,
  UnorderedList,
} from '@chakra-ui/react';
import { Employee } from '~/shared/models/api/employee';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ProfileFillingProps {
  profile: Employee;
}

export const ProfileFilling = ({ profile }: ProfileFillingProps) => {
  const { t } = useTranslation('profile');

  const { progressValue, color, missingAttributes } =
    useMissingAttributes(profile);

  return (
    <>
      <Badge colorScheme={color} borderRadius="xl" px="2" my="2">
        {t('filling.percentage', { percentage: progressValue })}
      </Badge>
      <Tooltip
        label={t('filling.percentage', { percentage: progressValue })}
        hasArrow
        placement="top"
        aria-label="Tooltip Profile Filing"
        mt="5"
      >
        <Progress
          colorScheme={color}
          size="sm"
          borderRadius="full"
          w="full"
          maxW="xl"
          my="2"
          value={progressValue}
          hasStripe
        />
      </Tooltip>
      {missingAttributes.length > 0 && (
        <Box>
          <Text>{t('filling.missing')}</Text>
          <UnorderedList>
            {missingAttributes.map((attribute) => (
              <ListItem key={attribute}>
                {t(`filling.attributes.${attribute}`)}
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
      )}
    </>
  );
};
