import {
  Badge,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import ModalButton from '~/shared/components/ui/ModalButton';
import useUpdateShortDescription from '~/shared/hooks/employee/useUpdateShortDescription';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { EmployeeShortDescriptionProps } from '~/shared/models/api/employee';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface EditModalProps extends EmployeeShortDescriptionProps {
  isOpen: boolean;
  employeeName: string;
  onClose: () => void;
  setShortDescription: (shortDescription: string) => void;
}
const EditModal: React.FC<EditModalProps> = ({
  isOpen,
  onClose,
  employeeId,
  employeeName,
  setShortDescription,
  shortDescription = '',
}) => {
  const { t } = useTranslation('profile');
  const { openConfirmation } = useConfirmationModalContext();
  const { startLoading, stopLoading } = useLoadingContext();
  const updateShortDescription = useUpdateShortDescription();

  const { handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: { shortDescription },
  });

  useEffect(() => {
    reset({ shortDescription });
  }, [shortDescription, reset]);

  const onSubmit = async (data: { shortDescription: string }) => {
    startLoading();

    try {
      await updateShortDescription.mutateAsync({
        employeeId,
        shortDescription: data.shortDescription,
      });

      setShortDescription(watch('shortDescription'));

      onClose();
    } finally {
      stopLoading();
    }
  };

  const onCloseModal = () => {
    const hasChanged = watch('shortDescription') !== shortDescription;

    if (hasChanged) {
      handleUnsavedChanges();
    } else {
      reset({ shortDescription });
      onClose();
    }
  };

  const handleUnsavedChanges = async () => {
    const result = await openConfirmation();

    if (result) {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <Stack direction="column">
          <ModalHeader mt="2" mb="0">
            <Badge colorScheme="blue">
              {t(`short_description.edit_modal.badge`)}
            </Badge>
            <Heading fontSize="2xl" fontWeight="bold" mt="2">
              {t(`short_description.edit_modal.title`, { employeeName })}
            </Heading>
            <ModalCloseButton />
          </ModalHeader>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Textarea
              value={watch('shortDescription')}
              onChange={(e) => setValue('shortDescription', e.target.value)}
              placeholder={t(`short_description.edit_modal.placeholder`, {
                employeeName,
              })}
              size="md"
            />
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <ModalButton type="button" onClick={onCloseModal}>
              {t(`short_description.edit_modal.close`)}
            </ModalButton>
            <ModalButton colorScheme="blue" type="submit" variant="solid">
              {t(`short_description.edit_modal.save`)}
            </ModalButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
