import { Container } from '@chakra-ui/react';
import ProfileComponent from '~/profile/components/ProfileComponent';
import AppLayout from '~/shared/components/ui/AppLayout';
import Content from '~/shared/components/ui/Content';
import { CustomAlert } from '~/shared/components/ui/CustomAlert';
import useProfile from '~/shared/profile/useProfile';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Profile: React.FC = () => {
  const { isLoading, profile } = useProfile();
  const { t } = useTranslation('profile');

  useEffect(() => {
    if (profile) {
      document.title = `${t(`tab_title`, {
        name: `${profile.first_name} ${profile.second_name}`,
      })}`;
    }
  }, [profile]);

  return (
    <AppLayout>
      <Container maxW="container.lg">
        <Content isLoading={isLoading}>
          {profile ? (
            <ProfileComponent />
          ) : (
            <CustomAlert
              data-testid="test-alert-profile-not-available"
              rounded={25}
              status="error"
              text={t(`not_available`)}
            />
          )}
        </Content>
      </Container>
    </AppLayout>
  );
};

export default Profile;
