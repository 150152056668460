import { useToast } from '@chakra-ui/react';
import { updateEmployeeShortDescription } from '~/shared/api/request/employee';
import {
  EmployeeShortDescriptionPayload,
  EmployeeShortDescriptionProps,
} from '~/shared/models/api/employee';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

type UseUpdateShortDescription = UseMutationResult<
  EmployeeShortDescriptionPayload,
  unknown,
  EmployeeShortDescriptionProps,
  unknown
>;

const useUpdateShortDescription = (): UseUpdateShortDescription => {
  const toast = useToast();
  const { t } = useTranslation('profile');

  const updateShortDescription = useMutation(
    (props: EmployeeShortDescriptionProps) =>
      updateEmployeeShortDescription(props),
    {
      onSuccess: () => {
        toast({
          title: t(`short_description.update.title`),
          description: t(`short_description.update.success`),
          status: 'success',
        });
      },
      onError: () => {
        toast({
          title: t(`short_description.update.title`),
          description: t(`short_description.update.fail`),
          status: 'error',
        });
      },
    }
  );

  return updateShortDescription;
};

export default useUpdateShortDescription;
