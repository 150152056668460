import SkillsFiltersViewModes from './view-modes/SkillsFiltersViewModes';
import { Flex } from '@chakra-ui/react';
import SearchInput from '~/shared/components/SearchInput';
import { GridViewModes } from '~/shared/enums/gridViewModes';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type SkillsFiltersProps = {
  query?: string;
  mode: GridViewModes;
  onQueryChange: (query: string) => void;
  onViewModeChange: (mode: GridViewModes) => void;
};

const SkillsFilters: FC<SkillsFiltersProps> = ({
  query = '',
  mode,
  onQueryChange,
  onViewModeChange,
}) => {
  const { t } = useTranslation('skills');

  return (
    <Flex justifyContent="space-between">
      <SearchInput
        placeholder={t('search')}
        query={query}
        onChange={onQueryChange}
      />
      <SkillsFiltersViewModes mode={mode} onChange={onViewModeChange} />
    </Flex>
  );
};

export default SkillsFilters;
