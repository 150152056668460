import CertificatesFiltersOrderBySelect from './VerifyCertificatesFiltersOrderBySelect';
import AdvancedFiltersOrderByContainer from '~/administration/components/advanced-filters/AdvancedFiltersOrderByContainer';
import AdvancedFiltersClearButton from '~/administration/components/advanced-filters/buttons/AdvancedFiltersClearButton';
import AdvancedFiltersHeaderContainer from '~/administration/components/advanced-filters/header/AdvancedFiltersHeaderContainer';
import AdvancedFiltersHeaderLeftContent from '~/administration/components/advanced-filters/header/AdvancedFiltersHeaderLeftContentContainer';
import AdvancedFiltersHeaderRightContent from '~/administration/components/advanced-filters/header/AdvancedFiltersHeaderRightContentContainer';
import SearchInput from '~/shared/components/SearchInput';
import CertificateSelect from '~/shared/components/select/CertificateSelect';
import CompanySelect from '~/shared/components/select/CompanySelect';
import StatusSelect from '~/shared/components/status/StatusSelect';
import { Certificate } from '~/shared/models/api/certificate';
import { PartialEmployeeFilters } from '~/shared/models/search/filters/employees';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface VerifyCertificatesSearchFiltersProps {
  filters: PartialEmployeeFilters;
  showAdvanced: boolean;
  isFilterChanged: boolean;
  onFilterChange: (partialFilters: PartialEmployeeFilters) => void;
  onQueryChange: (query: string) => void;
  toggleShowAdvanced: () => void;
  resetFilters: () => void;
}

const VerifyCertificatesSearchFilters: FC<
  PropsWithChildren<VerifyCertificatesSearchFiltersProps>
> = ({
  children,
  filters,
  onFilterChange,
  onQueryChange,
  isFilterChanged,
  resetFilters,
}) => {
  const { t } = useTranslation([
    'base',
    'certificates',
    'employees',
    'verifyCertificates',
  ]);

  const [selectedCertificate, setSelectedCertificate] = useState<
    Certificate | undefined
  >(undefined);

  useEffect(() => {
    if (!filters.certificates?.length) {
      setSelectedCertificate(undefined);
    }
  }, [filters]);

  return (
    <>
      <AdvancedFiltersHeaderContainer
        alignItems={{ base: 'center', lg: 'start' }}
      >
        <AdvancedFiltersHeaderLeftContent
          templateColumns={{ md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr) auto' }}
        >
          <SearchInput
            placeholder={t('employees:search')}
            query={filters.query}
            onChange={onQueryChange}
            mb={0}
          />
          <CertificateSelect
            id="filter-certificate"
            placeholder={t('certificates:search')}
            selectedCertificate={selectedCertificate}
            onChange={(certificate) => {
              setSelectedCertificate(certificate);
              onFilterChange({
                certificates: certificate?.id ? [certificate?.id] : [],
              });
            }}
          />
          <CompanySelect
            placeholder={t('base:company')}
            companyId={
              filters.companies?.length ? filters.companies[0] : undefined
            }
            onChange={(c) =>
              onFilterChange({ companies: c ? [c.id] : undefined })
            }
          />
          <StatusSelect
            selectedStatus={filters.certification_status}
            placeholder={t('verifyCertificates:certification_status')}
            onChange={(status) =>
              onFilterChange({ certification_status: status || undefined })
            }
          />
          <AdvancedFiltersClearButton
            disabled={!isFilterChanged}
            onClick={resetFilters}
          />
        </AdvancedFiltersHeaderLeftContent>
        <AdvancedFiltersHeaderRightContent>
          {children}
        </AdvancedFiltersHeaderRightContent>
      </AdvancedFiltersHeaderContainer>
      <AdvancedFiltersOrderByContainer>
        <CertificatesFiltersOrderBySelect
          filters={filters}
          onFilterChange={onFilterChange}
        />
      </AdvancedFiltersOrderByContainer>
    </>
  );
};

export default VerifyCertificatesSearchFilters;
