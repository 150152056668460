import { certificationFileType } from '~/shared/constants/certifications';
import { DateFormat } from '~/shared/enums/date';
import { Status } from '~/shared/enums/status';
import { Certificate } from '~/shared/models/api/certificate';
import { CreateEmployeeCertification } from '~/shared/models/api/employee';
import { formatDate } from '~/shared/utils/date';
import { yupFileValidation } from '~/shared/utils/form';
import { object, string } from 'yup';

export interface CertificationAdd {
  employee_id: number;
  certificate: Certificate;
  expiring_date?: string;
  adquired_date?: string;
  certificate_file?: File;
}

export type CertificationFormType = {
  certificate?: Certificate;
  expiring_date?: string;
  permanent?: boolean;
  adquired_date?: string;
  certificate_file?: File;
};

export const newCertificationSchema = object().shape({
  certificate: object().required('Certificate is required.'),
  adquired_date: string().required('Issued date is required.'),
  expiring_date: string()
    .nullable()
    .when('certificate', ([certificate], schema) => {
      return certificate && certificate.permanent
        ? schema
        : schema.required('Expiration date is required.');
    }),
  certificate_file: yupFileValidation(certificationFileType).nullable(),
});

export const mapCertification = (
  certification: CertificationAdd
): CreateEmployeeCertification => ({
  certificate_id: certification.certificate.id,
  certificate_name: certification.certificate.name,
  employee_id: certification.employee_id,
  status: Status.PENDING,
  adquired_date: formatDate(
    certification.adquired_date,
    DateFormat.YEAR_MONTH_DAY
  ),
  expiring_date:
    formatDate(certification.expiring_date, DateFormat.YEAR_MONTH_DAY) ||
    undefined,
  new_certification_file: certification.certificate_file,
});
