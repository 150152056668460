import { SkillLevel } from '~/shared/enums/skills';
import { EmployeeSkill } from '~/shared/models/api/employee';

const getSkillProficiencyAsSimbols = (skillLevel: SkillLevel) =>
  '+'.repeat(skillLevel);

/**
 * It sorts the skills by the skill level
 * @param skills - Array<EmployeeSkill> - The skills of the employee.
 * @returns the data sorted by the skill level.
 */
const orderSkillsByLevel = (skills: Array<EmployeeSkill>) => {
  return skills.sort((x, y) => y.skill_level_id - x.skill_level_id);
};

export { getSkillProficiencyAsSimbols, orderSkillsByLevel };
