import { PermissionModal } from './PermissionModal';
import { EditIcon } from '@chakra-ui/icons';
import { Button, useDisclosure } from '@chakra-ui/react';
import usePermissions from '~/administration/hooks/permissions/usePermissions';
import { Employee } from '~/shared/models/api/employee';
import { PermissionData, RoleData } from '~/shared/models/api/permissions';
import { useGetPermissions } from '~/shared/queries/useGetPermissions';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  employee: Employee;
  roles: RoleData[];
  refetch: () => void;
}

export const PermissionModalButton = ({ employee, roles, refetch }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('permissions');

  const { onEmployeePermissionsChange } = usePermissions();
  const { data: permissionList } = useGetPermissions();

  const selectedPermissions = employee.permissions.map(
    (permission) => permission.permission.id
  );

  const selectedPermissionsInRoles = roles.reduce<number[]>((acc, role) => {
    if (employee.roles.includes(role.id)) {
      const permissionIds = role.permissions.map(
        (rolePermission) => rolePermission.permission.id
      );
      return [...acc, ...permissionIds];
    }

    return acc;
  }, []);

  return (
    <>
      <PermissionModal
        title={t('further_permissions')}
        list={permissionList || []}
        selected={selectedPermissions}
        disabled={selectedPermissionsInRoles}
        isOpen={isOpen}
        onChange={(permissions) =>
          onEmployeePermissionsChange(
            employee?.id,
            permissions as PermissionData[],
            refetch
          )
        }
        onClose={onClose}
      />
      <Button onClick={onOpen} backgroundColor="transparent">
        <EditIcon />
      </Button>
    </>
  );
};
