import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { Button, ButtonGroup, Container } from '@chakra-ui/react';
import React, { PropsWithoutRef, useEffect } from 'react';

interface PaginatorProps {
  itemsPerPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
  currentPage: number;
}

const Paginator: React.FC<PropsWithoutRef<PaginatorProps>> = (
  props: PaginatorProps
) => {
  const { itemsPerPage, totalItems, onPageChange, currentPage } = props;
  const [totalPages, setTotalPages] = React.useState<number>(0);

  useEffect(() => {
    const totalPagesAux = Math.ceil(totalItems / itemsPerPage);
    setTotalPages(totalPagesAux);
    // We only want this to run once
  }, []);

  const IncrementPage = () => {
    let pageAux = currentPage;
    pageAux += 1;
    if (pageAux > totalPages) pageAux = totalPages;
    onPageChange(pageAux);
  };

  const DecrementPage = () => {
    let pageAux = currentPage;
    pageAux -= 1;
    if (pageAux < 1) pageAux = 1;
    onPageChange(pageAux);
  };

  const GoToLast = () => {
    onPageChange(totalPages);
  };

  return (
    <Container pl={1} mt="5">
      <ButtonGroup isAttached>
        <Button
          data-testid="btn-gotofirst"
          isDisabled={currentPage === 1}
          onClick={() => onPageChange(1)}
        >
          <ArrowLeftIcon />
        </Button>
        <Button
          data-testid="btn-decrement"
          isDisabled={currentPage === 1}
          onClick={() => DecrementPage()}
        >
          <ChevronLeftIcon w={7} h={7} />
        </Button>
        {currentPage - 2 >= 1 && (
          <Button onClick={() => onPageChange(currentPage - 2)}>
            {currentPage - 2}
          </Button>
        )}
        {currentPage - 1 >= 1 && (
          <Button onClick={() => onPageChange(currentPage - 1)}>
            {currentPage - 1}
          </Button>
        )}
        <Button isDisabled>{currentPage}</Button>
        {currentPage + 1 <= totalPages && (
          <Button onClick={() => onPageChange(currentPage + 1)}>
            {currentPage + 1}
          </Button>
        )}
        {currentPage + 2 <= totalPages && (
          <Button onClick={() => onPageChange(currentPage + 2)}>
            {currentPage + 2}
          </Button>
        )}
        <Button
          data-testid="btn-increment"
          isDisabled={currentPage === totalPages}
          onClick={() => IncrementPage()}
        >
          <ChevronRightIcon w={7} h={7} />
        </Button>
        <Button
          data-testid="btn-gotolast"
          isDisabled={currentPage === totalPages}
          onClick={() => GoToLast()}
        >
          <ArrowRightIcon />
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default Paginator;
