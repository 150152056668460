const fileToDataURL = (
  file: Blob
): Promise<string | ArrayBuffer | null | undefined> => {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onload = (event: ProgressEvent<FileReader>) => {
      resolve(event.target?.result);
    };
    reader.readAsDataURL(file);
  });
};

const getFileNameFromPath = (file_name: string): string => {
  return file_name.split('/').pop() || '';
};

export { fileToDataURL, getFileNameFromPath };
