import { OrderDirection } from '~/shared/models/search/filters/search';
import { Dispatch, SetStateAction, useState } from 'react';

export interface UseOrderDirectionType {
  order: OrderDirection;
  setOrder: Dispatch<SetStateAction<OrderDirection>>;
  getNextOrder: (order: OrderDirection) => OrderDirection;
  toggleOrder: () => void;
}

const useOrderDirection = (disableNone = false): UseOrderDirectionType => {
  const [order, setOrder] = useState<OrderDirection>(OrderDirection.NONE);

  const getNextOrder = (o: OrderDirection): OrderDirection => {
    if (disableNone) {
      return o == OrderDirection.ASCENDING
        ? OrderDirection.DESCENDING
        : OrderDirection.ASCENDING;
    }
    switch (o) {
      case OrderDirection.NONE:
        return OrderDirection.ASCENDING;
      case OrderDirection.ASCENDING:
        return OrderDirection.DESCENDING;
      default:
        return OrderDirection.NONE;
    }
  };

  const toggleOrder = () => {
    const newOrder = getNextOrder(order);
    setOrder(newOrder);
  };

  return {
    order,
    setOrder,
    getNextOrder,
    toggleOrder,
  };
};

export default useOrderDirection;
