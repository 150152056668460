import { Box, Flex, FlexProps } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';

const AdvancedFiltersOrderByContainer: FC<PropsWithChildren<FlexProps>> = ({
  children,
  ...props
}) => {
  return (
    <Flex ml="auto" mb={6} w={{ base: '50%', md: '35%', lg: '25%' }} {...props}>
      <Box w="full">{children}</Box>
    </Flex>
  );
};

export default AdvancedFiltersOrderByContainer;
