import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  IconButton,
  Img,
  List,
  ListItem,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { SOCIAL_LINKS } from '~/shared/constants/social-links';
import { SocialLink } from '~/shared/models/api/social';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SocialLinksEditListProps {
  socialLinks: SocialLink[];
  onDelete: (socialLinkIndex: number) => void;
}

export const getSocialLinkInfo = (socialLink: SocialLink) =>
  SOCIAL_LINKS.find((item) => item.slug === socialLink.network);

const buildDeleteIconLabel = (socialLink: SocialLink) =>
  `Remove Social Link: ${socialLink.network}`;

const SocialLinksEditList: React.FC<SocialLinksEditListProps> = ({
  socialLinks,
  onDelete,
}) => {
  const { t } = useTranslation('profile');

  return (
    <Stack width="100%" spacing="2">
      <Text fontSize="md" color="gray.600" fontWeight="bold">
        {t('about_me.social_link.current')}
      </Text>
      <Box flexGrow={1} borderWidth="1px" padding="2">
        <List spacing="2">
          {socialLinks?.map((s, index) => (
            <ListItem
              data-testid={`social-link-${index}`}
              key={`${s.network}/${s.user_name}`}
            >
              <Flex>
                <Img
                  data-testid={`social-link-image-${index}`}
                  w="4"
                  src={`/social-links/${getSocialLinkInfo(s)?.icon}`}
                  alt={`${getSocialLinkInfo(s)?.name}`}
                />
                <Text ml="1" flex="1">
                  {s.user_name}
                </Text>
                <Tooltip label={buildDeleteIconLabel(s)}>
                  <IconButton
                    aria-label={buildDeleteIconLabel(s)}
                    icon={<DeleteIcon />}
                    data-testid="social-link-remove-button"
                    variant="ghost"
                    boxSize={6}
                    onClick={() => onDelete(index)}
                    colorScheme="red"
                  />
                </Tooltip>
              </Flex>
            </ListItem>
          ))}
        </List>
      </Box>
    </Stack>
  );
};

export default SocialLinksEditList;
