import {
  mapDataListToOptions,
  mapDataToOption,
} from '~/shared/mappers/selectOption';
import { Language } from '~/shared/models/api/language';
import {
  SelectOption,
  SelectOptionMapperKeysType,
} from '~/shared/models/react-select/select-option';
import { useGetLanguages } from '~/shared/queries/useGetLanguages';
import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';

interface LanguageSelectProps {
  id?: string;
  placeholder?: string;
  selectedLanguages?: Language[];
  defaultValue?: Language;
  isDisabled?: boolean;
  isClearable?: boolean;
  onChange?: (language?: Language) => void;
}

const LanguageSelect: FC<LanguageSelectProps> = ({
  id = 'language-select',
  placeholder,
  selectedLanguages,
  defaultValue,
  isDisabled,
  isClearable = true,
  onChange,
}) => {
  const [selected, setSelected] = useState<SelectOption<Language> | null>(null);
  const { data, isLoading } = useGetLanguages();
  const keys: SelectOptionMapperKeysType<Language> = {
    valueKey: 'id',
    labelKey: 'name',
  };

  useEffect(() => {
    if (!defaultValue) {
      setSelected(null);
      return;
    }
    setSelected(
      mapDataToOption({
        option: defaultValue,
        ...keys,
      })
    );
  }, [defaultValue]);

  return (
    <Select
      id={id}
      placeholder={placeholder}
      isLoading={isLoading}
      isClearable={isClearable}
      isDisabled={isDisabled}
      value={selected}
      options={mapDataListToOptions<Language>({
        options: data || [],
        ...keys,
      })}
      isOptionDisabled={(value) =>
        selectedLanguages?.some((lan) => lan.id === value.origin?.id) || false
      }
      onChange={(value) => {
        onChange && onChange(value?.origin);
        setSelected(value);
      }}
    />
  );
};

export default LanguageSelect;
