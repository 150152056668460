import { ExperienceSkills } from '../components/ExperienceSkills';
import { Box, Flex } from '@chakra-ui/react';
import { ProfileSectionItemHeader } from '~/profile/components/ProfileSectionItemHeader';
import ReadOnlyRichText from '~/shared/components/ui/ReadOnlyRichText';
import { experiencesTranslation } from '~/shared/enums/externalExperiences';
import { EmployeeExternalExperience } from '~/shared/models/api/employee';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  experience: EmployeeExternalExperience;
  experienceIndex: number;
  onOpen: (project: EmployeeExternalExperience) => void;
  onDelete: () => void;
}

const ExternalExperienceItem: React.FC<Props> = ({
  experience,
  onOpen,
  onDelete,
}) => {
  const { t } = useTranslation(['base', 'profile']);

  return (
    <Box>
      <ProfileSectionItemHeader
        onEdit={() => onOpen(experience)}
        onDelete={() => onDelete()}
        title={experience.title}
        subTitle={experience.company}
        startDate={experience.start_date}
        endDate={experience.end_date}
        headerTestId={`external-experiences-item-heading-${experience.id}`}
        dateIntervalTagTestId={t(
          `profile:experiences.external.types.${
            experiencesTranslation[experience.type]
          }`
        )}
        rightTag={t(
          `profile:experiences.external.types.${
            experiencesTranslation[experience.type]
          }`
        )}
        tagColorScheme="purple"
      />

      <Flex ml={4}>
        <ReadOnlyRichText value={experience.description} />
      </Flex>

      <ExperienceSkills
        skills={experience.skills}
        skillsKey={`external-experiences-skills-${experience.id}`}
      />
    </Box>
  );
};
export default ExternalExperienceItem;
