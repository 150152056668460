/* eslint-disable no-shadow */
export enum AdminRoutesPaths {
  CERTIFICATES = 'certificates',
  CLIENT_MAPPING = 'client-mapping',
  PERMISSIONS = 'permissions',
  PERMISSION_GROUPS = 'permission-groups',
  PROFILES_STATUSES = 'profiles-statuses',
  PROJECTS = 'projects',
  SKILLS = 'skills',
  VERIFY_CERTIFICATES = 'verify-certificates',
  VERIFY_PROFILES = 'verify-profiles',
}
