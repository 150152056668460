import { searchProjectsDebounce } from '~/shared/api/request/projects';
import { QueriesTypes } from '~/shared/enums/queries';
import { Project } from '~/shared/models/api/project';
import { ProjectsSearchFilters } from '~/shared/models/search/filters/projects';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useProjectsSearch = (filters: Partial<ProjectsSearchFilters>) => {
  return useQuery<PaginatedResponse<Project>, AxiosError>({
    queryKey: [QueriesTypes.SEARCH_PROJECTS, { ...filters }],
    queryFn: searchProjectsDebounce,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
