export const compareArrays = (arr1: unknown[], arr2: unknown[]) => {
  if (arr1.length < 1 && arr2.length < 1) {
    return true;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  const matchingValues = arr1.filter((obj1) =>
    arr2.some((obj2) => compareObjects(obj1, obj2))
  );

  return matchingValues.length === arr1.length;
};

const compareObjects = (v1: unknown, v2: unknown) => {
  if (typeof v1 !== typeof v2) {
    return false;
  }

  if (typeof v1 === 'function' && typeof v2 === 'function') {
    return v1.toString() === v2.toString();
  }

  if (v1 instanceof Object && v2 instanceof Object) {
    if (Object.keys(v1).length !== Object.keys(v2).length) {
      return false;
    }
    const obj1 = v1 as Record<string, unknown>;
    const obj2 = v2 as Record<string, unknown>;

    let r = true;

    for (const k in obj1) {
      if (Object.prototype.hasOwnProperty.call(obj1, k)) {
        if (k !== 'id') {
          r = compareObjects(obj1[k], obj2[k]);
          if (!r) {
            return false;
          }
        }
      }
    }
    return true;
  }
  return v1 === v2;
};
