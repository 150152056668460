import { ObjectKeyValue } from '~/shared/models/object';

const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const charactersLength = characters.length;

export const randomNumber = (max = 1000, min = 0) =>
  Math.floor(Math.random() * (max - min) + min);

export const randomId = () => randomNumber(2147483647, 1);

export const randomString = (length = 10) =>
  [...Array(length)]
    .map(() => characters[randomNumber(charactersLength)])
    .join('');

export const randomBoolean = () => randomNumber(10) > 5;

export const randomDate = () =>
  `200${randomNumber(10, 1)}/0${randomNumber(10, 1)}/0${randomNumber(10, 1)}`;

export const randomEnumKey = <T extends ObjectKeyValue>(enumeration: T) => {
  const values = Object.keys(enumeration);
  return values[Math.floor(Math.random() * values.length)];
};
