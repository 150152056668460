import { Box, Button, Stack, Text, Tooltip } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  defaultNewSocialLink,
  SocialLinkFormType,
  socialLinkSchema,
} from '~/profile/forms/socialLinks';
import FormControl from '~/shared/components/form/FormControl';
import FormControlInput from '~/shared/components/form/FormControlInput';
import SocialNetworkSelect from '~/shared/components/select/SocialNetworkSelect';
import { OTHER_LINK, SOCIAL_LINKS } from '~/shared/constants/social-links';
import { SocialLink } from '~/shared/models/api/social';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface SocialLinksFormProps {
  onAddSocialLink: (data: SocialLink) => void;
}

const findLeftAddon = (network: string) =>
  SOCIAL_LINKS.find((item) => item.slug === network)?.base;

const baseUrls = SOCIAL_LINKS.map((item) => item.base);

const SocialLinksForm: React.FC<SocialLinksFormProps> = ({
  onAddSocialLink,
}) => {
  const { t: tBase } = useTranslation('base');
  const { t: tProfile } = useTranslation('profile');
  const [isPasting, setIsPasting] = useState(false);

  const methods = useForm({
    resolver: yupResolver(socialLinkSchema),
    defaultValues: defaultNewSocialLink(),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    control,
  } = methods;

  const url = watch('user_name');

  useEffect(() => {
    if (!isPasting) return;

    let nextValue = url;

    if (!nextValue || !nextValue.startsWith('https://')) return;

    nextValue = nextValue.replaceAll('www.', '');

    const item = baseUrls.find((base) => nextValue.startsWith(base));

    if (!item) return;

    const nextBase = nextValue.slice(0, item.length);
    const nextUrl = nextValue.slice(item.length);

    if (!nextBase) return;

    const nextSlug = SOCIAL_LINKS.find(
      (socialLink) => socialLink.base === nextBase
    );

    if (!nextSlug || !nextUrl) return;

    setValue('user_name', nextUrl);
    setValue('network', nextSlug);

    setIsPasting(false);
  }, [isPasting]);

  useEffect(() => {
    retrieveCompleteURL();
  }, [url]);

  const onSubmit: SubmitHandler<SocialLinkFormType> = ({
    network,
    ...formValues
  }) => {
    if (!network) {
      return;
    }
    onAddSocialLink({
      network: network.slug,
      ...formValues,
    });

    reset();
  };

  const network = watch('network');

  const retrieveCompleteURL = (): void => {
    const findAddon: string | undefined = findLeftAddon(network?.slug || '');
    const newURL: string | undefined = findAddon?.concat(url);
    setValue('url', newURL || '');
  };

  useEffect(() => {
    if (!network) {
      setValue('user_name', '');
    }
  }, [network]);

  return (
    <Stack width="100%" gap="2">
      <Text fontSize="md" color="gray.600" fontWeight="bold">
        {tProfile('about_me.social_link.add')}
      </Text>
      <FormProvider {...methods}>
        <FormControl
          id="network"
          data-testid="test-form-network"
          control={control}
          error={errors.network}
          placeholder={tProfile(
            'about_me.social_link.placeholders.network_placeholder'
          )}
        >
          <SocialNetworkSelect />
        </FormControl>
        <Stack direction="row">
          <FormControlInput
            id="user_name"
            data-testid="test-form-user_name"
            register={register}
            leftAddon={findLeftAddon(network?.slug || '')}
            placeholder={
              network
                ? network?.slug === OTHER_LINK.base
                  ? tProfile('about_me.social_link.placeholders.url')
                  : tProfile('about_me.social_link.placeholders.username')
                : tProfile('about_me.social_link.placeholders.username_or_url')
            }
            error={errors.user_name}
            disabled={!network}
            onPaste={() => setIsPasting(true)}
            maxLength={50}
          />

          <Box>
            <Tooltip label="Add New Social Link">
              <Button
                data-testid="form-social-link-add-button"
                colorScheme="yellow"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                {tBase('add')}
              </Button>
            </Tooltip>
          </Box>
        </Stack>
      </FormProvider>
    </Stack>
  );
};

export default SocialLinksForm;
