import { SearchFilterTab } from '../enums/searchFilterTab';
import {
  PaginationFilters,
  SearchFilters,
} from '~/shared/models/search/filters/search';

export const filterTabs: SearchFilterTab[] = Object.values(SearchFilterTab);

export const defaultPagination: PaginationFilters = {
  skip: 0,
  limit: 20,
};

export const initialFilters: SearchFilters = {
  query: '',
  ...defaultPagination,
};
