import { useToast } from '@chakra-ui/react';
import { notifyEmployeeToUpdateProfile as notifyToUpdateProfile } from '~/shared/api/request/employee';
import { getErrorMessage } from '~/shared/errors/error';
import { EmployeeNameIdProps } from '~/shared/models/api/employee';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

export type EmployeeNotifications = UseMutationResult<
  string,
  unknown,
  EmployeeNameIdProps,
  unknown
>;

const useEmployeeNotifications = (): EmployeeNotifications => {
  const toast = useToast();
  const { t } = useTranslation('permissions');
  const { t: tBase } = useTranslation('base');

  const notifyEmployeeToUpdateProfile = useMutation(
    (employee: EmployeeNameIdProps) => notifyToUpdateProfile(employee.id),
    {
      onSuccess: (_verified: string, employeeNotified: EmployeeNameIdProps) => {
        toast({
          title: t(`notification_sent`),
          description: t(`user_notified`, { userName: employeeNotified.name }),
          status: 'success',
        });
      },
      onError: (err: AxiosError, employeeNotifyFail: EmployeeNameIdProps) => {
        toast({
          title: tBase(`something_went_wrong`),
          description: getErrorMessage(
            err.response?.status,
            t(`fail_user_message`, { userName: employeeNotifyFail.name }),
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return notifyEmployeeToUpdateProfile;
};

export default useEmployeeNotifications;
