import { employeesEndpoint } from '~/shared/api/urls/employees';
import { EndpointPaths } from '~/shared/enums/endpointPaths';
import { EmployeeIdOrMe } from '~/shared/types/employeeIdOrMe';

export const getProfileIdHelper = (id?: number): EmployeeIdOrMe =>
  id ?? EndpointPaths.ME;

export const getInternalExperiencesEndpoint = (
  employee_id: number | undefined
) => employeesEndpoint.id(getProfileIdHelper(employee_id)).internalExperiences;

export const getExternalExperiencesEndpoint = (
  employee_id: number | undefined
) => employeesEndpoint.id(getProfileIdHelper(employee_id)).externalExperiences;
