import { industriesEndpoint } from '../urls/industries';
import { patch, post, search } from '~/shared/api/endpoints';
import {
  CreateIndustry,
  Industry,
  UpdateIndustry,
} from '~/shared/models/api/industry';
import { SearchFilters } from '~/shared/models/search/filters/search';
import { SearchProps } from '~/shared/models/search/search';
import { getSearchFiltersByParamsType } from '~/shared/utils/search';
import debounce from 'debounce-promise';

export const createIndustry = async (
  industry: CreateIndustry
): Promise<Industry> => {
  const { data } = await post(industriesEndpoint.url, industry);

  return data;
};

export const updateIndustry = async ({
  id,
  ...industry
}: UpdateIndustry): Promise<Industry> => {
  const { data } = await patch<Industry>(
    industriesEndpoint.id(id).url,
    industry
  );

  return data;
};

export const searchIndustries = debounce(
  async (value: SearchProps | SearchFilters): Promise<Industry[]> => {
    const searchFilters = getSearchFiltersByParamsType(value);

    const { data } = await search<Industry>(
      industriesEndpoint.url,
      searchFilters
    );

    return data;
  },
  500
);
