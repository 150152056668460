export enum ExternalExperiences {
  JOB = 'Job',
  PERSONAL_PROJECT = 'Personal Project',
  OPEN_SOURCE_PROJECT_OR_CONTRIBUTION = 'Open Source Project or Contribution',
  VOLUNTEER = 'Volunteer',
  FREELANCER = 'Freelancer',
  OTHER = 'Other',
}

export const experiencesTranslation = {
  [ExternalExperiences.JOB]: 'job',
  [ExternalExperiences.PERSONAL_PROJECT]: 'personal_project',
  [ExternalExperiences.OPEN_SOURCE_PROJECT_OR_CONTRIBUTION]:
    'open_source_project_or_contribution',
  [ExternalExperiences.VOLUNTEER]: 'volunteer',
  [ExternalExperiences.FREELANCER]: 'freelancer',
  [ExternalExperiences.OTHER]: 'other',
};
