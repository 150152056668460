import { Box, BoxProps } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';

const FullScreenContent: FC<PropsWithChildren<BoxProps>> = ({
  children,
  ...boxPropsprops
}) => {
  return (
    <Box h="100vh" w="100vw" {...boxPropsprops}>
      {children}
    </Box>
  );
};

export default FullScreenContent;
