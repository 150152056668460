import useProfileStore from '~/profile/stores/profile';

interface UseEmployeeRefresh {
  onSkillUpdated: (skillId: number) => void;
  onCertificateUpdated: (skillId: number) => void;
}

const useEmployeeRefresh = (): UseEmployeeRefresh => {
  const { myProfile, loadMyProfile } = useProfileStore();

  const onSkillUpdated = (skillId: number): void => {
    if (!isSkillIdInUser(skillId)) {
      return;
    }
    loadMyProfile(true);
  };

  const isSkillIdInUser = (skillId: number): boolean => {
    if (!myProfile?.main_skills) {
      return false;
    }
    return myProfile.main_skills.some(
      (employeeSkill) => employeeSkill.skill_id === skillId
    );
  };

  const onCertificateUpdated = (certificationId: number): void => {
    if (!isCertificationIdInUser(certificationId)) {
      return;
    }
    loadMyProfile(true);
  };

  const isCertificationIdInUser = (certificationId: number): boolean => {
    if (!myProfile?.main_skills) {
      return false;
    }
    return myProfile.certifications.some(
      (employeeCertification) =>
        employeeCertification.certificate_id === certificationId
    );
  };

  return {
    onSkillUpdated,
    onCertificateUpdated,
  };
};

export default useEmployeeRefresh;
