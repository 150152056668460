import { Icon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { OrderDirection } from '~/shared/models/search/filters/search';
import React, { FC } from 'react';

interface SortableIconProps {
  order: OrderDirection;
  active?: boolean;
  size?: 'small' | 'large';
}

const SortableIcon: FC<SortableIconProps> = ({
  order,
  active,
  size = 'small',
}) => {
  return (
    <Icon
      as={
        order === OrderDirection.ASCENDING ? TriangleUpIcon : TriangleDownIcon
      }
      boxSize={size === 'small' ? 2.5 : 3}
      color={active ? 'blue.500' : 'gray.300'}
    />
  );
};

export default SortableIcon;
