import { Company } from '~/shared/models/api/company';

export const COMPANIES: Company[] = [
  {
    id: 1,
    name: 'xgeeks',
    logo_url: 'xgeeks_Logo.svg',
  },
  {
    id: 2,
    name: 'KI Professionals',
    logo_url: 'KIprofessionals_Logo_Black.svg',
  },
  {
    id: 3,
    name: 'KI Performance',
    logo_url: 'KIperformance_Logo_Black.svg',
  },
  {
    id: 4,
    name: 'KI Group',
    logo_url: 'KIgroup_Logo_Black.svg',
  },
  {
    id: 5,
    name: 'KI Challengers',
    logo_url: 'KIchallengers_Logo_Green_Black.svg',
  },
  {
    id: 6,
    name: 'KI Capital',
    logo_url: 'KIcapital_Logo_Black.svg',
  },
];
