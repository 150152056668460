import { Box, BoxProps } from '@chakra-ui/react';
import { GridViewModes } from '~/shared/enums/gridViewModes';
import React, { FC, PropsWithChildren } from 'react';

type GridElementType = Record<GridViewModes, BoxProps>;

const wrapperProps: GridElementType = {
  [GridViewModes.HORIZONTAL]: {
    flexGrow: 1,
    display: 'inline-flex',
  },
  [GridViewModes.VERTICAL]: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 350px)',
    minHeight: '400px',
    minWidth: '300px',
    height: '100%',
  },
  [GridViewModes.GRID]: {
    h: '100%',
  },
};

const contentProps: GridElementType = {
  [GridViewModes.HORIZONTAL]: {
    display: 'flex',
    alignItems: 'start',
    flexWrap: 'wrap',
    width: '100%',
  },
  [GridViewModes.VERTICAL]: {
    display: 'flex',
    overflowY: 'auto',
    flexWrap: 'wrap',
  },
  [GridViewModes.GRID]: {
    display: 'flex',
    overflowY: 'auto',
    flexWrap: 'wrap',
    maxHeight: '300px',
    overflowX: 'auto',
  },
};

export type SkillsGridItemByModeProps = {
  mode: GridViewModes;
  innerRef: (element: HTMLElement | null) => unknown;
};

const SkillsGridItemByMode: FC<
  PropsWithChildren<SkillsGridItemByModeProps>
> = ({ mode, innerRef, children }) => {
  return (
    <Box
      {...(mode !== GridViewModes.HORIZONTAL ? { ref: innerRef } : {})}
      {...wrapperProps[mode]}
    >
      <Box
        {...(mode === GridViewModes.HORIZONTAL ? { ref: innerRef } : {})}
        {...contentProps[mode]}
      >
        {children}
      </Box>
    </Box>
  );
};

export default SkillsGridItemByMode;
