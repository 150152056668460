import { Box, BoxProps, Flex, ThemeTypings } from '@chakra-ui/react';
import { ProfileSectionItemIntervalAndTag } from '~/profile/components/ProfileSectionItemIntervalAndTag';
import { ProfileSectionItemTitleAndSubTitle } from '~/profile/components/ProfileSectionItemTitleAndSubTitle';
import { ActionButtons } from '~/profile/components/shared/ActionButtons';
import ReadOnlyRichText from '~/shared/components/ui/ReadOnlyRichText';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import React from 'react';

interface ProfileSectionItemHeaderProps extends BoxProps {
  dateIntervalTagTestId?: string;
  description?: string;
  endDate?: string | null;
  endDateReplacementI18nKey?: string;
  headerTestId?: string;
  height?: number;
  onEdit?: () => void;
  onDelete?: () => void;
  rightTag?: string;
  startDate?: string;
  subTitle?: string;
  tagColorScheme?: ThemeTypings['colorSchemes'];
  title?: string;
}

export const ProfileSectionItemHeader: React.FC<
  ProfileSectionItemHeaderProps
> = ({
  subTitle,
  dateIntervalTagTestId,
  description,
  endDate,
  endDateReplacementI18nKey,
  headerTestId,
  onEdit,
  onDelete,
  rightTag,
  startDate,
  tagColorScheme,
  title,
  ...props
}) => {
  const { canEditProfile } = useAccessPermissions();

  return (
    <Box
      backgroundColor="gray.50"
      borderColor="gray.100"
      borderWidth={1}
      my={1}
      pl={4}
      pr={2}
      py={1}
      rounded={12}
      {...props}
    >
      <Flex
        alignContent="top"
        alignItems="center"
        justifyContent="space-between"
        w="full"
      >
        {title ? (
          <Flex alignContent="center" alignItems="center">
            <ProfileSectionItemTitleAndSubTitle
              data-testid={headerTestId}
              title={title}
              size="md"
            />
          </Flex>
        ) : (
          description && <ReadOnlyRichText value={description} />
        )}

        <Flex>
          <ProfileSectionItemIntervalAndTag
            dateIntervalTagTestId={dateIntervalTagTestId}
            startDate={startDate}
            endDate={endDate}
            sideTagText={rightTag}
            tagColorScheme={tagColorScheme}
            endDateReplacementI18nKey={endDateReplacementI18nKey}
          />

          <ActionButtons
            onClickEdit={onEdit}
            onClickDelete={onDelete}
            hasPermissions={canEditProfile}
            tooltipLabel={title}
          />
        </Flex>
      </Flex>

      {subTitle && (
        <ProfileSectionItemTitleAndSubTitle
          color="gray.500"
          data-testid={headerTestId}
          title={subTitle}
          size="sm"
        />
      )}

      {description && title && <ReadOnlyRichText value={description} />}
    </Box>
  );
};
