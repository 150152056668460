import CustomSelect from './CustomSelect';
import {
  mapDataListToOptions,
  mapDataToOption,
} from '~/shared/mappers/selectOption';
import { Company } from '~/shared/models/api/company';
import {
  SelectOption,
  SelectOptionMapperKeysType,
} from '~/shared/models/react-select/select-option';
import { useGetCompanies } from '~/shared/queries/useGetCompanies';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CompanySelectProps {
  id?: string;
  companyId?: number;
  placeholder?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  onChange: (value?: Company) => void;
}

const CompanySelect: FC<CompanySelectProps> = ({
  id = 'status-select',
  companyId,
  placeholder,
  isClearable = true,
  isDisabled,
  onChange,
}) => {
  const { t } = useTranslation('base');
  const { isLoading, data, error } = useGetCompanies();
  const [selectedOption, setSelectedOption] =
    useState<SelectOption<Company> | null>(null);
  const keys: SelectOptionMapperKeysType<Company> = {
    valueKey: 'id',
    labelKey: 'name',
  };

  useEffect(() => {
    if (!companyId || isLoading || !data) {
      setSelectedCompany();
      return;
    }

    const company = data?.find((c) => c.id === companyId);
    setSelectedCompany(company);
  }, [companyId, isLoading, data]);

  const setSelectedCompany = (company?: Company) => {
    if (!company) {
      setSelectedOption(null);

      return;
    }

    setSelectedOption(
      mapDataToOption({
        option: company,
        ...keys,
      })
    );
  };

  return (
    <CustomSelect<Company>
      id={id}
      options={mapDataListToOptions<Company>({
        options: data || [],
        ...keys,
      })}
      selectedOption={selectedOption}
      placeholder={placeholder || `${t('select')} ${t('company')}`}
      isDisabled={isDisabled || !!error}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={false}
      onChange={onChange}
    />
  );
};

export default CompanySelect;
