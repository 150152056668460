import { useBoolean } from '@chakra-ui/react';
import { initialFilters as defaultFilters } from '~/shared/constants/search';
import { SearchFilters } from '~/shared/models/search/filters/search';
import { checkFilterChanges } from '~/shared/utils/search';
import { useEffect, useState } from 'react';

export interface UseSearchFiltersType<T extends SearchFilters> {
  filters: Partial<T>;
  showAdvanced: boolean;
  isFilterChanged: boolean;
  toggleShowAdvanced: () => void;
  onPageChange: (skip: number) => void;
  onFiltersChange: (updatedFilters: T) => void;
  onFilterChange: (partialFilters: Partial<T>) => void;
  onQueryChange: (quary: string) => void;
  resetFilters: () => void;
}

const useSearchFilters = <T extends SearchFilters>(
  initialFilters: Partial<T> = defaultFilters as T
): UseSearchFiltersType<T> => {
  const [filters, setFilters] = useState<Partial<T>>({
    ...initialFilters,
  });
  const [showAdvanced, setShowAdvanced] = useBoolean();
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  useEffect(() => {
    setIsFilterChanged(checkFilterChanges<T>(filters, initialFilters));
  }, [filters]);

  const onPageChange = (skip: number) => {
    if (!filters) {
      return;
    }
    setFilters({
      ...filters,
      skip,
    });
  };

  const toggleShowAdvanced = () => {
    setShowAdvanced.toggle();
  };

  const onFiltersChange = (updatedFilters: Partial<T>) => {
    setFilters({
      ...updatedFilters,
      skip: 0,
    });
  };

  const onFilterChange = (partialFilters: Partial<T>) => {
    onFiltersChange({
      ...filters,
      ...partialFilters,
    });
  };

  const onQueryChange = (query: string) => {
    onFilterChange({ query } as Partial<T>);
  };

  const resetFilters = () => {
    setFilters({
      ...initialFilters,
    } as T);
  };

  return {
    filters,
    showAdvanced,
    isFilterChanged,
    toggleShowAdvanced,
    onPageChange,
    onFiltersChange,
    onFilterChange,
    onQueryChange,
    resetFilters,
  };
};

export default useSearchFilters;
