import { ButtonProps } from '@chakra-ui/react';
import RoundedButton from '~/shared/components/buttons/RoundedButton';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RiFilterOffFill } from 'react-icons/ri';

interface AdvancedFiltersClearButtonProps {
  disabled?: boolean;
  onClick: () => void;
}

const AdvancedFiltersClearButton: FC<
  AdvancedFiltersClearButtonProps & Omit<ButtonProps, 'onClick'>
> = ({ disabled, onClick, ...props }) => {
  const { t } = useTranslation('base');
  return (
    <RoundedButton
      isDisabled={disabled}
      onClick={onClick}
      tooltipLabel={t(`filters.reset`)}
      {...props}
    >
      <RiFilterOffFill size={24} opacity={0.8} />
    </RoundedButton>
  );
};

export default AdvancedFiltersClearButton;
