import { SkillLevel } from '~/shared/enums/skills';
import {
  mapEnumToLevelOption,
  mapEnumToLevelOptions,
} from '~/shared/mappers/selectOption';
import { SelectOption } from '~/shared/models/react-select/select-option';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

interface SkillLevelSelectProps {
  id?: string;
  defaultValue?: SkillLevel;
  placeholder?: string;
  isClearable?: boolean;
  onChange?: (level?: SkillLevel) => void;
}

const SkillLevelSelect: FC<SkillLevelSelectProps> = ({
  id,
  defaultValue,
  placeholder,
  isClearable = true,
  onChange,
}) => {
  const [selected, setSelected] = useState<SelectOption<
    typeof SkillLevel
  > | null>(null);
  const { t } = useTranslation('skills');

  const options = useMemo(() => mapEnumToLevelOptions(SkillLevel), []);

  useEffect(() => {
    if (!defaultValue) {
      setSelected(null);
      return;
    }
    setSelected(mapEnumToLevelOption(SkillLevel, defaultValue));
  }, [defaultValue]);

  return (
    <Select
      id={id}
      placeholder={placeholder}
      isClearable={isClearable}
      isSearchable={false}
      value={selected}
      options={options}
      getOptionLabel={(option) =>
        t(`skill_level_description.${option.translationKey}`)
      }
      onChange={(value) => {
        onChange && onChange(value?.value as SkillLevel);
        setSelected(value as SelectOption<typeof SkillLevel>);
      }}
    />
  );
};

export default SkillLevelSelect;
