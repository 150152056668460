import { EditIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  HStack,
  Icon,
  Spacer,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { DateIntervalTag } from '~/profile/components/shared/DateIntervalTag';
import { CustomTag } from '~/shared/components/ui/CustomTag';
import { EmployeeProject } from '~/shared/models/api/project';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface InternalProjectSelectItemProps {
  employeeProject: EmployeeProject;
  isSelected: boolean;
  onClick: (employeeProject: EmployeeProject | null) => void;
}

export const InternalProjectSelectItem: React.FC<
  InternalProjectSelectItemProps
> = ({ employeeProject, isSelected, onClick }) => {
  const { t } = useTranslation('profile', {
    keyPrefix: 'experiences.internal.form',
  });

  return (
    <Flex
      bg={employeeProject.added_to_profile ? 'gray.100' : 'white'}
      borderWidth={1}
      borderColor={isSelected ? 'gray.400' : 'gray.100'}
      cursor={isSelected ? 'unset' : 'pointer'}
      onClick={isSelected ? undefined : () => onClick(employeeProject)}
      opacity={employeeProject.added_to_profile ? 0.68 : 1}
      p={isSelected ? 'unset' : 1}
      pl={4}
      rounded={isSelected ? 4 : 8}
      shadow={isSelected ? 'unset' : 'sm'}
      w="full"
      _hover={
        isSelected
          ? {}
          : { bg: 'blue.100', borderColor: 'blue.300', shadow: 'lg' }
      }
    >
      <Tooltip label={employeeProject.project.title}>
        <HStack alignContent="space-between" w="full">
          <Text>{employeeProject.project.sms_title}</Text>
          <Spacer />
          <Flex>
            {employeeProject.added_to_profile && (
              <CustomTag alignItems="center" colorScheme="orange">
                {t(`tag.added_to_profile`)}
              </CustomTag>
            )}
            <CustomTag alignItems="center" colorScheme="green">
              {t(`tag.man_days`, { days: employeeProject.days_worked })}
            </CustomTag>

            <DateIntervalTag
              startDate={employeeProject.start_date}
              endDate={employeeProject.end_date}
              endDateReplacementI18nKey={`base:present`}
              alignItems="center"
            />
          </Flex>
        </HStack>
      </Tooltip>
      {isSelected && (
        <Tooltip label={t(`change_project`)}>
          <Button
            color="gray.500"
            bg="unset"
            fontWeight="extrabold"
            onClick={() => onClick(null)}
            opacity={'.6'}
            rounded="none"
            type="submit"
            variant="solid"
            _hover={{ opacity: '1' }}
          >
            <Icon as={EditIcon} />
          </Button>
        </Tooltip>
      )}
    </Flex>
  );
};
