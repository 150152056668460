import TextEntry from '../TextEntry';
import { Center, Spinner } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';

interface SearchAutocompleteEntryContainerProps {
  isLoading: boolean;
  noResults?: boolean;
  noResultsText: string;
}

const SearchAutocompleteEntryContainer: FC<
  PropsWithChildren<SearchAutocompleteEntryContainerProps>
> = ({ isLoading, noResults, noResultsText, children }) => {
  if (noResults) {
    return <TextEntry text={noResultsText} />;
  }
  return (
    <>
      {isLoading ? (
        <Center py={4}>
          <Spinner color="gray.300" size="sm" />
        </Center>
      ) : (
        children
      )}
    </>
  );
};

export default SearchAutocompleteEntryContainer;
