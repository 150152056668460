import {
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Input,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import CropperModal from '~/profile/components/about/picture/CropperModal';
import useProfileStore from '~/profile/stores/profile';
import EmployeeAvatar from '~/shared/components/EmployeeAvatar';
import useEmployeeProfilePicture from '~/shared/hooks/employee/useEmployeeProfilePicture';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import React, { ChangeEventHandler, useState } from 'react';
import { FaTrash, FaUpload } from 'react-icons/fa';

const ProfilePicture: React.FC = () => {
  const [uploadedImage, setUploadedImage] = useState<string | undefined>(
    undefined
  );

  const toast = useToast();
  const { profile, updateAvatar, loadMyProfile } = useProfileStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { canEditProfile } = useAccessPermissions();
  const { deleteProfilePicture } = useEmployeeProfilePicture();

  const onDeleteImageClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (profile) {
      await deleteProfilePicture.mutateAsync(profile.id);
      loadMyProfile(true);
    }
  };

  const onCroppedImage = async (
    image: Blob | null,
    imageBase64: string | undefined
  ) => {
    if (!image || !imageBase64) return;

    const updateSuccess = await updateAvatar(image, imageBase64);

    toast({
      title: 'Profile About Update',
      description: updateSuccess
        ? 'Profile About updated with success'
        : 'Something went wrong',
      status: updateSuccess ? 'success' : 'error',
    });

    if (updateSuccess) onClose();
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = () => setUploadedImage(reader.result as string);
      reader.readAsDataURL(e.target.files[0]);
    }
    onOpen();
  };

  return (
    <Box position="relative" role="group" w="300px" h="300px">
      <CropperModal
        isOpen={isOpen}
        onClose={onClose}
        currentImage={uploadedImage}
        onCroppedImage={onCroppedImage}
      />
      <EmployeeAvatar
        data-testid="profile-picture-img"
        borderRadius="md"
        w="100%"
        h="100%"
        name={`${profile?.first_name} ${profile?.second_name}`}
        imagePath={profile?.image_url}
      />
      {canEditProfile && (
        <Box position="absolute" top="0" left="0">
          <form>
            <Box w="300px" h="300px">
              <Flex w="300px" h="300px" alignItems="end" justifyContent="end">
                <FormLabel
                  color="black"
                  textAlign="end"
                  _hover={{ cursor: 'pointer' }}
                  htmlFor="picture"
                  margin="0 0 5px 0"
                >
                  <Icon
                    as={FaUpload}
                    css={{
                      mixBlendMode: 'difference',
                      color: 'hsl(240, 1%, 85%)',
                    }}
                  />
                </FormLabel>
                <Button
                  leftIcon={<Icon as={FaTrash} />}
                  style={{
                    mixBlendMode: 'difference',
                    color: 'hsl(240, 1%, 85%)',
                  }}
                  variant="ghost"
                  p={0}
                  onClick={onDeleteImageClick}
                  _hover={{
                    color: 'hsl(240, 1%, 85%)',
                  }}
                  _active={{
                    color: 'hsl(240, 1%, 85%)',
                  }}
                />
              </Flex>
              <Input
                data-testid="profile-picture-form-input"
                type="file"
                id="picture"
                name="picture"
                display="none"
                onChange={onChange}
              />
            </Box>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default ProfilePicture;
