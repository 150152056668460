import { Center, Spinner } from '@chakra-ui/react';
import Pagination, {
  PaginationByFiltersProps,
} from '~/shared/components/PaginationByFilters';
import { CustomAlert } from '~/shared/components/ui/CustomAlert';
import { AxiosError } from 'axios';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

type ErrorStateProps = {
  error: AxiosError | null;
};

const ErrorState = ({ error }: ErrorStateProps) => {
  const { t } = useTranslation('base');

  return (
    <CustomAlert
      alertTitleProps={{ mr: 2 }}
      errorCode={error?.code}
      status="error"
      text={t(`error_state.text`)}
      title={t(`error_state.title`)}
    />
  );
};

const EmptyState = () => {
  const { t } = useTranslation('base');

  return (
    <CustomAlert
      alertIconProps={{ boxSize: '40px', mr: 0 }}
      alertTitleProps={{ mt: 4, mb: 1, fontSize: 'lg' }}
      alertDescriptionProps={{ maxWidth: 'sm' }}
      alignItems="center"
      flexDirection="column"
      height="200px"
      justifyContent="center"
      status="warning"
      text={t(`empty_state.text`)}
      textAlign="center"
      title={t(`empty_state.title`)}
      variant="subtle"
    />
  );
};

type SearchResponseProps = {
  loading: boolean;
  error: AxiosError | null;
  paginationData?: PaginationByFiltersProps;
  noResults: boolean;
};

const SearchResponseContent: React.FC<
  PropsWithChildren<SearchResponseProps>
> = ({ loading, error, noResults, children, paginationData }) => {
  if (error) {
    return <ErrorState error={error} />;
  }

  if (loading) {
    return (
      <Center>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  if (noResults && !loading) {
    return <EmptyState />;
  }

  return (
    <>
      {children}

      {paginationData && <Pagination {...paginationData} />}
    </>
  );
};

export default SearchResponseContent;
