import ModalButton from '../ui/ModalButton';
import { HStack, Spinner, StackProps } from '@chakra-ui/react';
import { Status } from '~/shared/enums/status';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ApprovalButtonsProps {
  onClick: (status: Status) => void;
  status?: Status;
  showSpinner?: boolean;
}

const ApprovalButtons: FC<
  ApprovalButtonsProps & Omit<StackProps, 'onClick'>
> = ({ onClick, status, showSpinner = false, ...props }) => {
  const { t } = useTranslation('base');

  if (showSpinner) {
    return <Spinner size="md" m="auto" />;
  }

  return (
    <HStack {...props}>
      <ModalButton
        type="button"
        colorScheme="red"
        disabled={status === Status.REJECTED}
        onClick={() => onClick(Status.REJECTED)}
      >
        {t('reject')}
      </ModalButton>
      <ModalButton
        type="button"
        colorScheme="green"
        disabled={status === Status.APPROVED}
        onClick={() => onClick(Status.APPROVED)}
      >
        {t('approve')}
      </ModalButton>
    </HStack>
  );
};

export default ApprovalButtons;
