import {
  SearchFilters,
  SearchFiltersOrderBy,
} from '~/shared/models/search/filters/search';
import { SearchProps } from '~/shared/models/search/search';

const getSearchFiltersByParamsType = <T>(value: SearchProps | T): T => {
  if (value && typeof value === 'object' && 'queryKey' in value) {
    return value.queryKey[1] as T;
  }

  return value;
};

const checkFilterChanges = <
  T extends SearchFilters & SearchFiltersOrderBy<Partial<T>>
>(
  filters: Partial<T>,
  initialFilters: Partial<T>,
  ignoreOrder = false
): boolean => {
  type FilterKeys = (keyof T)[];

  const keysToIgnore: FilterKeys = ['skip', 'limit'];

  if (ignoreOrder) {
    keysToIgnore.push('order', 'order_by');
  }

  return (Object.keys(filters) as FilterKeys).some((key) => {
    if (keysToIgnore.includes(key)) {
      return false;
    }

    return filters[key] !== initialFilters[key];
  });
};

export { getSearchFiltersByParamsType, checkFilterChanges };
