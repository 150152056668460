import { hasPermissionBuilder } from './helper/hasEmployeePermissionBuilder';
import useProfileStore from '~/profile/stores/profile';
import { MainRoutesPaths } from '~/routes/enums/main';
import { Permission } from '~/shared/enums/permissions';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useRouteGuard = (permissions?: Permission[]) => {
  const navigate = useNavigate();
  const { myProfile } = useProfileStore();
  const canAccess = hasPermissionBuilder(myProfile?.permissions);

  useEffect(() => {
    if (!myProfile || !canAccess(permissions)) {
      navigate(MainRoutesPaths.DASHBOARD);
    }
  }, []);
};

export default useRouteGuard;
