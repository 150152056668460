import { useGetProjectPositions } from '~/shared/hooks/project/useGetProjectPositions';
import { mapValueToOption } from '~/shared/mappers/selectOption';
import { SelectOption } from '~/shared/models/react-select/select-option';
import React, { FC, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

interface JobTitleSelectProps {
  id?: string;
  placeholder?: string;
  defaultValue?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  onChange?: (job?: string) => void;
}

const JobTitleSelect: FC<JobTitleSelectProps> = ({
  id = 'job-title-select',
  placeholder,
  defaultValue,
  isDisabled,
  isClearable = true,
  onChange,
}) => {
  const [selected, setSelected] = useState<SelectOption<string> | null>(null);
  const { data } = useGetProjectPositions();

  useEffect(() => {
    if (!defaultValue) {
      setSelected(null);
      return;
    }
    setSelected({
      value: defaultValue,
      label: defaultValue,
    });
  }, [defaultValue]);

  return (
    <CreatableSelect
      id={id}
      placeholder={placeholder}
      isClearable={isClearable}
      isDisabled={isDisabled}
      value={selected}
      options={data?.positions.map((jobTitle) =>
        mapValueToOption<string>(jobTitle.name)
      )}
      onChange={(option) => {
        onChange && onChange(option?.value as string);
        setSelected(option);
      }}
    />
  );
};

export default JobTitleSelect;
