import { EndpointPaths } from '~/shared/enums/endpointPaths';
import { EndpointItem, EndpointUrl } from '~/shared/interfaces/endpoints';
import { urlBuilder, urlItemBuilder } from '~/shared/utils/urlBuilders';

interface PermissionsEndpoints extends EndpointUrl {
  roles: EndpointItem<string>;
}

export const permissionsEndpoint: PermissionsEndpoints = {
  ...urlBuilder(EndpointPaths.PERMISSIONS),
  roles: urlItemBuilder<string>(
    `${EndpointPaths.PERMISSIONS}/${EndpointPaths.ROLES}`
  ),
};
