import {
  Checkbox,
  CheckboxGroup,
  HStack,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import { ActionsButton } from '~/shared/components/ui/table/ActionsButton';
import { CompleteTableHeader } from '~/shared/components/ui/table/CompleteTableHeader';
import {
  ColumnData,
  TableAction,
  TableRowData,
} from '~/shared/components/ui/table/types';
import { OrderDirection } from '~/shared/models/search/filters/search';
import React, { useState } from 'react';

export interface CompleteTableProps<T extends TableRowData> {
  headers: ColumnData<T>[];
  data: T[];
  hasCheckboxes?: boolean;
  singleRowActions?: boolean;
  actions?: TableAction[];
  sortableColumns?: string[];
  sorting?: { column: string; order: OrderDirection };
  onSelected?: (ids: T['id'][]) => void;
  onSort?: (column: string, order: OrderDirection) => void;
}

export const CompleteTable = <T extends TableRowData>({
  headers,
  data,
  hasCheckboxes,
  singleRowActions,
  actions,
  sortableColumns,
  sorting,
  onSelected,
  onSort,
}: CompleteTableProps<T>) => {
  const [checkedClients, setCheckedClients] = useState<TableRowData['id'][]>(
    []
  );
  const onCheckChange = (checked: TableRowData['id'][]) => {
    if (checked.includes('all')) {
      const allIds = data.map((c) => c.id);
      setCheckedClients(['all', ...allIds]);
      onSelected?.(allIds);
      return;
    }

    if (checkedClients.includes('all')) {
      setCheckedClients([]);
      onSelected?.([]);
      return;
    }

    if (typeof data[0].id === 'number') {
      const numberChecked = checked.map(Number);
      setCheckedClients(numberChecked);
      onSelected?.(numberChecked);
    } else {
      setCheckedClients(checked);
      onSelected?.(checked);
    }
  };
  return (
    <>
      {actions && hasCheckboxes && (
        <HStack w="full" my="4" justify="flex-end">
          <ActionsButton actions={actions} selected={checkedClients} />
        </HStack>
      )}
      <CheckboxGroup onChange={onCheckChange} value={checkedClients}>
        <Table variant="striped">
          <CompleteTableHeader<T>
            headers={headers}
            sortableColumns={sortableColumns}
            onSort={onSort}
            sorting={sorting}
            singleRowActions={singleRowActions}
            hasCheckboxes={hasCheckboxes}
          />
          <Tbody>
            {data.map((row) => (
              <Tr key={`table-row-${row.id}`}>
                {hasCheckboxes && (
                  <Td>
                    <Checkbox value={row.id}></Checkbox>
                  </Td>
                )}
                {headers.map((header) => (
                  <Td key={`table-row-${row.id}-column-${String(header.id)}`}>
                    {header.value
                      ? header.value(row)
                      : (row[header.id] as string)}
                  </Td>
                ))}
                {singleRowActions && actions && (
                  <Td>
                    <ActionsButton
                      actions={actions}
                      selected={[row.id]}
                    ></ActionsButton>
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </CheckboxGroup>
    </>
  );
};
