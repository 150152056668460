import { searchLanguages } from '~/shared/api/request/languages';
import { initialFilters } from '~/shared/constants/search';
import { QueriesTypes } from '~/shared/enums/queries';
import { Language } from '~/shared/models/api/language';
import { SearchFilters } from '~/shared/models/search/filters/search';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useGetLanguages = (
  filters: SearchFilters = { ...initialFilters, limit: 1000 }
) => {
  return useQuery<Language[], AxiosError>({
    queryKey: [QueriesTypes.SEARCH_LANGUAGES, { ...filters }],
    queryFn: searchLanguages,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
