import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Button, ButtonProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface AdvancedFiltersButtonProps {
  isOpen: boolean;
  onClick: () => void;
}

const AdvancedFiltersButton: FC<AdvancedFiltersButtonProps & ButtonProps> = ({
  isOpen,
  onClick,
  ...props
}) => {
  const { t } = useTranslation('base');
  return (
    <Button
      variant="link"
      colorScheme="blue"
      fontSize="sm"
      fontWeight="normal"
      onClick={onClick}
      {...props}
      _focus={{
        outline: 'none',
      }}
    >
      {isOpen ? (
        <>
          {t('filters.hide_advanced')} <ChevronUpIcon boxSize={5} ml={1} />
        </>
      ) : (
        <>
          {t('filters.show_advanced')} <ChevronDownIcon boxSize={5} ml={1} />
        </>
      )}
    </Button>
  );
};

export default AdvancedFiltersButton;
