import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { loginRequest } from '~/authentication/config';
import fetcher from '~/shared/api/fetcher';

const HOUR = 60 * 60;
const DAY = 24 * HOUR;
const THREE_DAYS_IN_SECONDS = 3 * DAY;

const setupTokenInterceptors = (msalInstance: IPublicClientApplication) => {
  fetcher.interceptors.request.use(
    async (config) => {
      const account = msalInstance.getActiveAccount();
      const request = {
        ...loginRequest,
        account: account as AccountInfo,
        forceRefresh: true,
        refreshTokenExpirationOffsetSeconds: THREE_DAYS_IN_SECONDS,
      };
      const { accessToken } = await msalInstance.acquireTokenSilent(request);
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

export default setupTokenInterceptors;
