import { Permission } from '~/shared/enums/permissions';

export const adminPermissions = [
  Permission.CERTIFICATES,
  Permission.CLIENT_MAPPING,
  Permission.PERMISSIONS,
  Permission.PERMISSION_GROUPS,
  Permission.VERIFY_ALL_PROFILES,
  Permission.PROJECTS,
  Permission.SKILLS,
  Permission.TRAININGS,
  Permission.VERIFY_CERTIFICATES,
  Permission.VERIFY_TRAININGS,
];
