import { useToast } from '@chakra-ui/react';
import { updateEmployeePermissions } from '~/shared/api/request/employee';
import { updateRolePermissions as updateRolePermissionsRequest } from '~/shared/api/request/permissions';
import { getErrorMessage } from '~/shared/errors/error';
import { UpdateEmployeePermissions } from '~/shared/models/api/employee';
import {
  PermissionData,
  UpdateRolePermissions,
} from '~/shared/models/api/permissions';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

interface UsePermissionsType {
  onEmployeePermissionsChange: (
    employee_id: number,
    permissions: PermissionData[],
    callback?: () => void
  ) => void;
  onRolePermissionsChange: (
    updatedRole: string,
    permissions_ids: number[],
    callback?: () => void
  ) => void;
}

const usePermissions = (): UsePermissionsType => {
  const toast = useToast();
  const { t } = useTranslation('permissions');

  const updateRolePermissions = useMutation(
    (rolePermissions: UpdateRolePermissions) =>
      updateRolePermissionsRequest(rolePermissions),
    {
      onSuccess: () => {
        toast({
          title: t(`update.role_permissions.title`),
          description: t(`update.role_permissions.success`),
          status: 'success',
        });
      },
      onError: (err: AxiosError) => {
        toast({
          title: t(`update.role_permissions.title`),
          description: getErrorMessage(
            err.response?.status,
            t(`update.role_permissions.fail`),
            t
          ),
          status: 'error',
        });
      },
    }
  );

  const updateUserPermissions = useMutation(
    (permissionsUpdate: UpdateEmployeePermissions) =>
      updateEmployeePermissions({ ...permissionsUpdate }),
    {
      onSuccess: () => {
        toast({
          title: t(`update.employee_permissions.title`),
          description: t(`update.employee_permissions.success`),
          status: 'success',
        });
      },
      onError: (err: AxiosError) => {
        toast({
          title: t(`update.employee_permissions.title`),
          description: getErrorMessage(
            err.response?.status,
            t(`update.employee_permissions.fail`),
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return {
    onEmployeePermissionsChange: (
      employee_id: number,
      permissions: PermissionData[],
      callback?: () => void
    ) => {
      updateUserPermissions.mutateAsync(
        {
          employee_id,
          permissions_ids: permissions.map((permission) => permission.id),
        },
        {
          onSuccess: () => {
            callback && callback();
          },
        }
      );
    },

    onRolePermissionsChange: (
      role: string,
      permissions: number[],
      callback?: () => void
    ) => {
      updateRolePermissions.mutateAsync(
        { role, permissions },
        {
          onSuccess: () => {
            callback && callback();
          },
        }
      );
    },
  };
};

export default usePermissions;
