import { useToast } from '@chakra-ui/react';
import { getProjectPotentialChildren } from '~/shared/api/request/projects';
import { QueriesTypes } from '~/shared/enums/queries';
import { Project } from '~/shared/models/api/project';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

interface UseGetProjectPotentialChildren {
  data: Project[] | undefined;
  failLoading: boolean;
  isLoading: boolean;
}

export const useGetProjectPotentialChildren = (
  projectId?: number,
  query = ''
): UseGetProjectPotentialChildren => {
  const { t } = useTranslation('projects');
  const toast = useToast();

  const { data, isError, isFetching } = useQuery<Project[], AxiosError>({
    queryKey: [QueriesTypes.GET_PROJECT_POTENTIAL_CHILDREN, projectId, query],
    queryFn: ({ queryKey }) =>
      getProjectPotentialChildren(Number(queryKey[1]), String(queryKey[2])),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: () => {
      toast({
        title: t(`something_went_wrong`),
        description: t(`potential_children_load_fail`),
        status: 'error',
      });
    },
  });

  return { isLoading: isFetching, failLoading: isError, data };
};
