import { DateFormat, DatePickerFormat } from '~/shared/enums/date';
import { addMonths, isWithinInterval, parse } from 'date-fns';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const formatDate = (
  date: dayjs.ConfigType,
  format: DateFormat = DateFormat.YEAR_MONTH,
  defaultValue = ''
) => {
  return date ? dayjs(date).format(format) : defaultValue;
};

export const fileNameDate = () => {
  return formatDate(dayjs(), DateFormat.FILE_NAME);
};

export const checkExpirationMonths = (
  expirationDate: string,
  expireMonths = 3
) => {
  const expiration = parse(
    expirationDate,
    DatePickerFormat.YEAR_MONTH,
    new Date()
  );

  return isWithinInterval(new Date(), {
    start: addMonths(expiration, -expireMonths),
    end: expiration,
  });
};
