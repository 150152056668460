import { Text, TextProps } from '@chakra-ui/react';
import React, { FC } from 'react';

interface InputLabelProps {
  label: string;
}

const InputLabel: FC<InputLabelProps & TextProps> = ({ label, ...props }) => {
  return (
    <Text mb={2} {...props}>
      {label}
    </Text>
  );
};

export default InputLabel;
