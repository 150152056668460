import { OTHER_LINK } from '~/shared/constants/social-links';
import { SocialNetwork } from '~/shared/models/api/social';
import { object, string } from 'yup';

const emailRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/g;
const usernameRegexValidation = /^[^.]+$/g;

export type SocialLinkFormType = {
  url: string;
  network: SocialNetwork | null;
  user_name: string;
};

export const defaultNewSocialLink = (): SocialLinkFormType => ({
  url: '',
  network: null,
  user_name: '',
});

export const socialLinkSchema = object().shape({
  network: object().required('Network is required.'),
  user_name: string()
    .required('Username or url is required.')
    .when('network', ([network], schema) => {
      return network.slug === OTHER_LINK.slug
        ? schema.matches(emailRegex, 'Please provide a valid URL.')
        : schema.matches(
            usernameRegexValidation,
            'Please provide a valid username.'
          );
    }),
});
