import MissingFileIcon from '../../../shared/components/ui/MissingFileIcon';
import { DateIntervalTag } from '../shared/DateIntervalTag';
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Image, Text, Tooltip } from '@chakra-ui/react';
import { CustomTag } from '~/shared/components/ui/CustomTag';
import { Status, statusColorScheme } from '~/shared/enums/status';
import { EmployeeCertification } from '~/shared/models/api/employee';
import { useLoadAzureBlob } from '~/shared/queries/useLoadAzureBlob';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CiFileOn } from 'react-icons/ci';

interface CertificateProps {
  certificate: EmployeeCertification;
  onDelete: () => void;
}

const Certificate: React.FC<CertificateProps> = ({ certificate, onDelete }) => {
  const { t } = useTranslation('profile');

  const statusText =
    certificate.status !== Status.APPROVED
      ? t(`base:status_types.${certificate.status.toLowerCase()}`)
      : undefined;

  const { data: img } = useLoadAzureBlob(certificate.certificate.logo);

  return (
    <Box
      borderColor="gray.100"
      borderWidth={1}
      h="150px"
      overflow="hidden"
      position="relative"
      rounded={8}
      shadow="sm"
      w="150px"
      _hover={{ borderColor: 'gray.200', shadow: 'md' }}
    >
      <Tooltip
        label={
          <Box p={2}>
            <Text fontWeight="bold" mb={2}>
              {certificate.certificate.name}
            </Text>
            <DateIntervalTag
              endDate={certificate.expiring_date}
              endDateReplacementI18nKey={'base:permanent'}
              startDate={certificate.adquired_date}
            />
            {statusText && (
              <CustomTag colorScheme={statusColorScheme[certificate.status]}>
                {statusText}
              </CustomTag>
            )}
            {!certificate.certification_file && (
              <Text
                as={Flex}
                alignContent="center"
                bg="red.50"
                color={'red.500'}
                fontSize="xs"
                fontStyle="italic"
                fontWeight="thin"
                mt={2}
                p={2}
                rounded={4}
                textAlign="center"
              >
                <MissingFileIcon
                  as={CiFileOn}
                  boxSize={5}
                  tooltip={t('missing_certification_file')}
                />
                {t('missing_certification_file')}
              </Text>
            )}
          </Box>
        }
      >
        {certificate.certificate.logo ? (
          <Image
            alt={certificate.certificate.name}
            h="150px"
            loading="eager"
            objectFit="cover"
            position="absolute"
            rounded={8}
            src={img}
            w="150px"
          />
        ) : (
          <Text
            alignContent="center"
            color="gray.600"
            h="150px"
            p={2}
            position="absolute"
            rounded={8}
            textAlign="center"
            w="150px"
          >
            {certificate.certificate.name}
          </Text>
        )}
      </Tooltip>
      {!certificate.certification_file && (
        <Flex bottom="3px" left="0" position="absolute">
          <MissingFileIcon
            as={CiFileOn}
            bg="white"
            borderWidth={1}
            borderColor="red.200"
            boxSize={5}
            rounded={4}
            shadow="sm"
            tooltip={t('missing_certification_file')}
          />
        </Flex>
      )}
      <Tooltip
        label={t('delete_with_description', {
          description: certificate.certificate.name,
        })}
      >
        <IconButton
          alignContent="center"
          aria-label={`Delete "${certificate.certificate.name}"`}
          bg="white"
          boxSize={5}
          color={'red.400'}
          icon={<DeleteIcon />}
          onClick={() => onDelete()}
          position="absolute"
          right="3px"
          rounded={4}
          size="xl"
          top="3px"
          variant="link"
          _hover={{ color: 'red.600' }}
        />
      </Tooltip>
    </Box>
  );
};

export default Certificate;
