import { PermissionModal } from './permissions/PermissionModal';
import { EditIcon } from '@chakra-ui/icons';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import usePermissions from '~/administration/hooks/permissions/usePermissions';
import { RoleData } from '~/shared/models/api/permissions';
import { useGetPermissions } from '~/shared/queries/useGetPermissions';
import React from 'react';

interface Props {
  role: RoleData;
  refetch: () => void;
}

export const PermissionGroupModalButton = ({ role, refetch }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { onRolePermissionsChange } = usePermissions();
  const { data: permissionList = [] } = useGetPermissions();

  const selectedPermissions = role.permissions.map(
    ({ permission }) => permission.id
  );

  return (
    <>
      <PermissionModal
        title={role.id}
        list={permissionList}
        selected={selectedPermissions}
        isOpen={isOpen}
        onChange={(permissions) => {
          onRolePermissionsChange(
            role.id,
            permissions.map((p) => p.id),
            refetch
          );
        }}
        onClose={onClose}
      />
      <Button onClick={onOpen} backgroundColor="white" minW={48}>
        <Flex gap={2}>
          <EditIcon />
          {role.id}
        </Flex>
      </Button>
    </>
  );
};
