import { ObjectKey, ObjectValue } from '../models/object';
import { mixed } from 'yup';

const matchObjectByDirtyValues = <TItem extends Record<ObjectKey, ObjectValue>>(
  allValues: TItem,
  dirtyValues: TItem
) => {
  return Object.fromEntries(
    Object.entries(dirtyValues).map(([key]: [string, ObjectValue]) => {
      return [key, allValues[key]];
    })
  );
};

const yupFileValidation = (accept: string) => {
  return mixed().test('type', 'Invalid file type', (value) => {
    if (!value) return true;
    return 'type' in value && accept.includes(value.type as string);
  });
};

export { matchObjectByDirtyValues, yupFileValidation };
