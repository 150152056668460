import { getFilterEmployeeParameters } from '~/shared/api/request/employee';
import { locationNameToKey } from '~/shared/mappers/locationNameToKey';
import {
  EmployeeFilterParams,
  SingleFilterParameter,
} from '~/shared/models/api/employee';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface UseEmployeeFilterParamsProps {
  filterParams: EmployeeFilterParams | null;
  loaded: boolean;
}

const useEmployeeFilterParams = (): UseEmployeeFilterParamsProps => {
  const [filterParams, setFilterParams] = useState<EmployeeFilterParams | null>(
    null
  );
  const [loaded, setLoaded] = useState<boolean>(false);
  const { t } = useTranslation('base');

  const mapLocations = (
    locations: SingleFilterParameter[]
  ): SingleFilterParameter[] => {
    return locations.map((location) => {
      return {
        ...location,
        name: t(`locations.${locationNameToKey(location.name)}`),
      };
    });
  };

  useEffect(() => {
    async function getFilterParams() {
      setFilterParams(null);
      setLoaded(false);
      const data: EmployeeFilterParams = await getFilterEmployeeParameters();
      data.locations = mapLocations(data.locations);
      setFilterParams(data);
      setLoaded(true);
    }

    getFilterParams();
  }, []);

  return { filterParams, loaded };
};

export default useEmployeeFilterParams;
