import { useGetProjectChildren } from '../../shared/queries/useGetProjectChildren';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import EditModal from '~/administration/components/projects/EditModal';
import ProjectsSearchFilters from '~/administration/components/projects/filters/ProjectsSearchFilters';
import { ProjectAccordionTableHeaders } from '~/administration/components/projects/ProjectAccordionTableHeaders';
import { ProjectAccordionTableItem } from '~/administration/components/projects/ProjectAccordionTableItem';
import ProjectChildrenList from '~/administration/components/projects/ProjectChildrenList';
import ProjectMapModal from '~/administration/components/projects/ProjectMapModal';
import SearchResponseContent from '~/administration/components/SearchResponseContent';
import useProjects from '~/administration/hooks/projects/useProjects';
import { ActionButtons } from '~/profile/components/shared/ActionButtons';
import { DateIntervalTag } from '~/profile/components/shared/DateIntervalTag';
import { CustomTag } from '~/shared/components/ui/CustomTag';
import { DateFormat } from '~/shared/enums/date';
import { Permission } from '~/shared/enums/permissions';
import { projectStatusTypesColors } from '~/shared/enums/projectStatus';
import useRouteGuard from '~/shared/hooks/permissions/useRouteGuard';
import { Project } from '~/shared/models/api/project';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ProjectAccordionTableHeadersProps<T> {
  childWidth?: string;
  component?: React.FC<T>;
  header: string;
  isSortable?: boolean;
  key: keyof T | 'actions';
  width: string;
}

const Projects: React.FC = () => {
  useRouteGuard([Permission.PROJECTS]);

  const { t } = useTranslation(['base', 'projects']);

  const {
    editModal,
    handleAccordionItemClick,
    handleEditButtonClick,
    handleMapButtonClick,
    mapModal,
    onSort,
    parentProjects,
    projectClicked,
    searchFilters,
  } = useProjects();

  const { filters, onFilterChange, onPageChange, ...searchFilterRest } =
    searchFilters;

  const getChildrenProjects = useGetProjectChildren(projectClicked?.id);

  const calcWidth = (vw: number) => `calc(${vw}vw - 120px)`;

  const headers: ProjectAccordionTableHeadersProps<Project>[] = [
    {
      component: (project) => (
        <DateIntervalTag
          startDate={project.start_date.toString()}
          dateFormat={DateFormat.DAY_MONTH_YEAR_SHORT_SLASH}
        />
      ),
      header: t(`base:date.start_date`),
      isSortable: true,
      key: 'start_date',
      width: '100px',
    },
    {
      component: (project) => (
        <DateIntervalTag
          startDate={project.end_date ? project.end_date?.toString() : ''}
          dateFormat={DateFormat.DAY_MONTH_YEAR_SHORT_SLASH}
        />
      ),
      header: t(`base:date.end_date`),
      isSortable: true,
      key: 'end_date',
      width: '100px',
    },
    {
      component: (project) =>
        project.project_state && (
          <CustomTag
            bg={projectStatusTypesColors[project.project_state]}
            w="min-content"
            justifySelf="center"
          >
            {project.project_state}
          </CustomTag>
        ),
      header: t(`base:state`),
      key: 'project_state',
      width: '100px',
    },
    {
      childWidth: calcWidth(96),
      header: t(`projects:headers.project_alias`),
      isSortable: true,
      key: 'sms_title',
      width: calcWidth(25),
    },
    {
      component: (project) => (
        <Tooltip rounded={8} label={project?.industry?.name as string}>
          <Text isTruncated>{project?.industry?.name}</Text>
        </Tooltip>
      ),
      header: t(`base:industry`),
      key: 'industry',
      width: calcWidth(15),
    },
    {
      header: t(`base:client`),
      isSortable: true,
      key: 'client_name',
      width: calcWidth(15),
    },
    {
      component: (project) => (
        <Tooltip
          rounded={8}
          dangerouslySetInnerHTML={{ __html: project.description }}
        >
          <Text
            maxHeight="40px"
            overflowY="scroll"
            as="div"
            dangerouslySetInnerHTML={{ __html: project.description }}
          />
        </Tooltip>
      ),
      header: t(`base:description`),
      key: 'description',
      width: calcWidth(40),
    },
    {
      component: (project) => (
        <Flex w="80px">
          <ActionButtons
            tooltipLabel={project.title}
            onClickEdit={() => handleEditButtonClick(project)}
            onClickMap={() => handleMapButtonClick(project)}
          />
        </Flex>
      ),
      header: t(`base:actions`),
      key: 'actions',
      width: '100px',
    },
  ];

  return (
    <>
      <EditModal
        isOpen={editModal.isOpen}
        onClose={editModal.onClose}
        onChanged={() => parentProjects.refetch()}
        project={projectClicked}
      />

      <ProjectMapModal
        failLoading={getChildrenProjects.failLoading}
        isLoading={getChildrenProjects.isLoading}
        isOpen={mapModal.isOpen}
        onClose={mapModal.onClose}
        projectChildren={getChildrenProjects?.children}
        projectId={projectClicked?.id}
        title={projectClicked?.title}
      />

      <ProjectsSearchFilters
        {...searchFilterRest}
        filters={filters}
        onFilterChange={onFilterChange}
      />

      <SearchResponseContent
        loading={parentProjects.isFetching}
        error={parentProjects.error}
        noResults={parentProjects.data?.page_results.length === 0}
        paginationData={{
          filters: filters,
          totalItems: parentProjects.data?.total_results_count || 0,
          onPageChange: onPageChange,
        }}
      >
        <Flex gap={2} px="16px" rounded={8} w="full">
          <Flex w={`20px`} minW={`20px`}></Flex>
          <ProjectAccordionTableHeaders
            headers={headers}
            onSort={onSort}
            filters={searchFilters.filters}
          />
        </Flex>

        <Accordion allowToggle>
          {parentProjects.data?.page_results.map((project, index) => (
            <AccordionItem
              key={`accordion-item-project-${project.id}-index-${index}`}
              onClick={() => handleAccordionItemClick(project)}
            >
              <AccordionButton
                _expanded={{ bg: 'gray.200' }}
                alignItems="center"
                as={Flex}
                bg={index % 2 !== 0 ? '' : 'gray.50'}
                fontSize="sm"
                gap={2}
                rounded={8}
                w="full"
              >
                <Flex w={`20px`} minW={`20px`}>
                  <AccordionIcon />
                </Flex>

                <ProjectAccordionTableItem
                  project={project}
                  headers={headers}
                />
              </AccordionButton>

              <AccordionPanel
                bg="gray.100"
                border="1px whiteSmoke solid"
                mb={8}
                ml={8}
                rounded={8}
                shadow="md"
              >
                <ProjectChildrenList
                  data={getChildrenProjects.data}
                  failLoading={getChildrenProjects.failLoading}
                  isLoading={getChildrenProjects.isLoading}
                  headers={headers}
                  title={project.title}
                  projectId={projectClicked?.id}
                  handleEditProjectClick={handleEditButtonClick}
                />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </SearchResponseContent>
    </>
  );
};

export default Projects;
