import { SkillLevel } from '~/shared/enums/skills';
import { Employee, EmployeeSkill } from '~/shared/models/api/employee';
import { Skill } from '~/shared/models/api/skills';
import { number, object } from 'yup';

export type SkillsForm = {
  main_skills: EmployeeSkill[];
};

export interface SkillFormAdd {
  skill: Skill;
  skill_level_id: SkillLevel;
  employee_id?: number;
}

export type SkillFormType = Partial<SkillFormAdd>;

export const newSkillSchema = object().shape({
  skill: object().required('Skill is required.'),
  skill_level_id: number().required('Skill level is required.'),
});

export const defaultNewSkill = (profile: Employee | null): SkillFormType => {
  return {
    skill: undefined,
    skill_level_id: undefined,
    employee_id: profile?.id,
  };
};

export const mapSkill = (skillForm: SkillFormAdd): EmployeeSkill => ({
  skill_id: skillForm.skill.id,
  skill_type: skillForm.skill.type,
  skill_level_id: skillForm.skill_level_id,
  skill: skillForm.skill,
  employee_id: skillForm.employee_id,
});
