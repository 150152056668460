import { getRoles } from '../api/request/permissions';
import { QueriesTypes } from '~/shared/enums/queries';
import { RoleData } from '~/shared/models/api/permissions';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useGetRoles = () => {
  return useQuery<RoleData[], AxiosError>({
    queryKey: [QueriesTypes.GET_ROLES],
    queryFn: getRoles,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
