import { Text } from '@chakra-ui/react';
import React from 'react';

const Header: React.FC<{ text: string }> = ({ text }) => (
  <Text
    w="100%"
    background="gray.100"
    borderWidth="2px"
    borderColor="gray.200"
    p="2"
    mb="2"
    fontWeight="500"
    letterSpacing=".5px"
  >
    {text}
  </Text>
);

export default Header;
