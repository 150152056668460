import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import { ButtonTypes } from '~/shared/enums/actionButton';
import { IconType } from 'react-icons';
import { BiAddToQueue } from 'react-icons/bi';
import { CiMenuKebab } from 'react-icons/ci';
import { GrTree } from 'react-icons/gr';

export interface IActionButton {
  icon: ComponentWithAs<'svg', IconProps> | IconType;
  translationKey: string;
}

export type ActionButtonType = Record<ButtonTypes, IActionButton>;

export const actionButtonTypes: ActionButtonType = {
  [ButtonTypes.Add]: {
    icon: BiAddToQueue,
    translationKey: 'add',
  },
  [ButtonTypes.Delete]: {
    icon: DeleteIcon,
    translationKey: 'delete',
  },
  [ButtonTypes.Edit]: {
    icon: EditIcon,
    translationKey: 'edit',
  },

  [ButtonTypes.Map]: {
    icon: GrTree,
    translationKey: 'map',
  },
  [ButtonTypes.Actions]: {
    icon: CiMenuKebab,
    translationKey: 'actions',
  },
};
