import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import { GridViewModes } from '~/shared/enums/gridViewModes';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';

type SkillsGridProps = {
  mode: GridViewModes;
  children?: ReactNode;
};

const SkillsGrid: FC<SkillsGridProps> = ({ children, mode }) => {
  const [gridProps, setGridProps] = useState<SimpleGridProps>({});

  const getGridPropsByMode = useCallback(() => {
    switch (mode) {
      case GridViewModes.HORIZONTAL:
        return {
          gap: 2,
        };
      case GridViewModes.VERTICAL:
        return {
          gridAutoFlow: 'column',
          gap: 5,
        };
      default:
        return {
          columns: {
            base: 1,
            md: 2,
            lg: 3,
            xl: 4,
          },
          gap: 5,
        };
    }
  }, [mode]);

  useEffect(() => {
    const updateGridProps = () => {
      const props = getGridPropsByMode();
      setGridProps(props);
    };
    updateGridProps();
  }, [mode, getGridPropsByMode]);

  return (
    <SimpleGrid {...gridProps} overflowX="auto" paddingBottom={8}>
      {children && children}
    </SimpleGrid>
  );
};

export default SkillsGrid;
