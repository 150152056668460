import LanguagesProficiencyInfoPopover from './LanguagesProficiencyInfoPopover';
import { Badge, Stack, Text, Tooltip } from '@chakra-ui/react';
import {
  LanguageProficiency,
  LanguageProficiencyBackgroundColor,
  LanguageProficiencyBorderColor,
} from '~/shared/enums/language';
import { EmployeeLanguage } from '~/shared/models/api/employee';
import { orderEmployeeLanguagesByProficiency } from '~/shared/utils/languages';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface LanguageListProps {
  languages: EmployeeLanguage[];
}

const LanguageList: React.FC<LanguageListProps> = ({ languages }) => {
  const { t } = useTranslation('profile');

  return (
    <Stack>
      <LanguagesProficiencyInfoPopover />
      {orderEmployeeLanguagesByProficiency(languages).map((l) => (
        <Stack key={l.language_id}>
          <Badge
            w="fit-content"
            borderRadius={25}
            backgroundColor={
              LanguageProficiencyBackgroundColor[l.language_level_id]
            }
            borderColor={LanguageProficiencyBorderColor[l.language_level_id]}
            borderWidth={1}
          >
            <Tooltip
              label={t(
                `language_proficiency.${LanguageProficiency[
                  l.language_level_id
                ].toLowerCase()}`
              )}
            >
              <Text px={2}>{l.language?.name}</Text>
            </Tooltip>
          </Badge>
        </Stack>
      ))}
    </Stack>
  );
};

export default LanguageList;
