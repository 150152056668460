import { search } from '~/shared/api/endpoints';
import { languagesEndpoint } from '~/shared/api/urls/languages';
import { Language } from '~/shared/models/api/language';
import { SearchFilters } from '~/shared/models/search/filters/search';
import { SearchProps } from '~/shared/models/search/search';
import { getSearchFiltersByParamsType } from '~/shared/utils/search';

export const searchLanguages = async (
  value: SearchProps | SearchFilters
): Promise<Language[]> => {
  const searchFilters = getSearchFiltersByParamsType(value);

  const { data } = await search<Language>(languagesEndpoint.url, searchFilters);

  return data;
};
