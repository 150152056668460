import { Thead, Tr } from '@chakra-ui/react';
import { EmployeeTableKeys } from '~/administration/models/employee';
import { SortableHeader } from '~/shared/components/ui/table/SortableHeader';
import { EmployeeOrderBy } from '~/shared/models/search/filters/employees';
import { OrderDirection } from '~/shared/models/search/filters/search';
import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

type EmployeesSearchTableTheadProps = {
  keys?: EmployeeTableKeys;
  onSort?: (key: EmployeeOrderBy, order: OrderDirection) => void;
  sortableColumns?: string[];
  sorting?: { column: string; order: OrderDirection };
};

const EmployeesSearchTableThead: FC<
  PropsWithChildren<EmployeesSearchTableTheadProps>
> = ({ children, keys, onSort, sortableColumns, sorting }) => {
  const { t } = useTranslation('employees');

  return (
    <Thead>
      <Tr>
        {keys?.map((key) => (
          <SortableHeader
            key={`employees-search-table-header-${key}`}
            isSortable={sortableColumns?.includes(key) ?? false}
            onChangeOrder={(order: OrderDirection) =>
              onSort?.(key as EmployeeOrderBy, order)
            }
            overflow="hidden"
            whiteSpace="nowrap"
            sortedDirection={
              sorting?.column === key ? sorting.order : undefined
            }
          >
            {t(`employee.${key}`)}
          </SortableHeader>
        ))}
        {children && children}
      </Tr>
    </Thead>
  );
};

export default EmployeesSearchTableThead;
