import AdvancedFiltersClearButton from '../../advanced-filters/buttons/AdvancedFiltersClearButton';
import AdvancedFiltersElement from '../../advanced-filters/filters/AdvancedFiltersElement';
import AdvancedFiltersHeaderContainer from '../../advanced-filters/header/AdvancedFiltersHeaderContainer';
import AdvancedFiltersHeaderLeftContent from '../../advanced-filters/header/AdvancedFiltersHeaderLeftContentContainer';
import VerificationSearchVerifiedCheckbox from './VerificationSearchVerifiedCheckbox';
import SearchInput from '~/shared/components/SearchInput';
import { PartialEmployeeFilters } from '~/shared/models/search/filters/employees';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface VerificationSearchFiltersProps {
  filters: PartialEmployeeFilters;
  isFilterChanged: boolean;
  onFilterChange: (partialFilters: PartialEmployeeFilters) => void;
  onQueryChange: (query: string) => void;
  resetFilters: () => void;
}

const VerificationSearchFilters: FC<VerificationSearchFiltersProps> = ({
  filters,
  onFilterChange,
  onQueryChange,
  isFilterChanged,
  resetFilters,
}) => {
  const { t } = useTranslation('verification');

  return (
    <>
      <AdvancedFiltersHeaderContainer>
        <AdvancedFiltersHeaderLeftContent
          templateColumns={{ md: '1fr 1fr auto' }}
        >
          <SearchInput
            placeholder={t('search')}
            query={filters.query}
            onChange={onQueryChange}
            mb={0}
          />
          <AdvancedFiltersElement
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <VerificationSearchVerifiedCheckbox
              filters={filters}
              onFilterChange={onFilterChange}
            />
          </AdvancedFiltersElement>

          <AdvancedFiltersClearButton
            disabled={!isFilterChanged}
            onClick={resetFilters}
          />
        </AdvancedFiltersHeaderLeftContent>
      </AdvancedFiltersHeaderContainer>
    </>
  );
};

export default VerificationSearchFilters;
