import {
  Box,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import ModalButton from '~/shared/components/ui/ModalButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PermissionBase<T extends number | string> {
  id: T;
  name: string;
}

interface Props<T extends number | string> {
  title: string;
  list: PermissionBase<T>[];
  selected: T[];
  disabled?: T[];
  isOpen: boolean;
  onClose: () => void;
  onChange?: (permissions: PermissionBase<T>[]) => void;
}

export const PermissionModal = <T extends number | string>({
  title,
  list,
  selected,
  disabled,
  isOpen,
  onClose,
  onChange,
}: Props<T>) => {
  const { t } = useTranslation('base');
  const [userSelected, setUserSelected] = useState<T[]>();

  const handleCheckboxCheck = (newSelected: T) => {
    if (!userSelected) {
      return;
    }
    if (userSelected.includes(newSelected)) {
      setUserSelected(userSelected.filter((role) => role !== newSelected));
      return;
    }

    setUserSelected([...userSelected, newSelected]);
  };

  const handleClose = () => {
    setUserSelected(undefined);
    onClose();
  };

  const handleSave = () => {
    if (!userSelected) {
      return;
    }
    onChange && onChange(list.filter((p) => userSelected.includes(p.id)));
    handleClose();
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setUserSelected(selected);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        {userSelected && (
          <ModalBody textAlign="center">
            <Box display="inline-block">
              <Stack spacing={1}>
                {list.map((data) => (
                  <Checkbox
                    spacing={8}
                    isChecked={userSelected.includes(data.id)}
                    isDisabled={disabled && disabled.includes(data.id)}
                    onChange={() => handleCheckboxCheck(data.id)}
                    key={data.id}
                  >
                    {data.name}
                  </Checkbox>
                ))}
              </Stack>
            </Box>
          </ModalBody>
        )}
        <ModalFooter justifyContent="center" pt={6}>
          <HStack spacing={6}>
            <ModalButton type="button" mr={3} onClick={onClose}>
              {t('cancel')}
            </ModalButton>
            <ModalButton
              type="button"
              colorScheme="blue"
              onClick={() => handleSave()}
            >
              {t('save')}
            </ModalButton>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
