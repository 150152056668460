import { VStack } from '@chakra-ui/react';
import SortableIcon from '~/shared/components/ui/sortable-icons/SortableIcon';
import { OrderDirection } from '~/shared/models/search/filters/search';
import React, { FC } from 'react';

interface SortableIconsProps {
  order?: OrderDirection;
}

const SortableIcons: FC<SortableIconsProps> = ({ order }) => {
  return (
    <VStack spacing={-0.5}>
      {order ? (
        <SortableIcon order={order} size="large" active />
      ) : (
        <>
          <SortableIcon order={OrderDirection.ASCENDING} />
          <SortableIcon order={OrderDirection.DESCENDING} />
        </>
      )}
    </VStack>
  );
};

export default SortableIcons;
