import { useToast } from '@chakra-ui/react';
import { createIndustry } from '~/shared/api/request/industries';
import { getErrorMessage } from '~/shared/errors/error';
import { CreateIndustry, Industry } from '~/shared/models/api/industry';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

interface UseIndustryCreateType {
  create: UseMutationResult<Industry, unknown, CreateIndustry, unknown>;
}

const useIndustryCreate = (): UseIndustryCreateType => {
  const toast = useToast();
  const { t } = useTranslation('permissions');

  const create = useMutation(
    (createNewIndustry: CreateIndustry) =>
      createIndustry({ ...createNewIndustry }),
    {
      onSuccess: (newIndustry: Industry) => {
        toast({
          title: newIndustry.name,
          description: 'Certificate Created Successfully',
          status: 'success',
        });
      },
      onError: (err: AxiosError, industryFail: CreateIndustry) => {
        toast({
          title: industryFail.name || 'Error Creating Certificate',
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Creating Certificate',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return {
    create,
  };
};

export default useIndustryCreate;
