import { ObjectValue } from '../models/object';
import { FieldValues } from 'react-hook-form';

// converts values returned by useFieldArray to type - react-hook-form
export const mapFieldArrayWithIdToTypeIdNumber = <T>(
  elements: FieldValues
): T[] => [
  ...elements.map((el: Record<string, ObjectValue>) => {
    if (typeof el.id === 'string') {
      return { ...el, id: parseInt(el.id, 10) };
    }
    return { ...el };
  }),
];
