import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface RoundedButtonProps {
  isLoading?: boolean;
  linkTo?: string;
  tooltipLabel?: string;
}

const RoundedButton: FC<
  PropsWithChildren<RoundedButtonProps> & ButtonProps
> = ({ children, isLoading, linkTo, tooltipLabel, ...buttonProps }) => {
  return (
    <Tooltip aria-label="tooltip" label={tooltipLabel}>
      <Button
        alignContent="center"
        as={Link}
        backgroundColor="white"
        border="1px"
        borderColor="gray.400"
        boxShadow="sm"
        color="gray.700"
        height={10}
        isLoading={isLoading}
        marginLeft={2}
        padding={0}
        rounded={20}
        width={10}
        _hover={{
          backgroundColor: 'gray.50',
          borderColor: 'gray.600',
          boxShadow: 'xl',
          color: 'black',
        }}
        {...(linkTo ? { to: linkTo } : {})}
        {...buttonProps}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default RoundedButton;
