import { SkillType } from '~/shared/models/api/skills';
import { DisableType } from '~/shared/models/form/disable';
import { useSkillsTypes } from '~/shared/queries/skills/useSkillsTypes';
import React, { FC, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

interface SkillTypeSelectProps {
  id?: string;
  selected?: string;
  disabled?: DisableType;
  isCreatable?: boolean;
  onChange?: (value: string | null) => void;
}

const SkillTypeSelect: FC<SkillTypeSelectProps> = ({
  id = 'skill-type-select',
  disabled,
  selected,
  onChange,
}) => {
  const { isFetching, data } = useSkillsTypes(true);
  const [selectedType, setSelectedType] = useState<SkillType | null>();

  useEffect(() => {
    const selectedType = data?.find(
      (type: SkillType) => type.name === selected
    );
    setSelectedType(selectedType);
  }, [data, selected]);

  const handleChange = (value: SkillType | null) => {
    setSelectedType(value);
    if (!onChange) return;
    if (!value) {
      onChange(null);
      return;
    }
    onChange(value.name);
  };

  return (
    <AsyncSelect
      id={id}
      isDisabled={disabled}
      value={selectedType}
      onChange={handleChange}
      getOptionLabel={(skill: SkillType) => skill.name}
      getOptionValue={(skill: SkillType) => skill.id}
      defaultOptions={data}
      isClearable={true}
      cacheOptions={true}
      isLoading={isFetching}
    />
  );
};

export default SkillTypeSelect;
