import { EmployeeSkill } from '~/shared/models/api/employee';
import { orderSkillsByLevel } from '~/shared/utils/skills';
import { useEffect, useState } from 'react';

export interface UseEmployeeMatchingSkillsType {
  matchingSkills?: EmployeeSkill[];
  otherSkills?: EmployeeSkill[];
}

const useEmployeeMatchingSkills = (
  skills: EmployeeSkill[],
  skillIds?: number[]
): UseEmployeeMatchingSkillsType => {
  const [matchingSkills, setMatchingSkills] = useState<EmployeeSkill[]>();
  const [otherSkills, setOtherSkills] = useState<EmployeeSkill[]>();

  useEffect(() => {
    if (!skills.length) {
      return;
    }
    if (!skillIds?.length) {
      setOtherSkills(orderSkillsByLevel(skills));
      setMatchingSkills([]);
      return;
    }

    const [matching, others] = splitEmployeeSkillsByMatchingIds(
      skills,
      skillIds
    );

    setMatchingSkills(orderSkillsByLevel(matching));
    setOtherSkills(orderSkillsByLevel(others));
  }, [skills, skillIds]);

  const splitEmployeeSkillsByMatchingIds = (
    skills: Array<EmployeeSkill>,
    skillIds: number[]
  ) => {
    return skills.reduce<EmployeeSkill[][]>(
      (acc, skill) => {
        skillIds.includes(skill.skill_id)
          ? acc[0].push(skill)
          : acc[1].push(skill);
        return acc;
      },
      [[], []]
    );
  };

  return {
    matchingSkills,
    otherSkills,
  };
};

export default useEmployeeMatchingSkills;
