import {
  Badge,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import InternalExperiencesForm from '~/profile/components/experiences/internal/InternalExperiencesForm';
import { InternalExperiencesFormData } from '~/profile/forms/internalExperiences';
import useProfileStore from '~/profile/stores/profile';
import useInternalExperience from '~/shared/hooks/employee/useEmployeeInternalExperiences';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { EmployeeInternalExperience } from '~/shared/models/api/employee';
import React, { useState } from 'react';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface EditModalProps {
  onClose: () => void;
  experience?: EmployeeInternalExperience;
}

const EditModal: React.FC<EditModalProps> = ({ onClose, experience }) => {
  const [dirtyFields, setDirtyFields] = useState<
    Partial<Readonly<FieldNamesMarkedBoolean<EmployeeInternalExperience>>>
  >({});

  const { openConfirmation } = useConfirmationModalContext();
  const { profile, updateEmployeeProfile } = useProfileStore();
  const { startLoading, stopLoading } = useLoadingContext();

  const { createInternalExperience, updateInternalExperience } =
    useInternalExperience();

  const { t } = useTranslation('base');

  const createOrUpdateExperience = async (
    experience: InternalExperiencesFormData,
    employeeId: number
  ): Promise<EmployeeInternalExperience> => {
    const experienceWithEmployee = {
      ...experience,
      employee_id: employeeId,
    };
    if (!experience.id) {
      return await createInternalExperience.mutateAsync(experienceWithEmployee);
    }

    return await updateInternalExperience.mutateAsync(experienceWithEmployee);
  };

  const updateExperienceOnProfileStore = (
    experiences: EmployeeInternalExperience[],
    experience: EmployeeInternalExperience
  ) => {
    const filteredExperiences = experiences.filter(
      ({ id }) => id !== experience.id
    );

    updateEmployeeProfile({
      ki_projects: [...filteredExperiences, experience],
    });
  };

  const onSubmit = async (experience: InternalExperiencesFormData) => {
    if (!profile) {
      return;
    }

    startLoading();

    try {
      const createdOrUpdatedExperience = await createOrUpdateExperience(
        experience,
        profile.id
      );

      updateExperienceOnProfileStore(
        profile.ki_projects,
        createdOrUpdatedExperience
      );
    } finally {
      stopLoading();
      onClose();
    }
  };

  const onCloseModal = () => {
    const unsavedChanges = !!Object.keys(dirtyFields).length;
    if (unsavedChanges) {
      handleUnsavedChanges();
      return;
    }
    onClose();
  };

  const handleUnsavedChanges = async () => {
    const result = await openConfirmation();
    result && onClose();
  };

  return (
    <Modal isOpen onClose={onCloseModal} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <Stack direction="column">
          <ModalHeader data-testid="modal-experiences-header" mt="2" mb="0">
            <Badge colorScheme="blue">{t(`company_projects`)}</Badge>
            {experience?.project ? (
              <Heading fontSize="2xl" fontWeight="bold" mt="2">
                {t('edit_details')}
              </Heading>
            ) : null}
            <ModalCloseButton />
          </ModalHeader>
        </Stack>
        <InternalExperiencesForm
          experience={experience}
          onSubmit={onSubmit}
          onCancel={onCloseModal}
          onChange={(dirtyFields) => setDirtyFields(dirtyFields)}
        />
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
