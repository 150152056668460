import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  UseFormRegister,
} from 'react-hook-form';

interface FormControlInputProps<T extends FieldValues> {
  id: Path<T>;
  name?: string;
  tooltip?: string;
  register: UseFormRegister<T>;
  placeholder: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl>;
  disabled?: boolean;
  maxLength?: number;
  leftAddon?: string;
  onPaste?: () => void;
}

// HACK: disabling the control or input, the default/initial wasn't filled,
// so is just readable with disabled styles
const FormControlInput = <T extends FieldValues>({
  id,
  name,
  tooltip,
  register,
  placeholder,
  error,
  disabled,
  leftAddon,
  onPaste,
  maxLength,
}: FormControlInputProps<T>) => {
  const { onChange, ...rest } = register(id);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <FormControl
      id={id}
      isInvalid={Boolean(error)}
      mb="2"
      isReadOnly={disabled}
    >
      {!!name && (
        <FormLabel style={disabled ? { opacity: 0.4 } : {}}>
          <Tooltip label={tooltip} placement="right">
            {name}
          </Tooltip>
        </FormLabel>
      )}
      <InputGroup>
        {!!leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>}
        <Input
          {...rest}
          placeholder={placeholder}
          data-testid={`test-form-${id}`}
          style={disabled ? { opacity: 0.4, cursor: 'not-allowed' } : {}}
          onChange={changeHandler}
          onPasteCapture={() => onPaste?.()}
          maxLength={maxLength}
        />
      </InputGroup>
      <FormErrorMessage data-testid="test-form-error-message">
        {!!error && (error.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormControlInput;
