import {
  checkIfPropertyIsFilled,
  profileProperties,
  progressBarColorEval,
} from '../helper';
import { Employee } from '~/shared/models/api/employee';
import { useMemo } from 'react';

export const useMissingAttributes = (profile: Employee) => {
  const { progressValue, color, missingAttributes } = useMemo(() => {
    const missingAttributes: (keyof Employee)[] = [];

    let filledProperties = 0;

    for (const key of profileProperties) {
      if (checkIfPropertyIsFilled(profile, key)) {
        filledProperties++;
      } else {
        missingAttributes.push(key);
      }
    }

    const progressValue = Math.floor(
      (filledProperties * 100) / profileProperties.length
    );
    const color = progressBarColorEval.find(
      (i) => progressValue <= i.maxValue
    )?.colorScheme;

    return { progressValue, color, missingAttributes };
  }, [profile]);

  return { progressValue, color, missingAttributes };
};
