import SortableIcons from '../ui/sortable-icons/SortableIcons';
import { Button, Flex } from '@chakra-ui/react';
import SelectWithTranslations from '~/shared/components/SelectWithTranslations';
import useOrderDirection from '~/shared/hooks/filters/useOrderDirection';
import { OptionWithTranslation } from '~/shared/models/react-select/option-with-translation';
import {
  SearchFilters,
  SearchFiltersOrderBy,
} from '~/shared/models/search/filters/search';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectFiltersOrderByProps<T extends string> {
  filters?: Partial<SearchFiltersOrderBy<T> & SearchFilters>;
  options: OptionWithTranslation<T>[];
  placeholder?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  disableNone?: boolean;
  onFilterChange: (
    partialFilters: Partial<SearchFiltersOrderBy<T> & SearchFilters>
  ) => void;
}

const SelectFiltersOrderBySelect = <T extends string>({
  filters,
  options,
  placeholder,
  isClearable,
  isDisabled,
  disableNone,
  onFilterChange,
}: SelectFiltersOrderByProps<T>) => {
  const { t } = useTranslation('base');
  const { order, setOrder, toggleOrder } = useOrderDirection(disableNone);

  useEffect(() => {
    if (!filters || order == filters.order || filters.order == undefined) {
      return;
    }
    setOrder(filters.order);
  }, [filters]);

  useEffect(() => {
    if ((!order && !filters?.order) || order == filters?.order) {
      return;
    }
    onFilterChange({ order: order || undefined });
  }, [order]);

  return (
    <Flex>
      <SelectWithTranslations<T>
        value={filters?.order_by}
        options={options}
        placeholder={placeholder || t('select')}
        isClearable={isClearable}
        isDisabled={isDisabled}
        borderTopRightRadius="0"
        borderBottomRightRadius="0"
        onChange={(value) => {
          onFilterChange({ order_by: value || undefined });
        }}
      />
      <Button
        background="transparent"
        colorScheme="gray"
        color="blue.400"
        border="1px solid"
        height="auto"
        borderLeft="none"
        borderTopLeftRadius="0"
        borderBottomLeftRadius="0"
        borderColor="gray.200"
        disabled={!filters?.order_by?.length || isDisabled ? true : false}
        opacity="1 !important"
        onClick={toggleOrder}
      >
        <SortableIcons order={filters?.order} />
      </Button>
    </Flex>
  );
};

export default SelectFiltersOrderBySelect;
