import {
  Alert,
  AlertDescription,
  AlertDescriptionProps,
  AlertIcon,
  AlertIconProps,
  AlertProps,
  AlertTitle,
  AlertTitleProps,
  CloseButton,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';

interface CustomAlertProps extends AlertProps {
  alertDescriptionProps?: AlertDescriptionProps;
  alertIconProps?: AlertIconProps;
  alertTitleProps?: AlertTitleProps;
  errorCode?: string;
  isClosable?: boolean;
  text: string;
  title?: string;
}

export const CustomAlert: React.FC<CustomAlertProps> = ({
  alertDescriptionProps,
  alertIconProps,
  alertTitleProps,
  errorCode,
  isClosable = false,
  text,
  title,
  ...props
}) => {
  const [showAlert, setShowAlert] = React.useState<boolean>(true);

  if (!showAlert) {
    return null;
  }

  return (
    <Alert {...props}>
      <AlertIcon {...alertIconProps} />
      {title && (
        <AlertTitle {...alertTitleProps}>
          {errorCode && <>{errorCode}</>}
          {title}
        </AlertTitle>
      )}
      <AlertDescription {...alertDescriptionProps}>{text}</AlertDescription>
      {isClosable && (
        <>
          <Spacer />
          <CloseButton
            color="gray.500"
            onClick={() => setShowAlert(false)}
            _hover={{ bgColor: 'unset', color: 'gray.800' }}
          />
        </>
      )}
    </Alert>
  );
};
