import AuthWrapper from '~/authentication/components/AuthWrapper';
import Dashboard from '~/dashboard/Dashboard';
import NotFound from '~/pages/not-found';
import Profile from '~/profile/pages/Profile';
import { MainRoutesPaths } from '~/routes/enums/main';
import Search from '~/search/Search';
import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

// TODO: create wrapper for routes
export const mainRoutes: RouteObject[] = [
  { path: '*', element: <Navigate to="/404" /> },
  {
    path: MainRoutesPaths.PROFILE,
    element: (
      <AuthWrapper>
        <Profile />
      </AuthWrapper>
    ),
  },
  {
    path: MainRoutesPaths.PROFILE_ID,
    element: (
      <AuthWrapper>
        <Profile />
      </AuthWrapper>
    ),
  },
  {
    path: MainRoutesPaths.DASHBOARD,
    element: (
      <AuthWrapper>
        <Dashboard />
      </AuthWrapper>
    ),
  },
  {
    path: MainRoutesPaths.SEARCH,
    element: (
      <AuthWrapper>
        <Search />
      </AuthWrapper>
    ),
  },
  {
    path: '404',
    element: (
      <AuthWrapper>
        <NotFound />
      </AuthWrapper>
    ),
  },
];
