import LoadingModal from '~/shared/components/ui/LoadingModal';
import React, { ReactNode, useContext, useMemo, useState } from 'react';

interface UseLoadingContextType {
  startLoading: () => void;
  stopLoading: () => void;
}

const Context = React.createContext<UseLoadingContextType>({
  startLoading: () => ({}),
  stopLoading: () => ({}),
});

export default function LoadingContext({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const stopLoading = () => setIsLoading(false);

  const startLoading = () => setIsLoading(true);

  const value = useMemo(
    () => ({
      startLoading,
      stopLoading,
    }),
    [stopLoading, startLoading]
  );

  return (
    <Context.Provider value={value}>
      {isLoading && <LoadingModal isOpen={isLoading} />}
      {children}
    </Context.Provider>
  );
}

export function useLoadingContext() {
  return useContext(Context);
}
