import { Grid, GridProps } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';

const AdvancedFiltersHeaderLeftContentContainer: FC<
  PropsWithChildren<GridProps>
> = ({ children, ...props }) => {
  return (
    <Grid
      templateColumns={{ md: '1fr auto auto' }}
      columns={{ base: 1, md: 4 }}
      gap={7}
      mb={6}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default AdvancedFiltersHeaderLeftContentContainer;
