import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import DatePicker from '~/shared/components/ui/DatePicker';
import { DateFormat, DatePickerFormat } from '~/shared/enums/date';
import { UpdateEmployeeCertificationDates } from '~/shared/models/api/employee';
import { formatDate } from '~/shared/utils/date';
import React, { FC, useState } from 'react';

type Props = {
  date?: string;
  min?: string;
  max?: string;
  isUpdatingDate?: boolean;
  dateKey: keyof UpdateEmployeeCertificationDates;
  label: string;
  onDateChange: (
    updatedDates: Partial<UpdateEmployeeCertificationDates>,
    onSuccess: () => void
  ) => void;
};

export const CertificateDate: FC<Props> = ({
  date,
  min,
  max,
  dateKey,
  isUpdatingDate,
  label,
  onDateChange,
}) => {
  const [isEditingDate, setIsEditingDate] = useState<boolean>(false);
  const [initialDate, setInitialDate] = useState<string | undefined>(date);
  const [updatedDate, setUpdatedDate] = useState<string | undefined>(date);

  const onChangeDate = (updatedDate: Date) => {
    setUpdatedDate(formatDate(updatedDate, DateFormat.YEAR_MONTH));
  };

  const onClickSave = () => {
    onDateChange({ [dateKey]: updatedDate }, () => {
      setInitialDate(updatedDate);
      setIsEditingDate(false);
    });
  };

  const onClickCancel = () => {
    setUpdatedDate(initialDate);
    setIsEditingDate(false);
  };

  return (
    <>
      {isEditingDate ? (
        <Flex gap={1}>
          <Box
            sx={{
              '.react-datepicker__input-container': {
                height: 6,
                width: 28,
              },
            }}
          >
            <DatePicker
              dateFormat={DatePickerFormat.YEAR_MONTH}
              onChange={onChangeDate}
              selectedDate={updatedDate ? new Date(updatedDate) : null}
              minDate={min ? new Date(min) : undefined}
              maxDate={max ? new Date(max) : undefined}
            />
          </Box>
          <IconButton
            aria-label={`Save new ${label} date`}
            color="green"
            type="button"
            icon={<CheckIcon />}
            size="xs"
            isLoading={isUpdatingDate}
            onClick={onClickSave}
          />
          <IconButton
            aria-label={`Cancel editing ${label} date`}
            color="red"
            icon={<CloseIcon />}
            isLoading={isUpdatingDate}
            onClick={onClickCancel}
            size="xs"
            type="button"
          />
        </Flex>
      ) : (
        <Flex gap={1}>
          <Box width={16}>
            <Text sx={{ wordWrap: 'normal' }}>
              {formatDate(initialDate, DateFormat.MONTH_YEAR_SLASH)}
            </Text>
          </Box>
          <IconButton
            aria-label={`Edit ${label} date`}
            onClick={() => setIsEditingDate(true)}
            size="xs"
            type="button"
            icon={<EditIcon />}
          />
        </Flex>
      )}
    </>
  );
};
