import { initialFilters } from '~/shared/constants/search';
import { Status } from '~/shared/enums/status';
import {
  OrderDirection,
  SearchFilters,
  SearchFiltersOrderBy,
} from '~/shared/models/search/filters/search';

export enum EmployeeOrderBy {
  CERTIFICATIONS = 'certifications',
  COMPANY = 'company',
  LAST_UPDATE = 'last_update',
  NAME = 'name',
}

export interface EmployeeFilters
  extends SearchFilters,
    SearchFiltersOrderBy<EmployeeOrderBy> {
  certificates: number[];
  certification_status: Status;
  companies: number[];
  customers: number[];
  industries: number[];
  languages: number[];
  locations: string[];
  seniorities: number[];
  show_internal_resources: boolean;
  skills: number[];
  positions: string[];
  projects: number[];
  show_all: boolean;
  verified_profile: boolean;
  verify_profiles: boolean;
}

export type PartialEmployeeFilters = Partial<EmployeeFilters>;

export const initialEmployeeFilters: PartialEmployeeFilters = {
  ...initialFilters,
  order: OrderDirection.ASCENDING,
  order_by: EmployeeOrderBy.NAME,
  show_internal_resources: true,
};
