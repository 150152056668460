import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import { TextAreaResizeProperties } from '~/shared/enums/form';
import React from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  UseFormRegister,
} from 'react-hook-form';

interface FormControlTextProps<T extends FieldValues> {
  id: Path<T>;
  name: string;
  defaultValue?: string;
  register: UseFormRegister<T>;
  placeholder: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl>;
  disabled?: boolean;
  maxLength?: number;
  onPaste?: () => void;
  resize?: TextAreaResizeProperties;
}

const FormControlText = <T extends FieldValues>({
  id,
  name,
  defaultValue = '',
  register,
  placeholder,
  error,
  disabled,
  maxLength,
  onPaste,
  resize,
}: FormControlTextProps<T>) => {
  const { onChange, ...rest } = register(id);

  const changeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);
  };

  return (
    <FormControl
      id={id}
      isInvalid={Boolean(error)}
      mb="2"
      isDisabled={disabled}
    >
      <FormLabel style={disabled ? { opacity: 0.4 } : {}}>{name}</FormLabel>
      <Textarea
        {...rest}
        defaultValue={defaultValue}
        data-testid={`test-form-${id}`}
        placeholder={placeholder}
        onChange={changeHandler}
        style={disabled ? { opacity: 0.4, cursor: 'not-allowed' } : {}}
        maxLength={maxLength}
        onPasteCapture={() => onPaste?.()}
        resize={resize}
      />
      <FormErrorMessage data-testid="test-form-error-message">
        {!!error && (error.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormControlText;
