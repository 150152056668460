import { SearchFilterTab } from '~/shared/enums/searchFilterTab';

const isNumericTab = (tab: SearchFilterTab) =>
  [
    SearchFilterTab.CERTIFICATES,
    SearchFilterTab.COMPANIES,
    SearchFilterTab.INDUSTRIES,
    SearchFilterTab.LANGUAGES,
    SearchFilterTab.SENIORITIES,
    SearchFilterTab.SKILLS,
    // SearchFilterTab.CUSTOMERS, // TODO: uncomment when implemented
    // SearchFilterTab.PROJECTS, // TODO: uncomment when implemented
  ].includes(tab);

export const isSearchableFilter = (tab: string) =>
  ['projects', 'customers', 'languages'].includes(tab);

export const parseSearchFilterTabValues = (
  tab: SearchFilterTab,
  value: string[]
): (string | number)[] | undefined => {
  if (!value.length) {
    return undefined;
  }

  if (isNumericTab(tab)) {
    return value.map(Number);
  }

  return value;
};
