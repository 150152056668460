import { TagProps } from '@chakra-ui/react';
import { CustomTag } from '~/shared/components/ui/CustomTag';
import { Status, statusColorScheme } from '~/shared/enums/status';
import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface StatusMenuProps {
  status: Status;
}

const StatusTag: FC<PropsWithChildren<StatusMenuProps & TagProps>> = ({
  status,
  children,
  ...props
}) => {
  const { t } = useTranslation('base');

  return (
    <CustomTag
      colorScheme={statusColorScheme[status]}
      tooltipLabel={t('status')}
      {...props}
    >
      {children || t(`status_types.${status.toLowerCase()}`)}
    </CustomTag>
  );
};

export default StatusTag;
