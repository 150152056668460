import { useToast } from '@chakra-ui/react';
import { updateProject } from '~/shared/api/request/projects';
import { getErrorMessage } from '~/shared/errors/error';
import { Project, ProjectUpdatePayload } from '~/shared/models/api/project';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

const useProjectUpdate = (): UseMutationResult<
  Project,
  unknown,
  ProjectUpdatePayload,
  unknown
> => {
  const toast = useToast();
  const { t: tPermission } = useTranslation('permissions');
  const { t } = useTranslation('projects');

  return useMutation(
    (payload: ProjectUpdatePayload) => updateProject(payload),
    {
      onSuccess: ({ sms_title = '' }: Project) => {
        toast({
          title: t(`update.title`, { title: sms_title }),
          description: t(`update.success`),
          status: 'success',
        });
      },
      onError: (err: AxiosError, { sms_title = '' }: ProjectUpdatePayload) => {
        toast({
          title: t(`update.title`, { title: sms_title }),
          description: getErrorMessage(
            err.response?.status,
            t(`update.fail`),
            tPermission
          ),
          status: 'error',
        });
      },
    }
  );
};

export default useProjectUpdate;
