import { BellIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  Center,
  Grid,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { FaInfo } from 'react-icons/fa';
import ReactTimeAgo from 'react-time-ago';

interface PropTypes {
  verified: boolean;
  notified: boolean;
  lastUpdated: Date;
  isUpdating?: boolean;
  onVerify: () => void;
  onNotify: () => void;
}

const VerificationStatus: React.FC<PropTypes> = (props) => {
  const { verified, notified, lastUpdated, isUpdating, onVerify, onNotify } =
    props;
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const { t } = useTranslation('verification');
  const key = verified ? 'verified' : 'unverified';
  const color = verified ? 'green' : 'red';
  const monthsOld = dayjs(new Date()).diff(lastUpdated, 'month');

  return (
    <>
      <Modal
        data-testid="verActCell-modal"
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {verified ? t('notify_user') : t('verify_profile')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{verified ? t('prompt_notify') : t('prompt_verify')}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={() => setModalOpen(false)}>
              {t('no')}
            </Button>
            <Button
              colorScheme="green"
              ml={2}
              onClick={() => {
                setModalOpen(false);
                verified ? onNotify() : onVerify();
              }}
            >
              {t('yes')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Grid
        templateColumns="1fr 1fr 50px"
        alignItems="center"
        gap={3}
        maxWidth={96}
      >
        <Badge colorScheme={color} letterSpacing={1} textAlign="center" py={1}>
          {t(`${key}`)}
        </Badge>
        {lastUpdated && (
          <Text
            fontSize="sm"
            textAlign="center"
            whiteSpace="nowrap"
            {...(monthsOld >= 3
              ? {
                  color: monthsOld >= 6 ? 'red' : 'orange',
                }
              : {})}
          >
            <ReactTimeAgo date={new Date(lastUpdated)} />
          </Text>
        )}
        {/* Notification pending BE development, change to (!verified || !notified) */}
        {!verified && (
          <Tooltip label={verified ? t('notify_user') : t('verify_profile')}>
            <IconButton
              variant="ghost"
              fontSize={24}
              colorScheme={verified ? 'blue' : 'green'}
              icon={verified ? <BellIcon /> : <BsFillPersonCheckFill />}
              aria-label={verified ? t('notify_user') : t('verify_profile')}
              onClick={() => setModalOpen(true)}
              isLoading={isUpdating}
            />
          </Tooltip>
        )}
        {verified && notified && (
          <Tooltip label={t('user_notified')}>
            <Center>
              <Icon as={FaInfo} size={32} color="blue.600" />
            </Center>
          </Tooltip>
        )}
      </Grid>
    </>
  );
};

export default VerificationStatus;
