import { PptLayoutEnum } from './enums';
import { buildLongLayout } from './layouts/buildLongLayout';
import { buildShortLayout } from './layouts/buildShortLayout';
import { Ppt, PptLayout, PptLayoutBuilder } from './types';

export const buildPptLayout = (
  layout: PptLayoutEnum,
  ppt: Ppt,
  title: string
): PptLayout => {
  const layouts: PptLayoutBuilder = {
    longKiGroup: buildLongLayout,
    longKiProfessional: buildLongLayout,
    shortKiGroupOneProfilePerPage: buildShortLayout,
    shortKiGroupTwoProfilesPerPage: buildShortLayout,
  };

  const pptLayout = layouts[layout](ppt, layout, title);

  if (pptLayout) {
    return pptLayout;
  }

  throw new Error('Invalid PPT Layout');
};
