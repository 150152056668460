import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Center,
  HStack,
} from '@chakra-ui/react';
import React from 'react';

const CookieConsentAlert = () => {
  const cookie = localStorage.getItem('cookie');
  const [isOpen, setOpen] = React.useState(() => {
    if (cookie === null) return true;
    return false;
  });

  const handleClose = () => {
    localStorage.setItem('cookie', JSON.stringify(true));
    setOpen(!isOpen);
  };

  return (
    <Center style={{ zIndex: 10 }}>
      {isOpen && (
        <Alert
          variant="subtle"
          boxShadow="md"
          w="90%"
          position="fixed"
          bottom="2"
          bg="white"
        >
          <Box mr="10">
            <AlertTitle mb={2}>Your privacy 🔒</AlertTitle>
            <AlertDescription>
              We use cookies to personalize content and ads, to provide social
              media features and to analyze our traffic. We also share
              information about your use of our site with our social media team
              who may combine it with other information that you’ve provided to
              them or that they’ve collected from your use of their services.
              You consent to our cookies if you continue to use our website.
            </AlertDescription>
          </Box>

          <HStack spacing="15px">
            <Button colorScheme="green" onClick={handleClose}>
              Ok
            </Button>
            <Button colorScheme="green" variant="outline" onClick={handleClose}>
              Preferences
            </Button>
          </HStack>
        </Alert>
      )}
    </Center>
  );
};

export default CookieConsentAlert;
