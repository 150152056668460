import { getEmployeesBasicInfo } from '~/shared/api/request/employee';
import { QueriesTypes } from '~/shared/enums/queries';
import { EmployeeBasicInfo } from '~/shared/models/api/employee';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useGetEmployeesBasicInfo = (query: string) =>
  useQuery<EmployeeBasicInfo[], AxiosError>({
    queryKey: [QueriesTypes.GET_EMPLOYEES_BY_IDS, query],
    queryFn: ({ queryKey }) => getEmployeesBasicInfo(queryKey[1] as string),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!query.length,
  });
