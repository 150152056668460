import { Box, Flex, Text, useBoolean } from '@chakra-ui/react';
import CropperModal from '~/shared/components/CropperModal';
import FileUploadInput from '~/shared/components/FileUploadInput';
import Logo from '~/shared/components/Logo';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CertificateImageUploadModalProp {
  id: string;
  logo?: string;
  onChange: (image: Blob) => void;
}

const CertificateImageUploadModal: React.FC<
  CertificateImageUploadModalProp
> = ({ id, logo, onChange: onUploadChange }) => {
  const [image, setImage] = useState<string | undefined>(undefined);
  const [imageToCrop, setImageToCrop] = useState<string | undefined>(undefined);
  const { t } = useTranslation('base');
  const [isCropperOpen, setCropperOpen] = useBoolean();

  const onCroppedImage = async (
    image: Blob | null,
    imageBase64: string | undefined
  ) => {
    if (!image || !imageBase64) return;

    setImage(imageBase64);
    onUploadChange(image);
    setCropperOpen.off();
  };

  const onChange = (image: string | string[] | FileList | File | null) => {
    if (!image) {
      return;
    }
    setImageToCrop(image as string);
    setCropperOpen.on();
  };

  return (
    <>
      <CropperModal
        isOpen={isCropperOpen}
        onClose={setCropperOpen.off}
        image={imageToCrop}
        onCroppedImage={onCroppedImage}
      />
      <Flex width="inherit" justifyContent="center">
        <Box
          position="relative"
          role="group"
          w="100px"
          h="100px"
          textAlign="center"
        >
          <FileUploadInput
            id={id}
            accept="image/*"
            useReader
            onChange={onChange}
          >
            <Logo logo={logo} imageUrl={image} />
            <Text color="gray.500" textAlign="center" fontSize="xs">
              {t('click_to_upload')}
            </Text>
          </FileUploadInput>
        </Box>
      </Flex>
    </>
  );
};

export default CertificateImageUploadModal;
