import useNavSearch from './hooks/useNavSearch';
import SearchAutocomplete from './search-autocomplete/SearchAutocomplete';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  useBoolean,
} from '@chakra-ui/react';
import { MainRoutesPaths } from '~/routes/enums/main';
import RoundedButton from '~/shared/components/buttons/RoundedButton';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiFilter2Fill } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';

const SearchInput = () => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [showAutocomplete, setShowAutocomplete] = useBoolean(false);
  const [isSearchError, setIsSearchError] = useState<boolean>(false);
  const [autocompleteHover, setAutocompleteHover] = useState<boolean>(false);

  const location = useLocation();
  const { t } = useTranslation('base');
  const { data: searchData, loading } = useNavSearch(searchQuery);

  useEffect(() => {
    if ((inputFocus && searchQuery) || autocompleteHover) {
      setShowAutocomplete.on();
      return;
    }
    setShowAutocomplete.off();
  }, [inputFocus, autocompleteHover, searchQuery]);

  const isSearchPage = location.pathname === MainRoutesPaths.SEARCH;

  const handleSearchChange = useCallback((searchInput?: string) => {
    setSearchQuery(searchInput);
    setIsSearchError(false);
  }, []);

  const handleSearchFocus = () => setInputFocus(true);
  const handleSearchBlur = () => setInputFocus(false);

  const buildLinkWithSearchQuery = useCallback(() => {
    if (searchQuery) {
      return `${MainRoutesPaths.SEARCH}?query=${encodeURIComponent(
        searchQuery
      )}&show_internal_resources=true`;
    }

    return MainRoutesPaths.SEARCH;
  }, [searchQuery]);

  return (
    <>
      {!isSearchPage && (
        <Box position="relative">
          <HStack>
            <InputGroup
              color="gray.300"
              onBlur={handleSearchBlur}
              onFocus={handleSearchFocus}
              rounded={20}
              _hover={{
                color: 'gray.400',
              }}
              width={450}
            >
              <InputLeftElement>
                <SearchIcon
                  aria-label={t('search_user')}
                  data-testid="search-button"
                />
              </InputLeftElement>
              <Input
                backgroundColor="white"
                border="1px"
                borderColor="gray.400"
                boxShadow="sm"
                _focusVisible={{
                  color: 'black',

                  borderColor: 'gray.600',
                  boxShadow: 'md',
                }}
                color="gray.900"
                isInvalid={isSearchError}
                onChange={(e) => handleSearchChange(e.target.value)}
                placeholder={t('search')}
                rounded={20}
                _hover={{
                  color: 'black',
                  borderColor: 'gray.600',
                  boxShadow: 'md',
                }}
                _active={{
                  borderColor: 'gray.600',
                  color: 'black',
                }}
              />
              <InputRightElement>
                {loading && <Spinner color="gray.300" size="sm" />}
              </InputRightElement>
            </InputGroup>

            <RoundedButton
              tooltipLabel={t(`base:advanced_search`)}
              linkTo={buildLinkWithSearchQuery()}
              isLoading={false}
            >
              <RiFilter2Fill size={24} opacity={0.8} />
            </RoundedButton>
          </HStack>
          <Box
            onMouseEnter={() => setAutocompleteHover(true)}
            onMouseLeave={() => setAutocompleteHover(false)}
          >
            {searchData && showAutocomplete && (
              <SearchAutocomplete searchData={searchData} isLoading={loading} />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default SearchInput;
