import { PermissionGroupModalButton } from '../components/PermissionGroupModalButton';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Permission } from '~/shared/enums/permissions';
import useRouteGuard from '~/shared/hooks/permissions/useRouteGuard';
import { useGetRoles } from '~/shared/queries/useGetRoles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const PermissionGroups: FC = () => {
  useRouteGuard([Permission.PERMISSION_GROUPS]);

  const { data: roles, refetch } = useGetRoles();

  const { t } = useTranslation('permissionGroups');

  return (
    <TableContainer>
      <Text mt={5} mb={8} textAlign="center" fontWeight="semibold">
        {t('permissionGroupHeader')}
      </Text>
      <Table variant="striped-columns">
        <Thead>
          <Tr>
            {roles?.map((role) => (
              <Th minW={52} textAlign="center" key={`th-role-${role.id}`}>
                <PermissionGroupModalButton role={role} refetch={refetch} />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          <Tr verticalAlign="baseline">
            {roles?.map(({ permissions, id }) => (
              <Td key={`td-role-${id}`} textAlign="center">
                {permissions.map((permission) => (
                  <Text
                    key={`td-text-role-${id}-permission-${permission.permission.id}`}
                    mb={3}
                  >
                    {permission.permission.name}
                  </Text>
                ))}
              </Td>
            ))}
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default PermissionGroups;
