import { Box, Table } from '@chakra-ui/react';
import SearchResponseContent from '~/administration/components/SearchResponseContent';
import { Employee } from '~/shared/models/api/employee';
import { PartialEmployeeFilters } from '~/shared/models/search/filters/employees';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { AxiosError } from 'axios';
import React, { FC, PropsWithChildren } from 'react';

interface EmployeesSearchTableProps {
  filters: PartialEmployeeFilters;
  isFetching: boolean;
  data?: PaginatedResponse<Employee>;
  error: AxiosError | null;
  onPageChange: (skip: number) => void;
}

const EmployeesSearchTable: FC<
  PropsWithChildren<EmployeesSearchTableProps>
> = ({
  filters,
  isFetching,
  data: { total_results_count: totalCount, page_results: employees } = {},
  error,
  onPageChange,
  children,
}) => {
  return (
    <SearchResponseContent
      loading={isFetching}
      error={error}
      noResults={employees?.length === 0}
      paginationData={{
        filters,
        totalItems: totalCount || 0,
        onPageChange,
      }}
    >
      <Box overflowY="auto" pb={5}>
        <Table variant="striped">{children}</Table>
      </Box>
    </SearchResponseContent>
  );
};

export default EmployeesSearchTable;
