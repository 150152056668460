import { Center, Spinner } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

interface ContentProps {
  isLoading: boolean;
}

const Content: React.FC<PropsWithChildren<ContentProps>> = ({
  isLoading,
  children,
}) => {
  if (isLoading) {
    return (
      <Center w="100%">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  return <>{children}</>;
};

export default Content;
