import {
  DateIntervalTag,
  DateIntervalTagProps,
} from './shared/DateIntervalTag';
import { Flex, ThemeTypings } from '@chakra-ui/react';
import { CustomTag } from '~/shared/components/ui/CustomTag';
import React from 'react';

interface ProfileSectionItemIntervalAndTagProps extends DateIntervalTagProps {
  sideTagText?: string;
  tagColorScheme?: ThemeTypings['colorSchemes'];
}

export const ProfileSectionItemIntervalAndTag: React.FC<
  ProfileSectionItemIntervalAndTagProps
> = ({
  dateIntervalTagTestId,
  startDate,
  endDate,
  sideTagText,
  tagColorScheme = 'orange',
  endDateReplacementI18nKey = `base:present`,
}) => {
  return (
    <Flex>
      {sideTagText && (
        <CustomTag colorScheme={tagColorScheme}>{sideTagText}</CustomTag>
      )}
      <DateIntervalTag
        startDate={startDate}
        endDate={endDate}
        dateIntervalTagTestId={dateIntervalTagTestId}
        endDateReplacementI18nKey={endDateReplacementI18nKey}
      />
    </Flex>
  );
};
