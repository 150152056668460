import { searchSkillsDebounce } from '~/shared/api/request/skills';
import { QueriesTypes } from '~/shared/enums/queries';
import { Skill } from '~/shared/models/api/skills';
import { SearchFilters } from '~/shared/models/search/filters/search';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useSkillsSearch = (filters?: SearchFilters) => {
  return useQuery<Skill[], AxiosError>({
    queryKey: [QueriesTypes.SEARCH_SKILLS, { ...filters }],
    queryFn: searchSkillsDebounce,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!filters,
  });
};
