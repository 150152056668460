import { Center, Spinner, Stack, StackProps, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

interface LoadingDataProps {
  text?: string;
}

const LoadingData: FC<LoadingDataProps & StackProps> = ({
  text,
  ...stackProps
}) => {
  return (
    <Stack
      direction="column"
      align="center"
      spacing="5vw"
      data-testid="test-loading-data"
      {...stackProps}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="#d69e2e"
        size="xl"
      />
      <Center>
        <Text
          color="gray.700"
          fontSize="lg"
          fontWeight="bold"
          ml="auto"
          mr="auto"
        >
          {text || 'Loading information...'}
        </Text>
      </Center>
    </Stack>
  );
};

export default LoadingData;
