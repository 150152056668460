import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  LanguageProficiency,
  LanguageProficiencyBackgroundColor,
  LanguageProficiencyBorderColor,
} from '~/shared/enums/language';
import { mapEnumToLevelOptions } from '~/shared/mappers/selectOption';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguagesProficiencyInfoPopover: React.FC = () => {
  const { t } = useTranslation('profile');

  return (
    <Flex position="relative">
      <Popover trigger="hover" isLazy matchWidth={true} placement="top">
        <PopoverTrigger>
          <Flex cursor="pointer" color="blue.700">
            <Text fontWeight="bold">{t('about_me.languages.title')}</Text>
            <Text ml="1">
              <InfoOutlineIcon />
            </Text>
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          w="56"
          backgroundColor="white"
          border="1px"
          borderColor="gray.400"
          boxShadow="sm"
          color="gray.700"
          borderRadius={20}
          shadow="md"
        >
          <Stack padding="2">
            {mapEnumToLevelOptions(LanguageProficiency)
              .reverse()
              .map(({ value: level, translationKey }) => (
                <Flex alignItems="center" key={`language-proficiency-${level}`}>
                  <Box
                    width="4"
                    height="4"
                    ml={2}
                    backgroundColor={
                      LanguageProficiencyBackgroundColor[
                        level as LanguageProficiency
                      ]
                    }
                    borderColor={
                      LanguageProficiencyBorderColor[
                        level as LanguageProficiency
                      ]
                    }
                    borderRadius={25}
                  ></Box>
                  <Text ml="1">
                    {t(`language_proficiency.${translationKey}`)}
                  </Text>
                </Flex>
              ))}
          </Stack>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default LanguagesProficiencyInfoPopover;
