import { getProjectsPositions } from '~/shared/api/request/projects';
import { QueriesTypes } from '~/shared/enums/queries';
import { ProjectPositions } from '~/shared/models/api/project';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useGetProjectPositions = () => {
  return useQuery<ProjectPositions, AxiosError>({
    queryKey: [QueriesTypes.GET_PROJECT_POSITIONS],
    queryFn: getProjectsPositions,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
