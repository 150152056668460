import { useToast } from '@chakra-ui/react';
import { InternalExperiencesFormData } from '~/profile/forms/internalExperiences';
import {
  createInternalExperience as createExperience,
  deleteInternalExperience as deleteExperience,
  updateInternalExperience as updateExperience,
} from '~/shared/api/request/internalExperiences';
import { getErrorMessage } from '~/shared/errors/error';
import { ApiErrorResponse } from '~/shared/models/api/axios';
import { EmployeeInternalExperience } from '~/shared/models/api/employee';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

interface UseInternalExperiences {
  createInternalExperience: UseMutationResult<
    EmployeeInternalExperience,
    unknown,
    InternalExperiencesFormData,
    unknown
  >;

  deleteInternalExperience: UseMutationResult<
    EmployeeInternalExperience,
    unknown,
    EmployeeInternalExperience,
    unknown
  >;

  updateInternalExperience: UseMutationResult<
    EmployeeInternalExperience,
    unknown,
    InternalExperiencesFormData,
    unknown
  >;
}

const useInternalExperience = (): UseInternalExperiences => {
  const { t } = useTranslation(['base', 'profile', 'experiences']);
  const toast = useToast();

  const createInternalExperience = useMutation(
    (newExperience: InternalExperiencesFormData) =>
      createExperience(newExperience),
    {
      onSuccess: (newExperience: EmployeeInternalExperience) => {
        toast({
          title: newExperience.project.title,
          description: t('experiences:create.success'),
          status: 'success',
        });
      },
      onError: (err: ApiErrorResponse) => {
        const errorMsg =
          err.response?.data.detail ||
          t('experiences:create.error_description');
        toast({
          title: t('experiences:create.error'),
          description: getErrorMessage(err.response?.status, errorMsg, t),
          status: 'error',
        });
      },
    }
  );

  const deleteInternalExperience = useMutation(
    (experienceToDelete: EmployeeInternalExperience) =>
      deleteExperience(experienceToDelete),
    {
      onSuccess: (experienceToDelete: EmployeeInternalExperience) => {
        toast({
          title: experienceToDelete.project.title,
          description: t('experiences:delete.success'),
          status: 'success',
        });
      },
      onError: (
        err: ApiErrorResponse,
        experienceToDelete: EmployeeInternalExperience
      ) => {
        toast({
          title: `Fail deleting "${experienceToDelete.project.title}"`,
          description: getErrorMessage(
            err.response?.status,
            t('experiences:delete.error_description'),
            t
          ),
          status: 'error',
        });
      },
    }
  );

  const updateInternalExperience = useMutation(
    (experience: InternalExperiencesFormData) =>
      updateExperience({ ...experience }),
    {
      onSuccess: (employeeKIProject: EmployeeInternalExperience) => {
        toast({
          title: employeeKIProject.project.title,
          description: t('experiences:update.success'),
          status: 'success',
        });
      },
      onError: (err: ApiErrorResponse) => {
        toast({
          title: t('experiences:update.error'),
          description: getErrorMessage(
            err.response?.status,
            t('experiences:update.error_description'),
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return {
    createInternalExperience,
    deleteInternalExperience,
    updateInternalExperience,
  };
};

export default useInternalExperience;
