import {
  As,
  Icon,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react';
import InputLabel from '~/shared/components/InputLabel';
import InputClearIcon from '~/shared/components/ui/icons/InputClearIcon';
import { InputVariant } from '~/shared/enums/variant';
import React, {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
} from 'react';

interface InputGroupWithClearableProps {
  value?: string;
  placeholder?: string;
  label?: string;
  leftIcon?: As;
  variant?: InputVariant;
  onChange: (value: string) => void;
  onEnter?: () => void;
}

const InputGroupWithClearable: FC<
  PropsWithChildren<InputGroupWithClearableProps> &
    Omit<InputGroupProps, 'onChange'>
> = ({
  value = '',
  placeholder,
  label,
  leftIcon,
  variant,
  onChange,
  onEnter,
  children,
  ...props
}) => {
  const [inputFocus, setInputFocus] = useBoolean(false);
  const [text, setText] = React.useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setText(value);
    onChange(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key != 'Enter' || !onEnter) {
      return;
    }
    onEnter();
  };

  const onClear = () => {
    setText('');
    onChange('');
    inputRef.current?.focus();
  };

  return (
    <>
      {label && <InputLabel label={label} />}
      <InputGroup
        onFocus={() => setInputFocus.on()}
        onBlur={() => setInputFocus.off()}
        {...props}
      >
        {leftIcon && (
          <InputLeftElement>
            <Icon
              as={leftIcon}
              aria-label="search"
              color="gray.300"
              _hover={{ color: 'gray.600' }}
            />
          </InputLeftElement>
        )}
        {children ? (
          children
        ) : (
          <Input
            placeholder={placeholder}
            value={text}
            variant={variant}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            ref={inputRef}
          />
        )}
        <InputRightElement
          fontWeight="bold"
          color="grey"
          opacity={inputFocus ? '1' : '.4'}
          cursor="pointer"
          _hover={{ opacity: '1' }}
        >
          {text && <InputClearIcon boxSize={6} mt={1} onClick={onClear} />}
        </InputRightElement>
      </InputGroup>
    </>
  );
};

export default InputGroupWithClearable;
