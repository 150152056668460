import { deleteData, patch, post } from '~/shared/api/endpoints';
import { EmployeeExternalExperience } from '~/shared/models/api/employee';
import { getExternalExperiencesEndpoint } from '~/shared/utils/requests';

export const createExternalExperience = async ({
  employee_id,
  ...payload
}: EmployeeExternalExperience): Promise<EmployeeExternalExperience> => {
  const { data } = await post(
    getExternalExperiencesEndpoint(employee_id).url,
    payload
  );

  return data;
};

export const deleteExternalExperience = async ({
  employee_id,
  id,
}: EmployeeExternalExperience): Promise<EmployeeExternalExperience> => {
  const { data } = await deleteData<EmployeeExternalExperience>(
    getExternalExperiencesEndpoint(employee_id).id(id).url
  );

  return data;
};

export const updateExternalExperience = async ({
  employee_id,
  id,
  ...payload
}: EmployeeExternalExperience): Promise<EmployeeExternalExperience> => {
  const { data } = await patch<EmployeeExternalExperience>(
    getExternalExperiencesEndpoint(employee_id).id(id).url,
    payload
  );

  return data;
};
