import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
  },
  styles: {
    global: {
      '.disabled-select': {
        opacity: 0.4,
        cursor: 'not-allowed',
        rounded: 25,
      },
      '.ql-hidden': {
        display: 'none',
      },
      body: {
        bg: 'white',
        color: 'gray.900',
        fontFamily: 'Montserrat',
      },
    },
  },
  components: {
    Badge: {
      baseStyle: {
        rounded: 25,
        px: 1,
      },
    },
    Button: {
      baseStyle: {
        rounded: 25,
      },
    },
    Link: {
      variants: {
        'menu-link': {
          color: 'gray.700',
          mb: 2,
          fontSize: 12,
          display: 'block',
          fontWeight: 500,
        },
      },
    },
    Table: {
      variants: {
        'striped-columns': {
          th: {
            borderX: '1px solid',
            borderColor: 'gray.300',
          },
          td: {
            borderX: '1px solid',
            borderColor: 'gray.300',
          },
        },
      },
    },
    Text: {
      variants: {
        'menu-header': {
          color: 'gray.500',
          textTransform: 'uppercase',
          fontWeight: 700,
        },
      },
    },
    Tooltip: {
      baseStyle: {
        backgroundColor: 'white',
        border: '1px',
        borderColor: 'gray.400',
        boxShadow: 'lg',
        color: 'gray.800',
        marginTop: -1,
        rounded: 8,
      },
    },
  },
});

export default theme;
