import EmployeeEntry from '../EmployeeEntry';
import Header from '../Header';
import { NavSearchResponseData } from '../models/nav-search-response';
import SearchAutocompleteEntry from './SearchAutocompleteEntry';
import SearchAutocompleteEntryContainer from './SearchAutocompleteEntryContainer';
import { Box } from '@chakra-ui/react';
import SkillSearchLink from '~/shared/components/SkillSearchLink';
import React, { FC } from 'react';

interface SearchAutocompleteProps {
  searchData: NavSearchResponseData;
  isLoading: boolean;
}

const SearchAutocomplete: FC<SearchAutocompleteProps> = ({
  searchData,
  isLoading,
}) => {
  const { employees, skills } = searchData;

  return (
    <Box
      shadow="md"
      position="absolute"
      p="4"
      width="xl"
      left="50%"
      transform="translateX(-50%)"
      mt="4"
      background="white"
      borderRadius="md"
      borderWidth="2px"
      borderColor="gray.200"
      zIndex="2"
    >
      <Box maxHeight="xl" overflow="auto">
        <Header text="Profiles" />
        <SearchAutocompleteEntryContainer
          isLoading={isLoading}
          noResults={employees && employees.length === 0}
          noResultsText="No profiles found"
        >
          {employees?.map((employee) => (
            <EmployeeEntry
              key={`nav-employee-autocomplete${employee.id}`}
              employee={employee}
            />
          ))}
        </SearchAutocompleteEntryContainer>
        <Header text="Skills" />
        <SearchAutocompleteEntryContainer
          isLoading={isLoading}
          noResults={skills && skills.length === 0}
          noResultsText="No skills found"
        >
          {skills?.map((skill) => (
            <SkillSearchLink
              skillId={skill.id}
              key={`nav-skill-autocomplete${skill.id}`}
            >
              <SearchAutocompleteEntry name={skill.name} />
            </SkillSearchLink>
          ))}
        </SearchAutocompleteEntryContainer>
      </Box>
    </Box>
  );
};

export default SearchAutocomplete;
