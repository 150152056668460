import { getCompanies } from '~/shared/api/request/company';
import { QueriesTypes } from '~/shared/enums/queries';
import { Company } from '~/shared/models/api/company';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useGetCompanies = () => {
  return useQuery<Company[], AxiosError>({
    queryKey: [QueriesTypes.GET_KI_COMPANIES],
    queryFn: getCompanies,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
