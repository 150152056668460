import { QuestionIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';
import { SelectOption } from '~/shared/models/react-select/select-option';
import React from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  UseFormRegister,
} from 'react-hook-form';
import Select, { MultiValue } from 'react-select';

type FormControlMultipleSelectSyncProps<T extends FieldValues> = {
  id: Path<T>;
  name?: string;
  register: UseFormRegister<T>;
  placeholder?: string;
  error?: Merge<FieldError, (Merge<FieldError, FieldErrorsImpl> | undefined)[]>;
  options: SelectOption<T>[] | undefined;
};

const FormControlMultipleSelectSync = <T extends FieldValues>({
  id,
  name,
  error,
  placeholder,
  register,
  options,
}: FormControlMultipleSelectSyncProps<T>) => {
  const { onChange, ...rest } = register(id);

  const handleChange = (value: MultiValue<SelectOption<T>>) => {
    if (value.length) {
      onChange({ target: { value, name: id } });
      return;
    }

    onChange({ target: { value: null, name: id } });
  };

  return (
    <FormControl
      data-testid={`test-form-${id}`}
      id={id}
      isInvalid={Boolean(error)}
      mb="3"
    >
      <Flex>
        {!!name && <FormLabel>{name}</FormLabel>}
        <Tooltip
          label="Select certain skills to be highlighted when you export your CV"
          placement="right"
        >
          <QuestionIcon />
        </Tooltip>
      </Flex>
      <Select
        {...rest}
        onChange={handleChange}
        placeholder={placeholder}
        options={options}
        isMulti
      />
      <FormErrorMessage data-testid="test-form-error-message">
        {!!error && error.message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormControlMultipleSelectSync;
