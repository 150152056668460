import { EndpointPaths } from '~/shared/enums/endpointPaths';
import { EndpointItem } from '~/shared/interfaces/endpoints';
import { urlItemBuilder } from '~/shared/utils/urlBuilders';

export interface SkillsEndpoints extends EndpointItem<number> {
  merge: EndpointItem<number>;
}

export const skillsEndpoint: SkillsEndpoints = {
  ...urlItemBuilder<number>(EndpointPaths.SKILLS),

  merge: urlItemBuilder<number>(
    `${EndpointPaths.SKILLS}/${EndpointPaths.MERGE}`
  ),
};
