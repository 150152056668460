import { ProfileSectionHeader } from '../ProfileSectionHeader';
import EducationItem from './EducationItem';
import EducationUpsertModal from './EducationUpsertModal';
import { Box } from '@chakra-ui/react';
import useProfileEducationModal from '~/profile/hooks/useProfileEducationModal';
import useProfileStore from '~/profile/stores/profile';
import MissingData from '~/shared/components/ui/MissingData';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Educations: React.FC = () => {
  const { t } = useTranslation('profile');
  const { profile } = useProfileStore();
  const {
    upsertEducation,
    deleteEducation,
    currentEducation,
    isOpen,
    onClose,
    openModal,
  } = useProfileEducationModal();

  return (
    <Box data-testid="profile-component-educations">
      {isOpen && (
        <EducationUpsertModal
          education={currentEducation}
          onClose={onClose}
          onSubmit={upsertEducation}
        />
      )}

      <ProfileSectionHeader
        sectionTitle={t(`education.title`)}
        onClickAdd={() => openModal()}
        headingDataTestId="test-heading-educations"
      />
      {!profile?.education?.length ? (
        <MissingData
          text={t(`education.not_added`)}
          data-testid="test-empty-educations"
        />
      ) : (
        <Box mt="4" borderRadius="md">
          {profile?.education?.map((education) => (
            <EducationItem
              key={`education-${education.education_id}`}
              education={education}
              onDelete={() => deleteEducation(education)}
              onEdit={() => openModal(education)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Educations;
