import { ProfileSectionHeader } from '../ProfileSectionHeader';
import { Box, Flex, useDisclosure, useToast } from '@chakra-ui/react';
import Certificate from '~/profile/components/certifications/Certificate';
import EditModal from '~/profile/components/certifications/EditModal';
import useProfileStore from '~/profile/stores/profile';
import MissingData from '~/shared/components/ui/MissingData';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { EmployeeCertification } from '~/shared/models/api/employee';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Certificates = () => {
  const toast = useToast();
  const { profile, deleteCertificate } = useProfileStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { openConfirmation } = useConfirmationModalContext();
  const { startLoading, stopLoading } = useLoadingContext();
  const { t } = useTranslation(['base', 'certificates']);
  const [certifications, setCertifications] =
    useState<EmployeeCertification[]>();
  const { canSeeCertification } = useAccessPermissions();

  useEffect(() => {
    if (!profile?.certifications.length) {
      setCertifications([]);
      return;
    }
    setCertifications(sortCertificates(profile.certifications));
  }, [profile?.certifications]);

  const sortCertificates = (certificates: EmployeeCertification[]) =>
    certificates.sort((a, b) => {
      if (!a.expiring_date && !b.expiring_date) {
        return b.adquired_date.localeCompare(a.adquired_date);
      }

      if (a.expiring_date && b.expiring_date) {
        return b.expiring_date.localeCompare(a.expiring_date);
      }

      return !a.expiring_date ? -1 : 1;
    });

  const confirmDelete = async (certification: EmployeeCertification) => {
    const result = await openConfirmation({
      isDangerAction: true,
      titleText: certification.certificate.name,
      descriptionText: t(`base:delete_confirmation`),
      confirmText: t(`base:delete`),
    });

    if (result) {
      startLoading();
      const updateSuccess = await deleteCertificate({
        ...certification,
        certificate_name: certification.certificate.name,
      });

      stopLoading();

      toast({
        title: t(`certificates:profile_certification_delete`),
        description: updateSuccess
          ? t(`certificates:certification_deleted`)
          : t(`base:something_went_wrong`),
        status: updateSuccess ? 'success' : 'error',
      });
    }
  };

  const visibleCertifications = certifications?.filter((c) =>
    canSeeCertification(c.status)
  );

  return (
    <Box data-testid="profile-component-certifications">
      {isOpen && <EditModal onClose={onClose} />}

      <ProfileSectionHeader
        headingDataTestId="test-certification-heading"
        sectionTitle={t(`certificates:certifications`)}
        onClickAdd={() => onOpen()}
      />

      {!visibleCertifications?.length ? (
        <MissingData text={t(`certificates:no_certifications_added`)} />
      ) : (
        <Flex
          mt="4"
          borderRadius="md"
          data-testid="certifications-list"
          gap={2}
          w="full"
          wrap="wrap"
        >
          {visibleCertifications.map((certification) => (
            <Certificate
              key={`certification${certification.certificate_id}`}
              certificate={certification}
              onDelete={() => confirmDelete(certification)}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default Certificates;
