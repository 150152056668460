import EducationUpsertForm from './EducationUpsertForm';
import {
  Badge,
  Box,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { EmployeeEducation } from '~/shared/models/api/employee';
import React, { FC, useState } from 'react';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface EducationUpsertModalProps {
  education?: EmployeeEducation;
  onClose: () => void;
  onSubmit: (education: EmployeeEducation) => void;
}

const EducationUpsertModal: FC<EducationUpsertModalProps> = ({
  education,
  onClose,
  onSubmit,
}) => {
  const { t: tProfile } = useTranslation('profile');
  const { t: tBase } = useTranslation('base');
  const [dirtyFields, setDirtyFields] = useState<
    Partial<Readonly<FieldNamesMarkedBoolean<EmployeeEducation>>>
  >({});
  const { openConfirmation } = useConfirmationModalContext();

  const onCloseModal = () => {
    const unsavedChanges = !!Object.keys(dirtyFields).length;
    if (unsavedChanges) {
      handleUnsavedChanges();
      return;
    }
    onClose();
  };

  const handleUnsavedChanges = async () => {
    const result = await openConfirmation();
    result && onClose();
  };

  return (
    <Modal isOpen onClose={onCloseModal} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <Stack direction="column">
          <ModalHeader
            mt="2"
            mb="0"
            pr="14"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Badge
                colorScheme="blue"
                data-testid="education-modal-header-badge"
              >
                {tProfile('education.title')}
              </Badge>
              <Heading
                fontSize="2xl"
                fontWeight="bold"
                mt="2"
                data-testid="education-modal-header-title"
              >
                {education ? tBase('edit_details') : tBase('create_new')}
              </Heading>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
        </Stack>
        <EducationUpsertForm
          education={education}
          onSubmit={onSubmit}
          onCancel={onCloseModal}
          onChange={setDirtyFields}
        />
      </ModalContent>
    </Modal>
  );
};

export default EducationUpsertModal;
