import { Tag } from '@chakra-ui/react';
import { Skill } from '~/shared/models/api/skills';
import React, { FC } from 'react';

type SkillsListItemProps = {
  skill: Skill;
  isHighlighted?: boolean;
  isDropped?: boolean;
  isDragging?: boolean;
  onClick?: () => void;
};

const SkillsListItem: FC<SkillsListItemProps> = ({
  skill,
  onClick,
  isHighlighted,
  isDropped,
  isDragging,
}) => {
  const backgroundColor = () => {
    switch (true) {
      case isDropped:
        return 'blue.200';
      case isHighlighted:
        return 'blue.400';
      default:
        return 'teal.400';
    }
  };
  return (
    <Tag
      p={1.5}
      marginY={1}
      mr={3}
      width={isDragging && isDragging ? '200px' : 'auto'}
      color="white"
      bg={backgroundColor()}
      borderRadius="sm"
      textDecoration={isDropped ? 'line-through' : 'none'}
      role="button"
      onClick={onClick}
    >
      {skill.name}
    </Tag>
  );
};

export default SkillsListItem;
