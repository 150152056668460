import { ProfileFilling } from './ProfileFilling';
import { CardBody, CardHeader, Heading, Stack, Text } from '@chakra-ui/react';
import EmployeeAvatar from '~/shared/components/EmployeeAvatar';
import { ProfileCompanyLogo } from '~/shared/components/ProfileCompanyLogo';
import { Employee } from '~/shared/models/api/employee';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ProfileLinkCardProps {
  profile: Employee | null;
  hasError?: boolean;
}

const ProfileLinkCard: React.FC<ProfileLinkCardProps> = ({
  profile,
  hasError,
}) => {
  const { t } = useTranslation(['admin', 'profile']);

  if (!profile) {
    return (
      <CardBody data-testid="no-user">
        <Text color="gray.500" fontSize="md">
          {hasError ? t(`base:something_went_wrong`) : t(`profile:not_exists`)}
        </Text>
      </CardBody>
    );
  }

  return (
    <>
      {profile.company && (
        <CardHeader>
          <ProfileCompanyLogo
            name={profile.company.name}
            path={profile.company.logo_url}
          />
        </CardHeader>
      )}

      <CardBody data-testid="user-card">
        <Stack spacing="5" align="center">
          <Stack spacing="0" align="center">
            <EmployeeAvatar
              size={['xl', 'xl', 'xl', '2xl']}
              name={`${profile.first_name} ${profile.second_name}`}
              alignItems="center"
              justifyContent="center"
              alignContent="center"
              imagePath={profile.image_url}
            />
            <Heading size="lg" fontWeight={500}>
              {`${profile.first_name} ${profile.second_name}`}
            </Heading>
            <Text color="gray.700" align="center">
              {profile.job_title}
            </Text>
            <Text color="gray.500" align="center">
              {profile.email}
            </Text>
          </Stack>
          <ProfileFilling profile={profile} />
        </Stack>
      </CardBody>
    </>
  );
};

export default ProfileLinkCard;
