import { Box, FormHelperText, Input } from '@chakra-ui/react';
import { fileToDataURL } from '~/shared/utils/fileUpload';
import React, {
  ChangeEventHandler,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface FileUploadInputProps {
  id: string;
  accept?: string;
  multiple?: boolean;
  useReader?: boolean;
  disabled?: boolean;
  showAcceptFormats?: boolean;
  onChange?: (value: FileList | string[] | File | string | null) => void;
  children?: ReactNode;
}

const FileUploadInput: FC<FileUploadInputProps> = ({
  id,
  accept = '*/*',
  multiple = false,
  useReader = false,
  disabled,
  showAcceptFormats,
  onChange,
  children,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [validFileNames, setValidFileNames] = useState<string>();

  useEffect(() => {
    if (accept == '*/*' || !showAcceptFormats) return;
    const validFileNames = accept.split(',').map((a) => a.split('/').pop());
    setValidFileNames(validFileNames.toString().toUpperCase());
  }, [accept]);

  const handleClick = () => {
    reset();
    inputRef.current?.click();
  };

  const onFilesUploaded: ChangeEventHandler<HTMLInputElement> = async ({
    target,
  }) => {
    const { files } = target;

    if (!files) {
      return;
    }

    if (!useReader) {
      handleOnChange(files);
      return;
    }

    const urls = await Promise.all([...files].map(fileToDataURL));
    handleOnChange(urls as string[]);
  };

  // fix to allow upload same file multiple times
  const reset = () => {
    if (!inputRef.current) return;

    inputRef.current.value = '';
  };

  const handleOnChange = (files: FileList | string[]) => {
    if (!onChange) {
      return;
    }
    if (multiple) {
      onChange(files);
    }
    onChange(files[0]);
  };

  return (
    <Box onClick={handleClick} cursor="pointer" id={id}>
      <Input
        id={`file-upload${id}`}
        type="file"
        multiple={multiple || false}
        hidden
        disabled={disabled}
        accept={accept}
        onChange={onFilesUploaded}
        ref={inputRef}
      />
      <>{children}</>
      {validFileNames && (
        <FormHelperText>Accepted formats are: {validFileNames}</FormHelperText>
      )}
    </Box>
  );
};

export default FileUploadInput;
