import { searchClientsDebounce } from '~/shared/api/request/clients';
import { QueriesTypes } from '~/shared/enums/queries';
import { Client } from '~/shared/models/api/Client';
import { ClientSearchFilters } from '~/shared/models/search/filters/clients';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useClientsSearch = (filters: Partial<ClientSearchFilters>) => {
  return useQuery<PaginatedResponse<Client>, AxiosError>({
    queryKey: [QueriesTypes.SEARCH_CLIENTS, { ...filters }],
    queryFn: searchClientsDebounce,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
