import SkillsFiltersViewModesItem from './SkillsFiltersViewModesItem';
import { TabList, Tabs } from '@chakra-ui/react';
import { GridViewModes } from '~/shared/enums/gridViewModes';
import React, { FC, useEffect, useState } from 'react';

type SkillsFiltersViewModesProps = {
  mode: GridViewModes;
  onChange: (mode: GridViewModes) => void;
};

const SkillsFiltersViewModes: FC<SkillsFiltersViewModesProps> = ({
  mode,
  onChange,
}) => {
  const [activeViewIndex, setActiveViewIndex] = useState<number>(0);

  useEffect(() => {
    if (!mode) {
      return;
    }
    const index: number = Object.values(GridViewModes).indexOf(mode);
    if (index < 0) {
      return;
    }
    setActiveViewIndex(index);
  }, [mode]);

  return (
    <Tabs
      variant="soft-rounded"
      index={activeViewIndex}
      onChange={setActiveViewIndex}
    >
      <TabList
        borderRadius="md"
        border={1}
        borderColor="gray.200"
        borderStyle="solid"
        p="3px"
      >
        {Object.values(GridViewModes).map((viewMode) => (
          <SkillsFiltersViewModesItem
            mode={viewMode}
            onClick={() => onChange(viewMode)}
            key={`view-mode-${viewMode}`}
          />
        ))}
      </TabList>
    </Tabs>
  );
};

export default SkillsFiltersViewModes;
