import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

type LinkProps = {
  isDisabled?: boolean;
} & Pick<RouterLinkProps, 'to'>;

const Link: FC<PropsWithChildren<LinkProps & ChakraLinkProps>> = ({
  children,
  to,
  isDisabled,
  ...linkProps
}) => {
  return (
    <ChakraLink
      as={RouterLink}
      to={to}
      {...linkProps}
      {...(isDisabled ? { pointerEvents: 'none', textDecor: 'none' } : {})}
    >
      {children}
    </ChakraLink>
  );
};

export default Link;
