import { OptionWithTranslation } from '~/shared/models/react-select/option-with-translation';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface UseSearchFiltersType<T extends string> {
  options?: OptionWithTranslation<T>[];
}

const useTranslateOptions = <T extends string>(
  optionsToTranslate: OptionWithTranslation<T>[]
): UseSearchFiltersType<T> => {
  const [options, setOptions] = useState<OptionWithTranslation<T>[]>();

  const { t } = useTranslation(optionsToTranslate.map((op) => op.table));

  useEffect(() => {
    const optionsWithTranslations = optionsToTranslate.map((op) => ({
      ...op,
      label: t(op.key),
    }));
    setOptions(optionsWithTranslations);
  }, []);

  return {
    options,
  };
};

export default useTranslateOptions;
