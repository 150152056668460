import { PermissionModal } from './PermissionModal';
import { EditIcon } from '@chakra-ui/icons';
import { Button, useDisclosure } from '@chakra-ui/react';
import useEmployeeRoles from '~/administration/hooks/permissions/useEmployeeRoles';
import { Employee } from '~/shared/models/api/employee';
import { RoleData } from '~/shared/models/api/permissions';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  employee: Employee;
  roles: RoleData[];
  refetch: () => void;
}

export const PermissionRoleModalButton = ({
  employee,
  roles,
  refetch,
}: Props) => {
  const { t } = useTranslation('permissions');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { onEmployeeRolesChange } = useEmployeeRoles();

  const getRolesWithoutTeamLead = () => {
    const TEAM_LEAD_ROLE = 'Team Lead';

    return roles.filter((role) => role.id !== TEAM_LEAD_ROLE);
  };

  const buildRolesList = () =>
    getRolesWithoutTeamLead().map((role) => ({
      id: role.id,
      name: role.id,
    }));

  return (
    <>
      <PermissionModal
        title={t('permission_groups')}
        list={buildRolesList()}
        selected={employee.roles}
        isOpen={isOpen}
        onChange={(roles) =>
          onEmployeeRolesChange(
            employee?.id,
            roles.map((r) => r.name),
            refetch
          )
        }
        onClose={onClose}
      />
      <Button onClick={onOpen} backgroundColor="transparent">
        <EditIcon />
      </Button>
    </>
  );
};
