import { FormControl, Heading, Stack } from '@chakra-ui/react';
import LanguagesEditList from '~/profile/components/about/languages/LanguagesEditList';
import LanguagesForm from '~/profile/components/about/languages/LanguagesForm';
import { LanguageAdd, mapLanguage } from '~/profile/forms/languages';
import useProfileStore from '~/profile/stores/profile';
import { Employee } from '~/shared/models/api/employee';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const LanguagesEdit: React.FC = () => {
  const { getValues, setValue } = useFormContext<Employee>();
  const { t } = useTranslation('profile');
  const { profile } = useProfileStore();

  const [languages, setLanguages] = useState(getValues('languages'));

  const onLanguageAdded = (language: LanguageAdd) => {
    const newUserLangs = [
      ...languages,
      mapLanguage({ ...language, employee_id: profile?.id }),
    ];

    setValue('languages', newUserLangs);
    setLanguages(newUserLangs);
  };

  const onDelete = (languageIndex: number) => {
    const nextLanguages = [...languages];
    nextLanguages.splice(languageIndex, 1);

    setValue('languages', nextLanguages);
    setLanguages(nextLanguages);
  };

  return (
    <FormControl id="languages">
      <Heading fontSize="lg" color="blue.700" fontWeight="bold" mt="4" mb="4">
        {t('about_me.languages.general_info')}
      </Heading>
      <Stack spacing="2">
        <LanguagesForm onAdded={onLanguageAdded} languages={languages} />
        <LanguagesEditList languages={languages} onDelete={onDelete} />
      </Stack>
    </FormControl>
  );
};

export default LanguagesEdit;
