import { Permission } from '~/shared/enums/permissions';
import { EmployeePermission } from '~/shared/models/api/employee';

export const hasPermissionBuilder = (
  employeePermissions?: EmployeePermission[]
) => {
  return (permissions?: Permission[]): boolean => {
    if (employeePermissions) {
      return employeePermissions.some(({ permission }) =>
        permissions?.includes(permission.name)
      );
    }

    return false;
  };
};
