import { getPermissionsDebounce } from '../api/request/permissions';
import { QueriesTypes } from '~/shared/enums/queries';
import { PermissionData } from '~/shared/models/api/permissions';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useGetPermissions = () => {
  return useQuery<PermissionData[], AxiosError>({
    queryKey: [QueriesTypes.GET_PERMISSIONS],
    queryFn: getPermissionsDebounce,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
