import admin from './en/admin.json';
import base from './en/base.json';
import certificates from './en/certificates.json';
import clients from './en/clients.json';
import employees from './en/employees.json';
import experiences from './en/experiences.json';
import permissionGroups from './en/permissionGroups.json';
import permissions from './en/permissions.json';
import profile from './en/profile.json';
import projects from './en/projects.json';
import search from './en/search.json';
import skills from './en/skills.json';
import verification from './en/verification.json';
import verifyCertificates from './en/verifyCertificates.json';

export default {
  base,
  profile,
  admin,
  projects,
  verification,
  search,
  certificates,
  skills,
  verifyCertificates,
  employees,
  permissionGroups,
  permissions,
  clients,
  experiences,
};
