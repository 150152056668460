import AdvancedFiltersClearButton from '../../advanced-filters/buttons/AdvancedFiltersClearButton';
import AdvancedFiltersElement from '../../advanced-filters/filters/AdvancedFiltersElement';
import AdvancedFiltersHeaderContainer from '../../advanced-filters/header/AdvancedFiltersHeaderContainer';
import AdvancedFiltersHeaderLeftContent from '../../advanced-filters/header/AdvancedFiltersHeaderLeftContentContainer';
import AdvancedFiltersHeaderRightContent from '../../advanced-filters/header/AdvancedFiltersHeaderRightContentContainer';
import SearchInput from '~/shared/components/SearchInput';
import StatusSelect from '~/shared/components/status/StatusSelect';
import { CertificatesSearchFilters as SearchFilters } from '~/shared/models/search/filters/certificates';
import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface CertificatesSearchFiltersProps {
  filters: Partial<SearchFilters>;
  showAdvanced: boolean;
  isFilterChanged: boolean;
  onFilterChange: (partialFilters: Partial<SearchFilters>) => void;
  onQueryChange: (query: string) => void;
  toggleShowAdvanced: () => void;
  resetFilters: () => void;
}

const CertificatesSearchFilters: FC<
  PropsWithChildren<CertificatesSearchFiltersProps>
> = ({
  children,
  filters,
  onFilterChange,
  onQueryChange,
  isFilterChanged,
  resetFilters,
}) => {
  const { t: tBase } = useTranslation('base');
  const { t: tCertificates } = useTranslation('certificates');

  return (
    <>
      <AdvancedFiltersHeaderContainer>
        <AdvancedFiltersHeaderLeftContent
          templateColumns={{ md: '1fr 1fr auto' }}
        >
          <SearchInput
            placeholder={tCertificates('search')}
            query={filters.query}
            onChange={onQueryChange}
            mb={0}
          />
          <AdvancedFiltersElement>
            <StatusSelect
              selectedStatus={filters.status}
              placeholder={tBase('filters.filter_by_status')}
              onChange={(status) =>
                onFilterChange({ status: status || undefined })
              }
            />
          </AdvancedFiltersElement>

          <AdvancedFiltersClearButton
            disabled={!isFilterChanged}
            onClick={resetFilters}
          />
        </AdvancedFiltersHeaderLeftContent>
        <AdvancedFiltersHeaderRightContent>
          {children}
        </AdvancedFiltersHeaderRightContent>
      </AdvancedFiltersHeaderContainer>
      {/* Order by pending BE development */}
      {/* <AdvancedFiltersOrderByContainer>
        <CertificatesFiltersOrderBySelect
          filters={filters}
          onFilterChange={onFilterChange}
        />
      </AdvancedFiltersOrderByContainer> */}
    </>
  );
};

export default CertificatesSearchFilters;
