import { IconButton, Tooltip } from '@chakra-ui/react';
import { ButtonTypes } from '~/shared/enums/actionButton';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import { actionButtonTypes, IActionButton } from '~/shared/models/actionButton';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface ActionButtonProps {
  onClick?: () => void;
  hasPermission?: boolean;
  tooltipLabel?: string;
  type: ButtonTypes;
  isLoading?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  hasPermission = true,
  tooltipLabel,
  type,
  isLoading = false,
}) => {
  const buttonType: IActionButton = actionButtonTypes[type];
  const isDelete = useMemo(() => type === 'delete', [type]);
  const { t } = useTranslation('base');
  const { canEditProfile } = useAccessPermissions();

  return (
    <>
      {hasPermission && canEditProfile && (
        <Tooltip
          label={
            tooltipLabel
              ? `${t(buttonType.translationKey)}: ${tooltipLabel}`
              : t(buttonType.translationKey)
          }
        >
          <IconButton
            alignContent="center"
            aria-label={t(buttonType.translationKey)}
            backgroundColor="gray.50"
            border="1px"
            borderColor="gray.300"
            boxShadow="sm"
            color={isDelete ? 'red.400' : 'gray.500'}
            data-testid={`${type}-action-button`}
            fontSize="sm"
            height={6}
            icon={<buttonType.icon />}
            onClick={onClick}
            padding={0}
            rounded={20}
            variant="link"
            width={6}
            isLoading={isLoading}
            _hover={{
              backgroundColor: 'white',
              borderColor: 'gray.400',
              boxShadow: 'xl',
              color: isDelete ? 'red.600' : 'gray.700',
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

export default ActionButton;
