import SortableIcons from '../sortable-icons/SortableIcons';
import { HStack, TableColumnHeaderProps, Text, Th } from '@chakra-ui/react';
import { OrderDirection } from '~/shared/models/search/filters/search';
import React, { PropsWithChildren } from 'react';

interface SortableHeaderProps
  extends PropsWithChildren,
    TableColumnHeaderProps {
  isSortable: boolean;
  sortedDirection?: OrderDirection;
  onChangeOrder?: (direction: OrderDirection) => void;
}

const NEXT_ORDER = {
  [OrderDirection.NONE]: OrderDirection.ASCENDING,
  [OrderDirection.ASCENDING]: OrderDirection.DESCENDING,
  [OrderDirection.DESCENDING]: OrderDirection.NONE,
} as const;

export const SortableHeader = ({
  isSortable,
  children,
  sortedDirection = OrderDirection.NONE,
  onChangeOrder,
  ...props
}: SortableHeaderProps) => {
  const onClick = () => {
    if (!isSortable) {
      return;
    }

    onChangeOrder?.(NEXT_ORDER[sortedDirection]);
  };

  return (
    <Th
      {...(isSortable && { cursor: 'pointer', onClick: onClick })}
      whiteSpace="nowrap"
      {...props}
    >
      <HStack>
        <Text>{children}</Text>
        {isSortable && <SortableIcons order={sortedDirection}></SortableIcons>}
      </HStack>
    </Th>
  );
};
