import { useToast } from '@chakra-ui/react';
import {
  deleteSkill as removeSkill,
  mergeSkills as merge,
  updateSkill,
} from '~/shared/api/request/skills';
import { getErrorMessage } from '~/shared/errors/error';
import useEmployeeRefresh from '~/shared/hooks/employee/useEmployeeRefresh';
import {
  DeleteSkill,
  MergeSkill,
  Skill,
  UpdateSkill,
} from '~/shared/models/api/skills';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

interface UseUpdateSkillType {
  update: UseMutationResult<Skill, unknown, UpdateSkill, unknown>;
  updateSkillType: UseMutationResult<Skill, unknown, UpdateSkill, unknown>;
  mergeSkills: UseMutationResult<Skill, unknown, MergeSkill, unknown>;
  deleteSkill: UseMutationResult<Skill, unknown, DeleteSkill, unknown>;
}

const useSkillUpdate = (): UseUpdateSkillType => {
  const toast = useToast();
  const { t } = useTranslation('permissions');
  const { onSkillUpdated } = useEmployeeRefresh();

  const update = useMutation(
    (skillUpdate: UpdateSkill) => updateSkill({ ...skillUpdate }),
    {
      onSuccess: (updatedSkill: Skill) => {
        onSkillUpdated(updatedSkill.id);
        toast({
          title: updatedSkill.name,
          description: 'Skill Updated Successfully',
          status: 'success',
        });
      },
      onError: (err: AxiosError, skillUpdate: UpdateSkill) => {
        toast({
          title: skillUpdate.name,
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Updating Skill',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  const updateSkillType = useMutation(
    (skillUpdate: UpdateSkill) => updateSkill({ ...skillUpdate }),
    {
      onSuccess: (updatedSkill: Skill) => {
        onSkillUpdated(updatedSkill.id);
        toast({
          title: updatedSkill.name,
          description: 'Skill Type Updated Successfully',
          status: 'success',
        });
      },
      onError: (err: AxiosError, skillUpdate: UpdateSkill) => {
        toast({
          title: skillUpdate.name,
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Updating Skill Type',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  const mergeSkills = useMutation(
    (skillMerge: MergeSkill) => merge({ ...skillMerge }),
    {
      onSuccess: (_skill: Skill, skillMerge: MergeSkill) => {
        onSkillUpdated(skillMerge.id);
        toast({
          title: skillMerge.name,
          description: 'Skills Merged Successfully',
          status: 'success',
        });
      },
      onError: (err: AxiosError, skillMerge: MergeSkill) => {
        toast({
          title: skillMerge.name,
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Merging Skills',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  const deleteSkill = useMutation(
    (delSkill: DeleteSkill) => removeSkill(delSkill.id),
    {
      onSuccess: (delSkill: Skill) => {
        onSkillUpdated(delSkill.id);
        toast({
          title: delSkill.name,
          description: 'Skills Deleted Successfully',
          status: 'success',
        });
      },
      onError: (err: AxiosError, delSkill: DeleteSkill) => {
        toast({
          title: delSkill.name,
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Deleting Skill',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return {
    update,
    updateSkillType,
    mergeSkills,
    deleteSkill,
  };
};

export default useSkillUpdate;
