import { ProfileSectionHeader } from '../ProfileSectionHeader';
import { Box, useDisclosure } from '@chakra-ui/react';
import EditModal from '~/profile/components/skills/EditModal';
import SkillsPanel from '~/profile/components/skills/SkillsPanel';
import useProfileStore from '~/profile/stores/profile';
import MissingData from '~/shared/components/ui/MissingData';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Skills = () => {
  const { profile } = useProfileStore();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation('profile');

  return (
    <Box data-testid="profile-component-skills">
      <EditModal isOpen={isOpen} onClose={onClose} />
      <ProfileSectionHeader
        onClickEdit={() => onOpen()}
        sectionTitle={t(`skill.focus`)}
        headingDataTestId="test-heading-skills"
      />
      {!profile?.main_skills.length ? (
        <MissingData text={t(`skill.not_added`)} />
      ) : (
        <SkillsPanel skills={profile?.main_skills} />
      )}
    </Box>
  );
};

export default Skills;
