import { Badge } from '@chakra-ui/react';
import { EmployeeSkill } from '~/shared/models/api/employee';
import { getSkillProficiencyAsSimbols } from '~/shared/utils/skills';
import React, { FC } from 'react';

interface SearchProfileCardSkillProps {
  skill: EmployeeSkill;
  isHighlighted?: boolean;
}

export const SearchProfileCardSkill: FC<SearchProfileCardSkillProps> = ({
  skill,
  isHighlighted,
}) => {
  return (
    <Badge colorScheme={`${isHighlighted ? 'green' : 'blue'}`} fontSize="2xs">
      {`${skill.skill.name} ${getSkillProficiencyAsSimbols(
        skill.skill_level_id
      )}`}
    </Badge>
  );
};

export default SearchProfileCardSkill;
