import { Box } from '@chakra-ui/react';
import { ProfileSectionItemHeader } from '~/profile/components/ProfileSectionItemHeader';
import {
  EmployeeInternalExperience,
  EmployeeInternalExperienceParent,
} from '~/shared/models/api/employee';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface InternalExperienceItemProps {
  experience: EmployeeInternalExperienceParent;
  onOpen: (experience: EmployeeInternalExperience) => void;
  onDelete: (experience: EmployeeInternalExperience) => Promise<void>;
}

const InternalExperienceParent: React.FC<InternalExperienceItemProps> = ({
  experience,
  onOpen,
  onDelete,
}) => {
  const { t } = useTranslation('projects');
  const isInternal = !experience.project.external;

  return (
    <Box mt={4}>
      <ProfileSectionItemHeader
        backgroundColor="gray.100"
        description={experience.project.description}
        headerTestId={`internal-experiences-item-heading-${experience.id}`}
        rightTag={t(`${isInternal ? 'internal' : 'external'}`, {
          quantity: experience.children?.length || 1,
        })}
        subTitle={experience.project.client_name}
        tagColorScheme={isInternal ? 'orange' : 'cyan'}
        title={experience.project.sms_title}
      />
      {experience.children?.length ? (
        experience.children?.map((child, i) => (
          <ProfileSectionItemHeader
            description={child.tasks}
            endDate={child.end_at}
            headerTestId={`internal-experiences-item-child-${child.id}-${i}`}
            ml={8}
            onEdit={() => onOpen(child)}
            onDelete={() => onDelete(child)}
            key={`internal-experience-parent-${i}-id-${child.project.id}`}
            startDate={child.start_at}
            tagColorScheme="cyan"
            title={child.project.sms_title}
          />
        ))
      ) : (
        <ProfileSectionItemHeader
          description={experience.tasks}
          endDate={experience.end_at}
          headerTestId={`internal-experiences-item-experience-${experience.id}`}
          ml={8}
          onEdit={() => onOpen(experience)}
          onDelete={() => onDelete(experience)}
          key={`internal-experience-parent-id-${experience.project.id}`}
          startDate={experience.start_at}
          tagColorScheme="cyan"
        />
      )}
    </Box>
  );
};

export default InternalExperienceParent;
