import { Avatar, Flex, Text } from '@chakra-ui/react';
import { useLoadAzureBlob } from '~/shared/queries/useLoadAzureBlob';
import React, { FC } from 'react';

interface SearchAutocompleteEntryProps {
  name: string;
  base64Image?: string;
}

const SearchAutocompleteEntry: FC<SearchAutocompleteEntryProps> = ({
  name,
  base64Image,
}) => {
  const { data: avatarUrl } = useLoadAzureBlob(base64Image);
  return (
    <Flex
      w="100%"
      borderWidth="2px"
      borderColor="gray.200"
      p="2"
      mb="2"
      alignItems="center"
      _hover={{ background: 'gray.100' }}
      sx={{
        '&:focus': {
          boxShadow: 'none',
        },
      }}
    >
      {avatarUrl && <Avatar size="sm" name={name} src={avatarUrl} mr={2} />}
      <Text>{name}</Text>
    </Flex>
  );
};

export default SearchAutocompleteEntry;
