import { MainRoutesPaths } from '../routes/enums/main';
import { Card } from '@chakra-ui/react';
import ProfileLinkCard from '~/dashboard/components/ProfileLinkCard';
import useProfileStore from '~/profile/stores/profile';
import AppLayout from '~/shared/components/ui/AppLayout';
import React, { useEffect } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

const Dashboard = () => {
  const { myProfile, error, setProfile } = useProfileStore();

  useEffect(() => {
    setProfile(null);
  }, []);

  document.title = 'Skill-Management';

  return (
    <AppLayout>
      <Card
        size={['sm', 'sm', 'md', 'lg', 'lg']}
        w={['sm', 'md', '2xl', '4xl']}
        mx="auto"
        variant="outline"
        as={ReactRouterLink}
        to={MainRoutesPaths.PROFILE}
      >
        <ProfileLinkCard profile={myProfile} hasError={!!error} />
      </Card>
    </AppLayout>
  );
};

export default Dashboard;
