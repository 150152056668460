import { Employee } from '~/shared/models/api/employee';
import { Skill } from '~/shared/models/api/skills';
import { PartialEmployeeFilters } from '~/shared/models/search/filters/employees';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { useSkillsSearch } from '~/shared/queries/skills/useSkillsSearch';
import { useEmployeesSearch } from '~/shared/queries/useEmployeesSearch';
import { AxiosError } from 'axios';
import { UseQueryResult } from 'react-query';

export const useNavSearchQuery = (
  query?: string
): [
  UseQueryResult<PaginatedResponse<Employee>, AxiosError>,
  UseQueryResult<Skill[], AxiosError>
] => {
  const buildFilter = (optional?: PartialEmployeeFilters) =>
    query ? { query, ...optional } : undefined;

  const employeeFilter = buildFilter({ show_internal_resources: true });
  const skillFilter = buildFilter();

  const employeesSearch = useEmployeesSearch(employeeFilter);
  const skillsSearch = useSkillsSearch(skillFilter);

  return [employeesSearch, skillsSearch];
};
