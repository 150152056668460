import { useDisclosure } from '@chakra-ui/react';
import ConfirmationModal from '~/shared/components/ui/ConfirmationModal';
import React, { ReactNode, useContext, useMemo, useRef, useState } from 'react';

interface ConfirmationModalProps {
  titleText?: string;
  descriptionText?: string;
  confirmText?: string;
  isDangerAction?: boolean;
}

interface ConfirmationModalContextType {
  openConfirmation: (props?: ConfirmationModalProps) => Promise<boolean>;
}

const Context = React.createContext<ConfirmationModalContextType>(
  {} as ConfirmationModalContextType
);

export default function ConfirmationModalContext({
  children,
}: {
  children: ReactNode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState<ConfirmationModalProps>();
  const resolver = useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const onOpenConfirmation = (
    props?: ConfirmationModalProps
  ): Promise<boolean> => {
    setContent(props);
    onOpen();
    return new Promise((resolve) => (resolver.current = resolve));
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    onClose();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    onClose();
  };

  const modalContext = useMemo(
    () => ({
      openConfirmation: onOpenConfirmation,
    }),
    []
  );

  return (
    <Context.Provider value={modalContext}>
      <ConfirmationModal
        isOpen={isOpen}
        onClose={handleCancel}
        onConfirm={handleOk}
        {...content}
      />
      {children}
    </Context.Provider>
  );
}

export function useConfirmationModalContext() {
  return useContext(Context);
}
