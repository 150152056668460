import { Employee } from '~/shared/models/api/employee';

export const progressBarColorEval = [
  {
    maxValue: 10,
    colorScheme: 'gray',
  },
  {
    maxValue: 25,
    colorScheme: 'orange',
  },
  {
    maxValue: 50,
    colorScheme: 'yellow',
  },
  {
    maxValue: 85,
    colorScheme: 'teal',
  },
  {
    maxValue: 100,
    colorScheme: 'green',
  },
];

export const profileProperties: (keyof Employee)[] = [
  'work_since',
  'job_title',
  'seniority',
  'bio',
  'social_links',
  'languages',
  'education',
  'main_skills',
  'industries',
  'ki_projects',
];

export const checkIfPropertyIsFilled = (
  profile: Employee,
  key: keyof Employee
) => {
  const property = profile[key];
  if (
    ((typeof property === 'number' || typeof property === 'string') &&
      !!property) ||
    (Array.isArray(property) && property.length > 0)
  ) {
    return true;
  }

  return false;
};
