import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import AlertInfo from '~/shared/components/ui/AlertInfo';
import { EmployeeSkill } from '~/shared/models/api/employee';
import { getSkillProficiencyAsSimbols } from '~/shared/utils/skills';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SkillsListProps {
  skills: EmployeeSkill[];
  onRemove: (index: number) => void;
  onClick: (index: number) => void;
}

const SkillsList: React.FC<SkillsListProps> = ({
  skills,
  onRemove,
  onClick,
}) => {
  const { t } = useTranslation(['profile', 'base']);

  return (
    <Box>
      <Text fontSize="md" color="gray.600" fontWeight="bold" mb="2">
        Current Skill List
      </Text>
      {!skills.length ? (
        <AlertInfo text={t('profile:skill.please_add')} />
      ) : (
        <Flex
          data-testid="skills-list"
          gridGap="2"
          border="2px"
          px="2"
          py="3"
          borderColor="blue.300"
          rounded="base"
          wrap="wrap"
        >
          {skills.map((skill, index) => (
            <ButtonGroup
              size="sm"
              isAttached
              variant="outline"
              color="gray.600"
              sx={{
                button: {
                  bg: 'blue.100',
                  fontWeight: 'normal',
                },
              }}
              key={`skills-list-${skill.skill_id}`}
              data-testid={`skills-list-${skill.skill_id}`}
            >
              <Tooltip label={`Edit ${skill.skill?.name}`}>
                <Button
                  cursor="pointer"
                  onClick={() => onClick(index)}
                  fontSize={15}
                  _hover={{
                    bg: 'blue.500',
                    color: 'white',
                  }}
                >
                  {`${skill.skill?.name} ${getSkillProficiencyAsSimbols(
                    skill.skill_level_id
                  )}`}
                </Button>
              </Tooltip>

              <Tooltip
                label={t('base:remove_item', { item: skill.skill?.name })}
              >
                <IconButton
                  aria-label={t('base:remove_item_button', skill.skill?.name)}
                  icon={<CloseIcon fontSize={8} />}
                  data-testid="skills-list-remove-button"
                  _hover={{
                    bg: 'red',
                    color: 'white',
                  }}
                  onClick={() => onRemove(index)}
                />
              </Tooltip>
            </ButtonGroup>
          ))}
        </Flex>
      )}
    </Box>
  );
};
export default SkillsList;
