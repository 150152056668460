import {
  Employee,
  EmployeeCertification,
  EmployeeExportFields,
  EmployeeTraining,
} from '~/shared/models/api/employee';
import {
  SelectOption,
  SelectOptionMapperKeysType,
} from '~/shared/models/react-select/select-option';
import * as yup from 'yup';

export type ExportFormType = {
  company: string;
  skills: SelectOption<SelectOptionMapperKeysType>[] | null;
  industries: SelectOption<SelectOptionMapperKeysType>[] | null;
  showProficiencies: boolean;
  hideClientNames: boolean;
  isTech: boolean;
  personalData: Pick<Employee, 'first_name' | 'second_name'>;
  trainings: EmployeeTraining[] | undefined;
  certifications: EmployeeCertification[] | undefined;
};

export const exportSchema = yup.object().shape({
  company: yup.string().required('Company is required.'),
  skill: yup.array().nullable(),
  industry: yup.array().nullable(),
});

export const defaultExportSchema = (
  profile: Employee | null
): ExportFormType => {
  return {
    company: '',
    skills: null,
    industries: null,
    showProficiencies: false,
    hideClientNames: false,
    isTech: true,
    personalData: profile || ({} as Employee),
    trainings: profile?.trainings,
    certifications: profile?.certifications,
  };
};

export const mapExport = (
  exportData: ExportFormType
): EmployeeExportFields => ({
  company: exportData.company,
  highlight_skill: exportData.skills?.map((skill) => skill.label) ?? [],
  highlight_industry:
    exportData.industries?.map((industry) => industry.label) ?? [],
  show_skill_levels: exportData.showProficiencies,
  hide_companies_names: exportData.hideClientNames,
  is_tech: exportData.isTech,
});
