import SearchResponseContent from '../components/SearchResponseContent';
import SkillsFilters from '../components/skills/filters/SkillsFilters';
import SkillsGrid from '../components/skills/grid/SkillsGrid';
import SkillsGridItem from '../components/skills/grid/SkillsGridItem';
import SkillEditModal from '../components/skills/modals/SkillEditModal/SkillEditModal';
import SkillsListByType from '../components/skills/SkillsListByType';
import useSkillEditModal from '~/administration/hooks/skills/useSkillEditModal';
import useSkillsAndTypesSearch from '~/administration/hooks/skills/useSkillsAndTypesSearch';
import useSkillsUpdatesOnDragAndDrop from '~/administration/hooks/skills/useSkillsUpdatesOnDragAndDrop';
import { GridViewModes } from '~/shared/enums/gridViewModes';
import { Permission } from '~/shared/enums/permissions';
import useRouteGuard from '~/shared/hooks/permissions/useRouteGuard';
import { SkillType } from '~/shared/models/api/skills';
import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

const Skills = () => {
  useRouteGuard([Permission.SKILLS]);

  const {
    skills: skillsData,
    skillTypes: skillTypesData,
    isLoading,
    error,
    handleQueryChange,
  } = useSkillsAndTypesSearch();
  const {
    skills,
    skillTypes,
    skillPendingUpdate,
    isSkillHighlighted,
    onDragEnd,
    onSkillUpdated,
    onSkillMerged,
    onSkillDeleted,
  } = useSkillsUpdatesOnDragAndDrop(skillsData, skillTypesData);
  const [gridViewMode, setGridViewMode] = useState<GridViewModes>(
    Object.values(GridViewModes)[0]
  );
  const {
    onSkillClick,
    updatedSkill,
    mergedSkill,
    deletedSkill,
    deleteSelected,
    ...skillModalProps
  } = useSkillEditModal();

  useEffect(() => {
    onSkillUpdated(updatedSkill);
  }, [updatedSkill]);

  useEffect(() => {
    onSkillMerged(mergedSkill);
  }, [mergedSkill]);

  useEffect(() => {
    onSkillDeleted(deletedSkill);
  }, [deletedSkill]);

  return (
    <>
      <SkillEditModal {...skillModalProps} onDelete={deleteSelected} />
      <SkillsFilters
        mode={gridViewMode}
        onQueryChange={handleQueryChange}
        onViewModeChange={setGridViewMode}
      />
      <SearchResponseContent
        loading={isLoading}
        error={error}
        noResults={skillsData?.length === 0 || skillTypesData?.length === 0}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <SkillsGrid mode={gridViewMode}>
            {skillTypes?.map((type: SkillType) => (
              <SkillsGridItem
                {...(type.name ? { id: type.id, title: type.name } : {})}
                mode={gridViewMode}
                key={`skills-type-${type.id || 'Undefined'}`}
              >
                {skills.length > 0 && (
                  <SkillsListByType
                    type={type}
                    skills={skills}
                    skillPendingUpdate={skillPendingUpdate}
                    isSkillHighlighted={isSkillHighlighted}
                    onSkillClick={onSkillClick}
                  />
                )}
              </SkillsGridItem>
            ))}
          </SkillsGrid>
        </DragDropContext>
      </SearchResponseContent>
    </>
  );
};

export default Skills;
