import 'cropperjs/dist/cropper.css';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';

interface CropperModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentImage: string | undefined;
  onCroppedImage: (image: Blob | null, imageBase64: string | undefined) => void;
}

const CropperModal: React.FC<CropperModalProps> = (props) => {
  const { isOpen, onClose, currentImage, onCroppedImage } = props;
  const cropperRef = useRef<ReactCropperElement>(null);
  const canvasOptions: Cropper.GetCroppedCanvasOptions = {
    width: 300,
    height: 300,
    imageSmoothingQuality: 'high',
    imageSmoothingEnabled: false,
  };

  const onCrop = () => {
    const imageElement: ReactCropperElement | null = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const nextImageBase64 = cropper
      ?.getCroppedCanvas(canvasOptions)
      .toDataURL();

    cropper?.getCroppedCanvas(canvasOptions).toBlob((nextImage) => {
      onCroppedImage(nextImage, nextImageBase64);
    });

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent data-testid="test-modal">
        <ModalHeader data-testid="test-modal-header">
          Upload new profile picture
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody data-testid="test-modal-body">
          <Cropper
            src={currentImage}
            style={{ height: 400, width: '100%' }}
            aspectRatio={1}
            guides={false}
            size={300}
            ref={cropperRef}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            data-testid="test-button-close"
            type="button"
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            data-testid="test-button-submit"
            type="submit"
            colorScheme="blue"
            onClick={onCrop}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CropperModal;
