import Link from './ui/Link';
import { LinkProps } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';

type SkillSearchLinkProps = {
  skillId: number;
};

const SkillSearchLink: FC<
  PropsWithChildren<SkillSearchLinkProps & LinkProps>
> = ({ skillId, children, ...linkProps }) => {
  return (
    <Link
      to={{
        pathname: '/search',
        search: `?skills=${encodeURIComponent(skillId)}`,
      }}
      _hover={{ textDecor: 'none' }}
      {...linkProps}
    >
      {children}
    </Link>
  );
};

export default SkillSearchLink;
