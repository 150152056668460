import { Box } from '@chakra-ui/react';
import React from 'react';

const PermissionLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="4px"
      borderColor="black"
      padding="6px 8px"
      width="fit-content"
      whiteSpace="nowrap"
    >
      {children}
    </Box>
  );
};

export default PermissionLabel;
