import { EndpointPaths } from '~/shared/enums/endpointPaths';
import {
  EndpointFactory,
  EndpointItem,
  EndpointUrl,
} from '~/shared/interfaces/endpoints';
import { urlBuilder, urlItemBuilder } from '~/shared/utils/urlBuilders';

interface ProjectEndpointItem extends EndpointUrl {
  parent: EndpointItem<number>;
  children: EndpointUrl;
  potentialChildren: EndpointUrl;
}

type ProjectEndpoint = EndpointItem<number, ProjectEndpointItem>;

const projectUrlFactory: EndpointFactory<number, ProjectEndpointItem> =
  (projectUrl) => (projectId) => {
    const projectIdUrl = `${projectUrl}/${projectId}`;

    return {
      url: projectIdUrl,

      parent: urlItemBuilder<number>(`${projectIdUrl}/${EndpointPaths.PARENT}`),
      children: urlBuilder(`${projectIdUrl}/${EndpointPaths.CHILDREN}`),
      potentialChildren: urlBuilder(
        `${projectIdUrl}/${EndpointPaths.POTENTIAL_CHILDREN}`
      ),
    };
  };

export const projectsEndpoint: ProjectEndpoint = {
  url: EndpointPaths.PROJECTS,
  id: projectUrlFactory(EndpointPaths.PROJECTS),
};
