import { Box, BoxProps } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';

const AdvancedFiltersHeaderRightContentContainer: FC<
  PropsWithChildren<BoxProps>
> = ({ children, ...props }) => {
  return (
    <Box mb={6} alignSelf={{ md: 'end' }} {...props}>
      {children}
    </Box>
  );
};

export default AdvancedFiltersHeaderRightContentContainer;
