import { useBoolean } from '@chakra-ui/react';

interface UseUpdateSkillType {
  isOpen: boolean;
  openConfirmation: () => void;
  closeConfirmation: () => void;
}

const useConfirmationModal = (): UseUpdateSkillType => {
  const [isOpen, setIsOpen] = useBoolean(false);

  const openConfirmation = () => {
    setIsOpen.on();
  };

  const closeConfirmation = () => {
    setIsOpen.off();
  };

  return {
    isOpen,
    openConfirmation,
    closeConfirmation,
  };
};

export default useConfirmationModal;
