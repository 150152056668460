export enum OrderDirection {
  NONE = '',
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export interface PaginationFilters {
  skip: number;
  limit: number;
}

export interface SearchFilters extends Partial<PaginationFilters> {
  query: string;
}

export interface SearchFiltersOrderBy<T> {
  order?: OrderDirection;
  order_by?: T;
}
