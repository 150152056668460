import {
  Badge,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import ExternalExperiencesForm from '~/profile/components/experiences/external/ExternalExperiencesForm';
import useProfileStore from '~/profile/stores/profile';
import useExternalExperience from '~/shared/hooks/employee/useEmployeeExtrenalExperience';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { EmployeeExternalExperience } from '~/shared/models/api/employee';
import React, { useState } from 'react';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface EditModalProps {
  onClose: () => void;
  experience?: EmployeeExternalExperience;
}

const EditModal: React.FC<EditModalProps> = ({ onClose, experience }) => {
  const [dirtyFields, setDirtyFields] = useState<
    Partial<Readonly<FieldNamesMarkedBoolean<EmployeeExternalExperience>>>
  >({});

  const { t } = useTranslation('profile');
  const { openConfirmation } = useConfirmationModalContext();
  const { profile, updateEmployeeProfile } = useProfileStore();
  const { startLoading, stopLoading } = useLoadingContext();
  const { createExternalExperience, updateExternalExperience } =
    useExternalExperience();

  const onSubmit = async (experience: EmployeeExternalExperience) => {
    if (!profile) return;

    const experiences = [...profile.experiences_outside_ki];
    const experienceId = experiences.findIndex((e) => e.id === experience.id);

    startLoading();

    if (experienceId > -1) {
      await updateExternalExperience.mutateAsync(experience);
      experiences[experienceId] = experience;
    } else {
      await createExternalExperience.mutateAsync(experience);
      experiences.push(experience);
    }

    updateEmployeeProfile({ experiences_outside_ki: experiences });

    stopLoading();
    onClose();
  };

  const onCloseModal = () => {
    const unsavedChanges = !!Object.keys(dirtyFields).length;
    if (unsavedChanges) {
      handleUnsavedChanges();
      return;
    }
    onClose();
  };

  const handleUnsavedChanges = async () => {
    const result = await openConfirmation();
    result && onClose();
  };

  return (
    <Modal isOpen onClose={onCloseModal} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <Stack direction="column">
          <ModalHeader
            data-testid="modal-external-experiences-header"
            mt="2"
            mb="0"
          >
            <Badge colorScheme="blue">{t('experiences.external.title')}</Badge>
            <Heading fontSize="2xl" fontWeight="bold" mt="2">
              Edit Details
            </Heading>
            <ModalCloseButton />
          </ModalHeader>
        </Stack>
        <ExternalExperiencesForm
          project={experience}
          onSubmit={onSubmit}
          onCancel={onCloseModal}
          onChange={(dirtyFields) => setDirtyFields(dirtyFields)}
        />
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
