import { Image } from '@chakra-ui/react';
import { useLoadProfileCompanyLogo } from '~/shared/queries/useLoadProfileCompanyLogo';
import React from 'react';

interface Props {
  name?: string;
  path?: string;
}

export const ProfileCompanyLogo: React.FC<Props> = ({ name, path }) => {
  const { data: companyLogoUrl } = useLoadProfileCompanyLogo(path);

  return (
    <>
      {path && (
        <Image
          data-testid="test-img-company-logo"
          src={companyLogoUrl}
          alt={name}
          maxW={['20', '20', '24', '28']}
        />
      )}
    </>
  );
};
