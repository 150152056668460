import axios, { AxiosResponse as AxiosResponseDefault } from 'axios';
import qs from 'qs';

const fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

fetcher.defaults.paramsSerializer = {
  serialize: (params) => {
    return qs.stringify(params, { indices: false });
  },
};

export default fetcher;

export type AxiosResponse<T> = AxiosResponseDefault<T>;
