import SkillsGridItemByMode from './SkillsGridItemByMode';
import SkillsGridItemHeader from './SkillsGridItemHeader';
import { Flex } from '@chakra-ui/react';
import { GridViewModes } from '~/shared/enums/gridViewModes';
import React, { FC, ReactNode } from 'react';
import { Droppable } from 'react-beautiful-dnd';

type SkillsGridItemProps = {
  id?: string;
  title?: string;
  children?: ReactNode;
  mode: GridViewModes;
};

const SkillsGridItem: FC<SkillsGridItemProps> = ({
  id = 'Undefined',
  title = 'Undefined',
  children,
  mode,
}) => {
  return (
    <Droppable
      droppableId={id}
      type="CARD"
      direction={mode === GridViewModes.GRID ? undefined : mode}
      isDropDisabled={id === 'Undefined'}
    >
      {({ innerRef, placeholder, droppableProps }, { isDraggingOver }) => (
        <Flex
          flexDirection="column"
          userSelect="none"
          transition="background-color 0.1s ease"
          bg={isDraggingOver ? 'blue.100' : 'white'}
          py={3}
          px={4}
          borderRadius={5}
          {...droppableProps}
        >
          <SkillsGridItemHeader title={title} mode={mode} />
          <SkillsGridItemByMode innerRef={innerRef} mode={mode}>
            <>
              {children}
              {placeholder}
            </>
          </SkillsGridItemByMode>
        </Flex>
      )}
    </Droppable>
  );
};

export default SkillsGridItem;
