import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  ModalFooter,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import SkillSelect from '~/shared/components/select/skill/SkillSelect';
import ModalButton from '~/shared/components/ui/ModalButton';
import { Skill } from '~/shared/models/api/skills';
import React, { FC } from 'react';
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object } from 'yup';

const skillMergeModalFormSchema = object().shape({
  skill: object().nullable().required('skill is a required field'),
});

export interface SkillMergeModalFormProps {
  selectedSkill: Partial<Skill>;
  isUpdating: boolean;
  onClose?: () => void;
  merge: (skill: Skill) => void;
}

const SkillMergeModalForm: FC<SkillMergeModalFormProps> = ({
  selectedSkill,
  isUpdating,
  onClose,
  merge,
}) => {
  const { t: tBase } = useTranslation('base');
  const { t: tSkills } = useTranslation('skills');
  const form = useForm({ resolver: yupResolver(skillMergeModalFormSchema) });
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = form;

  const onSubmit = async ({ skill }: FieldValues) => merge(skill);

  return (
    <Stack>
      <FormProvider {...form}>
        <ModalBody>
          <FormControl isInvalid={Boolean(errors.skill)}>
            <FormLabel>{tSkills('edit.merge_with_existing_skill')}</FormLabel>
            <Controller
              control={control}
              name="skill"
              render={({ field: { onChange } }) => (
                <SkillSelect
                  id="skill-select"
                  onChange={onChange}
                  selectedSkills={[selectedSkill as Skill]}
                />
              )}
            />
            <FormErrorMessage>
              {errors.skill?.message as string}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          {onClose && (
            <ModalButton
              data-testid="modal-industries-close-button"
              type="button"
              onClick={onClose}
            >
              {tBase('close')}
            </ModalButton>
          )}
          <ModalButton
            colorScheme="blue"
            type="submit"
            variant="solid"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty}
            isUpdating={isUpdating}
            ml="auto"
          >
            {tBase('merge')}
          </ModalButton>
        </ModalFooter>
      </FormProvider>
    </Stack>
  );
};

export default SkillMergeModalForm;
