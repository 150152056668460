import { Button, Flex, Heading, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import CompaniesRadio from '~/profile/components/export/pdf/CompaniesRadio';
import {
  defaultExportSchema,
  ExportFormType,
  exportSchema,
  mapExport,
} from '~/profile/forms/export';
import useProfileStore from '~/profile/stores/profile';
import { getEmployeeExport } from '~/shared/api/request/employee';
import FormControlCheckbox from '~/shared/components/form/FormControlCheckbox';
import FormControlMultipleSelectSync from '~/shared/components/form/FormControlMultipleSelectSync';
import { fileNameDate } from '~/shared/utils/date';
import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface PdfExportProps {
  onClose: () => void;
}

const PdfExport: React.FC<PdfExportProps> = ({ onClose }) => {
  const toast = useToast();
  const { profile, getProfileId } = useProfileStore();

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    resolver: yupResolver(exportSchema),
    defaultValues: defaultExportSchema(profile),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    watch,
  } = methods;

  const { t } = useTranslation('profile');

  useEffect(() => {
    reset(defaultExportSchema(profile));
  }, []);

  const handleExportClick = async (values: ExportFormType) => {
    if (!values.personalData) return;

    setLoading(true);

    try {
      const mappedValues = mapExport(values);

      const pdfFile = await getEmployeeExport(mappedValues, getProfileId());

      download(
        pdfFile.data,
        `profile_${values.personalData.first_name}_${
          values.personalData.second_name
        }_${fileNameDate()}.pdf`
      );

      toast({
        title: t(`export.pdf.success.title`),
        description: t(`export.pdf.success.text`),
        status: 'success',
      });
    } catch (e: unknown) {
      toast({
        title: t(`export.pdf.fail.title`),
        description: (e as Error).message || t(`export.pdf.fail.text`),
        status: 'error',
      });
    } finally {
      reset();
      setLoading(false);
      onClose();
    }
  };

  return (
    <FormProvider {...methods}>
      <Heading fontSize="lg" fontWeight="200" mt="2" color="gray.600" mb={4}>
        {t(`export.pdf.title`, {
          name: `${profile?.first_name} ${profile?.second_name}`,
        })}
      </Heading>
      <CompaniesRadio
        id="company"
        error={errors.company}
        selected={watch('company')}
      />
      <Flex mt={2}>
        <FormControlMultipleSelectSync
          id="skills"
          name={t(`export.pdf.skills_to_highlight`)}
          register={register}
          error={errors.skills}
          options={profile?.main_skills?.map((skill) => ({
            value: skill.skill.id,
            label: skill.skill.name,
          }))}
        />
      </Flex>
      <Flex mt={2}>
        <FormControlMultipleSelectSync
          id="industries"
          name={t(`export.pdf.industries_to_highlight`)}
          register={register}
          error={errors.industries}
          options={profile?.industries?.map((industry) => ({
            value: industry.industry?.id || industry.industry_id,
            label: industry.industry?.name || '',
          }))}
        />
      </Flex>
      <Flex mt={2}>
        <FormControlCheckbox
          id="showProficiencies"
          name={t(`export.pdf.industries_to_highlight`)}
          error={errors.showProficiencies}
          register={register}
          checked={watch('showProficiencies')}
        />
      </Flex>
      <Flex mt={2}>
        <FormControlCheckbox
          id="isTech"
          name={
            watch('isTech')
              ? t(`export.pdf.tech_profile`)
              : t(`export.pdf.business_profile`)
          }
          error={errors.isTech}
          register={register}
          checked={watch('isTech')}
        />
      </Flex>
      <Flex mt={2} justifyContent="end">
        <Button
          type="submit"
          colorScheme="blue"
          onClick={handleSubmit(handleExportClick)}
          isLoading={loading}
        >
          {t(`export.pdf.button`)}
        </Button>
      </Flex>
    </FormProvider>
  );
};

export default PdfExport;
