import { CustomAlert } from './CustomAlert';
import { AlertStatus, Box } from '@chakra-ui/react';
import React from 'react';

interface AlertInfoProps {
  text: string;
  status?: AlertStatus;
}

const AlertInfo: React.FC<AlertInfoProps> = ({ text, status }) => {
  return (
    <Box>
      <CustomAlert
        alertDescriptionProps={{
          fontSize: 'md',
          color: 'gray.900',
          fontWeight: 'light',
        }}
        borderRadius="md"
        status={status ? status : 'warning'}
        text={text}
        variant="left-accent"
      />
    </Box>
  );
};

export default AlertInfo;
