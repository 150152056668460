import { Tag, TagProps, ThemeTypings, Tooltip } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

interface CustomTagProps {
  customTagTestId?: ThemeTypings['colorSchemes'];
  tooltipLabel?: string;
}

export const CustomTag: React.FC<
  PropsWithChildren<CustomTagProps & TagProps>
> = ({ customTagTestId, children, tooltipLabel, ...props }) => (
  <Tooltip label={tooltipLabel}>
    <Tag
      data-testid={customTagTestId}
      whiteSpace="nowrap"
      rounded={25}
      mr={2}
      height={4}
      {...props}
    >
      {children}
    </Tag>
  </Tooltip>
);
