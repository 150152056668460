import Login from './Login';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { useToast } from '@chakra-ui/react';
import useProfileStore from '~/profile/stores/profile';
import FullScreenLoading from '~/shared/components/ui/FullScreenLoading';
import LoadingData from '~/shared/components/ui/LoadingData';
import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const AuthWrapper: FC<PropsWithChildren> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { loadMyProfile, isLoadingMyProfile, myProfile, error } =
    useProfileStore();
  const toast = useToast();
  const { instance } = useMsal();
  const toastDisplayedRef = useRef(false); // keep state between renders
  const { t } = useTranslation('base');

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    loadMyProfile(true);
  }, [isAuthenticated]);

  useEffect(() => {
    if (error && !toastDisplayedRef.current) {
      toast({
        title: t('something_went_wrong'),
        description: t('redirecting_to_login_page'),
        status: 'error',
        onCloseComplete: () => {
          toastDisplayedRef.current = true;
          instance.logoutRedirect();
        },
      });

      toastDisplayedRef.current = false;
    }
  }, [error, instance, toast]);

  if (error) {
    return <LoadingData mt="20" />;
  }

  return (
    <>
      <AuthenticatedTemplate>
        {isLoadingMyProfile && !myProfile ? <FullScreenLoading /> : children}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
};

export default AuthWrapper;
