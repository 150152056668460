import { Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  defaultNewSkill,
  mapSkill,
  newSkillSchema,
  SkillFormType,
} from '~/profile/forms/skills';
import useProfileStore from '~/profile/stores/profile';
import FormControl from '~/shared/components/form/FormControl';
import SkillLevelSelect from '~/shared/components/select/skill/SkillLevelSelect';
import SkillSelect from '~/shared/components/select/skill/SkillSelect';
import { EmployeeSkill } from '~/shared/models/api/employee';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface SkillFormProps {
  selectedSkill?: EmployeeSkill;
  selectedSkills: EmployeeSkill[];
  onSubmit: (skill: EmployeeSkill) => void;
  onCancel: () => void;
}

const SkillForm: React.FC<SkillFormProps> = ({
  selectedSkill,
  selectedSkills,
  onSubmit,
  onCancel,
}) => {
  const { profile } = useProfileStore();
  const methods = useForm({
    resolver: yupResolver(newSkillSchema),
    defaultValues: defaultNewSkill(profile),
  });
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
  } = methods;

  useEffect(() => {
    if (!selectedSkill) {
      reset();
      return;
    }
    setValue('skill', selectedSkill.skill);
    setValue('skill_level_id', selectedSkill.skill_level_id);
  }, [selectedSkill]);

  const handleFormSubmit = ({ skill, skill_level_id }: SkillFormType) => {
    if (!skill || !skill_level_id) return;

    const employeeSkill = mapSkill({
      skill,
      skill_level_id,
    });

    onSubmit(employeeSkill);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Text fontSize="md" color="gray.600" fontWeight="bold" mb="2">
        {selectedSkill ? 'Update Skill' : 'Add New Skill'}
      </Text>
      <Flex gridGap="4" flexDir={selectedSkill ? 'column' : 'row'}>
        <Flex gridGap="4" flexGrow={1}>
          <FormControl id="skill" control={control} error={errors.skill}>
            <SkillSelect
              isCreatable
              isReadOnly={!!selectedSkill}
              selectedSkill={selectedSkill?.skill}
              selectedSkills={selectedSkills.map(
                (employeeSkill) => employeeSkill.skill
              )}
            />
          </FormControl>
          <FormControl
            id="skill_level_id"
            control={control}
            error={errors.skill_level_id}
          >
            <SkillLevelSelect defaultValue={selectedSkill?.skill_level_id} />
          </FormControl>
        </Flex>
        <Flex justifyContent="space-between">
          {selectedSkill && (
            <Button
              data-testid="form-skill-cancel-button"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
          <Tooltip label={selectedSkill ? 'Update Skill' : 'Add New Skill'}>
            <Button
              data-testid="form-skill-add-button"
              type="button"
              minW="auto"
              colorScheme="yellow"
              onClick={handleSubmit(handleFormSubmit)}
            >
              {selectedSkill ? 'Update' : 'Add'}
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </FormProvider>
  );
};

export default SkillForm;
