import { ProfileSectionHeader } from '../ProfileSectionHeader';
import { Box, useDisclosure } from '@chakra-ui/react';
import EditModal from '~/profile/components/industries/EditModal';
import IndustriesPanel from '~/profile/components/industries/IndustriesPanel';
import useProfileStore from '~/profile/stores/profile';
import MissingData from '~/shared/components/ui/MissingData';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Industries = () => {
  const { profile } = useProfileStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('profile');

  return (
    <Box>
      <EditModal isOpen={isOpen} onClose={onClose} />
      <ProfileSectionHeader
        headingDataTestId="test-heading-industries"
        sectionTitle={t(`industry.expertise`)}
        onClickEdit={() => onOpen()}
      />
      {!profile?.industries.length ? (
        <MissingData text={t(`industry.not_added`)} />
      ) : (
        <IndustriesPanel industries={profile?.industries} />
      )}
    </Box>
  );
};

export default Industries;
