import { Center, Flex, Spinner } from '@chakra-ui/react';
import { ProjectAccordionTableHeaders } from '~/administration/components/projects/ProjectAccordionTableHeaders';
import { ProjectAccordionTableItem } from '~/administration/components/projects/ProjectAccordionTableItem';
import { ProjectAccordionTableHeadersProps } from '~/administration/sections/Projects';
import { ActionButtons } from '~/profile/components/shared/ActionButtons';
import AlertInfo from '~/shared/components/ui/AlertInfo';
import MissingData from '~/shared/components/ui/MissingData';
import { Project } from '~/shared/models/api/project';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ProjectChildrenListProps {
  data?: Project[];
  failLoading: boolean;
  isLoading: boolean;
  handleEditProjectClick: (project: Project) => void;
  headers: ProjectAccordionTableHeadersProps<Project>[];
  projectId?: number;
  title?: string;
}

const ProjectChildrenList: React.FC<ProjectChildrenListProps> = ({
  data,
  failLoading,
  isLoading,
  handleEditProjectClick,
  headers,
  title,
}) => {
  const { t } = useTranslation('projects');

  if (failLoading) {
    return <AlertInfo text={t(`fail_loading_children`)} />;
  }

  if (isLoading || !data) {
    return (
      <Center>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  if (!data.length) {
    return <MissingData text={t(`no_children`)} />;
  }

  return (
    <>
      <Flex w="fit-content" gap={2}>
        <ProjectAccordionTableHeaders headers={headers} isChild />
        <Flex w="80px"></Flex>
      </Flex>
      {data?.map((project, index) => (
        <Flex
          alignItems="center"
          bg={index % 2 === 0 ? 'gray.50' : 'gray.100'}
          fontSize="sm"
          gap={2}
          key={`project-children-list-${project.id}-${index}`}
          py={1}
          rounded={8}
          w="min-content"
        >
          <ProjectAccordionTableItem {...{ headers, project }} isChild />

          <Flex w="80px">
            <ActionButtons
              tooltipLabel={title}
              onClickEdit={() => handleEditProjectClick(project)}
            />
          </Flex>
        </Flex>
      ))}
    </>
  );
};

export default ProjectChildrenList;
