import { EndpointPaths } from '~/shared/enums/endpointPaths';
import {
  EndpointFactory,
  EndpointItem,
  EndpointUrl,
} from '~/shared/interfaces/endpoints';
import { EmployeeIdOrMe } from '~/shared/types/employeeIdOrMe';
import { urlBuilder, urlItemBuilder } from '~/shared/utils/urlBuilders';

interface EmployeeEndpoints extends EndpointUrl {
  description: EndpointUrl;
  export: EndpointUrl;
  notify: EndpointUrl;
  picture: EndpointUrl;
  projectAllocations: EndpointUrl;
  permissions: EndpointUrl;
  roles: EndpointUrl;
  verify: EndpointUrl;
  certifications: EndpointItem<number>;
  externalExperiences: EndpointItem<number>;
  internalExperiences: EndpointItem<number>;
}

interface EmployeesEndpoints
  extends EndpointItem<EmployeeIdOrMe, EmployeeEndpoints> {
  basicInfo: EndpointUrl;
  filterParams: EndpointUrl;
  search: EndpointUrl;
}

const employeeUrlFactory: EndpointFactory<EmployeeIdOrMe, EmployeeEndpoints> =
  (employeesUrl) => (id) => {
    const employeeIdUrl = urlBuilder(`${employeesUrl}/${id}`);

    const buildEmployeeUrl = (e: EndpointPaths) =>
      urlBuilder(`${employeeIdUrl.url}/${e}`);

    const buildEmployeeItemUrl = <T>(e: EndpointPaths) =>
      urlItemBuilder<T>(buildEmployeeUrl(e).url);

    return {
      ...employeeIdUrl,
      certifications: buildEmployeeItemUrl<number>(
        EndpointPaths.CERTIFICATIONS
      ),
      description: buildEmployeeUrl(EndpointPaths.DESCRIPTION),
      export: buildEmployeeUrl(EndpointPaths.EXPORT),
      externalExperiences: buildEmployeeItemUrl<number>(
        EndpointPaths.EXTERNAL_EXPERIENCES
      ),
      internalExperiences: buildEmployeeItemUrl<number>(
        EndpointPaths.INTERNAL_EXPERIENCES
      ),
      notify: buildEmployeeUrl(EndpointPaths.NOTIFY),
      permissions: buildEmployeeUrl(EndpointPaths.PERMISSIONS),
      picture: buildEmployeeUrl(EndpointPaths.PICTURE),
      projectAllocations: buildEmployeeUrl(EndpointPaths.PROJECT_ALLOCATIONS),
      roles: buildEmployeeUrl(EndpointPaths.ROLES),
      verify: buildEmployeeUrl(EndpointPaths.VERIFY),
    };
  };

const employeesUrl = EndpointPaths.EMPLOYEES;

export const employeesEndpoint: EmployeesEndpoints = {
  url: employeesUrl,
  basicInfo: urlBuilder(`${employeesUrl}/${EndpointPaths.BASIC_INFO}`),
  id: employeeUrlFactory(employeesUrl),
  filterParams: urlBuilder(`${employeesUrl}/${EndpointPaths.FILTER_PARAMS}`),
  search: urlBuilder(`${EndpointPaths.EMPLOYEES}/${EndpointPaths.SEARCH}`),
};
