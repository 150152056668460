import AdvancedFiltersHeaderFull from '../../advanced-filters/header/AdvancedFiltersHeaderFull';
import ProjectsSearchAdvancedFilters from './ProjectsSearchAdvancedFilters';
import { ProjectsSearchFilters as SearchFilters } from '~/shared/models/search/filters/projects';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ProjectsSearchFiltersProps {
  filters: Partial<SearchFilters>;
  showAdvanced: boolean;
  isFilterChanged: boolean;
  onFilterChange: (partialFilters: Partial<SearchFilters>) => void;
  onQueryChange: (query: string) => void;
  toggleShowAdvanced: () => void;
  resetFilters: () => void;
}

const ProjectsSearchFilters: FC<ProjectsSearchFiltersProps> = ({
  filters,
  ...filtersProps
}) => {
  const { t } = useTranslation('projects');
  return (
    <>
      <AdvancedFiltersHeaderFull
        filters={filters}
        searchInputPlaceholder={t('search')}
        {...filtersProps}
      />
      <ProjectsSearchAdvancedFilters filters={filters} {...filtersProps} />
    </>
  );
};

export default ProjectsSearchFilters;
