import { Box, Container, Flex, Img, Stack, Text } from '@chakra-ui/react';
import AppLayout from '~/shared/components/ui/AppLayout';
import React from 'react';

const notFound = () => (
  <AppLayout>
    <Container>
      <Flex alignItems="center" minHeight="100vh">
        <Stack
          mt="24px"
          spacing="20"
          backgroundColor="white"
          borderRadius="2xl"
          shadow="sm"
          overflow="hidden"
        >
          <Img src="/banner.png" />
          <Box textAlign="center" fontSize="xl" mb="16" color="gray.900">
            <Box py="16">
              <Text fontSize="xl">Not Found</Text>
            </Box>
          </Box>
        </Stack>
      </Flex>
    </Container>
  </AppLayout>
);

export default notFound;
