import ProfilesStatusesSearchVerifiedCheckbox from './ProfilesStatusesSearchVerifiedCheckbox';
import AdvancedFiltersClearButton from '~/administration/components/advanced-filters/buttons/AdvancedFiltersClearButton';
import AdvancedFiltersElement from '~/administration/components/advanced-filters/filters/AdvancedFiltersElement';
import AdvancedFiltersHeaderContainer from '~/administration/components/advanced-filters/header/AdvancedFiltersHeaderContainer';
import AdvancedFiltersHeaderLeftContent from '~/administration/components/advanced-filters/header/AdvancedFiltersHeaderLeftContentContainer';
import SearchInput from '~/shared/components/SearchInput';
import CompanySelect from '~/shared/components/select/CompanySelect';
import { PartialEmployeeFilters } from '~/shared/models/search/filters/employees';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ProfilesStatusesSearchFiltersProps {
  filters: PartialEmployeeFilters;
  isFilterChanged: boolean;
  onFilterChange: (partialFilters: PartialEmployeeFilters) => void;
  onQueryChange: (query: string) => void;
  resetFilters: () => void;
}

const ProfilesStatusesSearchFilters: FC<ProfilesStatusesSearchFiltersProps> = ({
  filters,
  onFilterChange,
  onQueryChange,
  isFilterChanged,
  resetFilters,
}) => {
  const { t } = useTranslation('verification');

  return (
    <>
      <AdvancedFiltersHeaderContainer
        alignItems={{ base: 'center', lg: 'start' }}
      >
        <AdvancedFiltersHeaderLeftContent
          templateColumns={{ md: 'repeat(3, 1fr) auto' }}
        >
          <SearchInput
            placeholder={t('search')}
            query={filters.query}
            onChange={onQueryChange}
            mb={0}
          />

          <CompanySelect
            placeholder={t('base:company')}
            companyId={
              filters.companies?.length ? filters.companies[0] : undefined
            }
            onChange={(c) =>
              onFilterChange({ companies: c ? [c.id] : undefined })
            }
          />

          <AdvancedFiltersElement
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ProfilesStatusesSearchVerifiedCheckbox
              filters={filters}
              onFilterChange={onFilterChange}
            />
          </AdvancedFiltersElement>

          <AdvancedFiltersClearButton
            disabled={!isFilterChanged}
            onClick={resetFilters}
          />
        </AdvancedFiltersHeaderLeftContent>
      </AdvancedFiltersHeaderContainer>
    </>
  );
};

export default ProfilesStatusesSearchFilters;
