import { Heading, HeadingProps, Text } from '@chakra-ui/react';
import React from 'react';

export interface ProfileSectionItemTitleAndSubTitleProps extends HeadingProps {
  title?: string;
}

export const ProfileSectionItemTitleAndSubTitle: React.FC<
  ProfileSectionItemTitleAndSubTitleProps
> = ({ title, ...props }) => {
  return (
    <>
      {title && (
        <Heading color="gray.800" fontSize="md" {...props}>
          <Text>{title}</Text>
        </Heading>
      )}
    </>
  );
};
