import { Box, Text } from '@chakra-ui/react';
import ConfirmationModal from '~/shared/components/ui/ConfirmationModal';
import LoadingData from '~/shared/components/ui/LoadingData';
import { initialFilters } from '~/shared/constants/search';
import { Skill } from '~/shared/models/api/skills';
import { useEmployeesSearch } from '~/shared/queries/useEmployeesSearch';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface SkillDeleteConfirmationModalProps {
  skill: Partial<Skill>;
  isOpen: boolean;
  closeConfirmation: () => void;
  onConfirm: () => void;
}

const SkillDeleteConfirmationModal: FC<SkillDeleteConfirmationModalProps> = ({
  skill,
  isOpen,
  closeConfirmation,
  onConfirm,
}) => {
  const { t: tBase } = useTranslation('base');
  const { t: tSkills } = useTranslation('skills');
  const { data, isLoading } = useEmployeesSearch(
    isOpen
      ? {
          ...initialFilters,
          limit: 0,
          skills: [Number(skill.id)],
        }
      : undefined
  );
  return (
    <ConfirmationModal
      titleText={tSkills('edit.delete_skill', { skill_name: skill.name })}
      descriptionText={tSkills('edit.delete_verify_prompt', {
        skill_name: skill.name,
      })}
      confirmText={tBase('delete')}
      isOpen={isOpen}
      onClose={closeConfirmation}
      onConfirm={onConfirm}
      isDangerAction
    >
      <Box py={6}>
        {isLoading ? (
          <LoadingData spacing={5} text="Loading number of affected users..." />
        ) : (
          <Text color="red">
            {data?.total_results_count
              ? tSkills('edit.delete_users_warning', {
                  skill_name: skill.name,
                  count: data.total_results_count,
                })
              : tSkills('edit.delete_users_safe', { skill_name: skill.name })}
          </Text>
        )}
      </Box>
    </ConfirmationModal>
  );
};

export default SkillDeleteConfirmationModal;
