import { EndpointPaths } from '~/shared/enums/endpointPaths';
import { EndpointItem } from '~/shared/interfaces/endpoints';
import { urlItemBuilder } from '~/shared/utils/urlBuilders';

const { PROJECTS, PROJECT_POSITIONS } = EndpointPaths;

const permissionsRole = `${PROJECTS}/${PROJECT_POSITIONS}`;

export const projectPositionsEndpoint: EndpointItem<number> =
  urlItemBuilder<number>(permissionsRole);
