import { ProfileSectionHeader } from '../../ProfileSectionHeader';
import { Box, useDisclosure } from '@chakra-ui/react';
import EditModal from '~/profile/components/experiences/external/EditModal';
import ExternalExperienceItem from '~/profile/components/experiences/external/ExternalExperienceItem';
import useProfileStore from '~/profile/stores/profile';
import MissingData from '~/shared/components/ui/MissingData';
import useExternalExperience from '~/shared/hooks/employee/useEmployeeExtrenalExperience';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { EmployeeExternalExperience } from '~/shared/models/api/employee';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ExternalExperiences = () => {
  const { profile, updateEmployeeProfile } = useProfileStore();
  const { t } = useTranslation(['base', 'profile']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { openConfirmation } = useConfirmationModalContext();
  const { startLoading, stopLoading } = useLoadingContext();
  const { deleteExternalExperience } = useExternalExperience();

  const [selectedExperience, setSelectedExperience] =
    useState<EmployeeExternalExperience>();
  const [experiences, setExperiences] =
    useState<EmployeeExternalExperience[]>();

  useEffect(() => {
    if (!profile?.experiences_outside_ki.length) {
      setExperiences([]);
      return;
    }
    setExperiences(
      sortExternalExperiencesByDate(profile.experiences_outside_ki)
    );
  }, [profile?.experiences_outside_ki]);

  const sortExternalExperiencesByDate = (
    list: EmployeeExternalExperience[]
  ) => {
    return list.sort((a, b) => {
      // If both dates are null, order by adquired date
      if (!a.end_date && !b.end_date) {
        return b.start_date.localeCompare(a.start_date);
      }
      // If one date is null, the other one goes first
      if (!a.end_date) return -1;
      if (!b.end_date) return 1;

      return b.end_date.localeCompare(a.end_date);
    });
  };

  const openModal = (experience?: EmployeeExternalExperience) => {
    setSelectedExperience(experience);
    onOpen();
  };

  const closeModal = () => {
    setSelectedExperience(undefined);
    onClose();
  };

  const confirmDelete = async (experience: EmployeeExternalExperience) => {
    const result = await openConfirmation({
      isDangerAction: true,
      titleText: experience.title,
      descriptionText: t(`base:delete_confirmation`),
      confirmText: t(`base:delete`),
    });

    if (!result) {
      return;
    }

    deleteExperience(experience);
  };

  const deleteExperience = async (experience: EmployeeExternalExperience) => {
    if (!profile) {
      return;
    }

    startLoading();

    await deleteExternalExperience.mutateAsync(experience);

    updateEmployeeProfile({
      experiences_outside_ki: profile.experiences_outside_ki.filter(
        ({ id }) => id !== experience.id
      ),
    });

    stopLoading();
  };

  return (
    <Box data-testid="profile-component-external-experiences" mb={32}>
      {isOpen && (
        <EditModal experience={selectedExperience} onClose={closeModal} />
      )}

      <ProfileSectionHeader
        headingDataTestId="test-heading-external-experiences"
        sectionTitle={t(`profile:experiences.external.title`)}
        onClickAdd={openModal}
      />

      {!experiences?.length ? (
        <MissingData text={t(`profile:experiences.external.not_added`)} />
      ) : (
        <Box mt="4" borderRadius="md">
          {experiences?.map((experience, index) => (
            <ExternalExperienceItem
              key={`external-experience-${index}-id-${experience.id}`}
              onOpen={() => openModal(experience)}
              onDelete={() => confirmDelete(experience)}
              experience={experience}
              experienceIndex={index}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ExternalExperiences;
