import { TFunction } from 'i18next';

export const getErrorMessage = (
  errorCode = 404,
  defaultMessage: string,
  t: TFunction
) => {
  switch (errorCode) {
    case 401:
      return t('permission_error');
    default:
      return defaultMessage;
  }
};
