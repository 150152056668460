import { Flex, FlexProps } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';

const AdvancedFiltersHeaderContainer: FC<PropsWithChildren<FlexProps>> = ({
  children,
  ...props
}) => {
  return (
    <Flex
      justifyContent={{ md: 'space-between' }}
      flexDirection={{ base: 'column-reverse', lg: 'row' }}
      alignItems={{ base: 'center', md: 'start' }}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default AdvancedFiltersHeaderContainer;
