export enum DateFormat {
  DAY_MONTH_YEAR = 'DD-MM-YYYY',
  DAY_MONTH_YEAR_SHORT_SLASH = 'DD/MM/YY',
  FILE_NAME = 'YYYY_MM_DD_HH_mm_ss',
  MONTH_YEAR_SLASH = 'MM/YYYY',
  YEAR = 'YYYY',
  YEAR_MONTH_DAY = 'YYYY-MM-DD',
  YEAR_MONTH = 'YYYY-MM',
}

export enum DatePickerFormat {
  MONTH_YEAR_SLASH = 'MM/yyyy',
  YEAR = 'yyyy',
  YEAR_MONTH = 'yyyy-MM',
}
