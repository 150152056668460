import useProjectUpdate from '~/shared/hooks/project/useProjectUpdate';
import { Project, ProjectUpdatePayload } from '~/shared/models/api/project';
import { Dispatch, SetStateAction, useState } from 'react';
import { FieldValues } from 'react-hook-form';

export interface UseProjectEditModal {
  isUpdating: boolean;
  onSubmit: (update: FieldValues) => void;
  openModal?: (project: Project) => void;
  payload: ProjectUpdatePayload | undefined;
  setPayload: Dispatch<SetStateAction<ProjectUpdatePayload | undefined>>;
}

interface Props {
  onChanged: () => void;
  onClose: () => void;
}

const useProjectEditModal = ({
  onChanged,
  onClose,
}: Props): UseProjectEditModal => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [payload, setPayload] = useState<ProjectUpdatePayload>();
  const projectUpdate = useProjectUpdate();

  const onSubmit = async (formValues: FieldValues) => {
    setIsUpdating(true);

    await projectUpdate.mutateAsync(
      { ...payload, ...(formValues as ProjectUpdatePayload) },
      {
        onSuccess: () => onChanged(),
        onSettled: () => {
          setIsUpdating(false);
          onClose();
        },
      }
    );
  };

  return { isUpdating, onSubmit, payload, setPayload };
};

export default useProjectEditModal;
