import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_ID as string,
    redirectUri: process.env.REACT_APP_URL,
    authority: process.env.REACT_APP_AUTHORITY,
    postLogoutRedirectUri: process.env.REACT_APP_URL,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const externalUrls = {
  feedbackSurvey: process.env.REACT_APP_FEEDBACK_SURVEY_URL,
};
