import { searchIndustries } from '~/shared/api/request/industries';
import { QueriesTypes } from '~/shared/enums/queries';
import { Industry } from '~/shared/models/api/industry';
import { SearchFilters } from '~/shared/models/search/filters/search';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useIndustriesSearch = (filters: SearchFilters) => {
  return useQuery<Industry[], AxiosError>({
    queryKey: [QueriesTypes.SEARCH_INDUSTRIES, { ...filters }],
    queryFn: searchIndustries,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
