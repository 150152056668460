import { ExternalExperiences } from '~/shared/enums/externalExperiences';
import {
  Employee,
  EmployeeExternalExperience,
} from '~/shared/models/api/employee';
import { formatDate } from '~/shared/utils/date';
import { randomId } from '~/tests/factories';
import { array, object, string } from 'yup';

export type ExternalExperiencesFormType = {
  id?: number;
  company: string;
  title: string;
  description: string;
  technologies: string[];
  start_date: string;
  end_date?: string;
  employee_id?: number;
  type: ExternalExperiences;
};

export const newExternalExperiencesSchema = object().shape({
  company: string().nullable(),
  title: string().required('Title is required.'),
  description: string().required('Description is required.'),
  technologies: array().of(string()).min(1, 'Skills are required.'),
  start_date: string().required('Start date is required.'),
  end_date: string()
    .nullable()
    .when('start_at', (startAt, schema) =>
      schema.test({
        test: (endAt) =>
          endAt ? endAt >= (startAt as unknown as string) : true,
        message: 'The end date should be greater than the start date.',
      })
    ),
  type: string().oneOf(Object.values(ExternalExperiences)),
});

export const defaultNewExternalExperiencesSchema = (
  profile: Employee | null,
  currentProject: EmployeeExternalExperience | null = null
): ExternalExperiencesFormType => {
  if (currentProject) {
    return {
      ...currentProject,
      employee_id: profile?.id,
      technologies: currentProject.skills || [],
    };
  }

  return {
    company: '',
    title: '',
    description: '',
    technologies: [],
    start_date: '',
    employee_id: profile?.id,
    type: ExternalExperiences.FREELANCER,
  };
};

export const mapExternalExperiences = (
  experience: ExternalExperiencesFormType
): EmployeeExternalExperience => ({
  id: experience.id ?? randomId(),
  description: experience.description,
  skills: experience.technologies,
  start_date: formatDate(experience.start_date),
  end_date: formatDate(experience.end_date) || undefined,
  company: experience.company,
  title: experience.title,
  employee_id: experience.employee_id,
  type: experience.type,
});
