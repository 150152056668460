import SearchProfileCardSkill from './SearchProfileCardSkill';
import { Flex, Text } from '@chakra-ui/react';
import { EmployeeSkill } from '~/shared/models/api/employee';
import React, { FC } from 'react';

interface SearchProfileCardSkillsProps {
  matchingSkills?: EmployeeSkill[];
  otherSkills?: EmployeeSkill[];
}

export const SearchProfileCardSkills: FC<SearchProfileCardSkillsProps> = ({
  matchingSkills,
  otherSkills,
}) => {
  return (
    <>
      <Text
        fontSize="xs"
        fontWeight="bold"
        ml="2"
        mb="1"
        data-testid="test-profile-card-skill-title"
      >
        Focus Skills:
      </Text>
      <Flex justifyContent="flex-start" gridGap="1" wrap="wrap" ml="2">
        {!matchingSkills || !otherSkills ? (
          <Text
            fontSize="small"
            data-testid="test-profile-card-skills-no-skills"
          >
            No skills were added to the profile.
          </Text>
        ) : (
          <>
            {[...matchingSkills, ...otherSkills]
              .slice(0, 5)
              .map((skill: EmployeeSkill, index) => (
                <SearchProfileCardSkill
                  skill={skill}
                  isHighlighted={matchingSkills.length > index}
                  key={`skill-${skill.skill_id}`}
                  data-testid="test-search-profile-card-skills-List"
                />
              ))}
          </>
        )}
      </Flex>
    </>
  );
};

export default SearchProfileCardSkills;
