import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmationModalProps {
  titleText?: string;
  descriptionText?: string;
  cancelText?: string;
  confirmText?: string;
  isDangerAction?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: FC<PropsWithChildren<ConfirmationModalProps>> = ({
  titleText,
  descriptionText,
  cancelText,
  confirmText,
  isOpen,
  isDangerAction,
  onClose,
  onConfirm,
  children,
}) => {
  const { t } = useTranslation('base');

  if (!isOpen) return null;

  return (
    <Modal isOpen onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent data-testid="confirmation-modal">
        <ModalHeader>
          {titleText || t('confirmation')}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody maxH="70vh">
          <Text fontWeight="bold">
            {descriptionText || t('unsavedChanges')}
          </Text>
          {children}
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            data-testid="confirmation-modal-cancel-button"
            type="button"
            onClick={onClose}
          >
            {cancelText || t('cancel')}
          </Button>
          <Button
            data-testid="confirmation-modal-discard-button"
            colorScheme={isDangerAction ? 'red' : 'blue'}
            onClick={onConfirm}
            variant="solid"
          >
            {confirmText || t('discard')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
