import { getEmployeeProjectAllocations } from '../api/request/employee';
import { QueriesTypes } from '../enums/queries';
import { EmployeeProject } from '../models/api/project';
import { EmployeeIdOrMe } from '../types/employeeIdOrMe';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useEmployeeProjectAllocations = (
  employeeId: EmployeeIdOrMe,
  query = ''
) => {
  return useQuery<EmployeeProject[], AxiosError>({
    queryKey: [
      QueriesTypes.GET_EMPLOYEE_PROJECT_ALLOCATIONS,
      employeeId,
      query,
    ],
    queryFn: () => getEmployeeProjectAllocations(employeeId, query),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
