import { useToast } from '@chakra-ui/react';
import { deleteEmployeeImage as deleteImage } from '~/shared/api/request/employee';
import { getErrorMessage } from '~/shared/errors/error';
import { ApiErrorResponse } from '~/shared/models/api/axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

interface UseEmployeeProfilePictureType {
  deleteProfilePicture: UseMutationResult<unknown, unknown, number, unknown>;
}

const useEmployeeProfilePicture = (): UseEmployeeProfilePictureType => {
  const { t } = useTranslation(['base', 'profile']);

  const toast = useToast();

  const deleteProfilePicture = useMutation(
    (userId: number) => deleteImage(userId),
    {
      onSuccess: () => {
        toast({
          title: t('profile:delete_picture.success'),
          description: t('profile:delete_picture.success'),
          status: 'success',
        });
      },
      onError: (err: ApiErrorResponse) => {
        toast({
          title: t('profile:delete_picture.error'),
          description: getErrorMessage(
            err.response?.status,
            t('profile:delete_picture.error_description'),
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return {
    deleteProfilePicture,
  };
};

export default useEmployeeProfilePicture;
