import { ChildStatus } from '~/shared/enums/childStatusColor';
import { Child, ChildRecord } from '~/shared/interfaces/map';
import { MIN_CHARACTERS_TO_SEARCH } from '~/shared/utils/constants';
import {
  getSanitizePotentialChildren,
  hasChildToBeSaved,
  sortChildren,
} from '~/shared/utils/map';
import Fuse from 'fuse.js';
import { create } from 'zustand';

interface MapState {
  addChild: (child: Child) => void;
  allowReorder: boolean;
  children: ChildRecord[];
  deleteChild: (index: number) => void;
  getChildrenIds: () => number[];
  getPotentialChildren: () => Child[];
  isSearching: boolean;
  loadChildren: (children: Child[]) => void;
  loadPotentialChildren: (children: Child[]) => void;
  moveChildDown: (index: number) => void;
  moveChildUp: (index: number) => void;
  potentialChildren: Child[];
  isPotentialChildrenLoaded: boolean;
  query: string;
  resetMapState: () => void;
  setQuery: (query: string) => void;
  setAllowReorder: (allowReorder: boolean) => void;
  setShallRefetchOnSearch: (shallSearch: boolean) => void;
  setShallSearch: (shallSearch: boolean) => void;
  shallSearch: boolean;
  shallRefetchOnSearch: boolean;
  undoChildDeletion: (index: number) => void;
  wasChanged: boolean;
}

const initialState = {
  allowReorder: false,
  children: [],
  isLoadingChildren: false,
  isLoadingPotentialChildren: false,
  isPotentialChildrenLoaded: false,
  isSearching: false,
  potentialChildren: [],
  query: '',
  shallSearch: false,
  shallRefetchOnSearch: true,
  wasChanged: false,
};

export const useMapStore = create<MapState>((set, get) => ({
  ...initialState,

  addChild: (child: Child) => {
    set((state) => ({
      children: [
        { ...child, isNew: true, status: ChildStatus.ADDED },
        ...state.children,
      ],
      potentialChildren: state.potentialChildren.filter(
        ({ id }) => id !== child.id
      ),
      wasChanged: true,
    }));
  },

  getChildrenIds: () =>
    get()
      .children.filter(({ status }) => status !== ChildStatus.REMOVED)
      .map(({ id }) => id),

  getPotentialChildren: () => {
    const { potentialChildren, query, shallRefetchOnSearch } = get();

    if (shallRefetchOnSearch || !query) {
      return potentialChildren;
    }

    const fuse = new Fuse<Child>(potentialChildren, {
      keys: ['id', 'text'],
    });

    return fuse.search(query).map(({ item }) => item);
  },

  deleteChild: (index: number) => {
    set((state) => {
      const child = state.children[index];
      const newChildren = state.children.filter(({ id }) => id !== child.id);
      const newPotentialChildren = state.potentialChildren.filter(
        ({ id }) => id !== child.id
      );

      if (child.isNew) {
        newPotentialChildren.unshift(child);
      } else {
        newChildren.unshift({ ...child, status: ChildStatus.REMOVED });
      }

      return {
        ...state,
        children: newChildren,
        potentialChildren: newPotentialChildren,
        wasChanged: hasChildToBeSaved(newChildren),
      };
    });
  },

  undoChildDeletion: (index: number) => {
    set((state) => {
      const child = state.children[index];
      const newChildren = state.children.filter(({ id }) => id !== child.id);

      if (!child.isNew) {
        newChildren.unshift({ ...child, status: ChildStatus.SAVED });
      }

      return {
        ...state,
        children: sortChildren(newChildren),
        potentialChildren: [
          { id: child.id, text: child.text },
          ...state.potentialChildren,
        ],
        wasChanged: hasChildToBeSaved(newChildren),
      };
    });
  },

  loadChildren: (children: Child[] = []) => {
    const childrenRecord = children.map((child) => ({
      ...child,
      status: ChildStatus.SAVED,
      isNew: false,
    }));

    set((state) => ({
      ...state,
      ...initialState,
      children: childrenRecord,
      potentialChildren: getSanitizePotentialChildren(
        childrenRecord,
        state.potentialChildren
      ),
    }));
  },

  loadPotentialChildren: (potentialChildren: Child[] = []) => {
    set((state) => ({
      ...state,
      potentialChildren: getSanitizePotentialChildren(
        state.children,
        potentialChildren
      ),
      isPotentialChildrenLoaded: true,
    }));
  },

  moveChildDown: (index: number) => {
    set((state) => {
      if (index === state.children.length - 1) {
        return state;
      }

      const reorderedChildren = [...state.children];

      [reorderedChildren[index + 1], reorderedChildren[index]] = [
        reorderedChildren[index],
        reorderedChildren[index + 1],
      ];

      return { ...state, children: reorderedChildren };
    });
  },

  moveChildUp: (index: number) => {
    if (index === 0) {
      return;
    }
    set((state) => {
      const reorderedChildren = [...state.children];

      [reorderedChildren[index - 1], reorderedChildren[index]] = [
        reorderedChildren[index],
        reorderedChildren[index - 1],
      ];

      return { ...state, children: reorderedChildren };
    });
  },

  setAllowReorder: (allowReorder: boolean) => {
    set((state) => ({
      ...state,
      allowReorder,
    }));
  },

  setQuery: (query = '') => {
    set((state) => ({
      ...state,
      query,
      isSearching: query.length > 0,
      isPotentialChildrenLoaded: false,
      shallSearch: query.length >= MIN_CHARACTERS_TO_SEARCH,
    }));
  },

  setShallSearch: (shallSearch: boolean) => {
    set((state) => ({
      ...state,
      shallSearch,
    }));
  },

  setShallRefetchOnSearch: (shallRefetchOnSearch: boolean) => {
    set((state) => ({
      ...state,
      shallRefetchOnSearch,
    }));
  },

  resetMapState: () => {
    set((state) => ({ ...state, ...initialState }));
  },
}));
