import SkillsListItem from './SkillsListItem';
import { Box } from '@chakra-ui/react';
import { CustomAlert } from '~/shared/components/ui/CustomAlert';
import { Skill, SkillType } from '~/shared/models/api/skills';
import React, { FC, useEffect, useState } from 'react';
import {
  Draggable,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

type SkillsListByTypeProps = {
  type: SkillType;
  skills: Skill[];
  skillPendingUpdate?: Skill;
  isSkillHighlighted: (skill: Skill) => boolean;
  onSkillClick?: (skill: Skill) => void;
};

const SkillsListByType: FC<SkillsListByTypeProps> = ({
  type,
  skills,
  skillPendingUpdate,
  isSkillHighlighted,
  onSkillClick,
}) => {
  const [skillsByType, setSkillsByType] = useState<Skill[] | null>(null);
  const [pendingSkill, setPendingSkill] = useState<Skill | null>(null);

  const { t } = useTranslation('skills');

  // To prevent rearrange when dragging
  const getStyle = (
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot
  ) => {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
      return style;
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: '0.001s',
    };
  };

  useEffect(() => {
    const filtered = skills.filter((skill: Skill) => skill.type === type.name);
    setSkillsByType(filtered);
  }, [skills]);

  useEffect(() => {
    if (!skillPendingUpdate || skillPendingUpdate?.type !== type.name) {
      setPendingSkill(null);
      return;
    }
    setPendingSkill(skillPendingUpdate);
  }, [skillPendingUpdate]);

  const isDropped = (skill: Skill): boolean => {
    if (!skillPendingUpdate) {
      return false;
    }
    return skill.id === skillPendingUpdate.id;
  };

  if (!skillsByType) {
    return null;
  }

  if (!pendingSkill && skillsByType.length === 0) {
    return (
      <Box p={4} w="full">
        <CustomAlert
          alertDescriptionProps={{ maxWidth: 'sm' }}
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          rounded={8}
          status="warning"
          text={t(`no_results`)}
          textAlign="center"
          variant="subtle"
        />
      </Box>
    );
  }

  return (
    <>
      {/*
        Keeps the dragged item on the original list
        with dropped styles while updating skill type
      */}
      {pendingSkill && (
        <SkillsListItem
          skill={{
            ...pendingSkill,
            name: `${pendingSkill.name}`,
          }}
          isHighlighted
        />
      )}
      {skillsByType?.map((skill: Skill, index: number) => (
        <Draggable
          draggableId={`${skill.id}`}
          index={index}
          key={`skill-${type.id}-${skill.id}`}
        >
          {({ innerRef, draggableProps, dragHandleProps }, snapshot) => {
            return (
              <>
                <Box
                  ref={(ref) => innerRef(ref)}
                  {...draggableProps}
                  {...dragHandleProps}
                  style={getStyle(draggableProps.style, snapshot)}
                >
                  <SkillsListItem
                    skill={skill}
                    isHighlighted={isSkillHighlighted(skill)}
                    isDropped={isDropped(skill)}
                    isDragging={snapshot.isDragging}
                    onClick={() => onSkillClick && onSkillClick(skill)}
                  />
                </Box>
                {/*
                  Keeps the dragged item on the original list with dropped styles for preview
                */}
                {snapshot.isDragging ? (
                  <SkillsListItem skill={skill} isDropped />
                ) : null}
              </>
            );
          }}
        </Draggable>
      ))}
    </>
  );
};

export default SkillsListByType;
