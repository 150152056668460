import { Icon, Tab } from '@chakra-ui/react';
import { GridViewModes } from '~/shared/enums/gridViewModes';
import React, { FC } from 'react';
import { FaBars, FaGripHorizontal } from 'react-icons/fa';

type SkillsFiltersViewModesItemProps = {
  mode: GridViewModes;
  onClick: () => void;
};

const SkillsFiltersViewModesItem: FC<SkillsFiltersViewModesItemProps> = ({
  mode,
  onClick,
}) => {
  const renderIcon = () => {
    switch (mode) {
      case GridViewModes.GRID:
        return <Icon as={FaGripHorizontal} />;
      case GridViewModes.HORIZONTAL:
        return <Icon as={FaBars} />;
      default:
        return <Icon as={FaBars} transform="rotate(90deg)" />;
    }
  };

  return (
    <Tab onClick={onClick} borderRadius="sm">
      {renderIcon()}
    </Tab>
  );
};

export default SkillsFiltersViewModesItem;
