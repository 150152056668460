import { Box, Heading } from '@chakra-ui/react';
import ConfirmationModal from '~/shared/components/ui/ConfirmationModal';
import ChildrenList, {
  ChildrenListProps,
} from '~/shared/components/ui/map/ChildrenList';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface ProjectMapConfirmationModalProps extends ChildrenListProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  projectName?: string;
}

const ProjectMapConfirmationModal: FC<ProjectMapConfirmationModalProps> = ({
  data,
  isLoading,
  isOpen,
  onClose,
  onConfirm,
  projectName,
}) => {
  const { t } = useTranslation('projects');

  return (
    <ConfirmationModal
      confirmText={t('map_modal.confirmation.confirmation')}
      descriptionText={t(`map_modal.confirmation.description`)}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      titleText={t(`map_modal.confirmation.title`)}
    >
      <Heading fontSize="sm" fontWeight="bold" mt="2" color="gray.500">
        {t(`map_modal.confirmation.parent_project`)}
      </Heading>
      <Heading fontSize="sm" fontWeight="bold" mt="2" color="gray.800" mb={2}>
        {projectName}
      </Heading>

      <Heading fontSize="sm" fontWeight="bold" mt="2">
        {t(`map_modal.children_title`)}
      </Heading>
      <Box
        bg="whiteSmoke"
        maxH="50vh"
        minH="50vh"
        overflow="auto"
        p={2}
        rounded={8}
        w="100%"
      >
        <ChildrenList data={data} isLoading={isLoading} />
      </Box>
    </ConfirmationModal>
  );
};

export default ProjectMapConfirmationModal;
