import {
  Badge,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import IndustriesForm from '~/profile/components/industries/IndustriesForm';
import IndustriesList from '~/profile/components/industries/IndustriesList';
import useProfileStore from '~/profile/stores/profile';
import ModalButton from '~/shared/components/ui/ModalButton';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { mapFieldArrayWithIdToTypeIdNumber } from '~/shared/mappers/form';
import { EmployeeIndustry } from '~/shared/models/api/employee';
import { checkUnsavedChanges } from '~/shared/utils/verifications';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

interface EditModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const EditModal: React.FC<EditModalProps> = ({ isOpen, onClose }) => {
  const toast = useToast();
  const { openConfirmation } = useConfirmationModalContext();
  const { startLoading, stopLoading } = useLoadingContext();
  const { profile, updateIndustries } = useProfileStore();
  const { handleSubmit, control, watch, setValue, reset } = useForm({
    defaultValues: {
      industries: profile?.industries ? [...profile.industries] : [],
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'industries',
    keyName: 'id',
  });

  const fieldsAux = fields.map((field) => ({
    industry_id: field.industry_id,
  }));
  const industriesAux = profile?.industries.map((field) => ({
    industry_id: field.industry_id,
  }));
  const unsavedChanges = checkUnsavedChanges(fieldsAux, industriesAux);

  useEffect(() => {
    reset({ industries: [...(profile?.industries || [])] });
  }, []);

  const onSubmit = async () => {
    if (unsavedChanges) {
      startLoading();
      const updateSuccess = await updateIndustries(fields);
      stopLoading();
      toast({
        title: 'Profile industries Update',
        description: updateSuccess
          ? 'Profile industries updated with success'
          : 'Something went wrong',
        status: updateSuccess ? 'success' : 'error',
      });
    }

    onClose();
  };

  const handleIndustrySubmit = (industry: EmployeeIndustry) => {
    const foundIndex = fields.findIndex(
      (fs: EmployeeIndustry) => industry.industry_id === fs.industry_id
    );

    if (foundIndex > -1) {
      const fieldsClone =
        mapFieldArrayWithIdToTypeIdNumber<EmployeeIndustry>(fields);
      fieldsClone[foundIndex] = industry;
      setValue('industries', fieldsClone);

      return;
    }

    append({ ...industry });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const onCloseModal = () => {
    if (unsavedChanges) {
      handleUnsavedChanges();
      return;
    }
    onClose();
  };

  const handleUnsavedChanges = async () => {
    const result = await openConfirmation();
    result && onClose();
  };

  watch('industries', fields);

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <Stack direction="column">
          <ModalHeader data-testid="modal-industries-header" mt="2" mb="0">
            <Badge colorScheme="blue">Industry Expertise</Badge>
            <Heading fontSize="2xl" fontWeight="bold" mt="2">
              Edit Details
            </Heading>
            <ModalCloseButton />
          </ModalHeader>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <IndustriesForm
              selectedIndustries={fields}
              onSubmit={handleIndustrySubmit}
              // onFormEdit={setFormUpdated}
            />
            <IndustriesList industries={fields} onRemove={handleRemove} />
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <ModalButton
              data-testid="modal-industries-close-button"
              type="button"
              onClick={onCloseModal}
            >
              Close
            </ModalButton>
            <ModalButton colorScheme="blue" type="submit" variant="solid">
              Save
            </ModalButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default EditModal;
