import { useDisclosure } from '@chakra-ui/react';
import useSkillUpdate from '~/shared/hooks/skills/useSkillUpdate';
import { MergeSkill, Skill, UpdateSkill } from '~/shared/models/api/skills';
import { mapSkillToUpdate } from '~/shared/utils/mappers/skills';
import { useState } from 'react';

export interface UseSkillEditModalType {
  skillUpdate?: UpdateSkill;
  updatedSkill?: Skill;
  mergedSkill?: MergeSkill;
  deletedSkill?: Partial<Skill>;
  isUpdating: boolean;
  isMerging: boolean;
  isDeleting: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSkillClick?: (skill: Skill) => void;
  update: (formValues: Partial<UpdateSkill>) => void;
  merge: (skillToMerge: Skill) => void;
  deleteSelected: () => void;
}

const useSkillEditModal = (): UseSkillEditModalType => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [skillUpdate, setSkillUpdate] = useState<UpdateSkill>();
  const [updatedSkill, setUpdatedSkill] = useState<Skill>();
  const [mergedSkill, setMergedSkill] = useState<MergeSkill>();
  const [deletedSkill, setDeletedSkill] = useState<Partial<Skill>>();
  const {
    update: { mutateAsync: updateSkill, isLoading: isUpdating },
    mergeSkills: { mutateAsync: mergeSkills, isLoading: isMerging },
    deleteSkill: { mutateAsync: deleteSkill, isLoading: isDeleting },
  } = useSkillUpdate();

  const onSkillClick = (skill: Skill) => {
    const update = mapSkillToUpdate(skill);
    setSkillUpdate(update);
    onOpen();
  };

  const update = (formValues: Partial<UpdateSkill>) => {
    if (!skillUpdate) {
      return;
    }
    updateSkill(
      {
        ...skillUpdate,
        ...formValues,
      },
      {
        onSuccess: (updated: Skill) => setUpdatedSkill({ ...updated }),
      }
    );
  };

  const merge = (skillToMerge: Skill) => {
    if (!skillUpdate) {
      return;
    }

    mergeSkills(
      {
        ...skillUpdate,
        merge_with_id: skillToMerge.id,
      },
      {
        onSuccess: (_skill: Skill, merge: MergeSkill) => {
          setMergedSkill({ ...merge });
          onClose();
        },
      }
    );
  };

  const deleteSelected = () => {
    if (!skillUpdate) {
      return;
    }
    deleteSkill(
      {
        ...skillUpdate,
      },
      {
        onSuccess: () => {
          setDeletedSkill({ ...skillUpdate });
          onClose();
        },
      }
    );
  };

  return {
    isOpen,
    isUpdating,
    isMerging,
    isDeleting,
    skillUpdate,
    updatedSkill,
    mergedSkill,
    deletedSkill,
    onOpen,
    onClose,
    onSkillClick,
    update,
    merge,
    deleteSelected,
  };
};

export default useSkillEditModal;
