import { TagProps } from '@chakra-ui/react';
import { CustomTag } from '~/shared/components/ui/CustomTag';
import { DateFormat } from '~/shared/enums/date';
import { buildStartEndDateText } from '~/shared/utils/textBuilders';
import React from 'react';

export interface DateIntervalTagProps {
  dateIntervalTagTestId?: string;
  startDate?: string;
  endDate?: string | null;
  endDateReplacementI18nKey?: string;
  dateFormat?: DateFormat;
}

export const DateIntervalTag: React.FC<DateIntervalTagProps & TagProps> = ({
  dateIntervalTagTestId,
  startDate,
  endDate,
  endDateReplacementI18nKey,
  dateFormat,
  ...props
}) => (
  <>
    {startDate && (
      <CustomTag
        colorScheme="blue"
        customTagTestId={dateIntervalTagTestId}
        {...props}
      >
        {buildStartEndDateText(
          startDate,
          endDate,
          endDateReplacementI18nKey,
          dateFormat
        )}
      </CustomTag>
    )}
  </>
);
