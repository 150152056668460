import { searchCertificatesDebounce } from '~/shared/api/request/certificates';
import { QueriesTypes } from '~/shared/enums/queries';
import { Certificate } from '~/shared/models/api/certificate';
import { CertificatesSearchFilters } from '~/shared/models/search/filters/certificates';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useCertificatesSearch = (
  filters?: Partial<CertificatesSearchFilters>
) => {
  return useQuery<PaginatedResponse<Certificate>, AxiosError>({
    queryKey: [QueriesTypes.SEARCH_CERTIFICATES, { ...filters }],
    queryFn: searchCertificatesDebounce,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!filters,
  });
};
