import 'react-quill/dist/quill.snow.css';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import React from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import ReactQuill from 'react-quill';

const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'ordered-list',
  'link',
];

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'bullet' }, { list: 'ordered' }],
  ['link'],
];

interface FormControlRichTextProps<T extends FieldValues> {
  id: Path<T>;
  name: string;
  value: string;
  setValue: UseFormSetValue<T>;
  register: UseFormRegister<T>;
  placeholder: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl>;
  disabled?: boolean;
}

const FormControlRichText = <T extends FieldValues>({
  id,
  name,
  value,
  setValue,
  register,
  placeholder,
  error,
  disabled,
}: FormControlRichTextProps<T>) => {
  const { ref } = register(id);

  const changeHandler = (e: string) => {
    setValue(id, e as PathValue<T, Path<T>>, { shouldDirty: true });
  };

  return (
    <FormControl
      id={id}
      isInvalid={Boolean(error)}
      mb="2"
      isDisabled={disabled}
    >
      <FormLabel style={disabled ? { opacity: 0.4 } : {}}>{name}</FormLabel>
      <ReactQuill
        ref={ref}
        id={id}
        formats={formats}
        modules={{ toolbar: toolbarOptions }}
        theme="snow"
        value={value}
        onChange={changeHandler}
        placeholder={placeholder}
      />
      <FormErrorMessage data-testid="test-form-error-message">
        {!!error && (error.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormControlRichText;
