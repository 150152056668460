import { ProfileSectionHeader } from '../ProfileSectionHeader';
import EditModal from './EditModal';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import useProfileStore from '~/profile/stores/profile';
import MissingData from '~/shared/components/ui/MissingData';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ShortDescription = () => {
  const [shortDescription, setShortDescription] = useState<string>();

  const { profile, updateEmployeeProfile } = useProfileStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('profile');
  const { canEditProfileAdmOnly } = useAccessPermissions();

  const updateShortDescription = (shortDescription: string) => {
    updateEmployeeProfile({ short_description: shortDescription });
    setShortDescription(shortDescription);
  };

  useEffect(() => {
    setShortDescription(profile?.short_description);
  }, []);

  if (!canEditProfileAdmOnly || !profile?.id) {
    return null;
  }

  return (
    <Box>
      <EditModal
        employeeId={profile?.id}
        employeeName={`${profile?.first_name} ${profile?.second_name}`}
        isOpen={isOpen}
        onClose={onClose}
        setShortDescription={updateShortDescription}
        shortDescription={profile?.short_description}
      />
      <ProfileSectionHeader
        sectionTitle={t(`short_description.title`)}
        onClickEdit={() => onOpen()}
      />
      {!profile?.short_description ? (
        <MissingData text={t(`short_description.not_added`)} />
      ) : (
        <Text
          backgroundColor="gray.50"
          fontSize="md"
          ml={4}
          rounded={10}
          px={2}
          py={1}
          whiteSpace="pre-line"
        >
          {shortDescription}
        </Text>
      )}
    </Box>
  );
};

export default ShortDescription;
