import { OTHER_LINK, SOCIAL_LINKS } from '~/shared/constants/social-links';
import {
  mapDataListToOptions,
  mapDataToOption,
} from '~/shared/mappers/selectOption';
import { SocialNetwork } from '~/shared/models/api/social';
import {
  SelectOption,
  SelectOptionMapperKeysType,
} from '~/shared/models/react-select/select-option';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

interface SocialNetworkSelectProps {
  id?: string;
  defaultValue?: SocialNetwork;
  placeholder?: string;
  isClearable?: boolean;
  onChange?: (network?: SocialNetwork) => void;
}

const SocialNetworkSelect: FC<SocialNetworkSelectProps> = ({
  id,
  defaultValue,
  placeholder,
  isClearable = true,
  onChange,
}) => {
  const [selected, setSelected] = useState<SelectOption<SocialNetwork> | null>(
    null
  );
  const { t } = useTranslation('base');
  const keys: SelectOptionMapperKeysType<SocialNetwork> = {
    valueKey: 'slug',
    labelKey: 'name',
  };

  const options: SelectOption<SocialNetwork>[] = useMemo(
    () =>
      mapDataListToOptions({
        options: SOCIAL_LINKS,
        ...keys,
      }),
    []
  );

  useEffect(() => {
    if (!defaultValue) {
      setSelected(null);
      return;
    }
    setSelected(
      mapDataToOption({
        option: defaultValue,
        ...keys,
      })
    );
  }, [defaultValue]);

  return (
    <Select
      id={id}
      placeholder={placeholder}
      isClearable={isClearable}
      isSearchable={false}
      value={selected}
      options={options}
      getOptionLabel={(option) => {
        if (option.value === OTHER_LINK.slug) {
          return t(`${option.value}`);
        }
        return option.label;
      }}
      onChange={(value) => {
        setSelected(value);
        onChange && onChange(value?.origin);
      }}
    />
  );
};

export default SocialNetworkSelect;
