import { getSkillTypes } from '~/shared/api/request/skills';
import { QueriesTypes } from '~/shared/enums/queries';
import { SkillType } from '~/shared/models/api/skills';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useSkillsTypes = (onlyValid = false) => {
  return useQuery<SkillType[], AxiosError>({
    queryKey: [QueriesTypes.GET_SKILLS_TYPES],
    queryFn: getSkillTypes,
    select: (data) => {
      if (!onlyValid) {
        return data;
      }
      // API might return an empty type, in case we do not wont this
      // use onlyValid true
      return data?.filter((type) => type.name);
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
