import SearchAutocompleteEntry from './search-autocomplete/SearchAutocompleteEntry';
import EmployeeLink from '~/shared/components/EmployeeLink';
import { Employee } from '~/shared/models/api/employee';
import React, { FC } from 'react';

const EmployeeEntry: FC<{ employee: Employee }> = ({
  employee: { id, first_name, second_name, image_url },
}) => (
  <EmployeeLink employeeId={id}>
    <SearchAutocompleteEntry
      name={`${first_name} ${second_name}`}
      base64Image={image_url}
    />
  </EmployeeLink>
);

export default EmployeeEntry;
