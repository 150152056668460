import { useEffect, useState } from 'react';
import {
  FieldValues,
  useForm,
  UseFormReturn,
  UseFormSetValue,
} from 'react-hook-form';

interface UseAdvancedFiltersFormType<T extends FieldValues> {
  values: Partial<T>;
  form: UseFormReturn<T>;
  setFieldValue: UseFormSetValue<T>;
}

const useAdvancedFiltersForm = <
  T extends FieldValues
>(): UseAdvancedFiltersFormType<T> => {
  const form = useForm<T>();
  const { watch, setValue: setFieldValue } = form;
  const [values, setValues] = useState<Partial<T>>({});

  useEffect(() => {
    const subscription = watch((value) => setValues(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  return {
    values,
    form,
    setFieldValue,
  };
};

export default useAdvancedFiltersForm;
