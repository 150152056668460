import CookieConsentAlert from './CookieConsentAlert';
import Nav from './nav/Nav';
import { Container, Stack } from '@chakra-ui/react';
import React from 'react';
import { ContainerProps } from 'react-select';

interface AppLayoutProps extends Partial<ContainerProps> {
  showNav?: boolean;
}

const AppLayout = ({ showNav = true, children, ...props }: AppLayoutProps) => {
  return (
    <Stack direction="column" align="center" spacing="6">
      {showNav && <Nav />}
      <Container maxW="full" {...props}>
        {children}
        <CookieConsentAlert />
      </Container>
    </Stack>
  );
};

export default AppLayout;
