import { ProjectStatusTypes } from '~/shared/enums/projectStatus';
import { SelectOption } from '~/shared/models/react-select/select-option';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

interface ProjectStatusSelectProps {
  id?: string;
  status?: ProjectStatusTypes;
  isUpdating?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  onChange: (value: ProjectStatusTypes) => void;
}

const ProjectStatusSelect: FC<ProjectStatusSelectProps> = ({
  id = 'project-status-select',
  status,
  isUpdating,
  placeholder,
  isClearable = true,
  isDisabled,
  onChange,
}) => {
  const { t } = useTranslation('base');
  const [selected, setSelected] =
    useState<SelectOption<ProjectStatusTypes> | null>(null);

  useEffect(() => {
    if (!status) {
      setSelected(null);
      return;
    }
    setSelected({
      label: status,
      value: status,
    });
  }, [status]);

  const options = Object.values(ProjectStatusTypes).map((key: string) => ({
    label: key,
    value: key,
  }));

  // TODO: Show status colors
  return (
    <Select
      id={id}
      options={options}
      value={selected}
      placeholder={placeholder || t('select')}
      isDisabled={isDisabled || isUpdating}
      isLoading={isUpdating}
      isClearable={isClearable}
      onChange={(option) => {
        setSelected(option);
        onChange(option?.value as ProjectStatusTypes);
      }}
    />
  );
};

export default ProjectStatusSelect;
