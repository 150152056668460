import { NavSearchResponse } from '../models/nav-search-response';
import { useNavSearchQuery } from '../queries/useNavSearchQuery';

const useNavSearch = (query?: string): NavSearchResponse => {
  const [
    { data: employees, isLoading: employeesLoading },
    { data: skills, isLoading: skillsLoading },
  ] = useNavSearchQuery(query);

  if (query === '') {
    return { loading: false };
  }

  return {
    data: {
      employees: employees?.page_results,
      skills: skills,
    },
    loading: employeesLoading || skillsLoading,
  };
};

export default useNavSearch;
