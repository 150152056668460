import { Alert, AlertIcon, Box, Text } from '@chakra-ui/react';
import React from 'react';

interface MissingDataProps {
  text: string;
}

const MissingData: React.FC<MissingDataProps> = ({ text }) => {
  return (
    <Box>
      <Alert variant="subtle" status="info" bg="orange.50" rounded="16">
        <AlertIcon color="orange.200" />
        <Text fontWeight="light" color="orange.400">
          {text}
        </Text>
      </Alert>
    </Box>
  );
};

export default MissingData;
