import FullScreenContent from './FullScreenContent';
import LoadingData from './LoadingData';
import { Center } from '@chakra-ui/react';
import React, { FC } from 'react';

const FullScreenLoading: FC = () => {
  return (
    <FullScreenContent>
      <Center h="100%">
        <LoadingData />
      </Center>
    </FullScreenContent>
  );
};

export default FullScreenLoading;
