import { Checkbox, Th, Thead, Tr } from '@chakra-ui/react';
import { SortableHeader } from '~/shared/components/ui/table/SortableHeader';
import { ColumnData, TableRowData } from '~/shared/components/ui/table/types';
import { OrderDirection } from '~/shared/models/search/filters/search';
import React from 'react';

interface CompleteTableHeaderProps<T extends TableRowData> {
  headers: ColumnData<T>[];
  sortableColumns: string[] | undefined;
  onSort: ((column: string, order: OrderDirection) => void) | undefined;
  sorting: { column: string; order: OrderDirection } | undefined;
  singleRowActions: boolean | undefined;
  hasCheckboxes?: boolean;
}

export const CompleteTableHeader = <T extends TableRowData>({
  headers,
  sortableColumns,
  onSort,
  sorting,
  singleRowActions,
  hasCheckboxes,
}: CompleteTableHeaderProps<T>) => (
  <Thead>
    <Tr>
      {hasCheckboxes && (
        <Th w="0">
          <Checkbox value="all"></Checkbox>
        </Th>
      )}
      {headers.map((header) => (
        <SortableHeader
          key={`table-header-${String(header.id)}`}
          isSortable={sortableColumns?.includes(String(header.id)) ?? false}
          onChangeOrder={(order) => onSort?.(String(header.id), order)}
          sortedDirection={
            sorting?.column === String(header.id) ? sorting.order : undefined
          }
        >
          {header.label}
        </SortableHeader>
      ))}
      {singleRowActions && <Th w="0"></Th>}
    </Tr>
  </Thead>
);
