import {
  EnumLevelType,
  SelectOption,
  SelectOptionMapperType,
  SelectOptionsMapperType,
} from '~/shared/models/react-select/select-option';

export const mapDataToOption = <T>({
  option,
  valueKey,
  labelKey,
}: SelectOptionMapperType<T>): SelectOption<T> => {
  return {
    value: option[valueKey] as string,
    label: option[labelKey] as string,
    origin: option,
  };
};

export const mapDataListToOptions = <T>({
  options,
  ...other
}: SelectOptionsMapperType<T>): SelectOption<T>[] => {
  return (
    options?.map((option) =>
      mapDataToOption({
        option,
        ...other,
      })
    ) || []
  );
};

export const mapObjectAsOption = <
  T extends string | number,
  TEnumValue extends number | string
>(
  object: { [key in T]: TEnumValue },
  key: string
): SelectOption<T> => ({
  value: key,
  label: object[key as keyof T] as string,
  origin: key as T,
});

export const mapObjectListAsOptions = <
  T extends string | number,
  TEnumValue extends number | string
>(objectList: { [key in T]: TEnumValue }): SelectOption<T>[] =>
  Object.keys(objectList).map((value) => mapObjectAsOption(objectList, value));

export const mapValueToOption = <T>(
  value: string | number
): SelectOption<T> => {
  return {
    value: value,
    label: value as string,
  };
};

export const mapEnumToLevelOption = <T>(
  values: T,
  value: number
): SelectOption<T> => {
  const label = Object.keys(values as EnumLevelType)[
    Object.values(values as EnumLevelType).indexOf(value)
  ];
  return {
    value: value,
    label,
    translationKey: label ? label.toLowerCase() : '',
    origin: value as T,
  };
};

export const mapEnumToLevelOptions = <T>(values: T): SelectOption<T>[] => {
  return Object.keys(values as EnumLevelType)
    .filter((e) => isNaN(parseInt(e)))
    .map((v) => mapEnumToLevelOption(values, (values as EnumLevelType)[v]));
};
