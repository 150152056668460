import { useToast } from '@chakra-ui/react';
import {
  createExternalExperience as createExperience,
  deleteExternalExperience as deleteExperience,
  updateExternalExperience as updateExperience,
} from '~/shared/api/request/externalExperiences';
import { getErrorMessage } from '~/shared/errors/error';
import { ApiErrorResponse } from '~/shared/models/api/axios';
import { EmployeeExternalExperience } from '~/shared/models/api/employee';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

interface UseExternalExperienceType {
  createExternalExperience: UseMutationResult<
    EmployeeExternalExperience,
    unknown,
    EmployeeExternalExperience,
    unknown
  >;

  deleteExternalExperience: UseMutationResult<
    EmployeeExternalExperience,
    unknown,
    EmployeeExternalExperience,
    unknown
  >;

  updateExternalExperience: UseMutationResult<
    EmployeeExternalExperience,
    unknown,
    EmployeeExternalExperience,
    unknown
  >;
}

const useExternalExperience = (): UseExternalExperienceType => {
  // const { profile, deleteCertification } = useProfileStore();
  // TODO: chamar outras experiências aqui
  const { t } = useTranslation(['base', 'profile']);

  const toast = useToast();

  const createExternalExperience = useMutation(
    (newExperience: EmployeeExternalExperience) =>
      createExperience(newExperience),
    {
      onSuccess: (newExperience: EmployeeExternalExperience) => {
        toast({
          title: newExperience.title,
          description: 'Experience Created Successfully',
          status: 'success',
        });
      },
      onError: (err: ApiErrorResponse, newExperience) => {
        const errorMsg =
          err.response?.data.detail ||
          'Something Went Wrong Creating Certificate';
        toast({
          title: `Error Creating Certificate ${newExperience.title}`,
          description: getErrorMessage(err.response?.status, errorMsg, t),
          status: 'error',
        });
      },
    }
  );

  const deleteExternalExperience = useMutation(
    (experienceToDelete: EmployeeExternalExperience) =>
      deleteExperience(experienceToDelete),
    {
      onSuccess: (experienceToDelete: EmployeeExternalExperience) => {
        toast({
          title: experienceToDelete.title,
          description: 'Experience Deleted Successfully',
          status: 'success',
        });
      },
      onError: (
        err: ApiErrorResponse,
        experienceToDelete: EmployeeExternalExperience
      ) => {
        toast({
          title: `Fail deleting "${experienceToDelete.title}"`,
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Deleting Experience',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  const updateExternalExperience = useMutation(
    (experience: EmployeeExternalExperience) =>
      updateExperience({ ...experience }),
    {
      onSuccess: (employeeNonKIProject: EmployeeExternalExperience) => {
        toast({
          title: employeeNonKIProject.title,
          description: 'Experience Updated Successfully',
          status: 'success',
        });
      },
      onError: (err: ApiErrorResponse, experience) => {
        toast({
          title: experience.title,
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Updating Experience',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return {
    createExternalExperience,
    deleteExternalExperience,
    updateExternalExperience,
  };
};

export default useExternalExperience;
