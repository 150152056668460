import { Client } from '~/shared/models/api/Client';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { SearchProps } from '~/shared/models/search/search';
import debounce from 'debounce-promise';

//TODO: PENDING BE CHANGES
// const ENDPOINT = 'clients/';

const mockClientData: Client[] = [
  {
    id: 1,
    name: 'ClientName',
    mappedName: 'Parent Company',
  },
  {
    id: 2,
    name: 'Some Client Without Parent',
    mappedName: null,
  },
  {
    id: 3,
    name: 'Unmapped Client',
    mappedName: null,
  },
  {
    id: 4,
    name: 'Hello World',
    mappedName: null,
  },
];

const searchClients = async (
  _value: SearchProps
): Promise<PaginatedResponse<Client>> => {
  //TODO: PENDING BE CHANGES
  // const searchFilters = getSearchFiltersByParamsType(value);
  // const { data } = await search<Client>(ENDPOINT, searchFilters);
  const data = {
    total_results_count: mockClientData.length,
    page_results: mockClientData,
  };

  return data;
};

export const searchClientsDebounce = debounce(searchClients, 500);
