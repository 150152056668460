import Link from './ui/Link';
import { LinkIcon } from '@chakra-ui/icons';
import { LinkProps } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';

type EmployeeLinkProps = {
  employeeId: number;
  isDisabled?: boolean;
};

const EmployeeLink: FC<PropsWithChildren<EmployeeLinkProps & LinkProps>> = ({
  employeeId,
  isDisabled,
  children,
  ...linkProps
}) => {
  return (
    <Link
      to={{
        pathname: `/profile/${employeeId}`,
      }}
      _hover={{ textDecor: 'none' }}
      isDisabled={isDisabled}
      {...linkProps}
    >
      {children ?? <LinkIcon />}
    </Link>
  );
};

export default EmployeeLink;
