import { BlobServiceClient } from '@azure/storage-blob';

const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME || '';
const azureURL = process.env.REACT_APP_AZURE_BLOB_URL || '';
const azureSAS = process.env.REACT_APP_AZURE_SAS || '';
const blobServiceClient = new BlobServiceClient(`${azureURL}?${azureSAS}`);

export const loadAzureBlob = async (
  imagePath: string
): Promise<string | undefined> => {
  if (!imagePath) {
    return '';
  }

  try {
    // TODO: this function shall be removed once the User Stores 1148 and 1149 are done!
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(imagePath);
    const downloadBlockBlobResponse = await blobClient.download();
    const octetString = await downloadBlockBlobResponse.blobBody;

    if (octetString) {
      const imageUrl = URL.createObjectURL(octetString);

      return imageUrl;
    }
  } catch (error) {
    console.error((error as Error).message);
  }
};
