import { get } from '~/shared/api/endpoints';
import { companiesEndpoint } from '~/shared/api/urls/companies';
import { Company } from '~/shared/models/api/company';

export const getCompanyLogo = async (path: string): Promise<string> => {
  const { data } = await get<Blob>(path, { responseType: 'blob' });

  return URL.createObjectURL(data);
};

export const getCompanies = async (): Promise<Company[]> => {
  const { data } = await get<Company[]>(companiesEndpoint.url);

  return data;
};
