import CertificationForm from './CertificationForm';
import {
  Badge,
  Box,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import useProfileStore from '~/profile/stores/profile';
import Logo from '~/shared/components/Logo';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { CreateEmployeeCertification } from '~/shared/models/api/employee';
import React, { useState } from 'react';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface EditModalProps {
  onClose: () => void;
}

const EditModal: React.FC<EditModalProps> = ({ onClose }) => {
  const toast = useToast();
  const [dirtyFields, setDirtyFields] = useState<
    Partial<Readonly<FieldNamesMarkedBoolean<CreateEmployeeCertification>>>
  >({});
  const [logo, setLogo] = useState<string>();
  const { openConfirmation } = useConfirmationModalContext();
  const { startLoading, stopLoading } = useLoadingContext();
  const { createCertification } = useProfileStore();
  const { t } = useTranslation('certificates');

  const onSubmit = async (certification: CreateEmployeeCertification) => {
    startLoading();

    const updateSuccess = await createCertification(certification);

    stopLoading();

    onClose();

    toast({
      title: t(`add_new_certificate`),
      description: updateSuccess ? t(`add_success`) : t(`add_fail`),
      status: updateSuccess ? 'success' : 'error',
    });
  };

  const onCloseModal = () => {
    const unsavedChanges = !!Object.keys(dirtyFields).length;
    if (unsavedChanges) {
      handleUnsavedChanges();
      return;
    }
    onClose();
  };

  const handleUnsavedChanges = async () => {
    const result = await openConfirmation();
    result && onClose();
  };

  return (
    <Modal isOpen onClose={onCloseModal} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <Stack direction="column">
          <ModalHeader
            data-testid="modal-certification-header"
            mt="2"
            mb="0"
            pr="14"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Badge colorScheme="blue">Certifications</Badge>
              <Heading fontSize="2xl" fontWeight="bold" mt="2">
                {t(`edit_details`)}
              </Heading>
            </Box>
            {logo && <Logo logo={logo} noImageIconSize={50} size="lg" />}
          </ModalHeader>
          <ModalCloseButton />
        </Stack>
        <CertificationForm
          onSubmit={onSubmit}
          onCancel={onCloseModal}
          onChange={setDirtyFields}
          onCertificateChange={(certificate) => {
            if (!certificate) {
              setLogo(undefined);
              return;
            }
            setLogo(certificate.logo);
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
