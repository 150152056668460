import '~/locales/i18n';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import setupTokenInterceptors from '~/authentication/setupAuthInterceptor';
import AppRoutes from '~/routes/AppRoutes';
import theme from '~/theme';
import { createBrowserHistory } from 'history';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const appInsightsConnString = process.env.REACT_APP_APP_INSIGHTS_CONN_STRING;
const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
if (appInsightsConnString) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: appInsightsConnString,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();
}

TimeAgo.addDefaultLocale(en);

const queryClient = new QueryClient();

type AppProps = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: AppProps) => {
  useEffect(() => {
    setupTokenInterceptors(pca);
  }, []);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={pca}>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
          </QueryClientProvider>
        </ChakraProvider>
      </MsalProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
