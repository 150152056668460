import { Image, ImageProps } from '@chakra-ui/react';
import { getCompanyLogo } from '~/shared/api/request/company';
import { QueriesTypes } from '~/shared/enums/queries';
import React, { FC } from 'react';
import { useQuery } from 'react-query';

export interface CompanyLogoProps extends ImageProps {
  name?: string;
  path: string;
}

const CompanyLogo: FC<CompanyLogoProps> = ({ path, name, ...imageProps }) => {
  const { data: imageUrl } = useQuery({
    queryKey: [QueriesTypes.GET_COMPANY_LOGO, name],
    queryFn: () => getCompanyLogo(path),
  });

  return <Image src={imageUrl} alt={name} {...imageProps} />;
};

export default CompanyLogo;
