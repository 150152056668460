import { Tooltip } from '@chakra-ui/react';
import { CustomTag } from '~/shared/components/ui/CustomTag';
import MissingFileIcon from '~/shared/components/ui/MissingFileIcon';
import { statusColorBackground } from '~/shared/enums/status';
import { EmployeeCertification } from '~/shared/models/api/employee';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFile } from 'react-icons/fa';

type CertificateTagProps = {
  certificate: EmployeeCertification;
  onClick?: () => void;
};

const CertificateTag: FC<CertificateTagProps> = ({
  certificate: {
    certificate: { name },
    status,
    certification_file,
  },
  onClick,
}) => {
  const { t } = useTranslation('verifyCertificates');

  return (
    <CustomTag
      backgroundColor={statusColorBackground[status]}
      color="white"
      cursor={status ? 'pointer' : 'default'}
      fontSize="xs"
      fontWeight="bold"
      letterSpacing="1px"
      onClick={onClick}
      role="button"
      textTransform="uppercase"
    >
      <Tooltip label={status}>{name}</Tooltip>
      {!certification_file && (
        <MissingFileIcon
          as={FaFile}
          boxSize={4}
          color="white"
          tooltip={t('missing_certification_file')}
        />
      )}
    </CustomTag>
  );
};

export default CertificateTag;
