import { Button, Flex, ModalBody, ModalFooter, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  defaultNewEducation,
  EducationAdd,
  educationSchema,
  mapToEducationAdd,
  mapToEmployeeEducation,
} from '~/profile/forms/education';
import useProfileStore from '~/profile/stores/profile';
import FormControlDatePicker from '~/shared/components/form/FormControlDatePicker';
import FormControlInput from '~/shared/components/form/FormControlInput';
import { DatePickerFormat } from '~/shared/enums/date';
import { EmployeeEducation } from '~/shared/models/api/employee';
import React, { useEffect } from 'react';
import {
  FieldNamesMarkedBoolean,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface EducationUpsertFormProps {
  education?: EmployeeEducation;
  onSubmit: (education: EmployeeEducation) => void;
  onCancel: () => void;
  onChange: (
    dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<EmployeeEducation>>>
  ) => void;
}

const EducationUpsertForm: React.FC<EducationUpsertFormProps> = ({
  education,
  onSubmit,
  onCancel,
  onChange,
}) => {
  const { profile } = useProfileStore();
  const { t: tProfile } = useTranslation('profile');
  const { t: tBase } = useTranslation('base');
  const methods = useForm({
    resolver: yupResolver(educationSchema),
    defaultValues: education
      ? mapToEducationAdd(education)
      : defaultNewEducation(profile),
  });
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, dirtyFields },
  } = methods;

  const startFormDate = watch('started');
  const endFormDate = watch('ended');
  const formValues = watch();

  let startDate: Date | null = null;
  let endDate: Date | null = null;

  if (startFormDate) {
    startDate = new Date(startFormDate.toString());
  }

  if (endFormDate) {
    endDate = new Date(endFormDate.toString());
  }

  useEffect(() => {
    onChange(dirtyFields);
  }, [formValues, dirtyFields]);

  const handleFormSubmit = (values: EducationAdd) => {
    onSubmit(mapToEmployeeEducation(values));
  };

  return (
    <>
      <ModalBody>
        <Stack>
          <FormProvider {...methods}>
            <Flex gap={4}>
              <FormControlInput
                id="degree_name"
                placeholder={tProfile('education.degree_placeholder')}
                register={register}
                error={errors.degree_name}
                maxLength={100}
              />
              <FormControlInput
                id="study_field_name"
                placeholder={tProfile('education.field_placeholder')}
                register={register}
                error={errors.study_field_name}
                maxLength={100}
              />
            </Flex>
            <Flex>
              <FormControlInput
                id="institution_name"
                placeholder={tProfile('education.institution_placeholder')}
                register={register}
                error={errors.institution_name}
                maxLength={100}
              />
            </Flex>
            <Flex gap={4}>
              <FormControlDatePicker
                id="started"
                register={register}
                error={errors.started}
                selectedDate={startDate}
                dateFormat={DatePickerFormat.YEAR_MONTH}
                placeholder={tProfile('education.start_date_placeholder')}
              />
              <FormControlDatePicker
                id="ended"
                register={register}
                error={errors.ended}
                selectedDate={endDate}
                dateFormat={DatePickerFormat.YEAR_MONTH}
                placeholder={tProfile('education.end_date_placeholder')}
              />
            </Flex>
          </FormProvider>
        </Stack>
      </ModalBody>

      <ModalFooter justifyContent="space-between">
        <Button
          data-testid="modal-education-cancel-button"
          type="button"
          onClick={onCancel}
        >
          {tBase('close')}
        </Button>
        <Button
          colorScheme="blue"
          type="submit"
          variant="solid"
          data-testid="form-education-submit-button"
          onClick={handleSubmit(handleFormSubmit)}
        >
          {education ? tBase('update') : tBase('create')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default EducationUpsertForm;
