import {
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import React, { cloneElement, PropsWithChildren, ReactElement } from 'react';
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  useFormContext,
} from 'react-hook-form';

export interface CustomFormControlProps<T extends FieldValues> {
  id: Path<T>;
  label?: string;
  placeholder?: string;
  error?: Partial<FieldError> | Merge<FieldError, FieldErrorsImpl>;
  disabled?: boolean;
  control: Control<T>;
}

const FormControl = <T extends FieldValues>({
  id,
  label,
  placeholder,
  error,
  disabled,
  control,
  children,
}: PropsWithChildren<CustomFormControlProps<T>>) => {
  const { watch } = useFormContext();
  const defaultValue = watch(id);

  return (
    <ChakraFormControl
      id={id}
      isInvalid={Boolean(error)}
      mb="2"
      isDisabled={disabled}
    >
      {label && (
        <FormLabel style={disabled ? { opacity: 0.4 } : {}}>{label}</FormLabel>
      )}

      {children && (
        <Controller
          control={control}
          name={id}
          render={({ field }) =>
            cloneElement(children as ReactElement, {
              ...field,
              ref: null,
              placeholder,
              defaultValue,
            })
          }
        />
      )}
      <FormErrorMessage data-testid="test-form-error-message">
        {!!error && (error.message as string)}
      </FormErrorMessage>
    </ChakraFormControl>
  );
};

export default FormControl;
