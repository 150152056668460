import {
  Box,
  Button,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Client } from '~/shared/models/api/Client';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum ModalType {
  SetMap = 'setMap',
  RemoveMap = 'removeMap',
}

export interface ClientMappingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (mappedName: string | null) => void;
  data: Client[];
  type: ModalType;
}

export const ClientMappingModal = ({
  isOpen,
  onClose,
  onSave,
  data,
  type,
}: ClientMappingModalProps) => {
  const [mappedName, setMappedName] = React.useState(
    data[0]?.mappedName || null
  );
  const { t } = useTranslation('clients');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(`modal.${type}`)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={2} align="start">
            <Text>{t(`modal.description`)}</Text>
            <UnorderedList>
              {data.map((client) => (
                <ListItem ml="8" key={client.name}>
                  {client.name}
                </ListItem>
              ))}
            </UnorderedList>
            {type === ModalType.SetMap && (
              <Box w="full">
                <Text>{t(`modal.new`)}</Text>
                <Input
                  value={mappedName ?? ''}
                  onChange={(event) => setMappedName(event.target.value)}
                  placeholder={t(`modal.placeholder`)}
                />
              </Box>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            {t(`modal.close`)}
          </Button>
          <Button variant="solid" onClick={() => onSave(mappedName)}>
            {t(`modal.save`)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
