// Colors match the status on Blue Ant
export enum ProjectStatusTypes {
  FINISHED = 'Finished',
  RUNNING = 'Running',
}

export type ProjectStatusColorsType = {
  [key in ProjectStatusTypes]: string;
};

export const projectStatusTypesColors: ProjectStatusColorsType = {
  [ProjectStatusTypes.FINISHED]: 'yellow.100',
  [ProjectStatusTypes.RUNNING]: 'yellow.400',
};
