import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  ModalFooter,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControlInput from '~/shared/components/form/FormControlInput';
import SkillTypeSelect from '~/shared/components/SkillTypeSelect';
import ModalButton from '~/shared/components/ui/ModalButton';
import { UpdateSkill } from '~/shared/models/api/skills';
import React, { FC, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

const skillEditModalFormSchema = object().shape({
  name: string().required(),
  type: string().nullable().required(),
});

export interface SkillEditModalFormProps {
  skillUpdate?: UpdateSkill;
  isUpdating: boolean;
  onDelete: () => void;
  onUpdate: (formValues: Partial<UpdateSkill>) => void;
}

const SkillEditModalForm: FC<SkillEditModalFormProps> = ({
  skillUpdate,
  isUpdating,
  onDelete,
  onUpdate,
}) => {
  const { t } = useTranslation('base');
  const form = useForm({ resolver: yupResolver(skillEditModalFormSchema) });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
  } = form;

  useEffect(() => {
    if (!skillUpdate) {
      return;
    }
    reset(skillUpdate);
  }, [skillUpdate, reset]);

  return (
    <Stack>
      <FormProvider {...form}>
        <ModalBody pb={0}>
          <VStack spacing={4}>
            <FormControlInput
              id="name"
              name={t('name')}
              placeholder={t('name')}
              register={register}
              error={errors.name}
            />
            <FormControl isInvalid={Boolean(errors.type)}>
              <FormLabel>{t('type')}</FormLabel>
              <Controller
                control={control}
                name="type"
                render={({ field: { onChange } }) => (
                  <SkillTypeSelect
                    onChange={onChange}
                    selected={skillUpdate?.type}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.type?.message as string}
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          <ModalButton type="button" onClick={onDelete} colorScheme="red">
            {t('delete')}
          </ModalButton>
          <ModalButton
            colorScheme="blue"
            type="submit"
            variant="solid"
            onClick={handleSubmit(onUpdate)}
            disabled={!isDirty}
            isUpdating={isUpdating}
            ml="auto"
          >
            {t('update')}
          </ModalButton>
        </ModalFooter>
      </FormProvider>
    </Stack>
  );
};

export default SkillEditModalForm;
