import { Employee, EmployeeIndustry } from '~/shared/models/api/employee';
import { Industry } from '~/shared/models/api/industry';
import { object } from 'yup';

export type IndustriesForm = {
  industries: EmployeeIndustry[];
};

export interface IndustryFormAdd {
  industry: Industry;
  employee_id?: number;
}

export type IndustryFormType = Partial<IndustryFormAdd>;

export const newIndustrySchema = object().shape({
  industry: object().required('Industry is required.'),
});

export const defaultNewIndustry = (
  profile: Employee | null,
  currentIndustry: EmployeeIndustry | null = null
): IndustryFormType => {
  if (currentIndustry) return currentIndustry;

  return {
    employee_id: profile?.id,
    industry: undefined,
  };
};

export const mapIndustry = (
  industryForm: IndustryFormAdd
): EmployeeIndustry => ({
  industry_id: industryForm.industry.id,
  industry: industryForm.industry,
  employee_id: industryForm.employee_id,
});
