import { Flex } from '@chakra-ui/react';
import { CustomTag } from '~/shared/components/ui/CustomTag';
import { EmployeeIndustry } from '~/shared/models/api/employee';
import React from 'react';

interface IndustriesPanelProps {
  industries: EmployeeIndustry[] | undefined;
}

const IndustriesPanel: React.FC<IndustriesPanelProps> = ({ industries }) => {
  if (!industries || !industries.length) return <></>;

  return (
    <Flex
      data-testid="industries-panel"
      justifyContent="flex-start"
      wrap="wrap"
      maxW="container.xl"
    >
      {industries.map((industry) => (
        <CustomTag
          data-testid="industries-panel-list"
          key={`industry-${industry.industry_id}`}
          alignItems="center"
          colorScheme="blackAlpha"
          mb={2}
        >
          {industry.industry?.name}
        </CustomTag>
      ))}
    </Flex>
  );
};

export default IndustriesPanel;
