import { Box, Img, useRadio, UseRadioProps } from '@chakra-ui/react';
import { Company } from '~/shared/models/api/company';
import React from 'react';

type CompanyCardProps = UseRadioProps & {
  company: Company;
  selected?: string;
  index: number;
};

const CompanyCard: React.FC<CompanyCardProps> = (props) => {
  const { index, selected, company } = props;

  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box data-testid={`checkbox-company-card-${index}`} as="label">
      <input {...input} />
      <Box
        data-testid="test-checkbox-company-card"
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        {...(company.name === selected
          ? {
              bg: 'gray.100',
              color: 'white',
              borderColor: 'gray.100',
              boxShadow: 'outline',
            }
          : {
              boxShadow: 'md',
            })}
        px={5}
        py={3}
      >
        <Box height="40px" padding="8px">
          <Img
            data-testid="test-img-company-card"
            height="100%"
            margin="auto"
            src={`/company-logos/${company.logo_url}`}
            alt={company.name}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyCard;
