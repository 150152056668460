import VerifyCertificateFileUploadForm from './VerifyCertificateFileUploadForm';
import {
  Badge,
  Box,
  Heading,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import CertificateDates from '~/administration/components/verify-certificates/CertificateDates';
import ApprovalButtons from '~/shared/components/approval-buttons/ApprovalButtons';
import Logo from '~/shared/components/Logo';
import StatusTag from '~/shared/components/status/StatusTag';
import MissingFileIcon from '~/shared/components/ui/MissingFileIcon';
import { Status } from '~/shared/enums/status';
import {
  EmployeeCertification,
  UpdateEmployeeCertificationDates,
} from '~/shared/models/api/employee';
import { useLoadAzureBlob } from '~/shared/queries/useLoadAzureBlob';
import { getFileNameFromPath } from '~/shared/utils/fileUpload';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileDownload } from 'react-icons/fa';

type VerifyCertificateModalProps = {
  certification: EmployeeCertification;
  isUpdatingStatus?: boolean;
  isUpdatingDate?: boolean;
  isUploadingFile?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onStatusChange: (status: Status) => void;
  onDateChange: (
    updatedDates: Partial<UpdateEmployeeCertificationDates>,
    onSuccess: () => void
  ) => void;
  onFileChange: (file: Blob, onSuccess: () => void) => void;
};

const VerifyCertificateModal: FC<VerifyCertificateModalProps> = ({
  certification,
  isUpdatingDate,
  isUpdatingStatus,
  isUploadingFile,
  isOpen,
  onClose,
  onDateChange,
  onStatusChange,
  onFileChange,
}) => {
  const {
    certificate: { name, certificate_url, logo, unique_identifier, permanent },
    status,
    adquired_date,
    expiring_date,
    certification_file,
  } = certification;
  const [certificationFileUrl, setCertificationFileUrl] = useState<string>();
  const [certificationFileName, setCertificationFileName] = useState<string>();
  const {
    data: azureCertificationFile,
    refetch: refetchAzureCertificationFile,
  } = useLoadAzureBlob(certification_file);
  const { t } = useTranslation('verifyCertificates');

  useEffect(() => {
    if (!azureCertificationFile) {
      return;
    }
    setCertificationFileUrl(azureCertificationFile);
  }, [azureCertificationFile]);

  useEffect(() => {
    if (!certification_file) {
      return;
    }
    setCertificationFileName(getFileNameFromPath(certification_file));
  }, [certification_file]);

  const handleFileUploaded = async (file: Blob) => {
    onFileChange(file, () => {
      refetchAzureCertificationFile();
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={!isUpdatingStatus || !isUploadingFile}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize="lg" textTransform="uppercase">
            {name}
          </Heading>
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody mb={4} pt={0}>
          <HStack spacing={2} mb={2} justifyContent="space-between">
            <VStack spacing={2} align="start">
              {unique_identifier && (
                <Badge colorScheme="gray" letterSpacing="1px" p={1}>
                  {unique_identifier}
                </Badge>
              )}
              <StatusTag status={status} p={1} />
            </VStack>
            <Box maxW="90px">
              {logo && (
                <Link
                  href={certificate_url}
                  isExternal
                  {...(!certificate_url ? { pointerEvents: 'none' } : {})}
                >
                  <Logo logo={logo} size="lg" />
                </Link>
              )}
            </Box>
          </HStack>
          <HStack spacing={4} align="start">
            <VStack spacing={1} align="start">
              {adquired_date && <Text>Issued on:</Text>}
              {!permanent && <Text>Expired on:</Text>}
              <Text>File:</Text>
              <VerifyCertificateFileUploadForm
                isUploadingFile={isUploadingFile}
                onFileUploaded={handleFileUploaded}
              />
            </VStack>
            <VStack spacing={1} align="start">
              <CertificateDates
                adquired_date={adquired_date}
                expiring_date={expiring_date}
                isUpdatingDate={isUpdatingDate}
                onDateChange={onDateChange}
                permanent={permanent}
              />
              {certificationFileUrl ? (
                <Link
                  display="flex"
                  alignItems="center"
                  href={certificationFileUrl}
                  download={certificationFileName}
                >
                  {certificationFileName}
                  <Icon as={FaFileDownload} ml={2} color="grey" />
                </Link>
              ) : (
                <MissingFileIcon tooltip={t('missing_certification_file')} />
              )}
            </VStack>
          </HStack>
        </ModalBody>
        <ModalFooter justifyContent="end">
          <ApprovalButtons
            onClick={onStatusChange}
            showSpinner={isUpdatingStatus}
            status={status}
            width="full"
            justifyContent="space-between"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VerifyCertificateModal;
