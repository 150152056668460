import { SelectOption } from '~/shared/models/react-select/select-option';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetOptionLabel } from 'react-select';
import CreatableSelect from 'react-select/creatable';

export interface CustomSelectBaseProps {
  id?: string;
  placeholder?: string;
  isCreatable?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  onlyShowOptionsIfQuery?: boolean;
  openMenuOnFocus?: boolean;
}

interface CustomSelectProps<T> extends CustomSelectBaseProps {
  options?: SelectOption<T>[];
  selectedOption?: SelectOption<T> | null;
  selectedOptions?: SelectOption<T>[];
  onQueryChange?: (query: string) => void;
  onCreate?: (name: string) => void;
  onChange?: (option?: T) => void;
  getOptionLabel?: GetOptionLabel<SelectOption<T>>;
}

const CustomSelect = <T = object,>({
  id = 'select',
  placeholder,
  options,
  selectedOption,
  selectedOptions,
  isClearable = true,
  isCreatable,
  isDisabled,
  isReadOnly,
  isLoading,
  onlyShowOptionsIfQuery,
  openMenuOnFocus = true,
  getOptionLabel,
  onCreate,
  onQueryChange,
  onChange,
  isSearchable,
  ...customControlStyles
}: CustomSelectProps<T> & CSSProperties) => {
  const { t } = useTranslation('base');
  const [query, setQuery] = useState<string>('');
  const [selectedOptionState, setSelectedOptionState] =
    useState<SelectOption<T> | null>(null);

  useEffect(() => {
    setSelectedOptionState(selectedOption || null);
  }, [selectedOption]);

  return (
    <CreatableSelect
      id={id}
      placeholder={placeholder || `${t('select_input.placeholder')}`}
      isLoading={isLoading}
      isClearable={isClearable}
      isDisabled={isDisabled || isReadOnly}
      styles={{
        control: (styles, state) => ({
          ...styles,
          ...(state.isDisabled && isReadOnly
            ? { backgroundColor: 'var(--chakra-colors-gray-50)' }
            : {}),
          ...customControlStyles,
        }),
        singleValue: (styles, state) => ({
          ...styles,
          ...(state.isDisabled && isReadOnly
            ? { color: 'inherit', opacity: '.8' }
            : {}),
        }),
        container: (styles) => ({
          ...styles,
          flex: 1,
        }),
      }}
      openMenuOnFocus={openMenuOnFocus}
      value={selectedOptionState}
      options={onlyShowOptionsIfQuery && !query ? [] : options}
      isSearchable={isSearchable}
      onInputChange={(query) => {
        setQuery(query);
        onQueryChange && onQueryChange(query);
      }}
      isOptionDisabled={(option) =>
        selectedOptions?.some((selected) => selected.value === option.value) ||
        false
      }
      isValidNewOption={() =>
        !!isCreatable &&
        !!query &&
        !options?.some((option) => option.label === query)
      }
      onCreateOption={onCreate}
      noOptionsMessage={() =>
        query ? t('select_input.no_options') : t('select_input.start_typing')
      }
      getOptionLabel={getOptionLabel}
      onChange={(value) => {
        onChange && onChange(value?.origin || (value as T));
        setSelectedOptionState(value);
      }}
    />
  );
};

export default CustomSelect;
