import { Box, useDisclosure } from '@chakra-ui/react';
import { externalUrls } from '~/authentication/config';
import ExportModal from '~/profile/components/export/ExportModal';
import { MainRoutesPaths } from '~/routes/enums/main';
import RoundedButton from '~/shared/components/buttons/RoundedButton';
import EmployeeAvatar from '~/shared/components/EmployeeAvatar';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import { Employee } from '~/shared/models/api/employee';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiExportFill,
  RiLogoutCircleRLine,
  RiSurveyLine,
  RiUserSettingsFill,
} from 'react-icons/ri';

interface UserMenuProps {
  loggedProfile: Employee | null;
  isLoading: boolean;
  handleLogoutClick: () => void;
}

const UserMenu: FC<UserMenuProps> = ({
  isLoading,
  handleLogoutClick,
  loggedProfile,
}) => {
  const { t } = useTranslation(['base', 'admin', 'profile']);
  const { isAdmin } = useAccessPermissions();
  const exportModal = useDisclosure();

  return (
    <Box>
      <ExportModal isOpen={exportModal.isOpen} onClose={exportModal.onClose} />
      <RoundedButton
        tooltipLabel={t(`profile:export.title`)}
        type="submit"
        onClick={exportModal.onOpen}
        isLoading={isLoading}
      >
        <RiExportFill size={22} opacity={0.8} />
      </RoundedButton>

      {externalUrls.feedbackSurvey && (
        <RoundedButton
          linkTo={externalUrls.feedbackSurvey}
          tooltipLabel={t(`base:feedback_survey`)}
          isLoading={isLoading}
        >
          <RiSurveyLine size={24} opacity={0.8} />
        </RoundedButton>
      )}
      {isAdmin && (
        <RoundedButton
          linkTo={MainRoutesPaths.ADMINISTER}
          tooltipLabel={t(`admin:admin`)}
          isLoading={isLoading}
        >
          <RiUserSettingsFill size={24} opacity={0.8} />
        </RoundedButton>
      )}
      <RoundedButton
        tooltipLabel={t(`profile:my_profile`)}
        linkTo={MainRoutesPaths.PROFILE}
        isLoading={isLoading}
      >
        <EmployeeAvatar imagePath={loggedProfile?.image_url} size="sm" />
      </RoundedButton>
      <RoundedButton
        onClick={handleLogoutClick}
        isLoading={isLoading}
        tooltipLabel={t(`base:logout`)}
      >
        <RiLogoutCircleRLine size={22} opacity={0.8} />
      </RoundedButton>
    </Box>
  );
};

export default UserMenu;
