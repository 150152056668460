import {
  Employee,
  EmployeeInternalExperience,
} from '~/shared/models/api/employee';
import { array, number, object, string } from 'yup';

export interface InternalExperiencesFormData {
  project_id: number | null;
  role: string;
  technologies: string[];
  tasks: string;
  start_at: string;
  end_at?: string | null;
  employee_id?: number;
  id?: number;
}

export const newExperiencesSchema = object().shape({
  project_id: number().required('Project is required.'),
  role: string().required('Role is required.'),
  tasks: string().required('Tasks are required.'),
  technologies: array().of(string()).min(1, 'Skills are required.'),
  start_at: string().required('Start date is required.'),
  end_at: string()
    .nullable()
    .when('start_at', ([startAt], schema) =>
      schema.test({
        test: (endAt) => {
          if (!startAt || !endAt) {
            return true;
          }

          const endDate = new Date(endAt);
          const startDate = new Date(startAt);

          return endDate >= startDate;
        },
        message: 'The end date must be greater than the start date.',
      })
    ),
});

export const defaultNewExperiencesSchema = (
  profile: Employee | null,
  currentExperience: EmployeeInternalExperience | null = null
): InternalExperiencesFormData => {
  if (currentExperience?.project) {
    return {
      ...currentExperience,
      employee_id: currentExperience.employee_id || profile?.id,
      project_id: currentExperience.project.id,
      id: currentExperience.id,
    };
  }

  return {
    role: '',
    tasks: '',
    technologies: [],
    start_at: '',
    end_at: null,
    project_id: null,
    employee_id: profile?.id,
    id: -1,
  };
};
