import PermissionLabel from '../components/permissions/PermissionLabel';
import { PermissionModalButton } from '../components/permissions/PermissionModalButton';
import { PermissionRoleModalButton } from '../components/permissions/PermissionRoleModalButton';
import { Flex, Tbody, Th } from '@chakra-ui/react';
import EmployeesTable from '~/administration/components/employees-search-table/EmployeesSearchTable';
import EmployeesRow from '~/administration/components/employees-search-table/EmployeesSearchTableRow';
import EmployeesThead from '~/administration/components/employees-search-table/EmployeesSearchTableThead';
import { EmployeeTableKeys } from '~/administration/models/employee';
import SearchInput from '~/shared/components/SearchInput';
import { Permission } from '~/shared/enums/permissions';
import useSearchFilters from '~/shared/hooks/filters/useSearchFilters';
import useRouteGuard from '~/shared/hooks/permissions/useRouteGuard';
import {
  EmployeeFilters,
  initialEmployeeFilters,
} from '~/shared/models/search/filters/employees';
import { useEmployeesSearch } from '~/shared/queries/useEmployeesSearch';
import { useGetRoles } from '~/shared/queries/useGetRoles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const keys: EmployeeTableKeys = ['email', 'name'];

const Permissions: FC = () => {
  useRouteGuard([Permission.PERMISSIONS]);

  const { filters, onQueryChange, onPageChange } =
    useSearchFilters<EmployeeFilters>(initialEmployeeFilters);
  const { data, isFetching, error, refetch } = useEmployeesSearch(filters);
  const { data: roles } = useGetRoles();
  const { t: tPermissions } = useTranslation('permissions');
  const { t: tEmployees } = useTranslation('employees');

  return (
    <>
      <SearchInput
        placeholder={tEmployees('search')}
        query={filters.query}
        onChange={onQueryChange}
      />
      <EmployeesTable
        filters={filters}
        isFetching={isFetching}
        data={data}
        error={error}
        onPageChange={onPageChange}
      >
        <EmployeesThead keys={[...keys]}>
          <Th whiteSpace="nowrap">{tPermissions('permission_groups')}</Th>
          <Th whiteSpace="nowrap">{tPermissions('further_permissions')}</Th>
        </EmployeesThead>
        <Tbody>
          {data?.page_results?.map((employee) => {
            return (
              <EmployeesRow
                employee={employee}
                keys={keys}
                key={`row-${employee.id}`}
              >
                <Flex align="center" gap={2} pr={3}>
                  {employee.roles.map((role) => (
                    <PermissionLabel key={`${employee.id}-${role}`}>
                      {role}
                    </PermissionLabel>
                  ))}
                  {roles && (
                    <PermissionRoleModalButton
                      employee={employee}
                      roles={roles}
                      refetch={refetch}
                    />
                  )}
                </Flex>
                {roles && (
                  <PermissionModalButton
                    employee={employee}
                    roles={roles}
                    refetch={refetch}
                  />
                )}
              </EmployeesRow>
            );
          })}
        </Tbody>
      </EmployeesTable>
    </>
  );
};

export default Permissions;
