import { Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  defaultNewIndustry,
  IndustryFormType,
  mapIndustry,
  newIndustrySchema,
} from '~/profile/forms/industries';
import useProfileStore from '~/profile/stores/profile';
import FormControl from '~/shared/components/form/FormControl';
import IndustrySelect from '~/shared/components/select/IndustrySelect';
import { EmployeeIndustry } from '~/shared/models/api/employee';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface IndustriesFormProps {
  selectedIndustries: EmployeeIndustry[];
  onSubmit: (industry: EmployeeIndustry) => void;
}

const IndustriesForm: React.FC<IndustriesFormProps> = ({
  selectedIndustries,
  onSubmit,
}) => {
  const { profile } = useProfileStore();
  const methods = useForm({
    resolver: yupResolver(newIndustrySchema),
    defaultValues: defaultNewIndustry(profile),
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  const handleFormSubmit = ({ industry, ...formValues }: IndustryFormType) => {
    if (!industry) return;

    const employeeIndustry = mapIndustry({
      industry,
      ...formValues,
    });

    onSubmit(employeeIndustry);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Text fontSize="md" color="gray.600" fontWeight="bold" mb="2">
        Add New Industry Expertise
      </Text>
      <Flex gridGap="4">
        <FormControl id="industry" control={control} error={errors.industry}>
          <IndustrySelect
            selectedIndustries={selectedIndustries.map(
              (employeeIndustry) => employeeIndustry.industry
            )}
          />
        </FormControl>

        <Tooltip label="Add New Industry">
          <Button
            data-testid="form-industry-add-button"
            type="button"
            ml="2"
            colorScheme="yellow"
            onClick={handleSubmit(handleFormSubmit)}
          >
            Add
          </Button>
        </Tooltip>
      </Flex>
    </FormProvider>
  );
};

export default IndustriesForm;
