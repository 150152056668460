import { loadAzureBlob } from '~/shared/api/loadAzureBlob';
import { QueriesTypes } from '~/shared/enums/queries';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useLoadAzureBlob = (blobPath?: string) =>
  useQuery<string | undefined, AxiosError>({
    queryKey: [QueriesTypes.GET_AZURE_BLOB_URL, blobPath],
    queryFn: ({ queryKey }) => loadAzureBlob(queryKey[1] as string),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!blobPath,
  });
