import { useToast } from '@chakra-ui/react';
import { verifyEmployeeProfile } from '~/shared/api/request/employee';
import { getErrorMessage } from '~/shared/errors/error';
import { EmployeeNameIdProps } from '~/shared/models/api/employee';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

export type EmployeeVerifications = UseMutationResult<
  string,
  unknown,
  EmployeeNameIdProps,
  unknown
>;

const useEmployeeVerifications = (): EmployeeVerifications => {
  const toast = useToast();
  const { t: tPermissions } = useTranslation('permissions');
  const { t } = useTranslation(['base', 'verification']);

  return useMutation(
    (employee: EmployeeNameIdProps) => verifyEmployeeProfile(employee.id),
    {
      onSuccess: (_verified: string, employee: EmployeeNameIdProps) => {
        toast({
          title: t(`verification:succeeded`),
          description: t(`verification:user_verified`, {
            userName: employee.name,
          }),
          status: 'success',
        });
      },
      onError: (err: AxiosError, employee: EmployeeNameIdProps) => {
        toast({
          title: t(`base:something_went_wrong`),
          description: getErrorMessage(
            err.response?.status,
            t(`verification:fail_user_message`, { userName: employee.name }),
            tPermissions
          ),
          status: 'error',
        });
      },
    }
  );
};

export default useEmployeeVerifications;
