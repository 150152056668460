import { Box, Img, Link, Tooltip } from '@chakra-ui/react';
import { SOCIAL_LINKS } from '~/shared/constants/social-links';
import { SocialLink } from '~/shared/models/api/social';
import React from 'react';

interface SocialLinkSingleProps {
  socialLink: SocialLink;
}

const SocialLinkSingle: React.FC<SocialLinkSingleProps> = ({ socialLink }) => {
  const socialLinkInfo = SOCIAL_LINKS.find(
    (s) => s.slug === socialLink.network
  );
  const url = `${socialLinkInfo?.base}${socialLink.user_name}`;
  const label =
    socialLinkInfo?.slug === 'other'
      ? url
      : `${socialLinkInfo?.name}: ${socialLink.user_name}`;

  return (
    <Box mr="2">
      <Tooltip label={label}>
        <Link href={url} isExternal>
          <Img
            w="6"
            src={`/social-links/${socialLinkInfo?.icon}`}
            alt={socialLinkInfo?.name}
          />
        </Link>
      </Tooltip>
    </Box>
  );
};

export default SocialLinkSingle;
