import { Checkbox, Flex } from '@chakra-ui/react';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import { PartialEmployeeFilters } from '~/shared/models/search/filters/employees';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ProfilesStatusesSearchVerifiedCheckboxProps {
  filters: PartialEmployeeFilters;
  onFilterChange: (partialFilters: PartialEmployeeFilters) => void;
}

const ProfilesStatusesSearchVerifiedCheckbox: FC<
  ProfilesStatusesSearchVerifiedCheckboxProps
> = ({ filters, onFilterChange }) => {
  const [showUnverifiedOnly, setShowUnverifiedOnly] = useState(false);
  const [showAllProfiles, setShowAllProfiles] = useState(filters.show_all);

  const { canVerifyTeamProfiles, canVerifyAllProfiles } =
    useAccessPermissions();

  const { t } = useTranslation('verification');

  useEffect(() => {
    onFilterChange({
      verify_profiles: true,
      verified_profile: showUnverifiedOnly ? false : undefined,
      show_all: showAllProfiles ? true : undefined,
    });
  }, [showUnverifiedOnly, showAllProfiles]);

  return (
    <Flex ml={4}>
      <Checkbox
        isChecked={showUnverifiedOnly}
        onChange={(e) => setShowUnverifiedOnly(e.target.checked)}
      >
        {t('unverified_only')}
      </Checkbox>
      {canVerifyTeamProfiles && canVerifyAllProfiles && (
        <Checkbox
          ml={20}
          isChecked={showAllProfiles}
          onChange={(e) => setShowAllProfiles(e.target.checked)}
        >
          {t('show_all_profiles')}
        </Checkbox>
      )}
    </Flex>
  );
};

export default ProfilesStatusesSearchVerifiedCheckbox;
