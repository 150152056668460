import { useToast } from '@chakra-ui/react';
import { getProjectChildren } from '~/shared/api/request/projects';
import { QueriesTypes } from '~/shared/enums/queries';
import { Child } from '~/shared/interfaces/map';
import { Project } from '~/shared/models/api/project';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

interface UseGetProjectChildren {
  data: Project[] | undefined;
  children: Child[];
  failLoading: boolean;
  isLoading: boolean;
}

export const useGetProjectChildren = (
  projectId?: number
): UseGetProjectChildren => {
  const { t } = useTranslation('projects');
  const toast = useToast();

  const { data, isError, isFetching } = useQuery<Project[], AxiosError>({
    queryKey: [QueriesTypes.GET_PROJECT_CHILDREN, projectId],
    queryFn: ({ queryKey }) => getProjectChildren(Number(queryKey[1])),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: () => {
      toast({
        title: t(`something_went_wrong`),
        description: t(`fail_loading_children`),
        status: 'error',
      });
    },
  });

  return {
    children:
      data?.map(({ id, title }) => ({
        id,
        text: title,
      })) ?? [],
    data,
    isLoading: isFetching,
    failLoading: isError,
  };
};
