export enum Permission {
  CERTIFICATES = 'Certificates',
  CLIENT_MAPPING = 'Client Mapping',
  EDIT_PROFILES = 'Edit Profiles',
  EXPORT_PROFILES = 'Export Profiles',
  PERMISSIONS = 'Permissions',
  PERMISSION_GROUPS = 'Permission Groups',
  PROJECTS = 'Projects',
  SEE_VERIFICATION_STATE = 'See Verification State',
  SKILLS = 'Skills',
  TRAININGS = 'Trainings',
  VERIFY_ALL_PROFILES = 'Verify All Profiles',
  VERIFY_CERTIFICATES = 'Verify Certificates',
  VERIFY_TEAM_PROFILES = 'Verify Team Profiles',
  VERIFY_TRAININGS = 'Verify Trainings',
}
