import {
  createEmployeeCertification,
  deleteEmployeeCertification,
} from '~/shared/api/request/certifications';
import {
  getEmployee,
  updateEmployee,
  updateEmployeeImage,
} from '~/shared/api/request/employee';
import { EndpointPaths } from '~/shared/enums/endpointPaths';
import {
  CreateEmployeeCertification,
  Employee,
  EmployeeCertificationBase,
  EmployeeEducation,
  EmployeeExternalExperience,
  EmployeeIndustry,
  EmployeeInternalExperience,
  EmployeeInternalExperienceParent,
  EmployeeSkill,
  EmployeeTraining,
} from '~/shared/models/api/employee';
import { EmployeeIdOrMe } from '~/shared/types/employeeIdOrMe';
import { create } from 'zustand';

interface State {
  createCertification: (
    newCertificate: CreateEmployeeCertification
  ) => Promise<boolean>;
  createEducation: (
    newEmployeeEducation: EmployeeEducation
  ) => Promise<boolean>;
  deleteCertificate: (
    deletedCertificate: EmployeeCertificationBase
  ) => Promise<boolean>;
  deleteEducation: (deletedEducation: EmployeeEducation) => Promise<boolean>;
  error?: Error | unknown;
  getProfileId: () => EmployeeIdOrMe;
  handleProfileUpdated: () => void;
  isLoadingMyProfile: boolean;
  isSelf: boolean; // can be myProfile or profile by id if userId in url
  loadMyProfile: (force?: boolean) => void;
  myProfile: Employee | null; // Current/Logged user
  profile: Employee | null;
  removeInternalExperienceFromLocalStore: (
    deletedInternalExperience: EmployeeInternalExperience
  ) => void;
  setIsSelf: (isSelf: boolean) => void;
  setProfile: (employee: Employee | null) => void;
  updateAvatar: (newAvatar: Blob, newAvatarBase64: string) => Promise<boolean>;
  updateEducation: (updatedEducation: EmployeeEducation) => Promise<boolean>;
  updateExternalExperiences: (
    updatedExternalExperiences: EmployeeExternalExperience[]
  ) => Promise<boolean>;
  updateEmployeeProfile: (updatedProfile: Partial<Employee>) => void;
  updateIndustries: (updatedIndustries: EmployeeIndustry[]) => Promise<boolean>;
  updateInternalExperiences: (
    updatedInternalExperiences: EmployeeInternalExperience[]
  ) => Promise<boolean>;
  updatePersonalData: (updatedEmployee: Employee) => Promise<boolean>;
  updateProfile: (
    updatedPartialEmployee: Partial<Employee>
  ) => Promise<boolean>;
  updateSkills: (updatedSkills: EmployeeSkill[]) => Promise<boolean>;
  updateTrainings: (updatedTrainings: EmployeeTraining[]) => Promise<boolean>;
}

const useProfileStore = create<State>((set, get) => ({
  errorMessages: [],
  isLoadingMyProfile: true,
  isSelf: false,
  myProfile: null,
  profile: null,

  createCertification: async (newCertificate) => {
    const { getProfileId, profile, handleProfileUpdated } = get();

    if (!profile) {
      return false;
    }

    try {
      const addedCertificate = await createEmployeeCertification({
        ...newCertificate,
        employee_id: getProfileId(),
      });

      set({
        profile: {
          ...profile,
          certifications: [...profile.certifications, addedCertificate],
        },
      });

      handleProfileUpdated();
      return true;
    } catch (e) {
      return false;
    }
  },

  createEducation: async (newEducation) => {
    const { profile, updateProfile } = get();

    if (!profile) {
      return false;
    }

    return updateProfile({ education: [...profile.education, newEducation] });
  },

  deleteCertificate: async (deletedCertificate) => {
    const { getProfileId, profile, handleProfileUpdated } = get();

    if (!profile) {
      return false;
    }

    try {
      await deleteEmployeeCertification({
        ...deletedCertificate,
        employee_id: getProfileId(),
      });

      set({
        profile: {
          ...profile,
          certifications: profile.certifications.filter(
            (c) => c.certificate_id != deletedCertificate.certificate_id
          ),
        },
      });

      handleProfileUpdated();

      return true;
    } catch (e) {
      return false;
    }
  },

  deleteEducation: async (deletedEducation) => {
    const { profile, updateProfile } = get();

    if (!profile) {
      return false;
    }

    return updateProfile({
      education: profile.education.filter(
        (e) => e.education_id != deletedEducation.education_id
      ),
    });
  },

  getProfileId: () => {
    const { isSelf, profile } = get();

    return isSelf || !profile ? EndpointPaths.ME : profile.id;
  },

  loadMyProfile: async (force = false) => {
    const { isLoadingMyProfile, myProfile } = get();

    if ((isLoadingMyProfile || myProfile) && !force) {
      return;
    }

    set({ isLoadingMyProfile: true });

    try {
      const employee = await getEmployee(EndpointPaths.ME);

      set({ myProfile: employee });
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoadingMyProfile: false });
    }
  },

  removeInternalExperienceFromLocalStore: (removedInternalExperience) => {
    const { profile } = get();

    if (!profile?.ki_projects.length) {
      return false;
    }

    const removeParent = () =>
      profile.ki_projects.filter(
        (e) => e.project.id !== removedInternalExperience.project.id
      );

    const removeChild = () => {
      const experiences: EmployeeInternalExperienceParent[] = [];
      const rmChild = (e: EmployeeInternalExperience[] = []) =>
        e.filter((c) => c.id !== removedInternalExperience.id);

      for (const internalExperience of profile.ki_projects) {
        if (!internalExperience.children) {
          experiences.push(internalExperience);
        } else {
          const children = rmChild(internalExperience.children);

          if (children.length) {
            experiences.push({ ...internalExperience, children });
          }
        }
      }

      return experiences;
    };

    const isChild = removedInternalExperience.project.parent_id;
    const experiences = isChild ? removeChild() : removeParent();

    set({ profile: { ...profile, ki_projects: experiences } });
  },

  setIsSelf: (isSelf) => {
    set({ isSelf });
  },

  setProfile: (profile) => {
    set({ profile });
  },

  updateAvatar: async (newAvatar) => {
    const { profile, getProfileId, loadMyProfile } = get();

    if (!profile) {
      return false;
    }

    try {
      await updateEmployeeImage(getProfileId(), {
        image: newAvatar,
        email: profile.email,
      });

      loadMyProfile(true);
      return true;
    } catch (e) {
      return false;
    }
  },

  updateEducation: async (updatedEducation) => {
    const { profile, updateProfile } = get();

    if (!profile) {
      return false;
    }

    return updateProfile({
      education: profile.education.map((p) =>
        p.education_id == updatedEducation.education_id ? updatedEducation : p
      ),
    });
  },

  updateEmployeeProfile: async (updatedEmployeePartial) => {
    const { profile, handleProfileUpdated } = get();

    if (!profile) {
      return false;
    }

    set({ profile: { ...profile, ...updatedEmployeePartial } });

    handleProfileUpdated();
  },

  updateInternalExperiences: async (updatedInternalExperiences) => {
    const { updateProfile } = get();

    return updateProfile({ ki_projects: [...updatedInternalExperiences] });
  },

  updateExternalExperiences: async (updatedExternalExperiences) => {
    const { updateProfile } = get();

    return updateProfile({
      experiences_outside_ki: [...updatedExternalExperiences],
    });
  },

  updateIndustries: async (updatedIndustries) => {
    const { updateProfile } = get();

    return updateProfile({ industries: [...updatedIndustries] });
  },

  updatePersonalData: async ({
    seniority,
    job_title,
    social_links,
    bio,
    work_since,
    languages,
  }) => {
    const { updateProfile } = get();

    return updateProfile({
      seniority,
      job_title,
      social_links,
      bio,
      work_since,
      languages,
    });
  },

  updateProfile: async (updatedProfile) => {
    const { profile, getProfileId, handleProfileUpdated } = get();

    if (!profile) {
      return false;
    }

    try {
      const updated = await updateEmployee(getProfileId(), {
        ...updatedProfile,
      });

      set({ profile: updated });

      handleProfileUpdated();

      return true;
    } catch (e) {
      return false;
    }
  },

  handleProfileUpdated: () => {
    const { profile, myProfile } = get();

    if (profile?.id == myProfile?.id) {
      set({ myProfile: profile });
    }
  },

  updateSkills: async (updatedSkills) => {
    const { updateProfile } = get();

    return updateProfile({ main_skills: [...updatedSkills] });
  },

  updateTrainings: async (updatedTrainings) => {
    const { updateProfile } = get();

    return updateProfile({ trainings: [...updatedTrainings] });
  },
}));

export default useProfileStore;
