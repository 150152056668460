import Administration from '~/administration/Administration';
import Certificates from '~/administration/sections/Certificates';
import { ClientMapping } from '~/administration/sections/ClientMapping';
import PermissionGroups from '~/administration/sections/PermissionGroups';
import Permissions from '~/administration/sections/Permissions';
import ProfilesStatues from '~/administration/sections/ProfilesStatuses';
import Projects from '~/administration/sections/Projects';
import Skills from '~/administration/sections/Skills';
import VerifyCertificates from '~/administration/sections/VerifyCertificates';
import VerifyProfiles from '~/administration/sections/VerifyProfiles';
import AuthWrapper from '~/authentication/components/AuthWrapper';
import { AdminRoutesPaths } from '~/routes/enums/admin';
import { MainRoutesPaths } from '~/routes/enums/main';
import { Permission } from '~/shared/enums/permissions';
import React from 'react';
import { RouteObject } from 'react-router-dom';

type RouteWithPermissions = RouteObject & {
  permissions: Permission[];
};

export const adminChildRoutes: RouteWithPermissions[] = [
  {
    id: 'projects',
    path: AdminRoutesPaths.PROJECTS,
    element: <Projects />,
    permissions: [Permission.PROJECTS],
  },
  {
    id: 'permissions',
    path: AdminRoutesPaths.PERMISSIONS,
    element: <Permissions />,
    permissions: [Permission.PERMISSIONS],
  },
  {
    id: 'permission_groups',
    path: AdminRoutesPaths.PERMISSION_GROUPS,
    element: <PermissionGroups />,
    permissions: [Permission.PERMISSION_GROUPS],
  },
  {
    id: 'skills',
    path: AdminRoutesPaths.SKILLS,
    element: <Skills />,
    permissions: [Permission.SKILLS],
  },
  {
    id: 'certificates',
    path: AdminRoutesPaths.CERTIFICATES,
    element: <Certificates />,
    permissions: [Permission.CERTIFICATES],
  },
  {
    id: 'verify_certificates',
    path: AdminRoutesPaths.VERIFY_CERTIFICATES,
    element: <VerifyCertificates />,
    permissions: [Permission.VERIFY_CERTIFICATES],
  },
  {
    id: 'verify_profiles',
    path: AdminRoutesPaths.VERIFY_PROFILES,
    element: <VerifyProfiles />,
    permissions: [
      Permission.VERIFY_ALL_PROFILES,
      Permission.VERIFY_TEAM_PROFILES,
    ],
  },
  {
    id: 'profiles_statuses',
    path: AdminRoutesPaths.PROFILES_STATUSES,
    element: <ProfilesStatues />,
    permissions: [
      Permission.VERIFY_ALL_PROFILES,
      Permission.VERIFY_TEAM_PROFILES,
    ],
  },
  {
    id: 'client_mapping',
    path: AdminRoutesPaths.CLIENT_MAPPING,
    element: <ClientMapping />,
    permissions: [Permission.CLIENT_MAPPING],
  },
];

export const adminRoutes: RouteObject[] = [
  {
    path: MainRoutesPaths.ADMINISTER,
    element: (
      <AuthWrapper>
        <Administration />
      </AuthWrapper>
    ),
    children: [...adminChildRoutes],
  },
];
