import CustomSelect from './CustomSelect';
import useCertificates from '~/shared/hooks/certificate/useCertificates';
import {
  mapDataListToOptions,
  mapDataToOption,
} from '~/shared/mappers/selectOption';
import { Certificate } from '~/shared/models/api/certificate';
import {
  SelectOption,
  SelectOptionMapperKeysType,
} from '~/shared/models/react-select/select-option';
import { useCertificatesSearch } from '~/shared/queries/useCertificatesSearch';
import React, { FC, useEffect, useState } from 'react';

interface CertificateSelectProps {
  id?: string;
  placeholder?: string;
  selectedCertificates?: Certificate[];
  selectedCertificate?: Certificate;
  isClearable?: boolean;
  isCreatable?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  onChange?: (certificate?: Certificate) => void;
}

const CertificateSelect: FC<CertificateSelectProps> = ({
  id = 'certificate-select',
  placeholder,
  selectedCertificates,
  selectedCertificate,
  isClearable,
  isCreatable,
  isDisabled,
  isReadOnly,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] =
    useState<SelectOption<Certificate> | null>(null);
  const [query, setQuery] = useState<string>('');
  const { data: { page_results: certificates } = {}, isLoading } =
    useCertificatesSearch(query ? { query } : undefined);
  const { createCertificate } = useCertificates();

  const keys: SelectOptionMapperKeysType<Certificate> = {
    valueKey: 'id',
    labelKey: 'name',
  };

  const selectCertificate = (certificate: Certificate) => {
    setSelectedOption(
      mapDataToOption({
        option: certificate,
        ...keys,
      })
    );
  };

  const handleCreate = (name: string) => {
    createCertificate.mutateAsync(
      { name },
      {
        onSuccess: (createdCertificate: Certificate) => {
          selectCertificate(createdCertificate);

          if (onChange) {
            onChange(createdCertificate);
          }
        },
      }
    );
  };

  const buildOptions = (certificates: Certificate[] = []) =>
    mapDataListToOptions<Certificate>({
      options: certificates,
      ...keys,
    });

  useEffect(() => {
    if (!selectedCertificate) {
      setSelectedOption(null);
      return;
    }
    selectCertificate(selectedCertificate);
  }, [selectedCertificate]);

  return (
    <CustomSelect<Certificate>
      id={id}
      placeholder={placeholder}
      isLoading={isLoading}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      isCreatable={isCreatable}
      selectedOption={selectedOption}
      options={buildOptions(certificates)}
      selectedOptions={buildOptions(selectedCertificates)}
      getOptionLabel={(option) =>
        option.origin?.unique_identifier
          ? `${option.origin.name} (${option.origin.unique_identifier})`
          : option.label
      }
      onQueryChange={setQuery}
      onCreate={handleCreate}
      onChange={onChange}
      onlyShowOptionsIfQuery
    />
  );
};

export default CertificateSelect;
