import { Td, Text } from '@chakra-ui/react';
import { EmployeeTableKey } from '~/administration/models/employee';
import EmployeeLink from '~/shared/components/EmployeeLink';
import { Employee } from '~/shared/models/api/employee';
import React, { FC, PropsWithChildren } from 'react';

type EmployeesSearchTableCellProps = {
  employeeKey: EmployeeTableKey;
  employee: Employee;
  nameLinkToProfile?: boolean;
};

const EmployeesSearchTableCell: FC<
  PropsWithChildren<EmployeesSearchTableCellProps>
> = ({ employeeKey, employee, nameLinkToProfile }) => {
  const employeeValueByKey = () => {
    switch (employeeKey) {
      case 'name':
        return (
          <EmployeeLink
            employeeId={employee.id}
            isDisabled={!nameLinkToProfile}
            textDecor="underline"
            color={nameLinkToProfile ? 'blue.500' : 'inherit'}
            _hover={{ textDecor: 'underline', color: 'blue.800' }}
            whiteSpace="nowrap"
          >
            {`${employee.first_name} ${employee.second_name}`}
          </EmployeeLink>
        );
      case 'company':
        return <Text whiteSpace="nowrap">{employee.company?.name}</Text>;
      default:
        return employee[employeeKey].toString();
    }
  };

  return <Td>{employeeValueByKey()}</Td>;
};

export default EmployeesSearchTableCell;
