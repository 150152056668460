import useSkillCreate from '~/shared/hooks/skills/useSkillCreate';
import { mapValueToOption } from '~/shared/mappers/selectOption';
import { Skill } from '~/shared/models/api/skills';
import { SelectOption } from '~/shared/models/react-select/select-option';
import { useSkillsSearch } from '~/shared/queries/skills/useSkillsSearch';
import React, { FC, useEffect, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

type SkillSelectByValueType = string | number | (string | number)[];

interface SkillSelectByValueProps {
  id?: string;
  placeholder?: string;
  selectedSkills?: string[];
  selectedSkill?: SkillSelectByValueType;
  returnValue?: 'id' | 'name';
  isClearable?: boolean;
  isCreatable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  onChange?: (value?: SkillSelectByValueType) => void;
}

const SkillSelectByValue: FC<SkillSelectByValueProps> = ({
  id = 'skill-select',
  placeholder,
  selectedSkills = [],
  returnValue = 'name',
  isClearable = true,
  isCreatable = true,
  isMulti,
  isDisabled,
  onChange,
}) => {
  const [selected, setSelected] = useState<
    SelectOption<string | number> | SelectOption<string | number>[] | null
  >(isMulti ? [] : null);

  const [options, setOptions] = useState<SelectOption<string | number>[]>();
  const [query, setQuery] = useState<string>('');
  const skillsSearch = useSkillsSearch({ query });
  const { createSkill } = useSkillCreate();

  useEffect(() => {
    if (selectedSkills && isMulti) {
      setSelectedSkill(selectedSkills);
    }
  }, [selectedSkills, isMulti]);

  const setSelectedSkill = (value: SkillSelectByValueType) => {
    if (isMulti) {
      const arrayValue = value as (string | number)[];
      const selectOptions = arrayValue.map((v) =>
        mapValueToOption<string | number>(v)
      );
      setSelected(selectOptions);
    } else {
      const singleValue = value as string | number;
      const selectOption = mapValueToOption<string | number>(singleValue);
      setSelected(selectOption);
    }
  };

  useEffect(() => {
    if (!skillsSearch.data) {
      return;
    }
    const selectOptions = skillsSearch.data.map((skill) =>
      mapValueToOption<string | number>(skill[returnValue] as string)
    );

    setOptions(selectOptions);
  }, [skillsSearch.data]);

  const handleCreate = (name: string) => {
    createSkill.mutateAsync(
      { name },
      {
        onSuccess: (skill: Skill) => {
          const mappedSkill = mapValueToOption<string | number>(
            skill[returnValue]
          );
          if (isMulti) {
            handleChange([
              ...(selected as SelectOption<string | number>[]),
              mappedSkill,
            ]);
            return;
          }
          handleChange(mappedSkill);
        },
      }
    );
  };

  const handleChange = (
    value:
      | MultiValue<SelectOption<string | number>>
      | SingleValue<SelectOption<string | number>>
  ) => {
    if (isMulti) {
      const multiple = value as SelectOption<string | number>[];
      onChange && onChange(multiple.map((o) => o.value));
      setSelected(multiple);
      return;
    }
    const single = value as SelectOption<string | number>;
    onChange && onChange(single?.value || undefined);
    setSelected(single);
  };

  return (
    <CreatableSelect
      id={id}
      placeholder={placeholder}
      isLoading={(!!query && skillsSearch.isLoading) || createSkill.isLoading}
      isClearable={isClearable}
      isDisabled={isDisabled}
      value={selected}
      options={options}
      onInputChange={(query) => setQuery(query)}
      isMulti={isMulti}
      isOptionDisabled={(option) =>
        selectedSkills?.some((skill) => skill === option.value) || false
      }
      isValidNewOption={() => isCreatable && !!query}
      onCreateOption={handleCreate}
      onChange={handleChange}
    />
  );
};

export default SkillSelectByValue;
