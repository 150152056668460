import CustomSelect from '../select/CustomSelect';
import { Status } from '~/shared/enums/status';
import {
  mapObjectAsOption,
  mapObjectListAsOptions,
} from '~/shared/mappers/selectOption';
import { SelectOption } from '~/shared/models/react-select/select-option';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface StatusSelectProps {
  id?: string;
  selectedStatus?: Status;
  isUpdating?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  onChange?: (value?: Status) => void;
}

const statusSelectOptions: SelectOption<Status>[] = mapObjectListAsOptions(
  Status
) as SelectOption<Status>[];

const StatusSelect: FC<StatusSelectProps> = ({
  id = 'status-select',
  selectedStatus,
  isUpdating,
  placeholder,
  isDisabled,
  isClearable,
  onChange,
}) => {
  const { t } = useTranslation('base');
  const [selectedOption, setSelectedOption] =
    useState<SelectOption<Status> | null>(null);

  useEffect(() => {
    if (!selectedStatus) {
      setSelectedOption(null);
      return;
    }
    setSelectedOption(
      mapObjectAsOption(Status, selectedStatus) as SelectOption<Status>
    );
  }, [selectedStatus]);

  return (
    <CustomSelect<Status>
      id={id}
      placeholder={placeholder || t('select')}
      isClearable={isClearable}
      isLoading={isUpdating}
      isDisabled={isDisabled}
      isSearchable={false}
      selectedOption={selectedOption}
      options={statusSelectOptions}
      getOptionLabel={(option) =>
        t(`status_types.${option.label.toLowerCase()}`)
      }
      onChange={onChange}
    />
  );
};

export default StatusSelect;
