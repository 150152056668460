import { Center, Divider, GridItem, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

export interface SkillMergeConfirmationModalSkillBoxProps {
  name?: string;
  type?: string;
}

const SkillMergeConfirmationModalSkillBox: FC<
  SkillMergeConfirmationModalSkillBoxProps
> = ({ name, type }) => {
  return (
    <GridItem bg="teal.400" p={2} h="full" borderRadius="sm" color="white">
      <Center h="full" flexDirection="column">
        <Center minH={10}>
          <Text
            fontSize="sm"
            textTransform="uppercase"
            fontWeight="bold"
            letterSpacing={1}
          >
            {type || 'Undefined'}
          </Text>
        </Center>
        <Divider my={3} w="30%" borderColor="white" />
        <Center minH={10} flexGrow={1}>
          <Text fontWeight="medium">{name}</Text>
        </Center>
      </Center>
    </GridItem>
  );
};

export default SkillMergeConfirmationModalSkillBox;
