import { FormControl, Heading, Stack } from '@chakra-ui/react';
import SocialLinksEditList from '~/profile/components/about/social-links/SocialLinksEditList';
import SocialLinksForm from '~/profile/components/about/social-links/SocialLinksForm';
import { Employee } from '~/shared/models/api/employee';
import { SocialLink } from '~/shared/models/api/social';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const SocialLinksEdit: React.FC = () => {
  const { setValue, getValues } = useFormContext<Employee>();
  const { t } = useTranslation('profile');
  const [socialLinks, setSocialLinks] = useState(getValues('social_links'));

  const addSocialLink = (data: SocialLink) => {
    if (
      socialLinks.find(
        (sl) => sl.user_name === data.user_name && sl.network === data.network
      )
    ) {
      return;
    }

    const newSocialLinks = [...socialLinks, data];
    setValue('social_links', newSocialLinks);
    setSocialLinks(newSocialLinks);
  };

  const onDelete = (socialLinkIndex: number) => {
    const newSocialLinks = [...socialLinks];
    newSocialLinks.splice(socialLinkIndex, 1);

    setValue('social_links', newSocialLinks);
    setSocialLinks(newSocialLinks);
  };

  return (
    <FormControl id="socialLinks">
      <Heading fontSize="lg" color="blue.700" fontWeight="bold" mt="4" mb="4">
        {t('about_me.social_link.title')}
      </Heading>
      <Stack direction="row" gap="2">
        <SocialLinksForm onAddSocialLink={addSocialLink} />
        <SocialLinksEditList socialLinks={socialLinks} onDelete={onDelete} />
      </Stack>
    </FormControl>
  );
};

export default SocialLinksEdit;
