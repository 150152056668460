import App from './App';
import reportWebVitals from './reportWebVitals';
import { ColorModeScript } from '@chakra-ui/react';
import { msalInstance, setActiveAccount } from '~/authentication/setup';
import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

msalInstance.initialize().then(() => {
  setActiveAccount(msalInstance);

  const rootElement =
    document.getElementById('root') || document.createElement('div');
  const root = ReactDOMClient.createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ColorModeScript />
        <App pca={msalInstance} />
      </BrowserRouter>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
