import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  Box,
  Button,
  Container,
  Flex,
  Img,
  Stack,
  Text,
} from '@chakra-ui/react';
import { loginRequest } from '~/authentication/config';
import LoadingData from '~/shared/components/ui/LoadingData';
import React, { FC } from 'react';

const Login: FC = () => {
  const { inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLoginClick = async () => {
    instance.loginRedirect({
      ...loginRequest,
      prompt: 'create',
      redirectUri: `${window.location.origin}`,
    });
  };

  return (
    <Container>
      <Flex alignItems="center" justify="center" minHeight="100vh">
        {isAuthenticated || inProgress == InteractionStatus.Logout ? (
          <LoadingData />
        ) : (
          <Stack
            mt="24px"
            spacing="20"
            backgroundColor="white"
            borderRadius="2xl"
            shadow="sm"
            overflow="hidden"
          >
            <Img src="banner.png" alt="KI group" />
            <Box textAlign="center" fontSize="xl" mb="16" color="gray.900">
              <Text data-testid="title" as="h3">
                Skill Management System
              </Text>
              <Box py="16">
                <Button
                  data-testid="login-microsoft-button"
                  onClick={handleLoginClick}
                  variant="outline"
                  colorScheme="blue"
                >
                  Login with Microsoft Account
                </Button>
              </Box>
            </Box>
          </Stack>
        )}
      </Flex>
    </Container>
  );
};

export default Login;
