import SearchInput from '../SearchInput';
import { InternalProjectSelectItem } from './InternalProjectSelectItem';
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { InternalExperiencesFormData } from '~/profile/forms/internalExperiences';
import { InternalProjectSelectItemsList } from '~/shared/components/select/InternalProjectSelectItemsList';
import { CustomTag } from '~/shared/components/ui/CustomTag';
import { EmployeeProject } from '~/shared/models/api/project';
import { useEmployeeProjectAllocations } from '~/shared/queries/useGetEmployeeProjectAllocations';
import React, { FC, useCallback, useState } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ProjectSelectProps {
  employeeId: number;
  control: Control<InternalExperiencesFormData, unknown>;
  onChange?: (project_id?: number) => void;
  selectedProject: EmployeeProject | null;
  setSelectedProject: React.Dispatch<
    React.SetStateAction<EmployeeProject | null>
  >;
}

const InternalProjectSelect: FC<ProjectSelectProps> = ({
  onChange,
  employeeId,
  setSelectedProject,
  selectedProject,
}) => {
  const [query, setQuery] = useState<string>('');

  const { t } = useTranslation('profile', {
    keyPrefix: 'experiences.internal.form',
  });

  const { data: employeeProjects = [], isLoading } =
    useEmployeeProjectAllocations(employeeId, query);

  const getProjects = useCallback(
    ({ external }: { external: boolean }): EmployeeProject[] =>
      employeeProjects.filter(({ project }) => project.external === external),
    [query, employeeProjects]
  );

  const handleClick = (employeeProject: EmployeeProject | null) => {
    setSelectedProject(employeeProject);
    onChange && onChange(employeeProject?.project.id);
  };

  const clientProjects = getProjects({ external: true });
  const internalProjects = getProjects({ external: false });

  const tabs = [
    {
      title: t(`tab.client`),
      quantity: clientProjects.length,
      panelProps: {
        employeeProjects: clientProjects,
        selected: selectedProject,
        onClick: handleClick,
        isLoading,
      },
    },
    {
      title: t(`tab.internal`),
      quantity: internalProjects.length,
      panelProps: {
        employeeProjects: internalProjects,
        selected: selectedProject,
        onClick: handleClick,
        isLoading,
      },
    },
  ];

  if (selectedProject) {
    return (
      <InternalProjectSelectItem
        employeeProject={selectedProject}
        isSelected={true}
        onClick={handleClick}
      />
    );
  }

  return (
    <Flex
      borderColor="gray.400"
      borderWidth={1}
      flexDir="column"
      p={2}
      rounded={4}
    >
      <SearchInput
        bg="white"
        onChange={(q: string) => setQuery(q)}
        mb={2}
        rounded={8}
        shadow="sm"
        query={query}
      />

      <Tabs bg="white" variant="enclosed">
        <TabList>
          {tabs.map(({ title, quantity }, i) => (
            <Tab key={`internal-experience-select-tab-list-${i}`} bg="gray.50">
              <Text>{title}</Text>
              <CustomTag alignItems="center" colorScheme="cyan" ml={1}>
                {quantity}
              </CustomTag>
            </Tab>
          ))}
        </TabList>
        <TabPanels h="200px" overflow="scroll" rounded={8}>
          {tabs.map(({ panelProps }, i) => (
            <TabPanel
              bg="gray.50"
              key={`internal-experience-select-tab-panel-${i}`}
            >
              <InternalProjectSelectItemsList {...panelProps} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default InternalProjectSelect;
