import useProfileStore from '../profile/stores/profile';
import { Box, Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import { MainRoutesPaths } from '~/routes/enums/main';
import { adminChildRoutes } from '~/routes/models/admin';
import AppLayout from '~/shared/components/ui/AppLayout';
import { adminPermissions } from '~/shared/constants/permissions';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import useRouteGuard from '~/shared/hooks/permissions/useRouteGuard';
import ConfirmationModalContext from '~/shared/hooks/useConfirmationModalContext';
import LoadingContext from '~/shared/hooks/useLoadingContext';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';

const Administration: FC = () => {
  useRouteGuard(adminPermissions);

  const { hasPermission } = useAccessPermissions();
  const { setProfile } = useProfileStore();

  const { t } = useTranslation('admin');
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const { pathname } = useLocation();

  const tabs = adminChildRoutes.filter(({ permissions }) => {
    return hasPermission(permissions);
  });

  useEffect(() => {
    setProfile(null);
    if (pathname == '/admin') {
      navigate(`${MainRoutesPaths.ADMINISTER}/${tabs[0].path}`);
    }
  }, []);

  useEffect(() => {
    const index = tabs.findIndex(
      (route) => `${MainRoutesPaths.ADMINISTER}/${route.path}` === pathname
    );
    if (index < 0) {
      return;
    }
    setTabIndex(index);
  }, [pathname]);

  return (
    <>
      <AppLayout>
        <LoadingContext>
          <ConfirmationModalContext>
            <Text
              alignContent="center"
              bg="gray.50"
              fontWeight="bold"
              h="28px"
              mb="4"
              ml="2"
              mr="5"
              pr="5"
              rounded={8}
              textAlign="center"
              shadow="sm"
            >
              {t('title')}
            </Text>
            <Tabs index={tabIndex} onChange={setTabIndex}>
              <TabList alignItems="center">
                {tabs.map(({ path, id }) => {
                  return (
                    <Tab key={`admin-route-${id}`} as={NavLink} to={`${path}`}>
                      {t(`tabs.${id}`)}
                    </Tab>
                  );
                })}
              </TabList>
            </Tabs>
            <Box p={4}>
              <Outlet />
            </Box>
          </ConfirmationModalContext>
        </LoadingContext>
      </AppLayout>
    </>
  );
};

export default Administration;
