import { InfoIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Switch,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import {
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';

interface FormControlCheckboxProps<T extends FieldValues> {
  id: Path<T>;
  name: string;
  register: UseFormRegister<T>;
  checked?: boolean;
  error?: Partial<FieldError>;
  tooltip?: string;
}

const FormControlCheckbox = <T extends FieldValues>({
  id,
  name,
  register,
  checked,
  error,
  tooltip,
  ...props
}: FormControlCheckboxProps<T> & FormControlProps) => {
  const { onChange, ...rest } = register(id);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.checked;
    onChange({ target: { value: nextValue, name: id } });
  };

  return (
    <FormControl id={id} isInvalid={Boolean(error)} mb="2" {...props}>
      <Flex align="center">
        <FormLabel mb="0" mr="1">
          {name}
        </FormLabel>
        {tooltip && (
          <Tooltip label={tooltip} fontSize="md">
            <InfoIcon />
          </Tooltip>
        )}
        <Switch
          {...rest}
          data-testid={`test-form-${id}`}
          isChecked={checked}
          onChange={handleChange}
          size="lg"
          ml="6"
        />
      </Flex>
      <FormErrorMessage data-testid="test-form-error-message">
        {!!error && error.message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormControlCheckbox;
