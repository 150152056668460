import { CertificateDate } from './CertificateDate';
import { UpdateEmployeeCertificationDates } from '~/shared/models/api/employee';
import React, { FC } from 'react';

type VerifyCertificateDatesProps = {
  adquired_date?: string;
  expiring_date?: string;
  isUpdatingDate?: boolean;
  permanent?: boolean;
  onDateChange: (
    updatedDates: Partial<UpdateEmployeeCertificationDates>,
    onSuccess: () => void
  ) => void;
};

const CertificateDates: FC<VerifyCertificateDatesProps> = ({
  adquired_date,
  expiring_date,
  isUpdatingDate,
  onDateChange,
  permanent,
}) => (
  <>
    {adquired_date && (
      <CertificateDate
        date={adquired_date}
        max={expiring_date}
        isUpdatingDate={isUpdatingDate}
        dateKey="adquired_date"
        label="issued"
        onDateChange={onDateChange}
      />
    )}
    {!permanent && (
      <CertificateDate
        date={expiring_date}
        min={adquired_date}
        isUpdatingDate={isUpdatingDate}
        dateKey="expiring_date"
        label="expired"
        onDateChange={onDateChange}
      />
    )}
  </>
);

export default CertificateDates;
