import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react';
import { initialFilters } from '~/shared/constants/search';
import useSearchFilters, {
  UseSearchFiltersType,
} from '~/shared/hooks/filters/useSearchFilters';
import { Project } from '~/shared/models/api/project';
import {
  ProjectsOrderBy,
  ProjectsSearchFilters,
} from '~/shared/models/search/filters/projects';
import { OrderDirection } from '~/shared/models/search/filters/search';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { useProjectsSearch } from '~/shared/queries/useProjectsSearch';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { UseQueryResult } from 'react-query';

export interface UseProjects {
  editModal: UseDisclosureReturn;
  handleAccordionItemClick: (project: Project) => void;
  handleEditButtonClick: (project: Project) => void;
  handleMapButtonClick: (project: Project) => void;
  onSort: (sortParam: keyof Project) => void;
  mapModal: UseDisclosureReturn;
  parentProjects: UseQueryResult<
    PaginatedResponse<Project>,
    AxiosError<unknown, unknown>
  >;
  projectClicked?: Project;
  searchFilters: UseSearchFiltersType<ProjectsSearchFilters>;
}

const useProjects = (): UseProjects => {
  const [projectClicked, setProjectClicked] = useState<Project>();
  const mapModal = useDisclosure();
  const editModal = useDisclosure();

  const searchFilters = useSearchFilters<ProjectsSearchFilters>(initialFilters);

  const parentProjects = useProjectsSearch(searchFilters.filters);

  const getOrderDirection = useCallback(
    (sortParam: keyof Project) => {
      const isSameParam = searchFilters.filters.order_by == sortParam;

      if (isSameParam && searchFilters.filters.order === OrderDirection.NONE) {
        return OrderDirection.ASCENDING;
      }

      if (
        isSameParam &&
        searchFilters.filters.order === OrderDirection.ASCENDING
      ) {
        return OrderDirection.DESCENDING;
      }

      if (
        isSameParam &&
        searchFilters.filters.order === OrderDirection.DESCENDING
      ) {
        return OrderDirection.NONE;
      }

      return OrderDirection.ASCENDING;
    },
    [searchFilters.filters.order, searchFilters.filters.order_by]
  );

  const onSort = useCallback(
    (sortParam: keyof Project) => {
      searchFilters.onFilterChange({
        order: getOrderDirection(sortParam),
        order_by: sortParam as ProjectsOrderBy,
      });
    },
    [getOrderDirection, searchFilters.onFilterChange]
  );

  const clickAction = {
    accordion: () => null,
    editButton: () => editModal.onOpen(),
    mapButton: () => mapModal.onOpen(),
  };

  const handleClick = (project: Project, action: () => void) => {
    setProjectClicked(project);
    action();
  };

  return {
    editModal,
    handleAccordionItemClick: (project) =>
      handleClick(project, clickAction.accordion),
    handleEditButtonClick: (project) =>
      handleClick(project, clickAction.editButton),
    handleMapButtonClick: (project) =>
      handleClick(project, clickAction.mapButton),
    mapModal,
    onSort,
    parentProjects,
    projectClicked,
    searchFilters,
  };
};

export default useProjects;
