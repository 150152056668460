import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { ProjectAccordionTableHeadersProps } from '~/administration/sections/Projects';
import { Project } from '~/shared/models/api/project';
import React from 'react';

interface ProjectAccordionTableItemProps {
  headers: ProjectAccordionTableHeadersProps<Project>[];
  project: Project;
  isChild?: boolean;
}

export const ProjectAccordionTableItem: React.FC<
  ProjectAccordionTableItemProps
> = ({ headers, project, isChild }) => (
  <>
    {headers
      .filter(({ childWidth }) => !isChild || childWidth)
      .map(({ width, childWidth, key, component }) => (
        <Flex
          key={`${key}-${project.id}`}
          minW={isChild && childWidth ? childWidth : width}
          w={isChild && childWidth ? childWidth : width}
        >
          {component ? (
            component(project)
          ) : (
            <Tooltip
              label={project[key as keyof Project] as string}
              rounded={8}
            >
              <Text isTruncated>{project[key as keyof Project] as string}</Text>
            </Tooltip>
          )}
        </Flex>
      ))}
  </>
);
