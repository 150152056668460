import { Box, HStack, useToast } from '@chakra-ui/react';
import { PptLayoutEnum } from '~/profile/components/export/ppt/enums';
import generatePpt from '~/profile/components/export/ppt/generatePpt';
import PptExportButton from '~/profile/components/export/ppt/PptExportButton';
import useProfileStore from '~/profile/stores/profile';
import Map from '~/shared/components/ui/map/Map';
import { useMapStore } from '~/shared/hooks/useMapStore';
import { Employee, EmployeeBasicInfo } from '~/shared/models/api/employee';
import { useGetEmployeesBasicInfo } from '~/shared/queries/useGetEmployeesBasicInfo';
import { useGetEmployeesByIds } from '~/shared/queries/useGetEmployeesByIds';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface PptExportProps {
  onClose: () => void;
}

const PptExport: React.FC<PptExportProps> = ({ onClose }) => {
  const { t } = useTranslation('profile');

  const { profile, myProfile } = useProfileStore();
  const {
    addChild,
    getChildrenIds,
    loadPotentialChildren,
    query,
    resetMapState,
    setAllowReorder,
    setShallRefetchOnSearch,
    setShallSearch,
  } = useMapStore();

  const getEmployeesBasicInfoHook = useGetEmployeesBasicInfo('');
  const getEmployeeByIdsHook = useGetEmployeesByIds(getChildrenIds());
  const toast = useToast();

  const pptToastResult = async (
    fileName: string,
    status: 'success' | 'error',
    title: string
  ) => {
    toast({
      title,
      description: t(`export.ppt.result.${status}`, { fileName }),
      status,
    });
  };

  const handleClick = async (layout: PptLayoutEnum, title: string) => {
    toast({
      title,
      description: t(`export.ppt.will_export`),
      status: 'success',
    });

    const { data } = await getEmployeeByIdsHook.refetch();

    if (data?.length) {
      generatePpt(layout, data, title, pptToastResult);
    } else {
      toast({
        title,
        description: t(`export.ppt.result.no_data`),
        status: 'error',
      });
    }

    onClose();
  };

  const sections = [
    {
      title: t(`export.ppt.layout.short.title`),
      buttons: [
        {
          imgSrc: '/ppt-icons/ki_group_short_one_per_page_layout_ppt_icon.png',
          layout: PptLayoutEnum.SHORT_KI_GROUP_ONE_PROFILE_PER_PAGE,
          text: t(`export.ppt.layout.short.one_per_page`),
        },
        {
          imgSrc: '/ppt-icons/ki_group_short_two_per_page_layout_ppt_icon.png',
          layout: PptLayoutEnum.SHORT_KI_GROUP_TWO_PROFILES_PER_PAGE,
          text: t(`export.ppt.layout.short.two_per_page`),
        },
      ],
    },
    {
      title: t(`export.ppt.layout.long.title`),
      buttons: [
        {
          imgSrc: '/ppt-icons/ki_group_long_layout_ppt_icon.png',
          layout: PptLayoutEnum.LONG_KI_GROUP,
          text: t(`export.ppt.layout.long.ki_group`),
        },
        {
          imgSrc: '/ppt-icons/ki_professional_long_layout_ppt_icon.png',
          layout: PptLayoutEnum.LONG_KI_PROFESSIONAL,
          text: t(`export.ppt.layout.long.ki_professional`),
        },
      ],
    },
  ];

  const toChild = ({ id, name }: EmployeeBasicInfo) => ({ id, text: name });
  const addProfileAsChild = (profile: Employee | null) => {
    if (profile) {
      const { id, first_name, second_name } = profile;

      addChild({ id, text: `${first_name} ${second_name}` });
    }
  };

  useEffect(() => {
    loadPotentialChildren(getEmployeesBasicInfoHook.data?.map(toChild) || []);
  }, [getEmployeesBasicInfoHook.data]);

  useEffect(() => {
    resetMapState();
    setAllowReorder(true);
    setShallSearch(true);
    setShallRefetchOnSearch(false);
    getEmployeesBasicInfoHook.refetch();

    addProfileAsChild(profile ? profile : myProfile);
  }, []);

  useEffect(() => {
    getEmployeesBasicInfoHook.refetch();
    loadPotentialChildren(getEmployeesBasicInfoHook.data?.map(toChild) || []);
  }, [query]);

  return (
    <Box>
      <Map
        childrenTitle={t(`map_modal.children_title`)}
        isLoadingPotentialChildren={getEmployeesBasicInfoHook.isLoading}
        potentialChildrenTitle={t(`map_modal.potential_children_title`)}
      />

      <HStack
        gap={2}
        ml={2}
        mt={4}
        w="full"
        bg="gray.50"
        p={2}
        rounded={8}
        shadow="sm"
      >
        {sections.map((section, sectionIndex) => (
          <Box
            bg="white"
            key={`ppt-export-section-${sectionIndex}`}
            p={2}
            rounded={8}
            shadow="sm"
            w="full"
          >
            {t(section.title)}
            <HStack gap={2} mt={2} w="full">
              {section.buttons.map(({ layout, text, imgSrc }, buttonIndex) => (
                <PptExportButton
                  imgSrc={imgSrc}
                  key={`ppt-export-section-${sectionIndex}-button-${buttonIndex}`}
                  onClick={() => handleClick(layout, text)}
                  text={text}
                />
              ))}
            </HStack>
          </Box>
        ))}
      </HStack>
    </Box>
  );
};

export default PptExport;
