import fetcher, { AxiosResponse } from '~/shared/api/fetcher';
import { SearchFilters } from '~/shared/models/search/filters/search';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { AxiosRequestConfig } from 'axios';

const BASE_URL = '/api/';

type QueryResponse<T> = AxiosResponse<T[]>;

export const get = <T>(
  endpoint: string,
  params?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => fetcher.get(BASE_URL + endpoint, params);

export const search = <T>(
  endpoint: string,
  filters: SearchFilters
): Promise<QueryResponse<T>> =>
  fetcher.get(`${BASE_URL}${endpoint}`, {
    params: { ...filters },
  });

// merge searchPaginated with search after all endpoints are using paginated response
export const searchPaginated = <T, F = unknown>(
  endpoint: string,
  filters: F
): Promise<AxiosResponse<PaginatedResponse<T>>> =>
  fetcher.get(`${BASE_URL}${endpoint}`, {
    params: { ...filters },
  });

export const patch = <T, K = T>(
  endpoint: string,
  data?: Partial<K>
): Promise<AxiosResponse<T>> => fetcher.patch(BASE_URL + endpoint, data);

export const patchFormData = (
  endpoint: string,
  formData: FormData,
  headers = { headers: { 'Content-Type': 'multipart/form-data' } }
) => fetcher.patch(BASE_URL + endpoint, formData, headers);

export const post = <T>(endpoint: string, params: T) =>
  fetcher.post(BASE_URL + endpoint, params);

export const postFormData = (
  endpoint: string,
  formData: FormData,
  headers = { headers: { 'Content-Type': 'multipart/form-data' } }
) => fetcher.post(BASE_URL + endpoint, formData, headers);

export const putFormData = (
  endpoint: string,
  formData: FormData,
  headers = { headers: { 'Content-Type': 'multipart/form-data' } }
) => fetcher.put(BASE_URL + endpoint, formData, headers);
export const deleteData = <T>(endpoint: string): Promise<AxiosResponse<T>> =>
  fetcher.delete(BASE_URL + endpoint);
