import AlertInfo from '../AlertInfo';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Spacer, Text, Tooltip } from '@chakra-ui/react';
import LoadingData from '~/shared/components/ui/LoadingData';
import MissingData from '~/shared/components/ui/MissingData';
import { ChildStatus, ChildStatusColor } from '~/shared/enums/childStatusColor';
import { useMapStore } from '~/shared/hooks/useMapStore';
import { ChildRecord } from '~/shared/interfaces/map';
import { wasAdded, wasDeleted } from '~/shared/utils/map';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BiCheck,
  BiMinus,
  BiPlus,
  BiSolidChevronDownSquare,
  BiSolidChevronUpSquare,
} from 'react-icons/bi';

export interface ChildrenListProps {
  data: ChildRecord[];
  failLoading?: boolean;
  isLoading?: boolean;
  onDelete?: (index: number) => void;
  onUndoDeletion?: (index: number) => void;
}

const ChildrenList: React.FC<ChildrenListProps> = ({
  data = [],
  failLoading,
  isLoading,
  onDelete,
  onUndoDeletion,
}) => {
  const { t } = useTranslation('base');
  const { allowReorder, moveChildDown, moveChildUp } = useMapStore();

  const getIcon = useCallback(
    (status: ChildStatus) => {
      if (onDelete) {
        return wasDeleted(status) ? <AddIcon /> : <DeleteIcon />;
      }

      if (wasAdded(status)) {
        return <BiPlus />;
      }

      return wasDeleted(status) ? <BiMinus /> : <BiCheck />;
    },
    [wasAdded, wasDeleted]
  );

  const getIconColor = useCallback(
    (status: ChildStatus) => {
      if (onDelete && !wasDeleted(status)) {
        return 'red.400';
      }

      if (wasAdded(status)) {
        return 'blue.600';
      }

      return 'gray.500';
    },
    [wasAdded, wasDeleted, onDelete]
  );

  const getOnClickFn = useCallback(
    (status: ChildStatus, index: number) => () => {
      if (wasDeleted(status) && onUndoDeletion) {
        onUndoDeletion(index);
      } else if (onDelete) {
        onDelete(index);
      }
    },
    [wasDeleted]
  );

  if (failLoading) {
    return <AlertInfo text={t(`fail_loading_children`)} />;
  }

  if (isLoading || !data) {
    return <LoadingData mt="5vh" />;
  }

  if (!data.length) {
    return <MissingData text={t(`map_modal.no_children`)} />;
  }

  return (
    <>
      {data.map(({ id, text: title, status }, index) => (
        <Tooltip key={`map-children-${id}`} rounded={8} label={title}>
          <Flex
            alignItems="center"
            bg={ChildStatusColor[status]}
            my={2}
            p={1}
            rounded={8}
            shadow="sm"
            w="full"
          >
            <IconButton
              aria-label={title}
              color={getIconColor(status)}
              fontSize="sm"
              icon={getIcon(status)}
              variant="link"
              onClick={getOnClickFn(status, index)}
            />
            <Text
              fontSize="xs"
              isTruncated
              style={{
                textDecoration: wasDeleted(status) ? 'line-through' : 'none',
              }}
            >
              {title}
            </Text>

            <Spacer />
            {allowReorder && (
              <Flex>
                <IconButton
                  aria-label="up"
                  alignContent="center"
                  bg="green.50"
                  color={index === 0 ? 'green.100' : 'green.300'}
                  disabled={index === 0}
                  fontSize="xl"
                  icon={<BiSolidChevronUpSquare />}
                  h="24px"
                  mr={1}
                  onClick={() => moveChildUp(index)}
                  rounded={8}
                  variant="solid"
                />
                <IconButton
                  aria-label="down"
                  alignContent="center"
                  bg="green.50"
                  color={index === data.length - 1 ? 'green.100' : 'green.300'}
                  disabled={index === data.length - 1}
                  fontSize="xl"
                  icon={<BiSolidChevronDownSquare />}
                  h="24px"
                  onClick={() => moveChildDown(index)}
                  rounded={8}
                  variant="solid"
                />
              </Flex>
            )}
          </Flex>
        </Tooltip>
      ))}
    </>
  );
};

export default ChildrenList;
