import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import AlertInfo from '~/shared/components/ui/AlertInfo';
import { EmployeeIndustry } from '~/shared/models/api/employee';
import React from 'react';
import { useTranslation } from 'react-i18next';

const IndustriesList: React.FC<{
  industries: EmployeeIndustry[];
  onRemove: (index: number) => void;
}> = ({ industries, onRemove }) => {
  const { t } = useTranslation(['base', 'profile']);

  return (
    <Box>
      <Text fontSize="md" color="gray.600" fontWeight="bold" mb="2">
        Current Industries List
      </Text>
      {!industries.length ? (
        <AlertInfo text={t(`profile:industry.please_add`)} />
      ) : (
        <Flex
          data-testid="industries-list"
          gridGap="2"
          border="2px"
          px="2"
          py="3"
          borderColor="blue.300"
          rounded="base"
          wrap="wrap"
        >
          {industries.map((industry: EmployeeIndustry, index: number) => (
            <Flex
              data-testid={`industries-list-${industry.industry_id}`}
              key={industry.industry_id}
              align="center"
              px="2"
              py="1"
              color="gray.600"
              bg="blue.100"
              borderRadius="base"
              justifyContent="space-between"
              alignItems="center"
            >
              {industry.industry?.name}
              <Tooltip
                label={t(`base:remove_item`, { item: industry.industry?.name })}
              >
                <IconButton
                  aria-label={t(`base:remove_item_button`, {
                    item: industry.industry?.name,
                  })}
                  data-testid="industries-list-remove-button"
                  icon={<CloseIcon h="2.5" />}
                  color="blue.600"
                  variant="link"
                  onClick={() => onRemove(index)}
                  sx={{
                    ':hover': {
                      color: 'blue.900',
                    },
                  }}
                />
              </Tooltip>
            </Flex>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default IndustriesList;
