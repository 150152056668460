import { adminRoutes } from './models/admin';
import { mainRoutes } from './models/main';
import { FC } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

const AppRoutes: FC = () => {
  const routes: RouteObject[] = [...mainRoutes, ...adminRoutes];

  return useRoutes(routes);
};

export default AppRoutes;
