import { Badge, Divider, Flex, Text } from '@chakra-ui/react';
import { GridViewModes } from '~/shared/enums/gridViewModes';
import React, { FC } from 'react';

type SkillsGridItemHeaderProps = {
  title: string;
  mode: GridViewModes;
};

const SkillsGridItemHeader: FC<SkillsGridItemHeaderProps> = ({
  title = 'Undefined',
  mode,
}) => {
  return (
    <Flex mb={mode === GridViewModes.HORIZONTAL ? 1 : 3} alignItems="center">
      <Badge
        fontSize={mode === GridViewModes.HORIZONTAL ? 'md' : 'sm'}
        margin={mode === GridViewModes.HORIZONTAL ? '0' : 'auto'}
        bg={mode === GridViewModes.HORIZONTAL ? 'white' : 'blue.100'}
        px={2}
        letterSpacing={1}
      >
        <Text fontWeight="bold" whiteSpace="nowrap">
          {title}
        </Text>
      </Badge>
      {mode === GridViewModes.HORIZONTAL && (
        <Divider
          orientation="horizontal"
          variant="solid"
          opacity={1}
          borderColor="gray.800"
          borderWidth={1}
          ml={5}
        />
      )}
    </Flex>
  );
};

export default SkillsGridItemHeader;
