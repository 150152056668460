import useProfileStore from '~/profile/stores/profile';
import { Employee } from '~/shared/models/api/employee';
import { useGetEmployeeById } from '~/shared/queries/useGetEmployeeById';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface UseProfile {
  profile: Employee | null;
  isLoading: boolean;
}

const useProfile = (): UseProfile => {
  const { setProfile, profile, myProfile, setIsSelf } = useProfileStore();
  const { userId: id } = useParams();
  const userIdParams = Number(id);
  const { data: employee, isLoading } = useGetEmployeeById(userIdParams);

  useEffect(() => {
    if (employee) {
      setIsSelf(false);
      setProfile(employee);
    }
  }, [employee]);

  useEffect(() => {
    setProfile(null);
  }, [id]);

  useEffect(() => {
    if (!profile && (!id || myProfile?.id === userIdParams)) {
      setProfile(myProfile);
      setIsSelf(true);
    }
  }, [id, profile, myProfile]);

  return { profile, isLoading: !profile || isLoading };
};

export default useProfile;
