export enum SearchFilterTab {
  SKILLS = 'skills',
  CERTIFICATES = 'certificates',
  SENIORITIES = 'seniorities',
  POSITIONS = 'positions',
  COMPANIES = 'companies',
  LANGUAGES = 'languages',
  INDUSTRIES = 'industries',
  LOCATIONS = 'locations',
  // PROJECTS = 'projects',
  // CUSTOMERS = 'customers', // TODO: uncomment when implemented
}
