import { TableAction, TableRowData } from './types';
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';

export interface TableActionsProps {
  actions: TableAction[];
  selected: TableRowData['id'][];
}

export const ActionsButton = ({ actions, selected }: TableActionsProps) => {
  const [showActions, setShowActions] = useBoolean();

  const visibleButtons = useMemo(
    () =>
      actions.filter(
        (action) => !action.isVisible || action.isVisible(selected)
      ),
    [actions, selected.join('')]
  );

  if (visibleButtons.length === 1) {
    return (
      <>
        <Button
          size="sm"
          variant="outline"
          onClick={() => {
            visibleButtons[0].action(selected);
            setShowActions.off();
          }}
          key={`table-action-${visibleButtons[0].label}`}
          gap="1"
        >
          {visibleButtons[0].icon ?? null}
          {visibleButtons[0].label}
        </Button>
      </>
    );
  }

  return (
    <Popover
      isOpen={showActions}
      onOpen={setShowActions.on}
      onClose={setShowActions.off}
      size="xs"
    >
      <PopoverTrigger>
        <Button variant="outline" size="sm" isDisabled={!visibleButtons.length}>
          Actions
        </Button>
      </PopoverTrigger>
      <PopoverContent w="max-content">
        <PopoverBody>
          <VStack spacing="0">
            {visibleButtons.map((action) => (
              <Button
                size="sm"
                variant="outline"
                onClick={() => {
                  action.action(selected);
                  setShowActions.off();
                }}
                key={`table-action-${action.label}`}
                gap="1"
              >
                {action.icon ?? null}
                {action.label}
              </Button>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
