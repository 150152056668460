import { initialFilters } from '~/shared/constants/search';
import { Skill, SkillType } from '~/shared/models/api/skills';
import { SearchFilters } from '~/shared/models/search/filters/search';
import { useSkillsSearch } from '~/shared/queries/skills/useSkillsSearch';
import { useSkillsTypes } from '~/shared/queries/skills/useSkillsTypes';
import { AxiosError } from 'axios';
import { useState } from 'react';

interface UseSkillsAndTypesType {
  skills?: Skill[];
  skillTypes?: SkillType[];
  isLoading: boolean;
  error: AxiosError | null;
  handleFiltersChange: (updatedFilters: SearchFilters) => void;
  handleQueryChange: (query: string) => void;
}

const useSkillsAndTypesSearch = (): UseSkillsAndTypesType => {
  const [filters, setFilters] = useState<SearchFilters>({
    ...initialFilters,
    limit: 10000,
  });
  const {
    isLoading: isLoadingSkillTypes,
    data: skillTypes,
    error: skillTypesError,
  } = useSkillsTypes();
  const {
    isFetching: isFetchingSkills,
    data: skills,
    error: skillsError,
  } = useSkillsSearch(filters);

  const handleFiltersChange = (updatedFilters: SearchFilters) => {
    setFilters({
      ...updatedFilters,
      skip: 0,
    });
  };

  const handleQueryChange = (query: string) => {
    handleFiltersChange({
      ...filters,
      query,
    });
  };

  return {
    skills,
    skillTypes,
    isLoading: isLoadingSkillTypes || isFetchingSkills,
    error: skillTypesError || skillsError,
    handleFiltersChange,
    handleQueryChange,
  };
};

export default useSkillsAndTypesSearch;
