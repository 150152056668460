import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';
import { DatePickerFormat } from '~/shared/enums/date';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { UseFormRegisterReturn } from 'react-hook-form';

interface DatePickerProps {
  selectedDate: Date | null;
  maxDate?: Date | null;
  minDate?: Date | null;
  onChange: (date: Date) => void;
  isDisabled?: boolean;
  isClearable?: boolean;
  id?: string;
  innerRef?: UseFormRegisterReturn['ref'];
  placeholder?: string;
  dateFormat: DatePickerFormat;
}

const DatePicker: React.FC<DatePickerProps> = ({
  selectedDate,
  isDisabled,
  innerRef,
  placeholder,
  dateFormat,
  ...rest
}) => (
  <ReactDatePicker
    selected={isDisabled ? null : selectedDate}
    showPopperArrow={false}
    dateFormat={dateFormat}
    showMonthYearPicker={dateFormat === DatePickerFormat.YEAR_MONTH}
    showYearPicker={dateFormat === DatePickerFormat.YEAR}
    disabled={isDisabled}
    placeholderText={placeholder || 'YYYY/MM'}
    ref={innerRef}
    {...rest}
  />
);

export default DatePicker;
