import {
  deleteData,
  patchFormData,
  postFormData,
  searchPaginated,
} from '~/shared/api/endpoints';
import { certificatesEndpoint } from '~/shared/api/urls/certificates';
import { FormDataKey } from '~/shared/enums/formDataKey';
import {
  Certificate,
  CreateCertificate,
  UpdateCertificate,
} from '~/shared/models/api/certificate';
import { CertificatesSearchFilters } from '~/shared/models/search/filters/certificates';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { SearchProps } from '~/shared/models/search/search';
import { getSearchFiltersByParamsType } from '~/shared/utils/search';
import debounce from 'debounce-promise';

export const createCertificate = async ({
  newLogo,
  ...newCertificate
}: CreateCertificate): Promise<Certificate> => {
  const formData = new FormData();

  formData.append(FormDataKey.CERTIFICATE_IN, JSON.stringify(newCertificate));

  if (newLogo) {
    formData.append(FormDataKey.FILE, newLogo);
  }

  const { data } = await postFormData(certificatesEndpoint.url, formData);

  return data;
};

export const updateCertificate = async ({
  id,
  newLogo,
  ...certificate
}: UpdateCertificate): Promise<Certificate> => {
  const formData = new FormData();
  formData.append(FormDataKey.CERTIFICATE_IN, JSON.stringify(certificate));

  if (newLogo) {
    formData.append(FormDataKey.FILE, newLogo);
  }

  const { data } = await patchFormData(
    certificatesEndpoint.id(id).url,
    formData
  );

  return data;
};

export const deleteCertificate = async (id: number): Promise<Certificate> => {
  const { data } = await deleteData<Certificate>(
    certificatesEndpoint.id(id).url
  );

  return data;
};

export const searchCertificates = async (
  value: SearchProps | CertificatesSearchFilters
): Promise<PaginatedResponse<Certificate>> => {
  const searchFilters = getSearchFiltersByParamsType(value);

  const { data } = await searchPaginated<Certificate>(
    certificatesEndpoint.url,
    searchFilters
  );

  return data;
};

export const searchCertificatesDebounce = debounce(searchCertificates, 500);
