import useEmployeeRefresh from './useEmployeeRefresh';
import { useToast } from '@chakra-ui/react';
import { updateEmployeeCertification as updateCertification } from '~/shared/api/request/certifications';
import { getErrorMessage } from '~/shared/errors/error';
import {
  EmployeeCertification,
  UpdateEmployeeCertification,
  UpdateEmployeeCertificationDates,
  UpdateEmployeeCertificationStatus,
  UploadEmployeeCertificationFile,
} from '~/shared/models/api/employee';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

interface UseEmployeeCertificationsType {
  updateEmployeeCertification: UseMutationResult<
    EmployeeCertification,
    unknown,
    UpdateEmployeeCertification,
    unknown
  >;
  updateEmployeeCertificationStatus: UseMutationResult<
    EmployeeCertification,
    unknown,
    UpdateEmployeeCertificationStatus,
    unknown
  >;
  updateEmployeeCertificationDates: UseMutationResult<
    EmployeeCertification,
    unknown,
    UpdateEmployeeCertificationDates,
    unknown
  >;
  uploadEmployeeCertificationFile: UseMutationResult<
    EmployeeCertification,
    unknown,
    UploadEmployeeCertificationFile,
    unknown
  >;
}

const useEmployeeCertifications = (): UseEmployeeCertificationsType => {
  const toast = useToast();
  const { t } = useTranslation('permissions');
  const { onCertificateUpdated } = useEmployeeRefresh();

  const updateEmployeeCertification = useMutation(
    (certificationUpdate: UpdateEmployeeCertification) =>
      updateCertification(certificationUpdate),
    {
      onSuccess: (updated: EmployeeCertification) => {
        onCertificateUpdated(updated.certificate_id);
        toast({
          title: updated.certificate.name,
          description: 'Employee Certification Updated Successfully',
          status: 'success',
        });
      },
      onError: (err: AxiosError, update: UpdateEmployeeCertification) => {
        toast({
          title:
            update.certificate_name || 'Error Updating Employee Certification',
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Updating Employee Certification',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  const updateEmployeeCertificationStatus = useMutation(
    (certification: UpdateEmployeeCertificationStatus) =>
      updateCertification(certification),
    {
      onSuccess: (updated: EmployeeCertification) => {
        onCertificateUpdated(updated.certificate_id);
        toast({
          title: updated.certificate.name,
          description: `Employee Certification Status Updated to ${updated.status} Successfully`,
          status: 'success',
        });
      },
      onError: (
        err: AxiosError,
        certification: UpdateEmployeeCertificationStatus
      ) => {
        toast({
          title: certification.certificate_name,
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Updating Employee Certification Status',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  const updateEmployeeCertificationDates = useMutation(
    (certification: UpdateEmployeeCertificationDates) =>
      updateCertification(certification),
    {
      onSuccess: (updated: EmployeeCertification) => {
        onCertificateUpdated(updated.certificate_id);
        toast({
          title: updated.certificate.name,
          description: 'Employee Certification Date Updated Successfully',
          status: 'success',
        });
      },
      onError: (
        err: AxiosError,
        certification: UpdateEmployeeCertificationDates
      ) => {
        toast({
          title: certification.certificate_name,
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Updating Employee Certification Date',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  const uploadEmployeeCertificationFile = useMutation(
    (upload: UploadEmployeeCertificationFile) => updateCertification(upload),
    {
      onSuccess: (updated: EmployeeCertification) => {
        onCertificateUpdated(updated.certificate_id);
        toast({
          title: updated.certificate.name,
          description: 'Certification File Uploaded Successfully',
          status: 'success',
        });
      },
      onError: (err: AxiosError, uploaded: UploadEmployeeCertificationFile) => {
        toast({
          title: uploaded.certificate_name,
          description: getErrorMessage(
            err.response?.status,
            'Something Went Uploading Employee Certification File',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return {
    updateEmployeeCertification,
    updateEmployeeCertificationStatus,
    updateEmployeeCertificationDates,
    uploadEmployeeCertificationFile,
  };
};

export default useEmployeeCertifications;
