export enum Status {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type StatusColor = Record<Status, string>;

export const statusColorScheme: StatusColor = {
  [Status.PENDING]: 'orange',
  [Status.APPROVED]: 'green',
  [Status.REJECTED]: 'red',
};

export const statusColorBackground: StatusColor = {
  [Status.PENDING]: 'orange.300',
  [Status.APPROVED]: 'green.400',
  [Status.REJECTED]: 'red.400',
};
