import { useDisclosure, UseDisclosureReturn, useToast } from '@chakra-ui/react';
import { useSaveProjectChildren } from '~/shared/hooks/project/useSaveProjectChildren';
import { useMapStore } from '~/shared/hooks/useMapStore';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface UseProjectMapModal {
  confirmationModal: UseDisclosureReturn;
  isSaving: boolean;
  handleCancelConfirmation: () => void;
  handleClose: () => void;
  handleConfirmationSave: () => Promise<void>;
  handleSave: () => Promise<void>;
}

interface UseProjectMapModalProps {
  projectId?: number;
  onClose: () => void;
}

const useProjectMapModal = ({
  projectId,
  onClose,
}: UseProjectMapModalProps): UseProjectMapModal => {
  const { getChildrenIds, resetMapState, wasChanged } = useMapStore();

  const confirmationModal = useDisclosure();
  const { t } = useTranslation('projects');

  const { saveProjectChildren } = useSaveProjectChildren();
  const toast = useToast();

  const closeModals = useCallback(() => {
    if (confirmationModal.isOpen) {
      confirmationModal.onClose();
    }

    onClose();
  }, [confirmationModal]);

  const handleClose = () => {
    if (wasChanged) {
      confirmationModal.onOpen();
    } else {
      closeModals();
    }
  };

  const handleCancelConfirmation = () => {
    closeModals();
  };

  const handleSave = async () => {
    if (!projectId) {
      toast({
        title: t(`something_went_wrong`),
        description: t(`parent_id_missing`),
        status: 'error',
      });

      return;
    }

    const childrenIds = getChildrenIds();

    await saveProjectChildren.mutateAsync({ projectId, childrenIds });

    closeModals();
  };

  const handleConfirmationSave = async () => {
    await handleSave();

    closeModals();
  };

  useEffect(() => {
    resetMapState();
  }, []);

  return {
    confirmationModal,
    isSaving: saveProjectChildren.isLoading,
    handleCancelConfirmation,
    handleClose,
    handleConfirmationSave,
    handleSave,
  };
};

export default useProjectMapModal;
