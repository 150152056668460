import useTranslateOptions from '~/shared/hooks/react-select/useTranslateOptions';
import { OptionWithTranslation } from '~/shared/models/react-select/option-with-translation';
import React, { CSSProperties, useEffect, useState } from 'react';
import Select from 'react-select';

interface SelectWithTranslationsProps<T extends string> {
  id?: string;
  value?: T;
  options: OptionWithTranslation<T>[];
  placeholder?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  onChange: (value: T) => void;
}

const SelectWithTranslations = <T extends string>({
  id = 'status-select',
  value,
  options,
  placeholder,
  isClearable = true,
  isDisabled,
  onChange,
  ...customControlStyles
}: SelectWithTranslationsProps<T> & CSSProperties) => {
  const [selected, setSelected] = useState<OptionWithTranslation<T> | null>(
    null
  );
  const { options: defaultOptions } = useTranslateOptions(options);

  useEffect(() => {
    if (!defaultOptions) {
      return;
    }
    const option = defaultOptions.find((op) => op.value === value);
    setSelected(option || null);
  }, [value, defaultOptions]);

  return (
    <Select
      id={id}
      options={defaultOptions}
      placeholder={placeholder}
      value={selected}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isSearchable={false}
      styles={{
        control: (base) => ({
          ...base,
          ...customControlStyles,
        }),
        container: (base) => ({
          ...base,
          flex: 1,
        }),
      }}
      onChange={(option) => {
        setSelected(option);
        onChange(option?.value as T);
      }}
    />
  );
};

export default SelectWithTranslations;
