import useSkillUpdate from '~/shared/hooks/skills/useSkillUpdate';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { MergeSkill, Skill, SkillType } from '~/shared/models/api/skills';
import { mapSkillToUpdate } from '~/shared/utils/mappers/skills';
import { useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

interface UseSkillsUpdatesOnDragAndDropType {
  skills: Skill[];
  skillTypes: SkillType[];
  highlightedSkillsIds: number[];
  skillPendingUpdate?: Skill;

  onDragEnd: (result: DropResult) => void;
  onSkillUpdated: (updatedSkill?: Skill) => void;
  onSkillMerged: (mergedSkill?: MergeSkill) => void;
  onSkillDeleted: (skill?: Partial<Skill>) => void;
  isSkillHighlighted: (skill: Skill) => boolean;
}

const useSkillsUpdatesOnDragAndDrop = (
  skillsData?: Skill[],
  skillTypesData?: SkillType[]
): UseSkillsUpdatesOnDragAndDropType => {
  const { startLoading, stopLoading } = useLoadingContext();
  const [skills, setSkills] = useState<Skill[]>([]);
  const [skillTypes, setSkillTypes] = useState<SkillType[]>([]);
  // skillPendingUpdate has two purposes:
  //  - updating skills state until success api call
  //  - keep a visual preview on the component while making the api call
  const [skillPendingUpdate, setSkillPendingUpdate] = useState<Skill>();
  const [highlightedSkillsIds, setHighlightedSkillsIds] = useState<number[]>(
    []
  );
  const {
    updateSkillType: { mutateAsync: updateSkillType },
  } = useSkillUpdate();

  useEffect(() => {
    setSkills(skillsData || []);
  }, [skillsData]);

  useEffect(() => {
    setSkillTypes(skillTypesData || []);
  }, [skillTypesData]);

  const onDragEnd = (result: DropResult) => {
    // Do nothing if dropped on same box
    if (
      !result.destination ||
      result.source.droppableId === result.destination.droppableId
    ) {
      return;
    }
    onSkillDrop(
      parseInt(result.draggableId, 10),
      result.destination.droppableId
    );
  };

  const onSkillDrop = (skillId: number, typeId: string) => {
    const skill = getSkillById(skillId);
    const type = getSkillTypeById(typeId);
    if (!skill || !type) {
      return;
    }
    updateSkillNewType(skill, type);
  };

  const updateSkillNewType = (skill: Skill, type: SkillType): void => {
    startLoading();
    const selectedSkill: Skill = {
      ...skill,
      type: type.name,
    };
    const selectedSkillToUpdate = mapSkillToUpdate(selectedSkill);
    setSkillPendingUpdate(selectedSkill);
    updateSkillType(selectedSkillToUpdate, {
      onSuccess: (updatedSkill: Skill) =>
        handleSkillUpdated(updatedSkill, skills),
      onSettled: () => {
        setSkillPendingUpdate(undefined);
        stopLoading();
      },
    });
  };

  const onSkillUpdated = (updatedSkill?: Skill) => {
    if (!updatedSkill) {
      return;
    }
    const newState = skills.map((skill: Skill) => {
      if (updatedSkill.id === skill.id) {
        return { ...updatedSkill };
      }
      return skill;
    });
    handleSkillUpdated(updatedSkill, newState);
  };

  const onSkillMerged = (merged?: MergeSkill) => {
    if (!merged || !merged.merge_with_id) {
      return;
    }

    const { newState, skill } = skills.reduce<{
      newState: Skill[];
      skill: Skill | null;
    }>(
      (acc, skill) => {
        if (skill.id === merged.merge_with_id) {
          acc.skill = skill;
        }

        if (skill.id !== merged.id) {
          acc.newState.push(skill);
        }

        return acc;
      },
      { newState: [], skill: null }
    );
    if (!skill) {
      setSkills(newState);
      return;
    }
    handleSkillUpdated(skill, newState);
  };

  const onSkillDeleted = (skill?: Partial<Skill>) => {
    if (!skill) {
      return;
    }
    const filtered = skills.filter((sk: Skill) => sk.id !== skill.id);
    setSkills(filtered);
  };

  const handleSkillUpdated = (updatedSkill: Skill, allSkills: Skill[]) => {
    moveSkillToTop(updatedSkill, allSkills);

    if (isSkillHighlighted(updatedSkill)) {
      return;
    }
    setHighlightedSkillsIds([...highlightedSkillsIds, updatedSkill.id]);
  };

  // will remove the skill from the array and adds to the beginning
  // to be highlighted as newly updated
  const moveSkillToTop = (skill: Skill, allSkills: Skill[]) => {
    const filtered = allSkills.filter((sk: Skill) => sk.id !== skill.id);
    setSkills([skill, ...filtered]);
  };

  const isSkillHighlighted = (skill: Skill): boolean => {
    return highlightedSkillsIds.some((skillId: number) => skillId === skill.id);
  };

  const getSkillById = (skillId: number): Skill | undefined => {
    return skills.find((skill: Skill) => skill.id === skillId);
  };

  const getSkillTypeById = (typeId: string): SkillType | undefined => {
    return skillTypes.find((type: SkillType) => type.id === typeId);
  };

  return {
    skills,
    skillTypes,
    highlightedSkillsIds,
    skillPendingUpdate,
    onDragEnd,
    onSkillUpdated,
    onSkillMerged,
    isSkillHighlighted,
    onSkillDeleted,
  };
};

export default useSkillsUpdatesOnDragAndDrop;
