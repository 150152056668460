import {
  deleteData,
  get,
  patch,
  search,
  searchPaginated,
} from '~/shared/api/endpoints';
import { projectPositionsEndpoint } from '~/shared/api/urls/projectPositions';
import { projectsEndpoint } from '~/shared/api/urls/projects';
import {
  ProjectChildrenProps,
  ProjectParentProps,
} from '~/shared/interfaces/props';
import {
  Project,
  ProjectPositions,
  ProjectUpdatePayload,
  UpdateProjectChildren,
} from '~/shared/models/api/project';
import { ProjectsSearchFilters } from '~/shared/models/search/filters/projects';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { SearchProps } from '~/shared/models/search/search';
import { MIN_CHARACTERS_TO_SEARCH } from '~/shared/utils/constants';
import { getSearchFiltersByParamsType } from '~/shared/utils/search';
import { format } from 'date-fns';
import debounce from 'debounce-promise';

export const updateProject = async ({
  id,
  ...payload
}: ProjectUpdatePayload): Promise<Project> => {
  const { data } = await patch<Project>(projectsEndpoint.id(id).url, payload);

  return data;
};

export const searchProjects = async (
  value: SearchProps | ProjectsSearchFilters
): Promise<PaginatedResponse<Project>> => {
  const searchFilters = getSearchFiltersByParamsType(value);
  const formattedSearchFilters = {
    ...searchFilters,
    industry_id: searchFilters.industry?.id,
    end_date: searchFilters.end_date
      ? format(searchFilters.end_date, 'yyyy-MM-dd')
      : undefined,
    start_date: searchFilters.start_date
      ? format(searchFilters.start_date, 'yyyy-MM-dd')
      : undefined,
  };
  const { data } = await searchPaginated<Project>(
    projectsEndpoint.url,
    formattedSearchFilters
  );

  return data;
};
export const searchProjectsDebounce = debounce(searchProjects, 500);

export const getProjectChildren = async (
  projectId?: number
): Promise<Project[]> => {
  if (!projectId) {
    return [];
  }

  const { data } = await get<Project[]>(
    projectsEndpoint.id(projectId).children.url
  );

  return data;
};

export const getProjectPotentialChildren = debounce(
  async (projectId?: number, query = ''): Promise<Project[]> => {
    if (!projectId || query.length < MIN_CHARACTERS_TO_SEARCH) {
      return [];
    }

    const { data } = await search<Project>(
      projectsEndpoint.id(projectId).potentialChildren.url,
      { query }
    );

    return data;
  },
  500
);

export const getProjectsPositions = async (): Promise<ProjectPositions> => {
  const { data } = await get<ProjectPositions>(projectPositionsEndpoint.url);

  return data;
};

export const setProjectParent = async ({
  projectId,
  parentProjectId: parentId,
}: ProjectParentProps): Promise<Project> => {
  const { data } = await patch<Project>(
    projectsEndpoint.id(projectId).parent.id(parentId).url
  );

  return data;
};

export const saveProjectChildren = async ({
  projectId,
  childrenIds,
}: ProjectChildrenProps): Promise<Project> => {
  const { data } = await patch<Project, number[]>(
    projectsEndpoint.id(projectId).children.url,
    childrenIds
  );

  return data;
};

export const removeProjectParent = async (
  projectId: number
): Promise<Project> => {
  const { data } = await deleteData<Project>(
    projectsEndpoint.id(projectId).parent.url
  );

  return data;
};

export const updateProjectChildren = async ({
  projectId,
  projectChildrenIds,
}: UpdateProjectChildren): Promise<Project> => {
  const { data } = await patch<
    Project,
    UpdateProjectChildren['projectChildrenIds']
  >(projectsEndpoint.id(projectId).children.url, projectChildrenIds);

  return data;
};
