import { Icon, Stack, Text, Wrap } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

export const ProfileBio = ({ profileBio }: { profileBio?: string }) => {
  const { t } = useTranslation('profile');

  return profileBio ? (
    <Wrap
      spacing={2}
      mb="auto"
      border="1px"
      borderColor="gray.100"
      borderRadius="4px"
      shadow="xs"
      p="1"
    >
      <Stack
        direction="column"
        align="center"
        color="gray.600"
        bg="gray.50"
        p="5"
      >
        <Icon as={FaQuoteLeft} aria-label={t(`quote`)} />
        <Text
          fontSize="md"
          fontWeight="thin"
          fontStyle="italic"
          lineHeight="20px"
          align="center"
        >
          {profileBio}
        </Text>
        <Icon as={FaQuoteRight} aria-label={t(`quote`)} />
      </Stack>
    </Wrap>
  ) : null;
};
