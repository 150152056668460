import {
  Badge,
  Button,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import LanguagesEdit from '~/profile/components/about/languages/LanguagesEdit';
import SocialLinksEdit from '~/profile/components/about/social-links/SocialLinksEdit';
import useProfileStore from '~/profile/stores/profile';
import FormControlDatePicker from '~/shared/components/form/FormControlDatePicker';
import FormControlText from '~/shared/components/form/FormControlText';
import { DatePickerFormat } from '~/shared/enums/date';
import { TextAreaResizeProperties } from '~/shared/enums/form';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { Employee } from '~/shared/models/api/employee';
import { checkUnsavedChanges } from '~/shared/utils/verifications';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface EditModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const EditModal: React.FC<EditModalProps> = ({ isOpen, onClose }) => {
  const [isPasting, setIsPasting] = useState(false);

  const toast = useToast();
  const { updatePersonalData, profile } = useProfileStore();
  const { openConfirmation } = useConfirmationModalContext();
  const { t } = useTranslation('profile');
  const methods = useForm({
    defaultValues: profile || undefined,
  });
  const { startLoading, stopLoading } = useLoadingContext();
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    reset,
    getValues,
    setValue,
  } = methods;

  const fieldsChanged = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    reset();
  }, []);

  const profileQuote = watch('bio');

  useEffect(() => {
    if (!isPasting) return;

    let finalValue = profileQuote;
    if (!finalValue || !finalValue.startsWith('"')) return;

    finalValue = finalValue.replaceAll('"', '');

    setValue('bio', finalValue);

    setIsPasting(false);
  }, [isPasting, profileQuote, setValue]);

  const onSubmit = async (data: Employee) => {
    startLoading();
    const updateSuccess = await updatePersonalData(data);

    stopLoading();
    toast({
      title: 'Profile About Update',
      description: updateSuccess
        ? 'Profile About updated with success'
        : 'Something went wrong',
      status: updateSuccess ? 'success' : 'error',
    });
    onClose();
  };

  const onCloseModal = async () => {
    const languagesChanged = checkUnsavedChanges(
      getValues('languages'),
      profile?.languages
    );
    const socialLinksChanged = checkUnsavedChanges(
      getValues('social_links'),
      profile?.social_links
    );

    if (fieldsChanged || languagesChanged || socialLinksChanged) {
      const result = await openConfirmation();
      result && onClose();
      return;
    }
    onClose();
  };

  const today: Date = new Date();
  const work_since: number | undefined = watch('work_since');
  const defaultYear: number = new Date(today).getFullYear();
  let currentDate: Date = new Date(defaultYear.toString());

  if (work_since) {
    currentDate = new Date(work_since.toString());
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <Stack direction="column">
          <ModalHeader data-testid="modal-about-header" mt="2">
            <Badge colorScheme="blue">About Me</Badge>
            <Heading fontSize="2xl" fontWeight="bold" mt="2">
              Edit Details
            </Heading>
            <ModalCloseButton />
          </ModalHeader>
        </Stack>
        <FormProvider {...methods}>
          <ModalBody>
            <Stack direction="column" spacing={2}>
              <Heading
                fontSize="lg"
                color="blue.700"
                fontWeight="bold"
                mt="4"
                mb="4"
              >
                {t('about_me.general')}
              </Heading>
              <Grid templateColumns="repeat(3, 1fr)" gap="4">
                <GridItem colSpan={3}>
                  <FormControlText
                    id="bio"
                    name={t('about_me.bio')}
                    placeholder="Inspiring quote"
                    register={register}
                    error={errors.bio}
                    maxLength={150}
                    onPaste={() => setIsPasting(true)}
                    resize={TextAreaResizeProperties.NONE}
                  />
                </GridItem>
                <FormControlDatePicker
                  id="work_since"
                  name={t('about_me.experience_since')}
                  placeholder="2019"
                  register={register}
                  selectedDate={currentDate}
                  dateFormat={DatePickerFormat.YEAR}
                  error={errors.work_since}
                  maxDate={today}
                />
              </Grid>
              <SocialLinksEdit />
              <LanguagesEdit />
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              data-testid="modal-about-close-button"
              type="button"
              mr={3}
              onClick={onCloseModal}
            >
              Close
            </Button>
            <Button
              data-testid="modal-about-save-button"
              type="submit"
              colorScheme="blue"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
