import AdvancedFiltersButton from '../buttons/AdvancedFiltersButton';
import AdvancedFiltersClearButton from '../buttons/AdvancedFiltersClearButton';
import AdvancedFiltersHeaderContainer from './AdvancedFiltersHeaderContainer';
import AdvancedFiltersHeaderLeftContent from './AdvancedFiltersHeaderLeftContentContainer';
import AdvancedFiltersHeaderRightContent from './AdvancedFiltersHeaderRightContentContainer';
import SearchInput from '~/shared/components/SearchInput';
import { SearchFilters } from '~/shared/models/search/filters/search';
import React, { PropsWithChildren } from 'react';

interface AdvancedFiltersHeaderFullProps<FiltersType> {
  filters: Partial<FiltersType>;
  searchInputPlaceholder: string;
  showAdvanced: boolean;
  isFilterChanged: boolean;
  onQueryChange: (query: string) => void;
  toggleShowAdvanced: () => void;
  resetFilters: () => void;
}

const AdvancedFiltersHeaderFull = <FiltersType extends SearchFilters>({
  filters,
  searchInputPlaceholder,
  showAdvanced,
  isFilterChanged,
  onQueryChange,
  toggleShowAdvanced,
  resetFilters,
  children,
}: PropsWithChildren<AdvancedFiltersHeaderFullProps<FiltersType>>) => {
  return (
    <AdvancedFiltersHeaderContainer>
      <AdvancedFiltersHeaderLeftContent>
        <SearchInput
          placeholder={searchInputPlaceholder}
          query={filters.query}
          onChange={onQueryChange}
          mb={0}
        />
        <AdvancedFiltersClearButton
          disabled={!isFilterChanged}
          onClick={resetFilters}
        />
        <AdvancedFiltersButton
          isOpen={showAdvanced}
          onClick={toggleShowAdvanced}
        />
      </AdvancedFiltersHeaderLeftContent>
      {children && (
        <AdvancedFiltersHeaderRightContent>
          {children}
        </AdvancedFiltersHeaderRightContent>
      )}
    </AdvancedFiltersHeaderContainer>
  );
};

export default AdvancedFiltersHeaderFull;
