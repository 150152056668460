import AlertInfo from '../AlertInfo';
import { AddIcon } from '@chakra-ui/icons';
import { IconButton, Stack, Text, Tooltip } from '@chakra-ui/react';
import LoadingData from '~/shared/components/ui/LoadingData';
import MissingData from '~/shared/components/ui/MissingData';
import { Child } from '~/shared/interfaces/map';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface PotentialChildrenListProps {
  data?: Child[];
  failLoading?: boolean;
  isLoading?: boolean;
  onAdd: (child: Child) => void;
}

const PotentialChildrenList: React.FC<PotentialChildrenListProps> = ({
  data,
  failLoading,
  isLoading,
  onAdd,
}) => {
  const { t } = useTranslation('base');

  if (failLoading) {
    return <AlertInfo text={t(`fail_loading_potential_children`)} />;
  }

  if (isLoading || !data) {
    return <LoadingData mt="5vh" />;
  }

  if (!data?.length) {
    return <MissingData text={t(`map_modal.not_found`)} />;
  }

  return (
    <>
      {data.map(({ text, id }, i) => (
        <Stack key={`potential-children-${id}-${i}`} w="full">
          <Tooltip rounded={8} label={text}>
            <Text
              bg="orange.100"
              isTruncated
              fontSize="xs"
              mx={1}
              onClick={() => onAdd({ id, text })}
              p={1}
              rounded={8}
              shadow="sm"
            >
              <IconButton
                alignContent="center"
                aria-label={text}
                color="blue.600"
                fontSize="sm"
                icon={<AddIcon />}
                mt={1}
                variant="link"
              />
              {text}
            </Text>
          </Tooltip>
        </Stack>
      ))}
    </>
  );
};

export default PotentialChildrenList;
