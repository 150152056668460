import {
  Badge,
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import SkillForm from '~/profile/components/skills/SkillForm';
import SkillsList from '~/profile/components/skills/SkillsList';
import useProfileStore from '~/profile/stores/profile';
import ModalButton from '~/shared/components/ui/ModalButton';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { EmployeeSkill } from '~/shared/models/api/employee';
import { checkUnsavedChanges } from '~/shared/utils/verifications';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

interface EditModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const EditModal: React.FC<EditModalProps> = ({ isOpen, onClose }) => {
  const toast = useToast();
  const { openConfirmation } = useConfirmationModalContext();
  const { profile, updateSkills } = useProfileStore();
  const { startLoading, stopLoading } = useLoadingContext();
  const [selectedSkillIndex, setSelectedSkillIndex] = useState<number>(-1);
  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: { skills: [...(profile?.main_skills || [])] },
  });
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'skills',
    keyName: 'skills_form_id',
  });

  const fieldsAux = fields.map((field) => ({
    skill_id: field.skill_id,
    skill_level: field.skill_level_id,
  }));
  const mainSkillsAux = profile?.main_skills.map((field) => ({
    skill_id: field.skill_id,
    skill_level: field.skill_level_id,
  }));
  const unsavedChanges = checkUnsavedChanges(fieldsAux, mainSkillsAux);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    reset({ skills: [...(profile?.main_skills || [])] });
  }, [isOpen]);

  const onSubmit = async () => {
    if (unsavedChanges) {
      startLoading();
      const updateSuccess = await updateSkills(fields);
      stopLoading();
      toast({
        title: 'Profile Skills Update',
        description: updateSuccess
          ? 'Profile Skills updated with success'
          : 'Something went wrong',
        status: updateSuccess ? 'success' : 'error',
      });
    }
    onClose();
  };

  const handleSkillSubmit = (skill: EmployeeSkill) => {
    if (selectedSkillIndex > -1) {
      update(selectedSkillIndex, skill);
    } else {
      append({ ...skill });
    }
    setSelectedSkillIndex(-1);
  };

  const onCloseModal = () => {
    if (unsavedChanges) {
      handleUnsavedChanges();
      return;
    }
    onClose();
  };

  const handleUnsavedChanges = async () => {
    const result = await openConfirmation();
    result && onClose();
  };

  watch('skills', fields);

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <Stack direction="column">
          <ModalHeader data-testid="modal-skills-header" mt="2" mb="0">
            <Badge colorScheme="blue">Focus Skills</Badge>
            <Heading fontSize="2xl" fontWeight="bold" mt="2">
              Edit Details
            </Heading>
            <ModalCloseButton />
          </ModalHeader>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Box mb={5}>
              <SkillForm
                selectedSkill={fields[selectedSkillIndex]}
                selectedSkills={fields}
                onSubmit={handleSkillSubmit}
                onCancel={() => setSelectedSkillIndex(-1)}
              />
            </Box>
            {selectedSkillIndex < 0 && (
              <SkillsList
                skills={fields}
                onRemove={remove}
                onClick={(index) => setSelectedSkillIndex(index)}
              />
            )}
          </ModalBody>
          {selectedSkillIndex < 0 && (
            <ModalFooter justifyContent="space-between">
              <ModalButton
                data-testid="modal-skills-close-button"
                type="button"
                onClick={onCloseModal}
              >
                Close
              </ModalButton>
              <ModalButton
                data-testid="modal-skills-save-button"
                colorScheme="blue"
                type="submit"
                variant="solid"
              >
                Save
              </ModalButton>
            </ModalFooter>
          )}
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
