import { useToast } from '@chakra-ui/react';
import { updateEmployeeRoles } from '~/shared/api/request/employee';
import { getErrorMessage } from '~/shared/errors/error';
import {
  UpdateEmployeeRolesPayload,
  UpdateEmployeeRolesProps,
} from '~/shared/models/api/employee';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

interface UseEmployeeRoles {
  onEmployeeRolesChange: (
    employee_id: number,
    roles: string[],
    callback?: () => void
  ) => void;
}

const useEmployeeRoles = (): UseEmployeeRoles => {
  const toast = useToast();
  const { t } = useTranslation('permissions');

  const buildRolesText = (roles: string[]) =>
    roles.length ? `(${roles.join(', ')})` : '';

  const updateRole = useMutation(
    (props: UpdateEmployeeRolesProps) => updateEmployeeRoles(props),
    {
      onSuccess: (payload: UpdateEmployeeRolesPayload) => {
        toast({
          title: t(`update.employee_roles.title`),
          description: t(`update.employee_roles.success`, {
            roles: buildRolesText(payload.roles),
          }),
          status: 'success',
        });
      },
      onError: (err: AxiosError, props: UpdateEmployeeRolesProps) => {
        toast({
          title: t(`update.employee_roles.title`),
          description: getErrorMessage(
            err.response?.status,
            t(`update.employee_roles.fail`, {
              roles: buildRolesText(props.roles),
            }),
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return {
    onEmployeeRolesChange: (
      employeeId: number,
      roles: string[],
      callback?: () => void
    ) =>
      updateRole.mutateAsync(
        { employeeId, roles },
        {
          onSuccess: () => {
            callback && callback();
          },
        }
      ),
  };
};

export default useEmployeeRoles;
