import { Box, BoxProps, Text } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';

interface AdvancedFiltersElementProps {
  label?: string;
}

const AdvancedFiltersElement: FC<
  PropsWithChildren<AdvancedFiltersElementProps> & BoxProps
> = ({ label, children, ...props }) => {
  return (
    <Box {...props}>
      {label && <Text fontSize="sm">{label}</Text>}
      {children}
    </Box>
  );
};

export default AdvancedFiltersElement;
