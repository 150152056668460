import queryString from 'query-string';

type Params = (string | null)[];
type Param = string | Params | null;

const sanitize = (params: Params): string[] =>
  params.filter((val): val is string => val !== null);

const toNumbers = (param: Param): number[] => {
  if (!param?.length) {
    return [];
  }

  if (typeof param === 'string') {
    return [Number(param)];
  }

  return sanitize(param).map(Number);
};

const toStrings = (param: Param): string[] => {
  if (!param) {
    return [];
  }

  if (!Array.isArray(param)) {
    return [param];
  }

  return sanitize(param);
};

export const fetchAndParseQueryParams = () => {
  const parsedQueryParams = queryString.parse(location.search, {
    arrayFormat: 'comma',
  });

  return {
    ...parsedQueryParams,
    certificates: toNumbers(parsedQueryParams.certificates),
    companies: toNumbers(parsedQueryParams.companies),
    customers: toNumbers(parsedQueryParams.customers),
    industries: toNumbers(parsedQueryParams.companies),
    languages: toNumbers(parsedQueryParams.languages),
    locations: toStrings(parsedQueryParams.locations),
    projects: toNumbers(parsedQueryParams.projects),
    seniorities: toNumbers(parsedQueryParams.seniorities),
    skills: toNumbers(parsedQueryParams.skills),
  };
};
