import SkillMergeConfirmationModalSkillBox from './SkillMergeConfirmationModalSkillBox';
import { ArrowRightIcon } from '@chakra-ui/icons';
import { Grid, GridItem, Text } from '@chakra-ui/react';
import ConfirmationModal from '~/shared/components/ui/ConfirmationModal';
import { Skill } from '~/shared/models/api/skills';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface SkillMergeConfirmationModalProps {
  origin: Partial<Skill>;
  final: Partial<Skill>;
  isOpen: boolean;
  closeConfirmation: () => void;
  onConfirm: () => void;
}

const SkillMergeConfirmationModal: FC<SkillMergeConfirmationModalProps> = ({
  origin,
  final,
  isOpen,
  closeConfirmation,
  onConfirm,
}) => {
  const { t: tBase } = useTranslation('base');
  const { t: tSkills } = useTranslation('skills');

  return (
    <ConfirmationModal
      titleText={tSkills('edit.merge_skill')}
      descriptionText={tSkills('edit.merge_verify_prompt_question')}
      confirmText={tBase('merge')}
      isOpen={isOpen}
      onClose={closeConfirmation}
      onConfirm={onConfirm}
    >
      <Grid
        templateColumns={{ md: '1fr auto 1fr' }}
        gap={2}
        pt={8}
        pb={4}
        alignItems="center"
        textAlign="center"
      >
        <SkillMergeConfirmationModalSkillBox
          name={origin.name}
          type={origin.type}
        />
        <GridItem>
          <ArrowRightIcon
            color="teal.400"
            mx={5}
            transform={{ base: 'rotate(90deg)', md: 'rotate(0)' }}
          />
        </GridItem>
        <SkillMergeConfirmationModalSkillBox
          name={final.name}
          type={final.type}
        />
      </Grid>
      <Text color="red" mt={2}>
        {tSkills('edit.merge_verify_prompt_description', {
          origin_name: origin.name,
          final_name: final.name,
        })}
      </Text>
    </ConfirmationModal>
  );
};

export default SkillMergeConfirmationModal;
