import { Child, ChildRecord } from '../interfaces/map';
import {
  ChildStatus,
  ChildStatusOrderValue,
} from '~/shared/enums/childStatusColor';

export const wasDeleted = (state: ChildStatus) => {
  return state === ChildStatus.REMOVED;
};

export const wasAdded = (state: ChildStatus) => {
  return state === ChildStatus.ADDED;
};

export const sortChildren = (children: ChildRecord[]) =>
  children.sort(
    (a, b) => ChildStatusOrderValue[b.status] - ChildStatusOrderValue[a.status]
  );

export const hasChildToBeSaved = (children: ChildRecord[]) =>
  !!children.find(({ status }) => status !== ChildStatus.SAVED);

export const getSanitizePotentialChildren = (
  children: ChildRecord[],
  potentialChildren: Child[]
) => {
  const childrenIds = new Set(children.map((child) => child.id));

  return potentialChildren.filter(({ id }) => !childrenIds.has(id));
};
