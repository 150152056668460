import { get, patch } from '~/shared/api/endpoints';
import { permissionsEndpoint } from '~/shared/api/urls/permissions';
import { UpdatePermissions } from '~/shared/models/api/employee';
import {
  PermissionData,
  RoleData,
  UpdateRolePermissions,
} from '~/shared/models/api/permissions';
import debounce from 'debounce-promise';

export const getPermissions = async (): Promise<PermissionData[]> => {
  const { data } = await get<PermissionData[]>(permissionsEndpoint.url);

  return data;
};

export const getRoles = async (): Promise<RoleData[]> => {
  const { data } = await get<RoleData[]>(permissionsEndpoint.roles.url);

  return data;
};

export const updateRolePermissions = async ({
  role,
  permissions,
}: UpdateRolePermissions): Promise<UpdatePermissions> => {
  const { data } = await patch<UpdatePermissions, number[]>(
    permissionsEndpoint.roles.id(role).url,
    permissions
  );

  return data;
};

export const getPermissionsDebounce = debounce(getPermissions, 500);
