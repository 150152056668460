import { buildPptLayout } from './buildPptLayout';
import { PptLayoutEnum } from './enums';
import { addMetadata, addSection } from './helper';
import { Employee } from '~/shared/models/api/employee';
import { fileNameDate } from '~/shared/utils/date';
import PptxGenJS from 'pptxgenjs';

const generatePpt = async (
  layout: PptLayoutEnum,
  profiles: Employee[],
  title: string,
  toastResult: (
    fileName: string,
    status: 'success' | 'error',
    title: string
  ) => void
): Promise<void> => {
  if (!profiles.length) {
    return;
  }

  const ppt = new PptxGenJS();
  const { fill } = buildPptLayout(layout, ppt, title);

  addMetadata(ppt, title);
  addSection(ppt, title);

  await fill(profiles);

  const prefix =
    profiles.length === 1
      ? `${profiles[0].first_name} ${profiles[0].second_name}`
      : `${profiles.length} Profiles`;

  const fileName = `${prefix}-${title}-${fileNameDate()}.pptx`;

  try {
    ppt.writeFile({ fileName });
    toastResult(fileName, 'success', title);
  } catch (error) {
    toastResult(fileName, 'error', title);
    console.error(error);
  }
};

export default generatePpt;
