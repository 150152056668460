import { Box, Checkbox, Stack } from '@chakra-ui/react';
import SearchInput from '~/shared/components/SearchInput';
import { InputVariant } from '~/shared/enums/variant';
import { SingleFilterParameter } from '~/shared/models/api/employee';
import Fuse from 'fuse.js';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SearchableFilterProps {
  filter: SingleFilterParameter[];
  filterValue: (string | number)[];
  dataType: string;
}

// TODO extract logic to a hook
export const SearchableFilter = ({
  filter,
  filterValue,
  dataType,
}: SearchableFilterProps) => {
  const { t } = useTranslation('search');
  const [searchFilter, setSearchFilter] = useState('');

  useEffect(() => {
    if (filterValue.length || !searchFilter.length) {
      return;
    }
    setSearchFilter('');
  }, [filterValue]);

  const fuseSearch = useMemo(() => {
    const array = filter.map(({ name }) => name);

    return new Fuse(array, {
      shouldSort: false,
      threshold: 0.3,
    });
  }, []);

  const visibleData = useMemo<SingleFilterParameter[]>(() => {
    const result = fuseSearch.search(searchFilter).map(({ item }) => item);

    return filter.filter(
      (project) =>
        filterValue.includes(Number(project.id)) ||
        result.includes(project.name)
    );
  }, [searchFilter, filterValue.join(',')]);

  return (
    <Stack gap="2">
      <Box>
        <SearchInput
          placeholder={t('start_typing')}
          variant={InputVariant.FLUSHED}
          query={searchFilter}
          onChange={setSearchFilter}
          w="full"
          size="sm"
        />
      </Box>
      {visibleData.map((filter) => (
        <Checkbox key={`${dataType}.${filter.id}`} value={filter.id}>
          {filter.name}
        </Checkbox>
      ))}
    </Stack>
  );
};
