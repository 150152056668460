import { deleteData, get, patch, post, search } from '~/shared/api/endpoints';
import { skillsEndpoint } from '~/shared/api/urls/skills';
import { skillTypesEndpoint } from '~/shared/api/urls/skillTypes';
import {
  CreateSkill,
  MergeSkill,
  Skill,
  SkillType,
  UpdateSkill,
} from '~/shared/models/api/skills';
import { SearchFilters } from '~/shared/models/search/filters/search';
import { SearchProps } from '~/shared/models/search/search';
import { getSearchFiltersByParamsType } from '~/shared/utils/search';
import debounce from 'debounce-promise';

export const createSkill = async ({
  add_by_user = true,
  ...skill
}: CreateSkill): Promise<Skill> => {
  const newSkill: CreateSkill = {
    ...skill,
    add_by_user,
  };
  const { data } = await post(skillsEndpoint.url, newSkill);

  return data;
};

export const updateSkill = async ({
  id,
  ...skill
}: UpdateSkill): Promise<Skill> => {
  const { data } = await patch<Skill, UpdateSkill>(
    skillsEndpoint.id(id).url,
    skill
  );

  return data;
};

export const mergeSkills = async ({
  id,
  ...skill
}: MergeSkill): Promise<Skill> => {
  const { data } = await post(skillsEndpoint.merge.id(id).url, skill);

  return data;
};

export const searchSkills = async (
  value: SearchProps | SearchFilters
): Promise<Skill[]> => {
  const searchFilters = getSearchFiltersByParamsType(value);

  const { data } = await search<Skill>(skillsEndpoint.url, searchFilters);

  return data;
};

export const searchSkillsDebounce = debounce(searchSkills, 500);

export const getSkillTypes = async (): Promise<SkillType[]> => {
  const { data } = await get<SkillType[]>(skillTypesEndpoint.url);

  // Generate id for types
  // should be removed after api returns the id
  const typesWithIds = data.map((type, index) => {
    const { id, name } = type;
    return {
      ...type,
      id:
        id || name.length
          ? `${name.replace(/\s/g, '').toLowerCase()}-${index}`
          : '',
    };
  });

  return typesWithIds;
};

export const deleteSkill = async (id: number): Promise<Skill> => {
  const { data } = await deleteData<Skill>(skillsEndpoint.id(id).url);

  return data;
};
