import { getEmployeesByIds } from '~/shared/api/request/employee';
import { QueriesTypes } from '~/shared/enums/queries';
import { Employee } from '~/shared/models/api/employee';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useGetEmployeesByIds = (ids: number[]) =>
  useQuery<Employee[], AxiosError>({
    queryKey: [QueriesTypes.GET_EMPLOYEES_BY_IDS, ids],
    queryFn: ({ queryKey }) => getEmployeesByIds(queryKey[1] as number[]),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: false,
  });
