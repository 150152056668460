import VerificationStatus from '../components/verification/VerificationStatus';
import { Tbody, Text } from '@chakra-ui/react';
import EmployeesTable from '~/administration/components/employees-search-table/EmployeesSearchTable';
import EmployeesRow from '~/administration/components/employees-search-table/EmployeesSearchTableRow';
import EmployeesThead from '~/administration/components/employees-search-table/EmployeesSearchTableThead';
import VerificationSearchFilters from '~/administration/components/verification/filters/VerificationSearchFilters';
import { EmployeeTableKeys } from '~/administration/models/employee';
import { DateFormat } from '~/shared/enums/date';
import { Permission } from '~/shared/enums/permissions';
import useEmployeeNotifications, {
  EmployeeNotifications,
} from '~/shared/hooks/employee/useEmployeeNotifications';
import useEmployeeVerifications, {
  EmployeeVerifications,
} from '~/shared/hooks/employee/useEmployeeVerifications';
import useSearchFilters from '~/shared/hooks/filters/useSearchFilters';
import useRouteGuard from '~/shared/hooks/permissions/useRouteGuard';
import { Employee } from '~/shared/models/api/employee';
import {
  EmployeeFilters,
  initialEmployeeFilters,
} from '~/shared/models/search/filters/employees';
import { useEmployeesSearch } from '~/shared/queries/useEmployeesSearch';
import { formatDate } from '~/shared/utils/date';
import React, { useEffect, useState } from 'react';

const keys: EmployeeTableKeys = ['name', 'email'];

const VerifyProfiles: React.FC = () => {
  const [employees, setEmployees] = useState<Employee[]>();

  useRouteGuard([
    Permission.VERIFY_ALL_PROFILES,
    Permission.VERIFY_TEAM_PROFILES,
  ]);

  const {
    filters,
    onFilterChange,
    onQueryChange,
    resetFilters,
    isFilterChanged,
    onPageChange,
  } = useSearchFilters<EmployeeFilters>({
    ...initialEmployeeFilters,
    verify_profiles: true,
    verified_profile: false,
  });
  const { data, isFetching, error } = useEmployeesSearch(filters);

  const notification = useEmployeeNotifications();
  const verification = useEmployeeVerifications();

  useEffect(() => {
    if (data) {
      setEmployees(data.page_results);
    }
  }, [data]);

  const notifyOrVerify = (
    action: EmployeeNotifications | EmployeeVerifications,
    employee: Employee
  ) =>
    action.mutateAsync(
      {
        id: employee.id,
        name: `${employee.first_name} ${employee.second_name}`,
      },
      {
        onSuccess: (_: string, { id }: Partial<Employee>) =>
          setEmployees((prevEmployees) =>
            prevEmployees?.map((e: Employee) => (id === e.id ? employee : e))
          ),
      }
    );

  const handleVerify = (employee: Employee) =>
    notifyOrVerify(verification, { ...employee, verified_profile: true });

  const handleNotify = (employee: Employee) =>
    notifyOrVerify(notification, { ...employee, notify_to_update: true });

  return (
    <>
      <VerificationSearchFilters
        filters={filters}
        onFilterChange={onFilterChange}
        onQueryChange={onQueryChange}
        resetFilters={resetFilters}
        isFilterChanged={isFilterChanged}
      />

      <EmployeesTable
        filters={filters}
        isFetching={isFetching}
        data={data}
        error={error}
        onPageChange={onPageChange}
      >
        <EmployeesThead keys={[...keys, 'last_update', 'verified_profile']} />
        <Tbody>
          {employees?.map((employee) => {
            return (
              <EmployeesRow
                employee={employee}
                keys={keys}
                nameLinkToProfile
                key={`row-${employee.id}`}
              >
                <Text whiteSpace="nowrap">
                  {employee.last_update
                    ? formatDate(
                        employee.last_update,
                        DateFormat.DAY_MONTH_YEAR
                      )
                    : 'Was never updated'}
                </Text>
                <VerificationStatus
                  verified={employee.verified_profile}
                  // Pending BE development, update to employee.notify_to_update
                  notified={false}
                  lastUpdated={employee.last_update}
                  isUpdating={verification.isLoading || notification.isLoading}
                  onVerify={() => handleVerify(employee)}
                  onNotify={() => handleNotify(employee)}
                />
              </EmployeesRow>
            );
          })}
        </Tbody>
      </EmployeesTable>
    </>
  );
};

export default VerifyProfiles;
