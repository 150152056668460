import { ObjectKeyValue } from '../models/object';

export const mapEmptyStringFielsForApi = <T = ObjectKeyValue>(
  obj: T,
  setValue?: null
): T => {
  return Object.fromEntries(
    Object.entries(obj ?? {}).map(([key, a]) => {
      if (a === '') {
        return [key, setValue];
      }
      return [key, a];
    })
  ) as T;
};
