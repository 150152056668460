import { LanguageProficiency } from '~/shared/enums/language';
import {
  mapEnumToLevelOption,
  mapEnumToLevelOptions,
} from '~/shared/mappers/selectOption';
import { SelectOption } from '~/shared/models/react-select/select-option';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

interface LanguageProficiencySelectProps {
  id?: string;
  defaultValue?: LanguageProficiency;
  placeholder?: string;
  isClearable?: boolean;
  onChange?: (languageProficiency?: LanguageProficiency) => void;
}

const LanguageProficiencySelect: FC<LanguageProficiencySelectProps> = ({
  id,
  defaultValue,
  placeholder,
  isClearable = true,
  onChange,
}) => {
  const [selected, setSelected] = useState<SelectOption<
    typeof LanguageProficiency
  > | null>(null);
  const { t } = useTranslation('profile');

  const options = useMemo(() => mapEnumToLevelOptions(LanguageProficiency), []);

  useEffect(() => {
    if (!defaultValue) {
      setSelected(null);
      return;
    }
    setSelectedProficiency(defaultValue);
  }, [defaultValue]);

  const setSelectedProficiency = (proficiency: LanguageProficiency) => {
    setSelected(mapEnumToLevelOption(LanguageProficiency, proficiency));
  };

  return (
    <Select
      id={id}
      placeholder={placeholder}
      isClearable={isClearable}
      isSearchable={false}
      value={selected}
      options={options}
      getOptionLabel={(option) =>
        t(`language_proficiency.${option.translationKey}`)
      }
      onChange={(value) => {
        onChange && onChange(value?.value as LanguageProficiency);
        setSelected(value as SelectOption<typeof LanguageProficiency>);
      }}
    />
  );
};

export default LanguageProficiencySelect;
