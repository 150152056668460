import Paginator from './ui/Paginator';
import { Box } from '@chakra-ui/react';
import { PaginationFilters } from '~/shared/models/search/filters/search';
import React, { useEffect } from 'react';

export interface PaginationByFiltersProps {
  filters?: Partial<PaginationFilters>;
  totalItems: number;
  onPageChange?: (page: number) => void;
}

const PaginationByFilters: React.FC<PaginationByFiltersProps> = ({
  filters,
  totalItems,
  onPageChange,
}) => {
  // remove if api returns current page
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  useEffect(() => {
    if (filters?.skip === undefined || filters?.limit === undefined) {
      return;
    }
    const page: number = filters.skip / filters.limit + 1;
    setCurrentPage(page);
  }, [filters]);

  if (filters?.skip === undefined || filters?.limit === undefined) {
    return null;
  }

  if (filters.limit > totalItems) {
    return null;
  }

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
    if (!onPageChange || !filters.limit) {
      return;
    }
    const skip: number = (page - 1) * filters.limit;
    onPageChange(skip);
  };

  return (
    <Box textAlign="center" mt="8">
      <Paginator
        currentPage={currentPage}
        itemsPerPage={filters.limit}
        onPageChange={handlePaginationChange}
        totalItems={totalItems}
      />
    </Box>
  );
};

export default PaginationByFilters;
