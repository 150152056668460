import { getEmployee } from '~/shared/api/request/employee';
import { QueriesTypes } from '~/shared/enums/queries';
import { Employee } from '~/shared/models/api/employee';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export const useGetEmployeeById = (id?: number) =>
  useQuery<Employee, AxiosError>({
    queryKey: [QueriesTypes.GET_EMPLOYEE, id],
    queryFn: ({ queryKey }) => getEmployee(Number(queryKey[1])),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!id,
  });
