import SearchProfileCard from './components/search-profile-card/SearchProfileCard';
import SearchFilter from './components/SearchFilter';
import {
  EmployeeSearchProvider,
  useEmployeeSearchContext,
} from './EmployeeSearchContext';
import { Box, Flex, Grid } from '@chakra-ui/react';
import SearchResponseContent from '~/administration/components/SearchResponseContent';
import AppLayout from '~/shared/components/ui/AppLayout';
import { useEmployeesSearch } from '~/shared/queries/useEmployeesSearch';
import React from 'react';

const SearchResults = () => {
  const { filters, onPageChange } = useEmployeeSearchContext();

  const {
    isFetching,
    data: { total_results_count: totalCount, page_results: employees } = {},
    error,
  } = useEmployeesSearch(filters);

  return (
    <Box flex="1" mb={10}>
      <SearchResponseContent
        loading={isFetching || !employees}
        error={error}
        noResults={employees?.length === 0}
        paginationData={{
          filters,
          totalItems: totalCount || 0,
          onPageChange,
        }}
      >
        <Grid templateColumns="repeat(2, 1fr)" gap={6} mb="24">
          {employees?.map((employee) => (
            <SearchProfileCard
              key={employee.id}
              employee={employee}
              filters={filters}
            />
          ))}
        </Grid>
      </SearchResponseContent>
    </Box>
  );
};

const SearchPage = () => {
  document.title = `Search`;

  return (
    <EmployeeSearchProvider>
      <AppLayout>
        <Flex gap="5" p="4">
          <SearchFilter />
          <SearchResults />
        </Flex>
      </AppLayout>
    </EmployeeSearchProvider>
  );
};

export default SearchPage;
