import useProfileStore from '~/profile/stores/profile';
import useIndustryCreate from '~/shared/hooks/useIndustryCreate';
import {
  mapDataListToOptions,
  mapDataToOption,
} from '~/shared/mappers/selectOption';
import { Industry } from '~/shared/models/api/industry';
import {
  SelectOption,
  SelectOptionMapperKeysType,
} from '~/shared/models/react-select/select-option';
import { useIndustriesSearch } from '~/shared/queries/useIndustriesSearch';
import React, { FC, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

interface IndustrySelectProps {
  id?: string;
  placeholder?: string;
  selectedIndustries: Industry[];
  defaultValue?: Industry;
  isClearable?: boolean;
  isCreatable?: boolean;
  onChange?: (industry?: Industry) => void;
}

const IndustrySelect: FC<IndustrySelectProps> = ({
  id = 'industries-select',
  placeholder,
  selectedIndustries,
  defaultValue,
  isClearable = true,
  isCreatable = false,
  onChange,
}) => {
  const { profile } = useProfileStore();
  const [selected, setSelected] = useState<SelectOption<Industry> | null>(null);
  const [options, setOptions] = useState<SelectOption<Industry>[]>();
  const [query, setQuery] = useState<string>('');
  const { data, isLoading } = useIndustriesSearch({ query });
  const {
    create: { mutateAsync: createIndustry },
  } = useIndustryCreate();
  const keys: SelectOptionMapperKeysType<Industry> = {
    valueKey: 'id',
    labelKey: 'name',
  };

  useEffect(() => {
    if (!defaultValue) {
      setSelected(null);
      return;
    }
    setSelectedIndustry(defaultValue);
  }, [defaultValue]);

  const setSelectedIndustry = (industry: Industry) => {
    setSelected(
      mapDataToOption({
        option: industry,
        ...keys,
      })
    );
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const selectOptions = mapDataListToOptions<Industry>({
      options: data || [],
      ...keys,
    });
    setOptions(selectOptions);
  }, [data]);

  const handleCreate = (name: string) => {
    if (!profile?.id) return;

    createIndustry(
      { name, employee_id: profile.id },
      {
        onSuccess: (industry: Industry) => {
          setSelectedIndustry(industry);
          onChange && onChange(industry);
        },
      }
    );
  };

  return (
    <CreatableSelect
      id={id}
      placeholder={placeholder}
      isLoading={!!query && isLoading}
      isClearable={isClearable}
      value={selected}
      options={options}
      onInputChange={(query) => setQuery(query)}
      isOptionDisabled={(value) =>
        selectedIndustries?.some(
          (industry) => industry.id === value.origin?.id
        ) || false
      }
      isValidNewOption={() => isCreatable && !!query}
      onCreateOption={handleCreate}
      onChange={(value) => {
        onChange && onChange(value?.origin);
        setSelected(value);
      }}
    />
  );
};

export default IndustrySelect;
