import { EditIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  IconButton,
  Link,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import EmployeeLink from '~/shared/components/EmployeeLink';
import Logo from '~/shared/components/Logo';
import StatusTag from '~/shared/components/status/StatusTag';
import { Certificate } from '~/shared/models/api/certificate';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CertificateCardProps {
  certificate: Certificate;
  onEdit: () => void;
}

const CertificateCard: FC<CertificateCardProps> = ({
  certificate: {
    name,
    certificate_url,
    issuer,
    logo,
    unique_identifier,
    status,
    permanent,
    created_by,
  },
  onEdit,
}) => {
  const { t: tCertificates } = useTranslation('certificates');
  const { t: tBase } = useTranslation('base');

  return (
    <Card overflow="hidden" variant="outline">
      <CardHeader display="flex" justifyContent="space-between" p={2}>
        <Box>
          {status && <StatusTag status={status} p={1} />}
          {permanent && (
            <Badge colorScheme="gray" letterSpacing="1px" p={1} ml={2}>
              permanent
            </Badge>
          )}
        </Box>
        <IconButton
          aria-label="Edit Certificate"
          icon={<EditIcon />}
          data-testid="editable-button"
          onClick={onEdit}
          ml="4"
          borderRadius="full"
          variant="link"
          minW="auto"
        />
      </CardHeader>
      <CardBody pt={1} pb={2}>
        <Flex gap="3" align="center" direction="column" textAlign="center">
          <Logo logo={logo} />
          <Text fontWeight="bold">{name}</Text>
          {unique_identifier && (
            <Tooltip label="Unique identifier">
              <Badge colorScheme="gray" letterSpacing="1px" p={1}>
                {unique_identifier}
              </Badge>
            </Tooltip>
          )}
          {issuer && (
            <Tooltip label="Issuer">
              <Text>{issuer}</Text>
            </Tooltip>
          )}
        </Flex>
      </CardBody>

      <CardFooter
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        fontSize="sm"
        pt={0}
      >
        {certificate_url && (
          <Link
            href={certificate_url}
            isExternal
            textDecoration="underline"
            color="blue.700"
          >
            {tCertificates('certificate.certification_url')}
          </Link>
        )}
        {created_by && (
          <Flex
            mt="1"
            alignItems="center"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Text mr="2">{tBase('created_by')}</Text>
            <EmployeeLink
              employeeId={created_by.id}
              textDecor="underline"
              color="blue.700"
            >
              {`${created_by.first_name} ${created_by.second_name}`}
            </EmployeeLink>
          </Flex>
        )}
      </CardFooter>
    </Card>
  );
};

export default CertificateCard;
