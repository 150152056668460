import {
  deleteData,
  patchFormData,
  postFormData,
} from '~/shared/api/endpoints';
import { employeesEndpoint } from '~/shared/api/urls/employees';
import { FormDataKey } from '~/shared/enums/formDataKey';
import {
  CreateEmployeeCertification,
  EmployeeCertification,
  EmployeeCertificationBase,
  UpdateEmployeeCertification,
} from '~/shared/models/api/employee';

export const createEmployeeCertification = async ({
  new_certification_file,
  employee_id,
  ...certification
}: CreateEmployeeCertification): Promise<EmployeeCertification> => {
  const formData = new FormData();

  formData.append(
    FormDataKey.EMPLOYEE_CERTIFICATION_IN,
    JSON.stringify(certification)
  );

  if (new_certification_file) {
    formData.append(FormDataKey.FILE, new_certification_file);
  }

  const { data } = await postFormData(
    employeesEndpoint.id(employee_id).certifications.url,
    formData
  );

  return data;
};

export const updateEmployeeCertification = async ({
  new_certification_file,
  employee_id,
  certificate_id,
  ...certification
}: UpdateEmployeeCertification): Promise<EmployeeCertification> => {
  const formData = new FormData();

  formData.append(
    FormDataKey.EMPLOYEE_CERTIFICATION_IN,
    JSON.stringify(certification)
  );

  if (new_certification_file) {
    formData.append(FormDataKey.FILE, new_certification_file);
  }

  const { data } = await patchFormData(
    employeesEndpoint.id(employee_id).certifications.id(certificate_id).url,
    formData
  );

  return data;
};

export const deleteEmployeeCertification = async ({
  employee_id,
  certificate_id,
}: EmployeeCertificationBase): Promise<EmployeeCertification> => {
  const { data } = await deleteData<EmployeeCertification>(
    employeesEndpoint.id(employee_id).certifications.id(certificate_id).url
  );

  return data;
};
