import CertificateCard from '../components/certificates/CertificateCard';
import CertificatesSearchFilters from '../components/certificates/filters/CertificatesSearchFilters';
import CertificateBaseModal from '../components/certificates/modals/CertificateBaseModal';
import SearchResponseContent from '../components/SearchResponseContent';
import { Button, SimpleGrid } from '@chakra-ui/react';
import useCertificateModal from '~/administration/hooks/certificates/useCertificateModal';
import { Permission } from '~/shared/enums/permissions';
import useSearchFilters from '~/shared/hooks/filters/useSearchFilters';
import useRouteGuard from '~/shared/hooks/permissions/useRouteGuard';
import { Certificate } from '~/shared/models/api/certificate';
import {
  CertificatesSearchFilters as SearchFilters,
  initialCertificatesSearchFilters as initialSearchFilters,
} from '~/shared/models/search/filters/certificates';
import { useCertificatesSearch } from '~/shared/queries/useCertificatesSearch';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Certificates = () => {
  useRouteGuard([Permission.CERTIFICATES]);

  const { t } = useTranslation('certificates');
  const {
    filters,
    onFilterChange,
    onPageChange,
    showAdvanced,
    isFilterChanged,
    onQueryChange,
    toggleShowAdvanced,
    resetFilters,
  } = useSearchFilters<SearchFilters>({
    ...initialSearchFilters,
    limit: 12,
  });
  const {
    isFetching,
    refetch,
    data: { total_results_count: totalCount, page_results: certificates } = {},
    error,
  } = useCertificatesSearch(filters);

  const {
    currentCertificate,
    changeViewMode,
    isOpen,
    isUpdating,
    mode,
    onClose,
    onSubmit,
    openModal,
    updateStatus,
    deleteCertificate,
    certificateWasUpdated,
  } = useCertificateModal();

  useEffect(() => {
    if (!certificateWasUpdated || isOpen) {
      return;
    }
    refetch();
  }, [certificateWasUpdated, isOpen]);

  return (
    <>
      {isOpen && (
        <CertificateBaseModal
          certificate={currentCertificate}
          changeViewMode={changeViewMode}
          isUpdating={isUpdating}
          mode={mode}
          onClose={onClose}
          onSubmit={onSubmit}
          onDelete={deleteCertificate}
          updateStatus={updateStatus}
        />
      )}
      <CertificatesSearchFilters
        filters={filters}
        showAdvanced={showAdvanced}
        isFilterChanged={isFilterChanged}
        onQueryChange={onQueryChange}
        toggleShowAdvanced={toggleShowAdvanced}
        resetFilters={resetFilters}
        onFilterChange={onFilterChange}
      >
        <Button colorScheme="blue" onClick={() => openModal && openModal()}>
          {t('add_new_certificate')}
        </Button>
      </CertificatesSearchFilters>

      <SearchResponseContent
        loading={isFetching}
        error={error}
        noResults={certificates?.length === 0}
        paginationData={{
          filters,
          totalItems: totalCount || 0,
          onPageChange,
        }}
      >
        <SimpleGrid columns={{ base: 1, sm: 2, lg: 3, xl: 4 }} gap={6}>
          {certificates?.map((certificate: Certificate) => (
            <CertificateCard
              key={`certificate-${certificate.id}`}
              certificate={certificate}
              onEdit={() => openModal && openModal(certificate)}
            />
          ))}
        </SimpleGrid>
      </SearchResponseContent>
    </>
  );
};

export default Certificates;
