import { Button, Icon } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import FileUploadInput from '~/shared/components/FileUploadInput';
import FormControl from '~/shared/components/form/FormControl';
import { certificationFileType } from '~/shared/constants/certifications';
import { yupFileValidation } from '~/shared/utils/form';
import React, { FC } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { FaFileUpload } from 'react-icons/fa';
import { object } from 'yup';

type VerifyCertificateFileUploadFormProps = {
  isUploadingFile?: boolean;
  onFileUploaded: (file: Blob) => void;
};

const VerifyCertificateFileUploadForm: FC<
  VerifyCertificateFileUploadFormProps
> = ({ isUploadingFile, onFileUploaded }) => {
  const methods = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    resolver: yupResolver(
      object().shape({
        certificate_file: yupFileValidation(certificationFileType).nullable(),
      })
    ),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const handleFormSubmit = ({ certificate_file }: FieldValues) => {
    if (!certificate_file) {
      return;
    }
    onFileUploaded(certificate_file);
  };

  return (
    <FormProvider {...methods}>
      <form onChange={handleSubmit(handleFormSubmit)}>
        <FormControl
          id="certificate_file"
          control={control}
          error={errors.certificate_file}
        >
          <FileUploadInput
            id="certificate-file-upload"
            accept={certificationFileType}
            showAcceptFormats
          >
            <Button type="button" size="sm" mt={2} isLoading={isUploadingFile}>
              <Icon as={FaFileUpload} mr={2} /> Upload File
            </Button>
          </FileUploadInput>
        </FormControl>
      </form>
    </FormProvider>
  );
};

export default VerifyCertificateFileUploadForm;
