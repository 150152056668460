import { defaultPagination } from '~/shared/constants/search';
import { PartialEmployeeFilters } from '~/shared/models/search/filters/employees';
import { fetchAndParseQueryParams } from '~/shared/utils/queryUtils';
import queryString from 'query-string';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

interface SearchContextType {
  filters: PartialEmployeeFilters;
  onPageChange: (skip: number) => void;
  onFiltersChange: (updatedFilters: PartialEmployeeFilters) => void;
}

const EmployeeSearchContext = createContext<SearchContextType>({
  filters: {},
  onPageChange: () => undefined,
  onFiltersChange: () => undefined,
});

export const EmployeeSearchProvider = ({ children }: PropsWithChildren) => {
  const [_, setSearchParams] = useSearchParams();
  const parsedQueryParams = fetchAndParseQueryParams();

  const [filters, setFilters] = useState<PartialEmployeeFilters>({
    ...parsedQueryParams,
    ...defaultPagination,
  });

  const onFiltersChange = (updatedFilters: PartialEmployeeFilters) => {
    setFilters({
      ...updatedFilters,
      skip: 0,
    });

    const paramsStr = queryString.stringify(updatedFilters, {
      arrayFormat: 'comma',
    });
    setSearchParams(paramsStr);
  };

  const onPageChange = (skip: number) => {
    if (filters) {
      setFilters({
        ...filters,
        skip,
      });
    }
  };

  const value = useMemo(
    () => ({
      filters,
      onPageChange,
      onFiltersChange,
    }),
    [filters]
  );

  return (
    <EmployeeSearchContext.Provider value={value}>
      {children}
    </EmployeeSearchContext.Provider>
  );
};

export const useEmployeeSearchContext = () =>
  useContext<SearchContextType>(EmployeeSearchContext);
