import { useBoolean, useDisclosure } from '@chakra-ui/react';
import useProfileStore from '~/profile/stores/profile';
import { Status } from '~/shared/enums/status';
import useCertificates from '~/shared/hooks/certificate/useCertificates';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { mapEmptyStringFielsForApi } from '~/shared/mappers/api';
import {
  Certificate,
  CreateCertificate,
  UpdateCertificate,
} from '~/shared/models/api/certificate';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export enum CertificateModalMode {
  CREATE = 'create',
  UPDATE = 'update',
  VIEW = 'view',
}

export interface UseCertificateModalType {
  currentCertificate?: Certificate;
  changeViewMode: (mode: CertificateModalMode) => void;
  isOpen: boolean;
  isUpdating: boolean;
  mode: CertificateModalMode;
  onClose: () => void;
  onSubmit: (upsert: FieldValues) => void;
  openModal?: (certificate?: Certificate | undefined) => void;
  updateStatus: (newStatus: Status) => void;
  deleteCertificate: () => void;
  certificateWasUpdated: boolean;
}

const useCertificateModal = (): UseCertificateModalType => {
  const { myProfile: profile } = useProfileStore();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [mode, setMode] = useState<CertificateModalMode>(
    CertificateModalMode.VIEW
  );
  const {
    isOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const [currentCertificate, setCurrentCertificate] = useState<Certificate>();
  const [certificateWasUpdated, setCertificateWasUpdated] = useBoolean(false);
  const {
    updateCertificate: { mutateAsync: updateCertificate },
    createCertificate: { mutateAsync: createCertificate },
    deleteCertificate: { mutateAsync: deleteCert },
  } = useCertificates();
  const { openConfirmation } = useConfirmationModalContext();
  const { t } = useTranslation('base');

  const openModal = (certificate: Certificate | undefined) => {
    setCertificateWasUpdated.off();
    setMode(
      certificate ? CertificateModalMode.VIEW : CertificateModalMode.CREATE
    );
    setCurrentCertificate(certificate);
    onModalOpen();
  };

  const onSubmit = (upsertFormValues: FieldValues) => {
    if (!profile) {
      return;
    }
    setIsUpdating(true);
    const mapped = mapEmptyStringFielsForApi(upsertFormValues);
    if (mode == CertificateModalMode.CREATE) {
      create({
        ...mapped,
        employee_id: profile.id,
      } as CreateCertificate);
      return;
    }
    update(mapped as UpdateCertificate);
  };

  const updateStatus = (newStatus: Status) => {
    setIsUpdating(true);
    updateCertificate(
      {
        ...(currentCertificate as UpdateCertificate),
        status: newStatus,
      },
      {
        onSuccess: (certificate: Certificate) => {
          onCertificateUpdated(certificate);
          onModalClose();
        },
        onError,
      }
    );
  };

  const update = (updatedFormValues: UpdateCertificate) => {
    updateCertificate(
      {
        ...updatedFormValues,
      },
      {
        onSuccess: (certificate: Certificate) => {
          onCertificateUpdated(certificate);
          setMode(CertificateModalMode.VIEW);
        },
        onError,
      }
    );
  };

  const create = (createFormValues: CreateCertificate) => {
    createCertificate(
      { ...createFormValues, status: Status.APPROVED },
      {
        onSuccess: () => {
          onCertificateUpdated();
          onClose();
        },
        onError,
      }
    );
  };

  const confirmDeleteCertificate = async () => {
    if (!currentCertificate) {
      return;
    }
    const result = await openConfirmation({
      titleText: currentCertificate.name,
      descriptionText: t('delete_confirmation'),
      confirmText: t('delete'),
      isDangerAction: true,
    });
    if (!result) {
      return;
    }
    deleteCertificate();
  };

  const deleteCertificate = () => {
    setIsUpdating(true);
    if (!currentCertificate) {
      return;
    }
    deleteCert(currentCertificate, {
      onSuccess: () => {
        onCertificateUpdated();
        onClose();
      },
      onError,
    });
  };

  const onError = () => setIsUpdating(false);

  const onCertificateUpdated = (certificate?: Certificate) => {
    setIsUpdating(false);
    setCertificateWasUpdated.on();
    if (!certificate) {
      return;
    }
    setCurrentCertificate(certificate);
  };

  const onClose = () => {
    setCurrentCertificate(undefined);
    onModalClose();
  };

  return {
    currentCertificate,
    changeViewMode: setMode,
    isOpen,
    isUpdating,
    mode,
    onClose,
    onSubmit,
    openModal,
    updateStatus,
    deleteCertificate: confirmDeleteCertificate,
    certificateWasUpdated,
  };
};

export default useCertificateModal;
